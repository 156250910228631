import {QUESTION_TYPE} from "./constants";

export function isMobileDevice() {
    return window.matchMedia("(max-width: 600px)").matches;
}

export const QUESTION_CATEGORY = {
    TEXT_TYPE: 0,
    CHOICE_TYPE: 1,
};

export function findQuestionType(questionType) {
    if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE ||
        questionType === QUESTION_TYPE.DROPDOWN_CHOICE ||
        questionType === QUESTION_TYPE.CHECKBOX_CHOICE ||
        questionType === QUESTION_TYPE.STAR_RATING_SCALE) {
        return QUESTION_CATEGORY.CHOICE_TYPE;
    } else if (questionType === QUESTION_TYPE.SHORT_TEXT ||
        questionType === QUESTION_TYPE.LONG_TEXT ||
        questionType === QUESTION_TYPE.DATE ||
        questionType === QUESTION_TYPE.TIME ||
        questionType === QUESTION_TYPE.NUMBER ||
        questionType === QUESTION_TYPE.EMAIL) {
        return QUESTION_CATEGORY.TEXT_TYPE;
    }
}

export function getTimeByFormat(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let AMPMString = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + AMPMString;
}
