import React, {useState} from 'react'
import '../css/switch.css'
import Loader from "../../../shared/components/Loader";
import {changeFormStatus} from "../../../shared/utils/sash-v2-api";
import {Pages} from "../../utils/pages";
import {QUIZ_BASE_URL, updateQuizStatusService} from "../../../shared/utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import formStatus from '../../../../public/images/form_status.svg';
import shareBlue from '../../../../public/images/share_blue.svg';
import viewResponsesBlue from '../../../../public/images/view_responses_blue.svg';
// import settingsBlue2 from '../../../../public/images/settings_blue_2.svg';
import editBlue from '../../../../public/images/edit_blue.svg';
import viewFormIcon from '../../../../public/images/view_form_icon.png';
import duplicateFormBlue from '../../../../public/images/duplicate_form_blue.svg';
import delete_Icon from '../../../../public/images/delete.svg';
import leaveCollab from '../../../../public/images/Leave_collab.svg'
import peoples from "../../../../public/images/collaborate.svg";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {SHARED_ACTION_TYPE} from "../../reducers/sharedReducer";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";


const FormControlContainer = (props) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const [isFormActive, setIsFormActive] = useState(props.formData.setting.is_active);
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    const updateStatusInServer = (isActive) => {
        setIsLoading(true);
        if (props.formData.is_quiz) {
            updateQuizStatusService(props.formData._id, isActive, res => {
                if (res.result) {
                    props.updateStatus(props.formData._id, isActive);
                    setIsLoading(false);
                    setIsFormActive(isActive);
                }
            }, err => console.log(err))
        } else {
            changeFormStatus(props.formData._id, isActive,
                res => {
                    if (res.result) {
                        props.updateStatus(props.formData._id, isActive);
                        setIsLoading(false);
                        setIsFormActive(isActive);
                    }
                },
                err => console.log(err)
            )
        }
    }
    const handleViewResponsesClick = (e) => {
        e.stopPropagation();
        if (formData.response_count && formData.response_count > 0) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: props.formData.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
        }
    }
    const handleEditClick = (e) => {
        if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
        } else if (formData.response_count > 0) {
            props.setShowFormControl(false);
            props.showDataEditWarning(true, formData);
        } else {
            dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
            dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: props.formData.theme})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
            dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: props.formData.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
        }
        e.stopPropagation();
    }
    const handleDuplicateClick = (e) => {
        e.stopPropagation();
        if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DUPLICATE_PREMIUM_POP_UP, payload: true})
            return
        }
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.DUPLICATE})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: props.formData.theme})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: props.formData.is_quiz})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
    }
    const showDeletePopUp = () => {
        if (showShared) {
            dispatch({type: SHARED_ACTION_TYPE.SET_DELETABLE_FORQ_DATA, payload: formData})
            dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
        } else {
            if (!formData.is_quiz) {
                dispatch({type: FORMS_ACTION_TYPE.SET_DELETABLE_FORM_ID, payload: formData._id})
                dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
            } else {
                dispatch({type: QUIZZES_ACTION_TYPE.SET_DELETABLE_QUIZ_ID, payload: formData._id})
                dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
            }
        }
    }
    const formData = props.formData;
    return (
        <div id={'dashboard-form-control-drop-down-card'}
             style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>
            {/*Form status*/}
            {!showShared ?
                <div id={'form-status-' + props.cardIndex} className="dashboard-form-control-drop-down-card"
                     style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                    <img
                        className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                        style={isMobileOnly ? {width: '24px'} : null}
                        src={formStatus}
                        alt="form status"/>
                    <p id={'form-quiz-status'} style={{
                        width: '150px',
                        textAlign: "start",
                        whiteSpace: 'nowrap'
                    }}>{formData.is_quiz ? t(translationKeys.quiz_status) : t(translationKeys.form_status)}</p>
                    <span id={'form-status-text'} className='form-status-text' style={isFormActive ? {color: 'green'}
                        : {color: 'red'}}>{isFormActive ? t(translationKeys.active).toUpperCase() : t(translationKeys.inactive).toUpperCase()}</span>
                    <img style={{width: 'auto'}} alt='status-switch'
                         src={isFormActive ? '../../../images/status_blue.svg' : '../../../images/status_grey.svg'}
                         onClick={e => {
                             e.stopPropagation();
                             isFormActive ? updateStatusInServer(false)
                                 : updateStatusInServer(true);
                         }}/>
                </div> : showShared && props.showEdit ?
                    <div id={'form-status-' + props.cardIndex} className="dashboard-form-control-drop-down-card"
                         style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            style={isMobileOnly ? {width: '24px'} : null}
                            src={formStatus}
                            alt="form status"/>
                        <p style={{width: '100px'}}>{formData.is_quiz ? t(translationKeys.quiz_status) : t(translationKeys.form_status)}</p>
                        <span className='form-status-text' style={isFormActive ? {color: 'green'}
                            : {color: 'red'}}>{isFormActive ? t(translationKeys.active).toUpperCase() : t(translationKeys.inactive).toUpperCase()}</span>
                        <img style={{width: 'auto'}} alt='status-switch'
                             src={isFormActive ? '../../../images/status_blue.svg' : '../../../images/status_grey.svg'}
                             onClick={e => {
                                 e.stopPropagation();
                                 isFormActive ? updateStatusInServer(false)
                                     : updateStatusInServer(true);
                             }}/>
                    </div> : null}

            {/*Form share*/}
            <div id={'dashboard-form-share' + props.cardIndex}
                 style={showShared && !props.showEdit ? {
                     borderTopLeftRadius: '8px',
                     borderTopRightRadius: '8px'
                 } : null}
                 className="dashboard-form-control-drop-down-card" onClick={(e) => {
                e.stopPropagation();
                props.setShowFormControl(false);
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: props.formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_SHOW_SHARE_POP_UP, payload: true})
            }}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={isMobileOnly ? {width: '24px'} : null}
                    src={shareBlue}
                    alt="share"/>
                <p>{t(translationKeys.share)}</p>
            </div>
            {/*view Responses*/}
            <div id={'dashboard-form-view-responses' + props.cardIndex}
                 className="dashboard-form-control-drop-down-card"
                 onClick={handleViewResponsesClick}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={isMobileOnly ? {width: '24px'} : null}
                    src={viewResponsesBlue}
                    alt="view responses"/>
                <p id={'dashboard-view-responses'}>{formData.is_quiz ? t(translationKeys.view_answers) : t(translationKeys.view_responses)}</p>
                <div id={'dashboard-view-responses-count'} className='form-control-response-count'>
                    {formData.response_count ? formData.response_count : 0}
                </div>
            </div>
            {/*/!*Quiz Quick Settings*!/*/}
            {/*{formData.is_quiz && getRole(formData.collaborators, userId) !== COLLABORATOR_ROLES.VIEWER ?*/}
            {/*    <div id={'dashboard-quiz-settings'} className="dashboard-form-control-drop-down-card"*/}
            {/*         onClick={(e) => {*/}
            {/*             props.setShowFormControl(false);*/}
            {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_QUIZ_ID, payload: formData._id})*/}
            {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_QUIZ_QUICK_SETTING, payload: formData.setting})*/}
            {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_QUIZ_QUICK_SETTINGS, payload: true})*/}
            {/*             e.stopPropagation();*/}
            {/*         }}>*/}
            {/*        <img*/}
            {/*            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}*/}
            {/*            style={isMobileOnly ? {width: '24px'} : null}*/}
            {/*            src={settingsBlue2}*/}
            {/*            alt="settings"/>*/}
            {/*        <p>{t(translationKeys.quick_settings)}</p>*/}
            {/*    </div>*/}
            {/*    : null}*/}
            {/*Edit*/}
            {!showShared ?
                <div id={'dashboard-form-edit' + props.cardIndex} className="dashboard-form-control-drop-down-card"
                     onClick={(e) => handleEditClick(e)}>
                    <img
                        className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                        style={isMobileOnly ? {width: '24px'} : null}
                        src={editBlue}
                        alt="edit"/>
                    <p>{formData.is_quiz ? t(translationKeys.edit) + ' ' + t(translationKeys.quiz) : t(translationKeys.edit_form)}</p>
                </div> : showShared && props.showEdit ?
                    <div id={'dashboard-form-edit' + props.cardIndex} className="dashboard-form-control-drop-down-card"
                         onClick={(e) => handleEditClick(e)}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            style={isMobileOnly ? {width: '24px'} : null}
                            src={editBlue}
                            alt="edit"/>
                        <p>{formData.is_quiz ? t(translationKeys.edit) + ' ' + t(translationKeys.quiz) : t(translationKeys.edit_form)}</p>
                    </div> : null}
            {/*Collaborate*/}
            <div id="form-control-collaborate" className="dashboard-form-control-drop-down-card"
                 onClick={(e) => {
                     e.stopPropagation();
                     registerGAEvent('Form-Dashboard', 'Collaboration', 'clicked-collaboration');
                     props.setShowFormControl(false);
                     dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
                     dispatch({
                         type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                         payload: BUILDER_CREATION_SOURCE.SAVED
                     })
                     dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: props.formData.is_quiz})
                     dispatch({type: APP_ACTION_TYPE.SET_SHOW_COLLABORATE_POP_UP, payload: true})
                 }}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    src={peoples}
                    alt="edit"/>
                <p>{t(translationKeys.collaboration)}</p>
            </div>
            {/*Favourite*/}
            {showShared ? null : <div id={'dashboard-form-add-favourite' + props.cardIndex}
                                      className="dashboard-form-control-drop-down-card"
                                      onClick={(e) => {
                                          props.favouriteClicked(formData._id, formData.date_favoured);
                                          e.stopPropagation();
                                      }}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={isMobileOnly ? {width: '24px'} : null}
                    src={formData.date_favoured ? "images/favourite_hollow_blue.svg" : "images/favourite_blue.svg"}
                    alt="Favourite"/>
                <p>{formData.date_favoured ? t(translationKeys.remove_favourite) : t(translationKeys.add_favourite)}</p>
            </div>}
            {/*View*/}
            <div id={'dashboard-form-view' + props.cardIndex} className="dashboard-form-control-drop-down-card"
                 onClick={(e) => {
                     e.stopPropagation();
                     formData.is_quiz ? window.open(QUIZ_BASE_URL + '/id/' + formData._id) :
                         window.open("/form/" + formData._id)
                 }}
            >
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={isMobileOnly ? {width: '24px'} : null}
                    src={viewFormIcon}
                    alt="view form"/>
                <p>{formData.is_quiz ? t(translationKeys.view_quiz) : t(translationKeys.view_form)}</p>
            </div>
            {/*Duplicate*/}
            <div id={'dashboard-form-duplicate' + props.cardIndex}
                 className="dashboard-form-control-drop-down-card"
                 onClick={handleDuplicateClick}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={isMobileOnly ? {width: '24px'} : null}
                    src={duplicateFormBlue}
                    alt="duplicate form"/>
                <p>{formData.is_quiz ? t(translationKeys.duplicate_quiz) : t(translationKeys.duplicate_form)}</p>
            </div>
            {/*Delete*/}
            <div id={"form-control-delete" + props.cardIndex} className="dashboard-form-control-drop-down-card"
                 style={{borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'}}
                 onClick={(e) => {
                     e.stopPropagation();
                     showShared ? registerGAEvent('Form-Dashboard', 'Collaboration', 'clicked-leave-collaboration') : null
                     props.setShowFormControl(false);
                     showDeletePopUp();
                 }}>
                <img
                    className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                    style={{width: isMobileOnly ? '24px' : null}}
                    src={!showShared ? delete_Icon : leaveCollab}
                    alt="delete"/>
                {!showShared ?
                    <p>{formData.is_quiz ? t(translationKeys.delete) + ' ' + t(translationKeys.quiz) : t(translationKeys.delete_form)}</p> :
                    <p>{t(translationKeys.leave_collaboration)}</p>}
            </div>
            {isLoading ? <Loader/> : null}
        </div>
    )
}


export default FormControlContainer;
