// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1yhidhkQ_puDNmIcUxdLQQ {\n    width: 100%;\n    overflow: auto;\n}\n\n._1yhidhkQ_puDNmIcUxdLQQ::-webkit-scrollbar {\n    width: 4px;\n    height: 4px;\n}\n\n._1yhidhkQ_puDNmIcUxdLQQ::-webkit-scrollbar-thumb {\n    background: #e0e0e0;\n}\n\n._1yhidhkQ_puDNmIcUxdLQQ::-webkit-scrollbar-track {\n    background: #eeeeee;\n}\n\ntable {\n    width: 100%;\n    max-height:  calc(100% - 110px);\n    border-spacing: 0;\n    border-collapse: collapse;\n}\n\nth, td {\n    font-family: \"Nunito Sans\", Sans-serif, serif;\n    padding: 15px;\n    font-size: 16px;\n    border: 0;\n    margin: 0;\n    border-collapse: collapse;\n}\n\nthead tr:hover {\n    cursor: default;\n    background-color: transparent;\n}\n\nth {\n    color: #004BA0;\n    text-align: left;\n    border-bottom: 1px solid #d0d0d0;\n}\n\ntbody {\n    overflow-y: scroll;\n    max-height: 85%;\n}\n\ntr:hover {\n    cursor: pointer;\n    background-color: #f5f5f5;\n}\n\ntd img {\n    width: 16px;\n}\n\n._1yTYi1Ovu4pdGOOUFVslNO {\n    width: 100%;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n}\n\n@media screen and (max-width: 1500px) {\n    th, td {\n        font-size: 10px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"table_holder": "_1yhidhkQ_puDNmIcUxdLQQ",
	"pagination_holder": "_1yTYi1Ovu4pdGOOUFVslNO"
};
module.exports = exports;
