import React from 'react'

const CurrentPageInfo = props => {
    const current_data_status = {
        position: 'absolute',
        right: '10px',
        top: '16px',
        display: 'flex',
        zIndex: 2
    }
    const current_data_status_span = {
        fontSize: '12px',
        color: '#212121'
    }

    //** First response s.no in current page **
    const firstResponseNumber = props.reverse && props.totalDataCount && props.perPageLimit ? props.totalDataCount - (props.perPageLimit * props.currentPageIndex)
        : props.totalDataCount && props.perPageLimit ? ((props.currentPageIndex * props.perPageLimit) + 1) : 0

    //** last response s.no in current page **
    const lastResponseNumber = props.reverse && props.totalDataCount && props.perPageLimit ? props.totalDataCount - (props.perPageLimit * props.currentPageIndex) - props.perPageLimit + 1
        : props.totalDataCount && props.perPageLimit ? ((props.currentPageIndex + 1) * props.perPageLimit) <= props.totalDataCount ?
        ((props.currentPageIndex + 1) * props.perPageLimit) : props.totalDataCount : 0


    return (
        <div id={"current-page-info"} style={current_data_status}>
            <span style={current_data_status_span}>
                {firstResponseNumber + ' - ' + lastResponseNumber + ' of ' + (props.totalDataCount ? props.totalDataCount : 0)}
            </span>
        </div>
    )
}

export default CurrentPageInfo