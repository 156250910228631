import React, {useState} from 'react'
import {Pages} from "../../utils/pages";
import {QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {handlePreviouslyOpenedSortCard} from "./AccInfoContainer";
import ModalDialog from "../../../shared/components/ModalDialog";
import leaveCollabBlue from "../../../../public/images/Leave_collab.svg";
import leaveCollabWhite from "../../../../public/images/Leave_collab_white.svg";
import editWhite from "../../../../public/images/edit_white.svg"
import editBlue from "../../../../public/images/edit_blue.svg"
import shareBlue from "../../../../public/images/share_dark_blue.svg"
import shareWhite from "../../../../public/images/share_icon_white.svg"
import previewBlue from "../../../../public/images/view_form_icon.svg"
import previewWhite from "../../../../public/images/preview_icon_white.svg"
import viewResponsesBlue from "../../../../public/images/view_responses_blue.svg"
import viewResponsesWhite from "../../../../public/images/view_responses_icon_white.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {SHARED_ACTION_TYPE} from "../../reducers/sharedReducer";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {PremiumJSON} from "../../../shared/utils/helper";

function HoverControls(props) {

    const formData = props.formData;
    const [showViewerPopUp, setShowViewerPopUp] = useState(false)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    const handleFormDelete = () => {
        if (showShared) {
            dispatch({type: SHARED_ACTION_TYPE.SET_DELETABLE_FORQ_DATA, payload: formData})
            dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
        } else {
            if (!formData.is_quiz) {
                dispatch({type: FORMS_ACTION_TYPE.SET_DELETABLE_FORM_ID, payload: formData._id})
                dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
            } else {
                dispatch({type: QUIZZES_ACTION_TYPE.SET_DELETABLE_QUIZ_ID, payload: formData._id})
                dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
            }
        }
    }
    const handleResponsesIconClick = e => {
        if (formData.response_count && formData.response_count > 0) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
            dispatch({type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET, payload: null})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: null})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
        }
        e.stopPropagation();
    };
    const handleCardClick = () => {
        if (showShared) {
            props.deleteNotificationHandler()
            if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
                return
            }
            if (formData.response_count === 0 && props.showEdit) {
                dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
                dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
            } else if (formData.response_count > 0) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET, payload: null})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: null})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
            } else if (formData.response_count === 0) {
                setShowViewerPopUp(true)
            }
        } else {
            if (formData.response_count > 0) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET, payload: null})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: null})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
            } else {
                if (formData.response_count === 0) {
                    if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
                        return
                    }
                    dispatch({
                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                        payload: BUILDER_CREATION_SOURCE.SAVED
                    })
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                }
            }
        }
    }
    const handleEditClick = (e) => {
        e.stopPropagation()
        if (showShared) {
            props.deleteNotificationHandler()
            if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
                return
            }
        }
        if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
        } else if (formData.response_count > 0) {
            props.showDataEditWarning(true, formData)
        } else {
            dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
            dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
        }
    }
    if (!showShared) {
        return (
            <div id={'hover-controls-container'} className="hover-controls-container"
                 style={props.listView ? {
                     display: "block",
                     background: "#FFFFFF",
                     height: "auto",
                     alignSelf: "center",
                     width: "20%"
                 } : null}
                 onClick={handleCardClick}>
                <div id={'hover-bottom-icons-container'} className="hover-bottom-icons-container">
                    {/*view Responses*/}
                    {props.listView ? null :
                        <div id={'hover-view-response-' + props.cardIndex}
                             className='hover-responses-icon-container'>
                            <img className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? viewResponsesBlue : viewResponsesWhite}
                                 onClick={(e) => handleResponsesIconClick(e)}
                                 alt="View Responses"/>
                            <div className='hover-response-count-badge' onClick={(e) => handleResponsesIconClick(e)}>
                                {formData?.response_count ? formData.response_count : 0}</div>
                        </div>}
                    {props.listView ?
                        <div className={props.listView ? "image-icon" : null}>
                            <img className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? viewResponsesBlue : viewResponsesWhite}
                                 onClick={(e) => handleResponsesIconClick(e)}
                                 alt="View Responses"/>
                        </div> : null}
                    {/*Edit Form*/}
                    <div className={props.listView ? "image-icon" : null}>
                        <img id={'hover-edit-' + props.cardIndex}
                             className={props.listView ? 'list-hover-image' : 'hover-image'}
                             src={props.listView ? editBlue : editWhite}
                             onClick={(e) => handleEditClick(e)}
                             alt="Edit"/>
                    </div>
                    {/*View Form*/}
                    <div className={props.listView ? "image-icon" : null}>
                        <img id={'hover-preview-' + props.cardIndex}
                             className={props.listView ? 'list-hover-image' : 'hover-image'}
                             src={props.listView ? previewBlue : previewWhite}
                             onClick={e => {
                                 formData.is_quiz ? window.open(QUIZ_BASE_URL + '/id/' + formData._id) : window.open("/form/" + formData._id);
                                 e.stopPropagation();
                             }
                             }
                             alt="Preview"/>
                    </div>
                    {/*Share Form*/}
                    <div className={props.listView ? "image-icon" : null}>
                        <img id={'hover-share' + props.cardIndex}
                             className={props.listView ? 'list-hover-image' : 'hover-image'}
                             src={props.listView ? shareBlue : shareWhite}
                             onClick={e => {
                                 e.stopPropagation();
                                 props.previouslyOpenedDropDownRef.current?.element ? handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current) : null
                                 props.previouslyOpenedDropDownRef.current = null
                                 dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                                 dispatch({type: APP_ACTION_TYPE.SET_SHOW_SHARE_POP_UP, payload: true})
                             }}
                             alt="Share"/>
                    </div>
                    {/*Favorite*/}
                    {props.listView ? null :
                        <img id={'hover-share' + props.cardIndex}
                             className={props.listView ? 'list-hover-image' : 'hover-image'}
                             src={formData.date_favoured ? "images/favourite_blue.svg" : "images/favourite_white.svg"}
                             onClick={e => {
                                 e.stopPropagation();
                                 props.favouriteClicked(formData._id, formData.date_favoured);
                             }}
                             alt="favorite"
                        />}
                    {!props.showMultipleDeleteOption ?
                        <div
                            className={props.listView ? 'multiple-select-tag-for-delete-in-hover-list-view' : 'multiple-select-tag-for-delete-in-hover'}
                            onClick={props.handleMultipleFormSelect}/>
                        : null}
                </div>
            </div>
        )
    } else {
        return (
            <>
                {showViewerPopUp ? <ModalDialog
                    header={(props.formData.is_quiz ? t(translationKeys.quiz_builder_access_denied) : t(translationKeys.form_builder_access_denied))}
                    body={[(props.formData.is_quiz ? t(translationKeys.you_only_have_viewer_rights_for_quiz) : t(translationKeys.you_only_have_viewer_rights_for_form)) + ' ' + (props.formData.is_quiz ? t(translationKeys.kindly_contact_the_quiz_owner_for_more_info) : t(translationKeys.kindly_contact_the_form_owner_for_more_info))]}
                    buttons={[{
                        text: t(translationKeys.ok),
                        action: () => {
                            setShowViewerPopUp(false)
                        }
                    }]}/> : null}
                <div id={'hover-controls-container'} className="hover-controls-container"
                     style={props.listView ? {
                         display: "block",
                         background: "#FFFFFF",
                         height: "auto",
                         alignSelf: "center",
                         width: "25%"
                     } : null}
                     onClick={handleCardClick}>
                    <div id={'hover-bottom-icons-container'} className="hover-bottom-icons-container">
                        {/*view Responses*/}
                        {props.listView ? null : <div id={'hover-view-response-' + props.cardIndex}
                                                      className='hover-responses-icon-container'>
                            <img className='hover-image' src="images/view_responses_icon_white.svg"
                                 onClick={(e) => {
                                     props.deleteNotificationHandler()
                                     handleResponsesIconClick(e)
                                 }}
                                 alt="View Responses"/>
                            <div className='hover-response-count-badge' onClick={(e) => handleResponsesIconClick(e)}>
                                {formData?.response_count ? formData.response_count : 0}</div>
                        </div>}
                        {props.listView ?
                            <div className={props.listView ? "image-icon" : null}>
                                <img className={props.listView ? 'list-hover-image' : 'hover-image'}
                                     src={props.listView ? viewResponsesBlue : viewResponsesWhite}
                                     onClick={(e) => handleResponsesIconClick(e)}
                                     alt="View Responses"/>
                            </div> : null}
                        {/*Edit Form*/}
                        {props.showEdit ? <div className={props.listView ? "image-icon" : null}>
                            <img id={'hover-edit-' + props.cardIndex}
                                 className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? editBlue : editWhite}
                                 onClick={(e) => handleEditClick(e)}
                                 alt="Edit"/>
                        </div> : null}
                        {/*View Form*/}
                        <div className={props.listView ? "image-icon" : null}>
                            <img id={'hover-preview-' + props.cardIndex}
                                 className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? previewBlue : previewWhite}
                                 onClick={e => {
                                     props.deleteNotificationHandler()
                                     formData.is_quiz ? window.open(QUIZ_BASE_URL + '/id/' + formData._id) : window.open("/form/" + formData._id);
                                     e.stopPropagation();
                                 }}
                                 alt="Preview"/>
                        </div>
                        {/*Share Form*/}
                        <div className={props.listView ? "image-icon" : null}>
                            <img id={'hover-share' + props.cardIndex}
                                 className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? shareBlue : shareWhite}
                                 onClick={e => {
                                     e.stopPropagation();
                                     props.deleteNotificationHandler()
                                     props.previouslyOpenedDropDownRef.current?.element ? handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current) : null
                                     props.previouslyOpenedDropDownRef.current = null
                                     dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                                     dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                                     dispatch({type: APP_ACTION_TYPE.SET_SHOW_SHARE_POP_UP, payload: true})
                                 }}
                                 alt="Share"/>
                        </div>
                        {/*Delete Form*/}
                        {!props.showEdit || props.listView ? <div className={props.listView ? "image-icon" : null}>
                            <img id={'hover-delete-' + props.cardIndex}
                                 className={props.listView ? 'list-hover-image' : 'hover-image'}
                                 src={props.listView ? leaveCollabBlue : leaveCollabWhite}
                                 onClick={e => {
                                     props.deleteNotificationHandler()
                                     handleFormDelete();
                                     e.stopPropagation();
                                 }}
                                 alt="Delete"/>
                        </div> : null}
                    </div>
                </div>
            </>
        )
    }
}

export default HoverControls
