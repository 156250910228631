import React from "react"
import * as DOMPurify from 'dompurify';

export function isValidEmailTextPattern(emailText) {
    const check = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return check.test(emailText);
}

export function isValidPasswordPattern(passwordText) {
    return /.{8,}/.test(passwordText)
        && /\d/.test(passwordText)
        && /[A-Z]/.test(passwordText)
        && /[@$!%*?^&#]/.test(passwordText)
}

export function validateCollaborator(ownerEmailAddress, newCollaboratorEmailAddress, existingCollaborators) {
    if (isValidEmailTextPattern(newCollaboratorEmailAddress)) {
        if (ownerEmailAddress === newCollaboratorEmailAddress) {
            return false
        } else return newCollaboratorEmailAddress !== existingCollaborators;
    } else return false
}

export function detectHyperlinkText(str) {
    const sanitizedStr = DOMPurify.sanitize(str);
    const regex = /\b(?:https?:\/\/\S+|[-+() ]*\d{10,13}|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)\b/g;

    const parts = sanitizedStr.split(regex);
    const matches = sanitizedStr.match(regex);

    return parts.reduce((acc, part, index) => {
        acc.push(<span key={`text-${index}`}>{part}</span>);
        if (matches && matches[index]) {
            const match = matches[index];
            if (match.match(/\bhttps?:\/\/\S+/gi)) {
                acc.push(<a key={`url-${index}`} href={match} target="_blank" rel="noopener noreferrer">{match}</a>);
            } else if (match.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)) {
                acc.push(<a key={`email-${index}`} href={`mailto:${match}`}>{match}</a>);
            } else if (match.match(/(?:[-+() ]*\d){10,13}/g)) {
                acc.push(<a key={`phone-${index}`} href={`tel:${match}`}>{match}</a>);
            }
        }
        return acc;
    }, []);
}
