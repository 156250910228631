export const translationsPunjabi = {
    all: 'ਸਭ',
    ago: "ਪਹਿਲਾਂ",
    now: "ਹੁਣ",
    start: "ਸ਼ੁਰੂ ਕਰੋ",
    your_account: "ਤੁਹਾਡਾ ਖਾਤਾ",
    account_blocked: "ਖਾਤਾ ਬਲੌਕ ਕੀਤਾ ਗਿਆ",
    account_blocked_alert: "ਇਸ ਖਾਤੇ ਨੂੰ ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਦੀ ਉਲੰਘਣਾ ਕਰਨ ਕਰਕੇ ਬਲੌਕ ਕੀਤਾ ਗਿਆ ਹੈ।",
    sign_out: "ਸਾਇਨ ਆਉਟ",
    short_answer: "ਛੋਟਾ ਜਵਾਬ",
    long_answer: "ਲੰਮਾ ਜਵਾਬ",
    email: "ਈ - ਮੇਲ",
    number: "ਗਿਣਤੀ",
    app_name: "SurveyHeart",
    file: "ਫਾਈਲ",
    files: "ਫਾਈਲਾਂ",
    faq_videos: "ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਵੀਡੀਓ",
    file_upload: "ਫਾਈਲ ਅਪਲੋਡ ਕਰੋ",
    question_error: "ਸਵਾਲ ਸ਼ਾਮਲ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ",
    storage: "ਸਟੋਰੇਜ",
    form: "ਫਾਰਮ",
    quiz: "ਕਵਿਜ਼",
    fup_desc: "ਤੁਸੀਂ ਹੁਣ ਜਵਾਬ ਦੇਣ ਵਾਲਿਆਂ ਨੂੰ ਆਪਣੇ ਫਾਰਮਾਂ ਅਤੇ ਕਵਿਜ਼ਾਂ ਨੂੰ ਫ਼ਾਈਲਾਂ ਅੱਪਲੋਡ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਦੇ ਸਕਦੇ ਹੋ!\n\nਸ਼ੁਰੂ ਕਰਨ ਲਈ ਫ਼ਾਈਲ ਅੱਪਲੋਡ ਪ੍ਰਸ਼ਨ ਕਿਸਮ ਦੀ ਚੋਣ ਕਰੋ।",
    new_fup_feature_title: "ਨਵੀਂ ਫਾਈਲ ਅਪਲੋਡ ਵਿਸ਼ੇਸ਼ਤਾ!",
    multiple_choice: "ਬਹੁ - ਚੋਣ",
    date_text: "ਤਾਰੀਖ਼",
    time_text: "ਸਮਾਂ",
    uploading: "ਅੱਪਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    add_question: "ਸਵਾਲ ਸ਼ਾਮਲ ਕਰੋ",
    add_question_alert: "ਕਿਰਪਾ ਕਰਕੇ ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਸਵਾਲ ਸ਼ਾਮਲ ਕਰੋ।",
    add_question_alert_quiz: "ਕਿਰਪਾ ਕਰਕੇ ਕਵਿਜ਼ ਜਮ੍ਹਾਂ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਸਵਾਲ ਸ਼ਾਮਲ ਕਰੋ।",
    ok: "ਠੀਕ ਹੈ",
    title: "ਸਿਰਲੇਖ",
    title_required: "ਸਿਰਲੇਖ ਲੋੜੀਂਦਾ ਹੈ",
    form_name_alert: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਨੂੰ ਜਮ੍ਹਾ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਫਾਰਮ ਦਾ ਨਾਮ ਦਿਓ",
    quiz_name_alert: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਨੂੰ ਸਪੁਰਦ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਕਵਿਜ਼ ਦਾ ਨਾਮ ਦਿਓ",
    no_connection: "ਕੋਈ ਕਨੈਕਸ਼ਨ ਨਹੀਂ",
    check_connection: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਨੈੱਟਵਰਕ ਕਨੈਕਸ਼ਨ ਦੀ ਜਾਂਚ ਕਰੋ।",
    finish_incomplete_question: "ਕਿਰਪਾ ਕਰਕੇ ਫਾਰਮ ਵਿੱਚ ਅਧੂਰੇ ਪ੍ਰਸ਼ਨਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ",
    draft: "ਡਰਾਫਟ",
    save_to_draft_alert: "ਕੀ ਤੁਸੀਂ ਆਪਣੀਆਂ ਤਬਦੀਲੀਆਂ ਨੂੰ ਗੁਆਉਣ ਤੋਂ ਬਚਣ ਲਈ ਇਸ ਫਾਰਮ ਨੂੰ ਡਰਾਫਟ ਵਜੋਂ ਸੁਰੱਖਿਅਤ ਕਰਨਾ ਚਾਹੋਗੇ?",
    save_to_draft_quiz_alert: "ਕੀ ਤੁਸੀਂ ਆਪਣੀਆਂ ਤਬਦੀਲੀਆਂ ਨੂੰ ਗੁਆਉਣ ਤੋਂ ਬਚਣ ਲਈ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਡਰਾਫਟ ਵਜੋਂ ਸੁਰੱਖਿਅਤ ਕਰਨਾ ਚਾਹੋਗੇ?",
    yes: "ਹਾਂ",
    no: "ਨੰ",
    cancel: "ਰੱਦ ਕਰੋ",
    live_preview: "ਲਾਈਵ ਪ੍ਰੀਵਿਊ",
    themes: "ਥੀਮ",
    tap_to_set_theme: "ਫਾਰਮ ਲਈ ਥੀਮ ਸੈੱਟ ਕਰਨ ਲਈ ਇੱਥੇ ਟੈਪ ਕਰੋ।",
    select_image: "ਚਿੱਤਰ ਚੁਣੋ",
    created: "ਬਣਾਇਆ",
    form_created_successfully: "ਫਾਰਮ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਇਆ ਗਿਆ",
    warning: "ਚੇਤਾਵਨੀ",
    favourites: "ਮਨਪਸੰਦ",
    feature_spotlight: 'ਵਿਸ਼ੇਸ਼ਤਾ ਸਪੌਟਲਾਈਟ',
    form_edit_alert: "ਇਹ ਫਾਰਮ ਪਹਿਲਾਂ ਹੀ ਸਪੁਰਦ ਕੀਤਾ ਜਾ ਚੁੱਕਾ ਹੈ ਅਤੇ ਜਵਾਬ ਇਕੱਠੇ ਕੀਤੇ ਜਾ ਚੁੱਕੇ ਹਨ। ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨਾ ਪਿਛਲੇ ਜਵਾਬਾਂ ਨੂੰ ਪ੍ਰਭਾਵਿਤ ਕਰ ਸਕਦਾ ਹੈ।",
    quiz_edit_alert: 'ਇਹ ਕਵਿਜ਼ ਪਹਿਲਾਂ ਹੀ ਜਮ੍ਹਾਂ ਹੋ ਚੁੱਕੀ ਹੈ ਅਤੇ ਜਵਾਬ ਇਕੱਠੇ ਕੀਤੇ ਜਾ ਚੁੱਕੇ ਹਨ। ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨਾ ਪਿਛਲੇ ਜਵਾਬਾਂ ਨੂੰ ਪ੍ਰਭਾਵਿਤ ਕਰ ਸਕਦਾ ਹੈ',
    edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
    download_as: "ਇਸ ਤਰ੍ਹਾਂ ਡਾਊਨਲੋਡ ਕਰੋ",
    delete_form: "ਫਾਰਮ ਮਿਟਾਓ",
    delete_form_alert: "ਕੀ ਤੁਸੀਂ ਇਸ ਫਾਰਮ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    delete_quizzes_alert: "ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਕਵਿਜ਼ਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    delete_forms_alert: "ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਫਾਰਮਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    delete_quiz_alert: "ਕੀ ਤੁਸੀਂ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    delete_answers_alert: "ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    delete_answer_alert: "ਕੀ ਤੁਸੀਂ ਇਸ ਜਵਾਬ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    delete: "ਮਿਟਾਓ",
    delete_all: "ਸਭ ਨੂੰ ਮਿਟਾਓ",
    section: "ਅਨੁਭਾਗ",
    worst: "ਸਭ ਤੋਂ ਭੈੜਾ",
    server_error_alert: "ਸਰਵਰ ਲੱਭਿਆ ਨਹੀਂ ਜਾ ਸਕਿਆ। ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ !!",
    signed_in_as: "ਵਜੋਂ ਸਾਈਨ ਇਨ ਕੀਤਾ",
    signing_out: "ਸਾਈਨ ਆਊਟ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    create_form: "ਫਾਰਮ ਬਣਾਓ",
    create_blank_form: "ਖਾਲੀ ਫਾਰਮ ਬਣਾਓ",
    create_blank_quiz: "ਖਾਲੀ ਕਵਿਜ਼ ਬਣਾਓ",
    close: "ਬੰਦ ਕਰੋ",
    response_collection_exit_alert: "ਕੀ ਤੁਸੀਂ ਜਵਾਬ ਇਕੱਠਾ ਕਰਨ ਤੋਂ ਬਾਹਰ ਜਾਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    form_builder: "ਫਾਰਮ ਬਿਲਡਰ",
    form_builder_promo: "ਫਾਰਮਾਂ ਅਤੇ ਕਵਿਜ਼ਾਂ ਲਈ ਤਤਕਾਲ ਝਲਕ, ਨਮੂਨੇ ਅਤੇ ਥੀਮ",
    results: "ਨਤੀਜੇ",
    result_feature_promo: "ਆਪਣੇ ਸਰਵੇਖਣ ਨਤੀਜਿਆਂ ਦਾ ਵਿਸ਼ਲੇਸ਼ਣ ਅਤੇ ਨਿਰਯਾਤ ਕਰੋ",
    notification: "ਤੁਰੰਤ ਸੂਚਨਾਵਾਂ",
    delete_option_alert: "ਕੀ ਤੁਸੀਂ ਇਸ ਵਿਕਲਪ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? [ਨੋਟ: ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ]",
    question_type: "ਸਵਾਲ ਦੀ ਕਿਸਮ",
    change_question_type_alert: "ਸਵਾਲ ਦੀ ਕਿਸਮ ਬਦਲਣ ਲਈ ਇੱਥੇ ਟੈਪ ਕਰੋ",
    preview_not_available: "ਪੂਰਵਦਰਸ਼ਨ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
    delete_question: "ਸਵਾਲ ਮਿਟਾਓ",
    delete_response_collected_question_alert: "ਇਸ ਸਵਾਲ ਨੂੰ ਮਿਟਾਉਣ ਨਾਲ ਪਿਛਲੇ ਇਕੱਠੇ ਕੀਤੇ ਜਵਾਬਾਂ 'ਤੇ ਅਸਰ ਪਵੇਗਾ।\n\nਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਹੋ, ਤੁਸੀਂ ਅੱਗੇ ਵਧਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    delete_question_alert: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਸਵਾਲ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    add_option: 'ਵਿਕਲਪ ਸ਼ਾਮਲ ਕਰੋ',
    add_other_option: "'ਹੋਰ' ਵਿਕਲਪ ਸ਼ਾਮਲ ਕਰੋ",
    general_form_type_description: "ਨਵਾਂ ਫਾਰਮ/ਸਰਵੇਖਣ ਬਣਾਓ",
    quiz_type_description: "ਨਵੀਂ ਕਵਿਜ਼ ਬਣਾਓ",
    sheet_disconnected: 'Google ਸ਼ੀਟ ਡਿਸਕਨੈਕਟ ਕੀਤੀ ਗਈ',
    sheet_not_connected: 'ਸ਼ੀਟ ਕਨੈਕਟ ਨਹੀਂ ਹੈ',
    sheet_not_connected_desc: 'ਕਿਰਪਾ ਕਰਕੇ ਗੂਗਲ ਸ਼ੀਟ ਨੂੰ ਕਨੈਕਟ ਕਰਨ ਲਈ ਮਾਲਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।',
    options: "ਵਿਕਲਪ",
    option: 'ਵਿਕਲਪ',
    no_answer: "ਕੋਈ ਉੱਤਰ ਨਹੀਂ",
    no_response: "ਕੋਈ ਜਵਾਬ ਨਹੀਂ",
    responses: "ਜਵਾਬ",
    response: "ਜਵਾਬ",
    serial_number: "ਐੱਸ.ਐੱਨ.ਓ",
    submit_time: "ਸਮਾਂ ਜਮ੍ਹਾਂ ਕਰੋ",
    export_success: "ਸਫਲਤਾਪੂਰਵਕ ਨਿਰਯਾਤ ਕੀਤਾ ਗਿਆ",
    answer_with_colan: "ਜਵਾਬ:",
    others: "ਹੋਰ",
    delete_response: "ਜਵਾਬ ਮਿਟਾਓ",
    deleted: "ਮਿਟਾਇਆ ਗਿਆ",
    my_themes: "ਮੇਰੇ ਥੀਮ",
    limit_reached: "ਸੀਮਾ ਪਹੁੰਚ ਗਈ",
    limit_reached_description: "ਤੁਸੀਂ ਚਿੱਤਰ ਅੱਪਲੋਡ ਕਰਨ ਦੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ।",
    modified: "ਸੋਧਿਆ ਗਿਆ",
    delete_image: "ਚਿੱਤਰ ਮਿਟਾਓ",
    delete_image_alert: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਚਿੱਤਰ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    delete_theme_image_desc: "ਕੀ ਤੁਸੀਂ ਇਸ ਚਿੱਤਰ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਜੇਕਰ ਤੁਸੀਂ ਅੱਗੇ ਵਧਦੇ ਹੋ, ਤਾਂ ਇਹ ਚਿੱਤਰ ਕਿਸੇ ਵੀ ਫਾਰਮ ਜਾਂ ਕਵਿਜ਼ ਲਈ ਉਪਲਬਧ ਨਹੀਂ ਹੋਵੇਗਾ ਜੋ ਇਸਨੂੰ ਵਰਤ ਰਹੇ ਹਨ। ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    section_title: "ਸੈਕਸ਼ਨ ਦਾ ਸਿਰਲੇਖ",
    section_description: "ਸੈਕਸ਼ਨ ਦਾ ਵੇਰਵਾ",
    show_scale: 'ਸਕੇਲ ਦਿਖਾਓ',
    show_options: "ਵਿਕਲਪ ਦਿਖਾਓ",
    options_required: "ਵਿਕਲਪਾਂ ਦੀ ਲੋੜ ਹੈ",
    link_copied: "ਲਿੰਕ ਕਾਪੀ ਕੀਤਾ ਗਿਆ",
    app_not_found: "ਐਪ ਨਹੀਂ ਮਿਲੀ",
    default_text: "ਡਿਫਾਲਟ",
    dark_mode: "ਡਾਰਕ ਮੋਡ",
    done: "ਹੋ ਗਿਆ",
    upload_image: "ਚਿੱਤਰ ਅੱਪਲੋਡ ਕਰੋ",
    question: "ਸਵਾਲ",
    required: "ਲੋੜੀਂਦਾ ਹੈ",
    share: "ਸ਼ੇਅਰ ਕਰੋ",
    form_link: "ਫਾਰਮ ਲਿੰਕ",
    copy_link: "ਲਿੰਕ ਕਾਪੀ ਕਰੋ",
    view_form: "ਫਾਰਮ ਦੇਖੋ",
    menu: "ਮੀਨੂ",
    view_all: "ਸਾਰੇ",
    settings: "ਸੈਟਿੰਗਾਂ",
    submit: "ਜਮ੍ਹਾਂ",
    change_theme: "ਥੀਮ ਬਦਲੋ",
    tap_here_to_choose_theme: "ਥੀਮ ਚੁਣਨ ਲਈ ਇੱਥੇ ਟੈਪ ਕਰੋ",
    click_or_change_current_theme: "ਕੋਈ ਵੱਖਰਾ ਥੀਮ ਚੁਣਨ ਲਈ ਮੌਜੂਦਾ ਥੀਮ ਜਾਂ 'ਬਦਲੋ' 'ਤੇ ਕਲਿੱਕ ਕਰੋ",
    show_logo: "ਲੋਗੋ ਦਿਖਾਓ",
    show_logo_alert: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ ਵਿੱਚ ਲੋਗੋ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    welcome_page: "ਸੁਆਗਤ ਪੰਨਾ",
    show_welcome_page_alert: "ਕੀ ਤੁਸੀਂ ਸੁਆਗਤ ਪੰਨਾ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    start_button: "ਸਟਾਰਟ ਬਟਨ",
    allow_multiple_responses: "ਇੱਕ ਤੋਂ ਵੱਧ ਜਵਾਬਾਂ ਦੀ ਆਗਿਆ ਦਿਓ",
    allow_more_response_alert: "ਕੀ ਤੁਸੀਂ ਉਪਭੋਗਤਾ ਨੂੰ ਇੱਕ ਤੋਂ ਵੱਧ ਵਾਰ ਜਵਾਬ ਦੇਣ ਦੀ ਇਜਾਜ਼ਤ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    allow_more_quiz_attempt_alert: "ਕੀ ਤੁਸੀਂ ਉਪਭੋਗਤਾ ਨੂੰ ਇੱਕ ਤੋਂ ਵੱਧ ਵਾਰ ਕੋਸ਼ਿਸ਼ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    show_summary: "ਸੰਖੇਪ ਦਿਖਾਓ",
    show_results: "ਕਵਿਜ਼ ਨਤੀਜੇ ਦਿਖਾਓ",
    allow_responders_view_summary_alert: "ਕੀ ਤੁਸੀਂ ਸਬਮਿਟ ਕਰਨ ਤੋਂ ਬਾਅਦ ਉੱਤਰਦਾਤਾਵਾਂ ਨੂੰ ਸਾਰੇ ਜਵਾਬਾਂ ਦਾ ਸਾਰਾਂਸ਼ ਦੇਖਣ ਦੀ ਇਜਾਜ਼ਤ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    allow_result_view_quiz_alert: "ਕੀ ਤੁਸੀਂ ਉਪਭੋਗਤਾ ਨੂੰ ਨਤੀਜੇ ਦੇਖਣ ਦੀ ਇਜਾਜ਼ਤ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    show_question_number: "ਪ੍ਰਸ਼ਨ ਨੰਬਰ ਦਿਖਾਓ",
    show_question_number_in_form: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ 'ਤੇ ਪ੍ਰਸ਼ਨ ਨੰਬਰ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    show_question_number_in_quiz: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ 'ਤੇ ਪ੍ਰਸ਼ਨ ਨੰਬਰ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    shuffle_questions: "ਸਵਾਲਾਂ ਨੂੰ ਸ਼ਫਲ ਕਰੋ",
    shuffle_questions_alert: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ ਸਵਾਲਾਂ ਨੂੰ ਬਦਲਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    shuffle_quiz_questions_alert: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ ਸਵਾਲਾਂ ਨੂੰ ਬਦਲਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    description: "ਵਰਣਨ",
    builder: "ਬਿਲਡਰ",
    preview: "ਝਲਕ",
    form_status: "ਫਾਰਮ ਸਥਿਤੀ",
    active: "ਕਿਰਿਆਸ਼ੀਲ",
    import_questions: 'ਸਵਾਲ ਆਯਾਤ ਕਰੋ',
    search_by_question_title_from_forms: 'ਮੌਜੂਦਾ ਫਾਰਮਾਂ ਤੋਂ ਪ੍ਰਸ਼ਨ ਸਿਰਲੇਖ ਦੁਆਰਾ ਖੋਜ ਕਰੋ',
    search_by_question_title_from_quizzes: 'ਮੌਜੂਦਾ ਕਵਿਜ਼ਾਂ ਤੋਂ ਪ੍ਰਸ਼ਨ ਸਿਰਲੇਖ ਦੁਆਰਾ ਖੋਜ ਕਰੋ',
    inactive: "ਅਕਿਰਿਆਸ਼ੀਲ",
    view_responses: "ਜਵਾਬ ਵੇਖੋ",
    edit_form: "ਫਾਰਮ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
    duplicate_form: "ਡੁਪਲੀਕੇਟ ਫਾਰਮ",
    drafts: "ਡਰਾਫਟ",
    no_form_available: "ਕੋਈ ਫਾਰਮ ਉਪਲਬਧ ਨਹੀਂ ਹੈ!",
    notifications: "ਸੂਚਨਾਵਾਂ",
    feedback: "ਸੁਝਾਅ",
    privacy_policy: "ਪਰਾਈਵੇਟ ਨੀਤੀ",
    open: 'ਖੋਲ੍',
    open_individual_responses: "ਵਿਅਕਤੀਗਤ ਜਵਾਬਾਂ ਨੂੰ ਖੋਲ੍ਹੋ",
    bar_chart: "ਬਾਰ ਚਾਰਟ",
    pie_chart: "ਪਾਈ ਚਾਰਟ",
    summary: "ਸੰਖੇਪ",
    invalidated_form_cannot_be_selected: "ਇਸ ਅਪ੍ਰਮਾਣਿਤ ਫਾਰਮ ਨੂੰ ਚੁਣਿਆ ਨਹੀਂ ਜਾ ਸਕਦਾ ਹੈ।",
    invalidated_quiz_cannot_be_selected: "ਇਸ ਅਯੋਗ ਕਵਿਜ਼ ਨੂੰ ਚੁਣਿਆ ਨਹੀਂ ਜਾ ਸਕਦਾ ਹੈ।",
    individual: "ਵਿਅਕਤੀਗਤ",
    search: "ਖੋਜ",
    image: "ਚਿੱਤਰ",
    video: "ਵੀਡੀਓ",
    website: "ਵੈੱਬਸਾਈਟ",
    exit: "ਨਿਕਾਸ",
    response_limit_reached: "ਜਵਾਬ ਦੀ ਸੀਮਾ ਪੂਰੀ ਹੋ ਗਈ ਹੈ",
    response_limit_reached_alert: "ਇਹ ਫਾਰਮ ਜਵਾਬ ਡੇਟਾ ਲਈ ਮਨਜ਼ੂਰ ਸੀਮਾ ਨੂੰ ਪਾਰ ਕਰ ਗਿਆ ਹੈ; ਇਸ ਲਈ, ਅਸੀਂ ਇਸ ਫਾਰਮ ਨੂੰ ਹੋਰ ਜਵਾਬਾਂ ਨੂੰ ਸਵੀਕਾਰ ਕਰਨ ਤੋਂ ਰੋਕਿਆ ਹੈ।\n\nਜੇਕਰ ਤੁਹਾਨੂੰ ਅਸਲ ਵਿੱਚ ਮੌਜੂਦਾ ਸਰਵੇਖਣ ਨੂੰ ਜਾਰੀ ਰੱਖਣ ਦੀ ਲੋੜ ਹੈ, ਤਾਂ ਅਸੀਂ ਸੁਝਾਅ ਦਿੰਦੇ ਹਾਂ ਕਿ ਤੁਸੀਂ ਇਸਨੂੰ ਡੁਪਲੀਕੇਟ ਕਰੋ ਅਤੇ ਲਿੰਕ ਨੂੰ ਸਾਂਝਾ ਕਰੋ।",
    unapproved_form: "ਗੈਰ-ਪ੍ਰਵਾਨਿਤ ਫਾਰਮ",
    unapproved_form_alert: "ਇਹ ਫਾਰਮ ਅਜਿਹੇ ਸ਼ਬਦਾਂ ਦੀ ਵਰਤੋਂ ਕਰ ਰਿਹਾ ਹੈ ਜੋ ਸਾਡੀਆਂ ਨੀਤੀਆਂ ਦੀ ਉਲੰਘਣਾ ਕਰ ਸਕਦੇ ਹਨ। \n\nਜੇਕਰ ਤੁਹਾਨੂੰ ਲੱਗਦਾ ਹੈ ਕਿ ਇਹ ਫਾਰਮ ਗਲਤੀ ਨਾਲ ਬਲੌਕ ਕੀਤਾ ਗਿਆ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੇ ਫਾਰਮ ਲਿੰਕ ਨੂੰ ਖੋਲ੍ਹੋ ਅਤੇ ਫਾਰਮ ਵਿੱਚ ਬੇਨਤੀ ਕੀਤੀ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ।",
    choose_your_language: "ਆਪਣੀ ਭਾਸ਼ਾ ਚੁਣੋ",
    provide_explanation: "ਸਪਸ਼ਟੀਕਰਨ ਪ੍ਰਦਾਨ ਕਰੋ",
    selected: "ਚੁਣਿਆ ਹੋਇਆ",
    review_button: "ਸਮੀਖਿਆ",
    not_specified: "ਨਹੀ ਦੱਸਇਆ",
    quiz_control: "ਕਵਿਜ਼ ਕੰਟਰੋਲ",
    allow_multiple_attempts: "ਕਈ ਕੋਸ਼ਿਸ਼ਾਂ ਦੀ ਆਗਿਆ ਦਿਓ",
    user_info: "ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ",
    name_s: "ਨਾਮ",
    duration: "ਮਿਆਦ",
    set_duration_quiz_alert: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ ਲਈ ਸਮਾਂ ਨਿਰਧਾਰਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    user_information_description: "ਕੀ ਤੁਸੀਂ ਭਾਗੀਦਾਰ ਦੀ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    set_duration: "ਸਮਾਂ ਨਿਰਧਾਰਤ ਕਰੋ (ਘੰਟੇ: ਮਿੰਟ)",
    minutes: "ਮਿੰਟ",
    hours: "ਘੰਟੇ",
    minute: "ਮਿੰਟ",
    hour: "ਘੰਟਾ",
    correct: "ਸਹੀ",
    wrong: "ਗਲਤ",
    skipped: "ਛੱਡਿਆ",
    score: "ਸਕੋਰ",
    forms: "ਫਾਰਮ",
    quizzes: "ਕਵਿਜ਼",
    more: "ਹੋਰ",
    quiz_status: "ਕਵਿਜ਼ ਸਥਿਤੀ",
    view_answers: "ਜਵਾਬ ਦੇਖੋ",
    view_quiz: "ਕਵਿਜ਼ ਦੇਖੋ",
    duplicate_quiz: "ਡੁਪਲੀਕੇਟ ਕਵਿਜ਼",
    no_quiz_available: "ਕੋਈ ਕਵਿਜ਼ ਉਪਲਬਧ ਨਹੀਂ ਹੈ!",
    quiz_link: "ਕਵਿਜ਼ ਲਿੰਕ",
    unapproved_quiz: "ਗੈਰ-ਮਨਜ਼ੂਰਸ਼ੁਦਾ ਕਵਿਜ਼",
    unapproved_quiz_alert: "ਇਹ ਕਵਿਜ਼ ਅਜਿਹੇ ਸ਼ਬਦਾਂ ਦੀ ਵਰਤੋਂ ਕਰ ਰਹੀ ਹੈ ਜੋ ਸਾਡੀਆਂ ਨੀਤੀਆਂ ਦੀ ਉਲੰਘਣਾ ਕਰ ਸਕਦੇ ਹਨ। \n\nਜੇਕਰ ਤੁਹਾਨੂੰ ਲੱਗਦਾ ਹੈ ਕਿ ਇਹ ਕਵਿਜ਼ ਗਲਤੀ ਨਾਲ ਬਲੌਕ ਕਰ ਦਿੱਤੀ ਗਈ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੇ ਫਾਰਮ ਲਿੰਕ ਨੂੰ ਖੋਲ੍ਹੋ ਅਤੇ ਫਾਰਮ ਵਿੱਚ ਬੇਨਤੀ ਕੀਤੀ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ।",
    do_you_want_to_show_all_correct_answers_to_users: "ਕੀ ਤੁਸੀਂ ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਸਹੀ ਜਵਾਬ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    results_publish_date: "ਨਤੀਜੇ ਪ੍ਰਕਾਸ਼ਿਤ ਹੋਣ ਦੀ ਮਿਤੀ",
    disconnect: 'ਡਿਸਕਨੈਕਟ ਕਰੋ',
    do_you_want_to_set_date_to_publish_results: "ਕੀ ਤੁਸੀਂ ਨਤੀਜੇ ਪ੍ਰਕਾਸ਼ਿਤ ਕਰਨ ਲਈ ਮਿਤੀ ਨਿਰਧਾਰਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    quiz_result: "ਕੁਇਜ਼ ਨਤੀਜਾ",
    enter_answer: "ਜਵਾਬ",
    manage: "ਪ੍ਰਬੰਧਨ",
    manage_storage: 'ਸਟੋਰੇਜ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ',
    select_all: 'ਸਾਰਿਆ ਨੂੰ ਚੁਣੋ',
    shuffle_options: 'ਸ਼ਫਲ ਵਿਕਲਪ',
    shuffle_options_enabled: 'ਸ਼ਫਲ ਵਿਕਲਪ ਸਮਰਥਿਤ ਹਨ',
    shuffle_options_disabled: 'ਸ਼ਫਲ ਵਿਕਲਪ ਅਯੋਗ ਹਨ',
    add_options_to_enable_this_feature: 'ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਸਮਰੱਥ ਕਰਨ ਲਈ ਵਿਕਲਪ ਸ਼ਾਮਲ ਕਰੋ',
    attach: 'ਅਟੈਚ ਕਰੋ',
    shuffle: 'ਸ਼ਫਲ',
    marks: "ਅੰਕ",
    mark: "ਅੰਕ",
    correction_status: "ਸਥਿਤੀ:",
    status_validated: "ਪ੍ਰਮਾਣਿਤ",
    pending: "ਬਕਾਇਆ",
    results_publish: "ਪ੍ਰਕਾਸ਼ਿਤ ਕਰੋ",
    publish: "ਪ੍ਰਕਾਸ਼ਿਤ ਕਰੋ",
    publish_description: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ ਨਤੀਜੇ ਪ੍ਰਕਾਸ਼ਿਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    publish_description_note: "(ਅੰਤਿਮ ਸਕੋਰ ਉੱਤਰਦਾਤਾ ਦੀ ਉੱਤਰ ਪੱਤਰੀ ਵਿੱਚ ਦਿਖਾਈ ਦੇਵੇਗਾ)",
    published_successfully: "ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤਾ ਗਿਆ",
    choice_type_validation_note: "* ਮਲਟੀਪਲ ਵਿਕਲਪ ਆਟੋਮੈਟਿਕਲੀ ਪ੍ਰਮਾਣਿਤ",
    short_answer_type_validation_note: "* ਛੋਟਾ ਜਵਾਬ ਆਪਣੇ ਆਪ ਪ੍ਰਮਾਣਿਤ ਹੁੰਦਾ ਹੈ",
    long_answer_type_validation_note: "*ਲੰਬਾ ਜਵਾਬ ਆਪਣੇ ਆਪ ਪ੍ਰਮਾਣਿਤ ਨਹੀਂ ਹੁੰਦਾ",
    fileupload_answer_type_validation_note: "*ਫਾਇਲ ਅੱਪਲੋਡ ਆਪਣੇ ਆਪ ਪ੍ਰਮਾਣਿਤ ਨਹੀਂ ਹੁੰਦਾ",
    dropdown_answer_type_validation_note: "* ਡ੍ਰੌਪਡਾਉਨ ਵਿਕਲਪ ਆਪਣੇ ਆਪ ਪ੍ਰਮਾਣਿਤ ਕੀਤਾ ਗਿਆ",
    save: "ਬਚਾਓ",
    questions: "ਸਵਾਲ",
    show_correct_answer: "ਸਹੀ ਜਵਾਬ ਦਿਖਾਓ",
    show_answer: "ਜਵਾਬ ਦਿਖਾਓ",
    hide_answer: "ਜਵਾਬ ਲੁਕਾਓ",
    no_marks: "ਕੋਈ ਅੰਕ ਨਹੀਂ",
    question_is_required: "ਸਵਾਲ ਦਾ ਸਿਰਲੇਖ ਲੋੜੀਂਦਾ ਹੈ",
    marks_is_required: "ਅੰਕ ਲੋੜੀਂਦੇ ਹਨ",
    options_are_required: "ਵਿਕਲਪਾਂ ਦੀ ਲੋੜ ਹੈ",
    options_limit_reached: 'ਵਿਕਲਪਾਂ ਦੀ ਸੀਮਾ ਪੂਰੀ ਹੋ ਗਈ ਹੈ',
    options_limit_reached_description: 'ਤੁਸੀਂ ਇੱਕ ਸਵਾਲ ਤੇ ਮਨਜ਼ੂਰ ਵਿਕਲਪਾਂ ਦੀ ਸੀਮਾ ਤੱਕ ਪਹੁੰਚ ਗਏ ਹੋ।',
    correct_answer_required: "ਸਹੀ ਜਵਾਬ ਦੀ ਲੋੜ ਹੈ",
    enter_valid_mark: "ਵੈਧ ਅੰਕ ਦਰਜ ਕਰੋ",
    evaluated: "ਪਰਖ",
    saved: "ਸੰਭਾਲੀ ਗਈ",
    pending_evaluation: "ਬਕਾਇਆ ਮੁਲਾਂਕਣ",
    alphabet: "ਵਰਣਮਾਲਾ",
    not_evaluated: "ਮੁਲਾਂਕਣ ਨਹੀਂ ਕੀਤਾ ਗਿਆ",
    percentage_ascending: "ਪ੍ਰਤੀਸ਼ਤ - ਚੜ੍ਹਦਾ",
    percentage_descending: "ਪ੍ਰਤੀਸ਼ਤ - ਘਟਦਾ",
    pending_evaluation_alert_message: "ਤੁਹਾਨੂੰ ਪ੍ਰਕਾਸ਼ਿਤ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਸਾਰੇ ਜਵਾਬਾਂ ਦਾ ਮੁਲਾਂਕਣ ਪੂਰਾ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ।",
    show_unevaluated: "ਅਣ-ਮੁਲਾਂਕਣ ਦਿਖਾਓ",
    publish_success_alert_message: "ਉੱਤਰਦਾਤਾ ਆਪਣੇ ਨਤੀਜੇ ਆਪਣੇ ਅਨੁਸਾਰੀ ਉੱਤਰ ਪੱਤਰੀਆਂ ਵਿੱਚ ਦੇਖ ਸਕਦੇ ਹਨ। \n\nਇਹ ਕਵਿਜ਼ ਆਪਣੇ ਆਪ ਬੰਦ ਹੋ ਗਿਆ ਹੈ।",
    publishing: "ਪ੍ਰਕਾਸ਼ਨ",
    quiz_published: "ਕੁਇਜ਼ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤੀ ਗਈ",
    quiz_published_alert_before_edit_message: "ਇਹ ਕਵਿਜ਼ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤਾ ਗਿਆ ਹੈ, ਜੇਕਰ ਤੁਹਾਨੂੰ ਬਦਲਾਅ ਕਰਨ ਦੀ ਲੋੜ ਹੈ, ਤਾਂ ਤੁਹਾਨੂੰ ਕਵਿਜ਼ ਨੂੰ ਡੁਪਲੀਕੇਟ ਕਰਨਾ ਹੋਵੇਗਾ ਅਤੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਸਾਂਝਾ ਕਰਨਾ ਹੋਵੇਗਾ।",
    quiz_has_answers: "ਕੁਇਜ਼ ਦੇ ਜਵਾਬ ਹਨ",
    quiz_has_answer_alert_message: "ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਲਈ, ਤੁਹਾਨੂੰ ਸਾਰੇ ਮੌਜੂਦਾ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੀਦਾ ਹੈ।", // ਦੋ ਸਟ੍ਰਿਨ",
    unavailable_features_description: 'ਇਸ ਫਾਰਮ ਵਿੱਚ ਅਜਿਹੀਆਂ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਹਨ ਜੋ ਅਜੇ ਤੱਕ ਵੈੱਬ ਐਪ ਵਿੱਚ ਸਮਰਥਿਤ ਨਹੀਂ ਹਨ',
    unavailable_quiz_features_description: 'ਇਸ ਕਵਿਜ਼ ਵਿੱਚ ਉਹ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਹਨ ਜੋ ਅਜੇ ਤੱਕ ਵੈੱਬ ਐਪ ਵਿੱਚ ਸਮਰਥਿਤ ਨਹੀਂ ਹਨ',
    unavailable: 'ਅਣਉਪਲਬਧ',
    unpublished: "ਅਪ੍ਰਕਾਸ਼ਿਤ",
    published: "ਪ੍ਰਕਾਸ਼ਿਤ",
    setting_updated: "ਸੈਟਿੰਗ ਅੱਪਡੇਟ ਕੀਤੀ ਗਈ",
    account: "ਖਾਤਾ",
    on: "ਚਾਲੂ",
    off: "ਬੰਦ",
    quizzory: "Quizzory",
    scan_qr_code: "QR ਕੋਡ ਸਕੈਨ ਕਰੋ",
    answers: "ਜਵਾਬ",
    answer_s: "ਜਵਾਬ",
    question_limit_reached: "ਸਵਾਲ ਦੀ ਸੀਮਾ ਪੂਰੀ ਹੋ ਗਈ ਹੈ",
    reached_limit_for_number_of_questions: "ਤੁਸੀਂ ਇੱਕ ਫਾਰਮ 'ਤੇ ਮਨਜ਼ੂਰ ਪ੍ਰਸ਼ਨਾਂ ਦੀ ਗਿਣਤੀ ਦੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ। ਇਸ ਲਈ, ਕੋਈ ਨਵਾਂ ਸਵਾਲ ਨਹੀਂ ਜੋੜਿਆ ਜਾ ਸਕਦਾ ਹੈ",
    life_time: "ਜੀਵਨ ਭਰ",
    last_year: "ਪਿਛਲੇ ਸਾਲ",
    last_30_days: "ਪਿਛਲੇ 30 ਦਿਨ",
    last_7_days: "ਪਿਛਲੇ 7 ਦਿਨ",
    last_24_hours: "ਪਿਛਲੇ 24 ਘੰਟੇ",
    custom_date: "ਕਸਟਮ ਮਿਤੀ",
    filter_by_date: "ਮਿਤੀ ਅਨੁਸਾਰ ਫਿਲਟਰ ਕਰੋ",
    filter_by_question: "ਸਵਾਲ ਦੁਆਰਾ ਫਿਲਟਰ ਕਰੋ",
    filter_text: "ਟੈਕਸਟ ਫਿਲਟਰ ਕਰੋ",
    add_filter: "ਫਿਲਟਰ ਸ਼ਾਮਲ ਕਰੋ",
    apply: "ਲਾਗੂ ਕਰੋ",
    from_date: "ਮਿਤੀ ਤੋਂ",
    today: 'ਅੱਜ',
    to_date: "ਮਿਤੀ ਤੱਕ",
    contains: "ਸ਼ਾਮਿਲ ਹੈ",
    equal_to: "ਦੇ ਬਰਾਬਰ",
    not_equal_to: "ਦੇ ਬਰਾਬਰ ਨਹੀਂ",
    from_date_required: "ਲੋੜੀਂਦੀ ਮਿਤੀ ਤੋਂ",
    to_date_required: "ਮਿਤੀ ਤੱਕ ਦੀ ਲੋੜ ਹੈ",
    webapp_promo_text: "ਆਪਣੇ ਫਾਰਮਾਂ, ਸਰਵੇਖਣਾਂ, ਪੋਲ ਅਤੇ ਕਵਿਜ਼ਾਂ ਨੂੰ ਔਨਲਾਈਨ ਐਕਸੈਸ ਕਰੋ!",
    question_title: "ਸਵਾਲ",
    invalid_date: "ਅਵੈਧ ਮਿਤੀ",
    invalid_url: "ਅਵੈਧ URL",
    invalid_url_description: "URL ਵੈਧ ਨਹੀਂ ਜਾਪਦਾ ਹੈ।",
    delete_response_alert: "ਕੀ ਤੁਸੀਂ ਇਸ ਜਵਾਬ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    delete_responses_alert: "ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    overview: "ਸੰਖੇਪ ਜਾਣਕਾਰੀ",
    no_results_found: "ਕੋਈ ਨਤੀਜਾ ਨਹੀਂ ਮਿਲਿਆ",
    date_format: "dd-mm-yyyy",
    powered_by_surveyheart_com: "\'SurveyHeart.com\' ਦੁਆਰਾ ਸੰਚਾਲਿਤ",
    title_dashboard: "ਡੈਸ਼ਬੋਰਡ",
    title_notifications: "ਸੂਚਨਾਵਾਂ",
    select_one_response: "ਕਿਰਪਾ ਕਰਕੇ ਮਿਟਾਉਣ ਲਈ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਜਵਾਬ ਚੁਣੋ",
    shared: "ਸਾਂਝਾ",
    hide: "ਓਹਲੇ",
    use_templates: "ਟੈਂਪਲੇਟਸ ਦੀ ਵਰਤੋਂ ਕਰੋ",
    quick_settings: "ਤਤਕਾਲ ਸੈਟਿੰਗਾਂ",
    leave_collaboration: "ਸਹਿਯੋਗ ਛੱਡੋ",
    templates: "ਟੈਂਪਲੇਟਸ",
    collapse: "ਸਮੇਟਣਾ",
    start_scale_from_zero: '0 ਤੋਂ ਸਕੇਲ ਸ਼ੁਰੂ ਕਰੋ',
    center_label_optional: 'ਸੈਂਟਰ ਲੇਬਲ (ਵਿਕਲਪਿਕ)',
    left_label_optional: 'ਖੱਬਾ ਲੇਬਲ (ਵਿਕਲਪਿਕ)',
    right_label_optional: 'ਸੱਜਾ ਲੇਬਲ (ਵਿਕਲਪਿਕ)',
    duplicate: "ਡੁਪਲੀਕੇਟ",
    row: "ਕਤਾਰ",
    column: "ਕਾਲਮ",
    rows: "ਕਤਾਰਾਂ",
    add_column: 'ਕਾਲਮ ਸ਼ਾਮਲ ਕਰੋ',
    add_row: 'ਕਤਾਰ ਸ਼ਾਮਲ ਕਰੋ',
    user_info_for_file_upload: "ਫਾਈਲ ਅਪਲੋਡਸ ਲਈ ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ",
    user_info_for_file_upload_desc1: "ਕਿਉਂਕਿ ਤੁਸੀਂ ਇੱਕ ਫਾਈਲ ਅਪਲੋਡ ਪ੍ਰਸ਼ਨ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ, ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ ਇਕੱਠੀ ਕਰਨ ਦਾ ਵਿਕਲਪ ਸਮਰੱਥ ਕੀਤਾ ਗਿਆ ਹੈ। ਇਸ ਨਾਲ ਡਾਊਨਲੋਡ ਕੀਤੀਆਂ ਫਾਈਲਾਂ ਦੀ ਪਛਾਣ ਕਰਨਾ ਆਸਾਨ ਹੋ ਜਾਵੇਗਾ। ਉੱਤਰਦਾਤਾ ਆਪਣੀ ਜਾਣਕਾਰੀ ਦਰਜ ਕਰਨ ਲਈ ਇੱਕ ਡਾਇਲਾਗ ਦੇਖਣਗੇ।",
    user_info_for_file_upload_desc2: "ਜੇਕਰ ਤੁਸੀਂ ਨਹੀਂ ਚਾਹੁੰਦੇ ਕਿ ਡਾਇਲਾਗ ਦਿਖਾਇਆ ਜਾਵੇ ਤਾਂ ਤੁਸੀਂ ਸੈਟਿੰਗਾਂ ਵਿੱਚ ਇਸ ਵਿਕਲਪ ਨੂੰ ਬਦਲ ਸਕਦੇ ਹੋ।",
    show_rows_and_columns: "ਦਿਖਾਓ ਕਤਾਰਾਂ & ਕਾਲਮ",
    scale_down: 'ਸਕੇਲ ਡਾਊਨ',
    scale_up: 'ਸਕੇਲ ਅੱਪ',
    dislike: 'ਨਾਪਸੰਦ',
    like: 'ਪਸੰਦ ਹੈ',
    best: 'ਵਧੀਆ',
    remove_favourite: "ਮਨਪਸੰਦ ਹਟਾਓ",
    add_favourite: "ਮਨਪਸੰਦ ਸ਼ਾਮਲ ਕਰੋ",
    collaborator: "ਸਹਿਯੋਗੀ",
    collaborators: "ਸਹਿਯੋਗੀ",
    show_more: "ਹੋਰ ਦਿਖਾਓ",
    create_quiz: "ਕਵਿਜ਼ ਬਣਾਓ",
    collaborate: "ਸਹਿਯੋਗ ਕਰੋ",
    share_QR_code: "QR ਕੋਡ ਸਾਂਝਾ ਕਰੋ",
    add_image: "ਚਿੱਤਰ ਸ਼ਾਮਲ ਕਰੋ",
    invalid_file_type: "ਅਵੈਧ ਫ਼ਾਈਲ ਕਿਸਮ",
    drag_drop: "ਖਿੱਚੋ ਅਤੇ ਸੁੱਟੋ",
    here: "ਇਥੇ",
    choose: "ਚੁਣੋ",
    add_account: 'ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ',
    choose_account: 'ਖਾਤਾ ਚੁਣੋ',
    maximum_allowed_file_size: "ਅਧਿਕਤਮ ਮਨਜ਼ੂਰ ਫ਼ਾਈਲ ਆਕਾਰ",
    or: "ਜਾਂ",
    next: "ਅਗਲਾ",
    change_image: "ਚਿੱਤਰ ਬਦਲੋ",
    upload: "ਅੱਪਲੋਡ ਕਰੋ",
    view: "ਦੇਖੋ",
    collaboration: "ਸਹਿਯੋਗ",
    share_collaboration_form: "ਕੀ ਤੁਸੀਂ ਇਸ ਫਾਰਮ ਨੂੰ ਹੋਰਾਂ ਨਾਲ ਸਾਂਝਾ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    share_collaboration_quiz: "ਕੀ ਤੁਸੀਂ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਦੂਜਿਆਂ ਨਾਲ ਸਾਂਝਾ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    multiple_responses_summary: "ਕਈ ਜਵਾਬ, ਸੰਖੇਪ",
    question_number_shuffle: "ਸਵਾਲ ਨੰਬਰ, ਪ੍ਰਸ਼ਨ ਅੰਕ, ਸ਼ਫਲ",
    general: "ਜਨਰਲ",
    browse: "ਬਰਾਊਜ਼ ਕਰੋ",
    owner: "ਮਾਲਕ",
    something_went_wrong: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ!",
    forget_your_password: "ਆਪਣਾ ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ ਹੋ?",
    sign_in: "ਸਾਈਨ - ਇਨ",
    sign_up: "ਸਾਇਨ ਅਪ",
    using_email: "ਈਮੇਲ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਹੋਏ",
    dont_have_account: "ਕੀ ਤੁਹਾਡੇ ਕੋਲ ਖਾਤਾ ਨਹੀਂ ਹੈ?",
    on_signing_up_agree_to_surveyhearts: "ਸਾਈਨ ਅੱਪ ਕਰਕੇ, ਤੁਸੀਂ SurveyHeart ਦੇ ਨਾਲ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ",
    and: "ਅਤੇ",
    terms_of_use: "ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ",
    already_have_an_account: "ਕੀ ਪਹਿਲਾਂ ਤੋਂ ਹੀ ਖਾਤਾ ਹੈ?",
    signed_in_previously_as_a_google_user: "ਤੁਸੀਂ ਪਹਿਲਾਂ ਇੱਕ Google ਉਪਭੋਗਤਾ ਵਜੋਂ ਸਾਈਨ ਇਨ ਕੀਤਾ ਹੈ।",
    sign_in_google_to_continue: "ਜਾਰੀ ਰੱਖਣ ਲਈ ਕਿਰਪਾ ਕਰਕੇ Google ਰਾਹੀਂ ਸਾਈਨ ਇਨ ਕਰੋ।",
    no_account_found_email_address: "ਇਸ ਈਮੇਲ ਪਤੇ ਨਾਲ ਕੋਈ ਖਾਤਾ ਨਹੀਂ ਮਿਲਿਆ",
    account_already_exist_email: "ਇਸ ਈਮੇਲ ਲਈ ਖਾਤਾ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ।",
    install_surveyheart_app: "Install SurveyHeart App",
    easy_login_via_app: "ਐਪ ਰਾਹੀਂ ਆਸਾਨ ਲੌਗਇਨ ਕਰੋ",
    get_notification_instantly: "ਤੁਰੰਤ ਸੂਚਨਾ ਪ੍ਰਾਪਤ ਕਰੋ",
    create_form_quiz_on_the_go: "ਜਾਂਦੇ ਸਮੇਂ ਫਾਰਮ/ਕੁਇਜ਼ ਬਣਾਓ",
    check_your_inbox_to_activate_your_account: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਖਾਤੇ ਨੂੰ ਕਿਰਿਆਸ਼ੀਲ ਕਰਨ ਲਈ ਆਪਣੇ ਇਨਬਾਕਸ ਦੀ ਜਾਂਚ ਕਰੋ",
    select_questions: "ਸਵਾਲ ਚੁਣੋ",
    percentage: "ਪ੍ਰਤੀਸ਼ਤ",
    free_App_to_create_forms: "ਫਾਰਮ ਬਣਾਉਣ ਲਈ ਮੁਫ਼ਤ ਐਪ ਪ੍ਰਾਪਤ ਕਰੋ",
    we_cant_find_the_page_that_you_are_looking_for: "ਅਸੀਂ ਉਹ ਪੰਨਾ ਨਹੀਂ ਲੱਭ ਸਕਦੇ ਜੋ ਤੁਸੀਂ ਲੱਭ ਰਹੇ ਹੋ",
    answered: "ਜਵਾਬ ਦਿੱਤਾ",
    completing_this_quiz: "ਇਸ ਕਵਿਜ਼ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਧੰਨਵਾਦ",
    connected_successfully: 'ਸਫਲਤਾਪੂਰਵਕ ਕਨੈਕਟ ਕੀਤਾ ਗਿਆ',
    seconds: "ਸਕਿੰਟ",
    enter_your_details: "ਆਪਣੇ ਵੇਰਵੇ ਦਰਜ ਕਰੋ",
    enter_all_required_information: "ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੀ ਲੋੜੀਂਦੀ ਜਾਣਕਾਰੀ ਦਾਖਲ ਕਰੋ",
    answer_data_not_found: "ਜਵਾਬ ਡੇਟਾ ਨਹੀਂ ਮਿਲਿਆ",
    results_published_soon: "ਨਤੀਜੇ ਜਲਦੀ ਹੀ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤੇ ਜਾਣਗੇ...",
    previous: 'ਪਿਛਲਾ',
    save_link_for_future_reference: "ਭਵਿੱਖ ਦੇ ਸੰਦਰਭ ਲਈ ਇਸ ਲਿੰਕ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰੋ",
    choice_questions_only: "ਸਿਰਫ਼ ਚੋਣ ਸਵਾਲ",
    score_is_not_final: "ਨੋਟ: ਇਹ ਸਕੋਰ ਅੰਤਿਮ ਨਹੀਂ ਹੈ",
    tabular: "ਟੇਬੂਲਰ",
    text: "ਟੈਕਸਟ",
    answer_response_alert: "ਇਸ ਸਵਾਲ ਦਾ ਜਵਾਬ ਮੰਗਦਾ ਹੈ",
    invalid_email_address: "ਗਲਤ ਈਮੇਲ ਪਤਾ",
    answer_not_available: "ਜਵਾਬ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
    validated_automatically: "* ਆਪਣੇ ਆਪ ਪ੍ਰਮਾਣਿਤ",
    answer_link: "ਜਵਾਬ ਲਿੰਕ",
    embed_code: 'ਏਮਬੇਡ ਕੋਡ',
    copy_code: 'ਕੋਡ ਕਾਪੀ ਕਰੋ',
    code_copied: 'ਕੋਡ ਕਾਪੀ ਕੀਤਾ ਗਿਆ',
    share_via: "ਰਾਹੀਂ ਸਾਂਝਾ ਕਰੋ",
    print: "ਛਾਪੋ",
    columns: 'ਕਾਲਮ',
    columns_all: "ਕਾਲਮ (ਸਾਰੇ)",
    submitted_time: "ਪੇਸ਼ ਕਰਨ ਦਾ ਸਮਾਂ",
    unable_to_submit: 'ਸਪੁਰਦ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ',
    unable_to_submit_alert_line1: 'ਕਿਉਂਕਿ ਇਸ ਕਵਿਜ਼ ਨੇ ਜਵਾਬ ਪ੍ਰਾਪਤ ਕੀਤੇ ਹਨ, ਤੁਹਾਡੇ ਕੋਲ ਤਬਦੀਲੀਆਂ ਕਰਨ ਲਈ ਸੀਮਤ ਪਹੁੰਚ ਹੈ।',
    unable_to_submit_alert_line2: 'ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਲਈ ਪੂਰੀ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ, ਤੁਹਾਨੂੰ ਸਾਰੇ ਮੌਜੂਦਾ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੀਦਾ ਹੈ।',
    action_blocked: "ਕਾਰਵਾਈ ਬਲੌਕ ਕੀਤੀ ਗਈ",
    action_blocked_alert: "ਇਸ ਕਵਿਜ਼ ਲਈ ਮੌਜੂਦਾ ਜਵਾਬਾਂ ਕਾਰਨ ਕਾਰਵਾਈ ਬਲੌਕ ਕੀਤੀ ਗਈ। ਇਸਦੀ ਇਜਾਜ਼ਤ ਦੇਣ ਨਾਲ ਪਿਛਲੇ ਜਵਾਬਾਂ 'ਤੇ ਅਸਰ ਪੈ ਸਕਦਾ ਹੈ।",
    quiz_edit_submit_alert: "ਕੁਇਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਨਾਲ ਅੰਕ, ਪ੍ਰਤੀਸ਼ਤਤਾ ਪ੍ਰਭਾਵਿਤ ਹੋ ਸਕਦੀ ਹੈ ਅਤੇ ਕੁਝ ਪ੍ਰਸ਼ਨਾਂ ਦੇ ਪੁਨਰ-ਮੁਲਾਂਕਣ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ।",
    continue: "ਜਾਰੀ ਰੱਖੋ",
    submitted: "ਪੇਸ਼ ਕੀਤਾ",
    submitting: "ਸਪੁਰਦ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    filter: "ਫਿਲਟਰ",
    exit_screen: "ਸਕ੍ਰੀਨ ਤੋਂ ਬਾਹਰ ਜਾਓ",
    full_screen: "ਪੂਰਾ ਸਕਰੀਨ",
    tabular_view: "ਟੇਬੂਲਰ ਦ੍ਰਿਸ਼",
    clear_all: "ਸਾਰਾ ਸਾਫ ਕਰੋ",
    you_are_all_caught_up: "ਤੁਸੀਂ ਸਾਰੇ ਫੜੇ ਗਏ ਹੋ!",
    check_back_later_for_new_notifications: "ਨਵੀਆਂ ਸੂਚਨਾਵਾਂ ਲਈ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਜਾਂਚ ਕਰੋ।",
    requires_answer: "ਜਵਾਬ ਦੀ ਲੋੜ ਹੈ",
    enter_member_id: "ਮੈਂਬਰ ਆਈਡੀ ਦਰਜ ਕਰੋ",
    viewers_can_read_form_responses: "ਦਰਸ਼ਕ ਫਾਰਮ ਨੂੰ ਪੜ੍ਹ ਸਕਦੇ ਹਨ ਅਤੇ ਜਵਾਬ ਦੇਖ ਸਕਦੇ ਹਨ",
    editors_edit_form_view_responses: "ਸੰਪਾਦਕ ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦੇ ਹਨ ਅਤੇ ਜਵਾਬਾਂ ਨੂੰ ਦੇਖ ਸਕਦੇ ਹਨ",
    admin_edit_form_add_collaborator: "ਪ੍ਰਸ਼ਾਸਕ ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦਾ ਹੈ ਅਤੇ ਸਹਿਯੋਗੀ ਨੂੰ ਸ਼ਾਮਲ ਕਰ ਸਕਦਾ ਹੈ",
    invalid_member_id: "ਅਵੈਧ ਮੈਂਬਰ ਆਈ.ਡੀ",
    already_member_added: "ਇਸ ਉਪਭੋਗਤਾ ਕੋਲ ਪਹਿਲਾਂ ਹੀ ਪਹੁੰਚ ਹੈ",
    add_collaborator: "ਸਹਿਯੋਗੀ ਸ਼ਾਮਲ ਕਰੋ",
    admin: "ਐਡਮਿਨ",
    editor: "ਸੰਪਾਦਕ",
    viewer: "ਦਰਸ਼ਕ",
    searched_form_not_found: "ਖੋਜਿਆ ਫਾਰਮ ਨਹੀਂ ਮਿਲਿਆ!",
    searched_quiz_not_found: "ਖੋਜ ਕੀਤੀ ਕੁਇਜ਼ ਨਹੀਂ ਮਿਲੀ!",
    no_shared_data_available: "ਕੋਈ ਸਾਂਝਾ ਡੇਟਾ ਉਪਲਬਧ ਨਹੀਂ ਹੈ!",
    forms_server_is_not_available: "ਫਾਰਮ ਸਰਵਰ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
    quiz_server_is_not_available: "ਕੁਇਜ਼ ਸਰਵਰ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
    leave: "ਛੱਡੋ",
    password: "ਪਾਸਵਰਡ",
    new_password: "ਨਵਾਂ ਪਾਸਵਰਡ",
    retype_password: "ਮੁੜ ਪਾਸਵਰਡ ਲਿਖੋ",
    create_password: "ਪਾਸਵਰਡ ਬਣਾਓ",
    view_results: "ਨਤੀਜੇ ਵੇਖੋ",
    control: "ਕੰਟਰੋਲ",
    q_control: "Q.Control",
    q_result: "Q. ਨਤੀਜਾ",
    no_matches_found: "ਕੋਈ ਮੇਲ ਨਹੀਂ ਮਿਲਿਆ",
    dropdown: "ਡਰਾਪ ਡਾਉਨ",
    checkboxes: "ਚੈੱਕਬਾਕਸ",
    star_rating: "ਸਟਾਰ ਰੇਟਿੰਗ",
    linear_scale: 'ਲੀਨੀਅਰ ਸਕੇਲ',
    multiple_choice_grid: 'ਮਲਟੀਪਲ ਚੁਆਇਸ ਗਰਿੱਡ',
    checkboxes_grid: 'ਚੈੱਕਬਾਕਸ ਗਰਿੱਡ',
    smile: 'ਮੁਸਕਰਾਓ',
    info_1: "ਜਾਣਕਾਰੀ 1",
    info_2: "ਜਾਣਕਾਰੀ 2",
    info_3: "ਜਾਣਕਾਰੀ 3",
    new: "ਨਵਾਂ",
    download: "ਡਾਊਨਲੋਡ ਕਰੋ",
    download_all: 'ਸਾਰੇ ਡਾਊਨਲੋਡ ਕਰੋ',
    sort_by: 'ਨਾਲ ਕ੍ਰਮਬੱਧ',
    sorting: "ਛਾਂਟੀ",
    refresh: "ਤਾਜ਼ਾ ਕਰੋ",
    add: "ਸ਼ਾਮਲ ਕਰੋ",
    refresh_preview_description: "ਝਲਕ ਨੂੰ ਅੱਪਡੇਟ ਕਰਨ ਲਈ 'ਰਿਫ੍ਰੈਸ਼' ਬਟਨ 'ਤੇ ਕਲਿੱਕ ਕਰੋ",
    enter_users_email_address: "ਉਪਭੋਗਤਾ ਦਾ ਈਮੇਲ ਪਤਾ ਦਰਜ ਕਰੋ",
    do_you_want_to_delete_these: "ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
    do_you_want_to_delete_this_form: "ਕੀ ਤੁਸੀਂ ਇਸ ਫਾਰਮ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
    all_responses_will_also_be_deleted: "ਸਾਰੇ ਜਵਾਬ ਵੀ ਮਿਟਾ ਦਿੱਤੇ ਜਾਣਗੇ",
    this_action_cannot_be_undone: "ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਣਕੀਤਾ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    do_you_want_to_delete_this_quiz: "ਕੀ ਤੁਸੀਂ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
    all_answer_sheets_will_also_be_unavailable: "ਸਾਰੀਆਂ ਉੱਤਰ ਪੱਤਰੀਆਂ ਵੀ ਉਪਲਬਧ ਨਹੀਂ ਹੋਣਗੀਆਂ।",
    change: "ਬਦਲੋ",
    roles_rights: "ਭੂਮਿਕਾਵਾਂ ਅਤੇ ਅਧਿਕਾਰ",
    learn_more: "ਜਿਆਦਾ ਜਾਣੋ",
    viewers_can_read_quiz_and_view_answers: "ਦਰਸ਼ਕ ਕਵਿਜ਼ ਪੜ੍ਹ ਸਕਦੇ ਹਨ ਅਤੇ ਜਵਾਬ ਦੇਖ ਸਕਦੇ ਹਨ",
    editors_can_edit_quiz_view_answers: "ਸੰਪਾਦਕ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦੇ ਹਨ ਅਤੇ ਜਵਾਬ ਦੇਖ ਸਕਦੇ ਹਨ",
    admin_can_edit_quiz_add_collaborator: "ਪ੍ਰਸ਼ਾਸਕ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦਾ ਹੈ ਅਤੇ ਸਹਿਯੋਗੀ ਨੂੰ ਸ਼ਾਮਲ ਕਰ ਸਕਦਾ ਹੈ",
    change_roles: "ਰੋਲ ਬਦਲੋ",
    remove: "ਹਟਾਓ",
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: "ਤੁਸੀਂ ਆਪਣੀਆਂ ਤਬਦੀਲੀਆਂ ਦਰਜ ਨਹੀਂ ਕੀਤੀਆਂ ਹਨ। ਕੀ ਸਬਮਿਟ ਕੀਤੇ ਬਿਨਾਂ ਛੱਡਣਾ ਠੀਕ ਹੈ?",
    select_language: "ਭਾਸ਼ਾ ਚੁਣੋ",
    are_you_sure_you_want_delete_this_collaborator: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਸਹਿਯੋਗੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    are_you_sure_you_want_to_delete_selected_files: 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ ਤੇ ਚੁਣੀ ਗਈ ਫ਼ਾਈਲ ਨੂੰ ਪੱਕੇ ਤੌਰ ਤੇ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?',
    please_download_before_deleting: 'ਕਿਰਪਾ ਕਰਕੇ ਮਿਟਾਉਣ ਤੋਂ ਪਹਿਲਾਂ ਡਾਊਨਲੋਡ ਕਰੋ। ਇੱਕ ਵਾਰ ਮਿਟਾਏ ਜਾਣ ਵਾਲੀਆਂ ਫਾਈਲਾਂ, ਮੁੜ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੀਆਂ ਜਾ ਸਕਦੀਆਂ!',
    role_has_been_changed_successfully: "ਭੂਮਿਕਾ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਬਦਲ ਦਿੱਤਾ ਗਿਆ ਹੈ",
    do_you_want_to_leave_collaboration: "ਕੀ ਤੁਸੀਂ ਸਹਿਯੋਗ ਛੱਡਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    form_builder_access_denied: "ਫਾਰਮ ਬਿਲਡਰ ਪਹੁੰਚ ਤੋਂ ਇਨਕਾਰ ਕੀਤਾ ਗਿਆ",
    quiz_builder_access_denied: "ਕਵਿਜ਼ ਬਿਲਡਰ ਪਹੁੰਚ ਤੋਂ ਇਨਕਾਰ ਕੀਤਾ ਗਿਆ",
    you_only_have_viewer_rights_for_quiz: "ਤੁਹਾਡੇ ਕੋਲ ਇਸ ਕਵਿਜ਼ ਲਈ ਸਿਰਫ਼ ਦਰਸ਼ਕ ਅਧਿਕਾਰ ਹਨ।",
    you_only_have_viewer_rights_for_form: "ਤੁਹਾਡੇ ਕੋਲ ਇਸ ਫਾਰਮ ਲਈ ਸਿਰਫ਼ ਦਰਸ਼ਕ ਅਧਿਕਾਰ ਹਨ।",
    kindly_contact_the_form_owner_for_more_info: "ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਫਾਰਮ ਮਾਲਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
    kindly_contact_the_quiz_owner_for_more_info: "ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਕੁਇਜ਼ ਦੇ ਮਾਲਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
    incomplete_questions: "ਅਧੂਰੇ ਸਵਾਲ:",
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: "ਤੁਹਾਡੇ ਕੋਲ ਅਣਰੱਖਿਅਤ ਤਬਦੀਲੀਆਂ ਹਨ - ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਪੰਨੇ ਨੂੰ ਛੱਡਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    languages: "ਭਾਸ਼ਾਵਾਂ",
    copy: "ਕਾਪੀ ਕਰੋ",
    not_good: "ਵਧੀਆ ਨਹੀ",
    not_allowed: 'ਇਜਾਜ਼ਤ ਨਹੀਂ ਹੈ',
    not_allowed_description: 'ਜਵਾਬ ਇਕੱਠੇ ਕਰਨ ਤੋਂ ਬਾਅਦ ਚੋਣ ਪ੍ਰਸ਼ਨ ਕਿਸਮ ਨੂੰ ਬਦਲਿਆ ਨਹੀਂ ਜਾ ਸਕਦਾ।',
    neutral: "ਨਿਰਪੱਖ",
    good: "ਚੰਗਾ",
    very_good: "ਬਹੁਤ ਅੱਛਾ",
    edit_rights_removed: "ਸੋਧ ਅਧਿਕਾਰ ਹਟਾਏ ਗਏ",
    your_rights_to_edit_this_form_have_been_removed: "ਇਸ ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਦੇ ਤੁਹਾਡੇ ਅਧਿਕਾਰ ਹਟਾ ਦਿੱਤੇ ਗਏ ਹਨ।",
    your_rights_to_edit_this_quiz_have_been_removed: "ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਦੇ ਤੁਹਾਡੇ ਅਧਿਕਾਰ ਹਟਾ ਦਿੱਤੇ ਗਏ ਹਨ।",
    please_contact_form_owner_for_more_info: "ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਫਾਰਮ ਦੇ ਮਾਲਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
    please_contact_quiz_owner_for_more_info: "ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਕੁਇਜ਼ ਦੇ ਮਾਲਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
    last_edited_on: "ਆਖਰੀ ਵਾਰ ਸੰਪਾਦਿਤ ਕੀਤਾ ਗਿਆ",
    by: "ਨਾਲ",
    you: "ਤੁਹਾਨੂੰ",
    estimated_time: "ਅਨੁਮਾਨਿਤ ਸਮਾਂ",
    do_you_want_to_show_estimated_time_taking_quiz: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ ਲੈਣ ਦਾ ਅਨੁਮਾਨਿਤ ਸਮਾਂ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    do_you_want_to_show_estimated_time_taking_form: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ ਲੈਣ ਦਾ ਅਨੁਮਾਨਿਤ ਸਮਾਂ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    show_question_count: "ਪ੍ਰਸ਼ਨ ਗਿਣਤੀ ਦਿਖਾਓ",
    do_you_want_to_show_total_number_questions_quiz: "ਕੀ ਤੁਸੀਂ ਕੁਇਜ਼ ਵਿੱਚ ਪ੍ਰਸ਼ਨਾਂ ਦੀ ਕੁੱਲ ਸੰਖਿਆ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    do_you_want_to_show_total_number_questions_form: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ ਵਿੱਚ ਪ੍ਰਸ਼ਨਾਂ ਦੀ ਕੁੱਲ ਸੰਖਿਆ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    show_question_marks: "ਪ੍ਰਸ਼ਨ ਅੰਕ ਦਿਖਾਓ",
    show_question_marks_quiz: "ਕਵਿਜ਼ ਵਿੱਚ ਪ੍ਰਸ਼ਨ ਅੰਕ ਦਿਖਾਓ",
    you_dont_have_add_collaborator_permission: "ਤੁਹਾਡੇ ਕੋਲ ਸਹਿਯੋਗੀ ਨੂੰ ਸ਼ਾਮਲ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਨਹੀਂ ਹੈ",
    modified_date: "ਸੰਸ਼ੋਧਿਤ ਮਿਤੀ",
    created_date: "ਬਣਾਉਣ ਦੀ ਮਿਤੀ",
    question_cant_be_added: "ਸਵਾਲ ਸ਼ਾਮਲ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ",
    another_file_upload_question_cant_added: "ਕੋਈ ਹੋਰ ਫ਼ਾਈਲ ਅੱਪਲੋਡ ਸਵਾਲ ਸ਼ਾਮਲ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ",
    you_can_have_only: "ਤੁਹਾਡੇ ਕੋਲ ਹੀ ਹੋ ਸਕਦਾ ਹੈ",
    file_upload_questions_in_form: "ਇੱਕ ਫਾਰਮ ਵਿੱਚ ਸਵਾਲ ਅੱਪਲੋਡ ਕਰੋ",
    file_upload_questions_in_quiz: "ਇੱਕ ਕਵਿਜ਼ ਵਿੱਚ ਸਵਾਲ ਅੱਪਲੋਡ ਕਰੋ",
    storage_capacity_exceeded: "ਸਟੋਰੇਜ ਸਮਰੱਥਾ ਵੱਧ ਗਈ",
    kindly_inform_form_builder_that: "ਕਿਰਪਾ ਕਰਕੇ ਫਾਰਮ ਬਿਲਡਰ ਨੂੰ ਸੂਚਿਤ ਕਰੋ",
    kindly_inform_quiz_builder_that: "ਕਿਰਪਾ ਕਰਕੇ ਕਵਿਜ਼ ਬਿਲਡਰ ਨੂੰ ਸੂਚਿਤ ਕਰੋ",
    their_storage_capacity_file_uploads: "ਫਾਈਲ ਅੱਪਲੋਡ ਲਈ ਉਹਨਾਂ ਦੀ ਸਟੋਰੇਜ ਸਮਰੱਥਾ",
    has_been_exceeded: "ਨੂੰ ਪਾਰ ਕੀਤਾ ਗਿਆ ਹੈ",
    view_details: "ਵੇਰਵੇ ਵੇਖੋ",
    paste_your_video_link_here: "ਆਪਣਾ ਵੀਡੀਓ ਲਿੰਕ ਇੱਥੇ ਪੇਸਟ ਕਰੋ",
    paste_your_website_link_here: "ਆਪਣੀ ਵੈੱਬਸਾਈਟ ਦਾ ਲਿੰਕ ਇੱਥੇ ਪੇਸਟ ਕਰੋ",
    view_answer_sheet: "ਜਵਾਬ ਸ਼ੀਟ ਦੇਖੋ",
    response_count: "ਜਵਾਬ ਗਿਣਤੀ",
    count: "ਗਿਣਤੀ",
    password_least_8_characters: "ਪਾਸਵਰਡ ਘੱਟੋ-ਘੱਟ 8 - 50 ਅੱਖਰਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    password_contain_least_1_number: "ਪਾਸਵਰਡ ਵਿੱਚ ਘੱਟੋ-ਘੱਟ 1 ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    password_contain_least_1_capital_letter: "ਪਾਸਵਰਡ ਵਿੱਚ ਘੱਟੋ-ਘੱਟ 1 ਵੱਡੇ ਅੱਖਰ ਹੋਣੇ ਚਾਹੀਦੇ ਹਨ",
    password_contain_least_1_special_character: "ਪਾਸਵਰਡ ਵਿੱਚ ਘੱਟੋ-ਘੱਟ 1 ਵਿਸ਼ੇਸ਼ ਅੱਖਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    please_fill_all_required_fields: "ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਲੋੜੀਂਦੇ ਖੇਤਰ ਭਰੋ",
    passwords_dont_match: "ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ",
    incorrect_password: "ਗਲਤ ਪਾਸਵਰਡ",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "ਕੀ ਤੁਸੀਂ ਇਸ ਚਿੱਤਰ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਜੇਕਰ ਤੁਸੀਂ ਅੱਗੇ ਵਧਦੇ ਹੋ, ਤਾਂ ਇਹ ਚਿੱਤਰ ਕਿਸੇ ਵੀ ਫਾਰਮ ਜਾਂ ਕਵਿਜ਼ ਲਈ ਉਪਲਬਧ ਨਹੀਂ ਹੋਵੇਗਾ ਜੋ ਇਸਨੂੰ ਵਰਤ ਰਹੇ ਹਨ। ਇਸ ਕਾਰਵਾਈ ਨੂੰ ਅਨਡੂਨ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
    url_does_not_seem_to_valid: "URL ਵੈਧ ਨਹੀਂ ਜਾਪਦਾ।",
    you_have_reached_image_uploaded_limit: "ਤੁਸੀਂ ਚਿੱਤਰ ਅਪਲੋਡ ਕੀਤੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ।",
    select: "ਚੁਣੋ",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "ਨੋਟ: ਇਸ ਜਾਣਕਾਰੀ ਨੂੰ ਦੁਬਾਰਾ ਦੇਖਣ ਲਈ, ਖੱਬੇ ਪਾਸੇ ਦੇ ਮੀਨੂ ਵਿੱਚ 'ਹੋਰ' 'ਤੇ ਕਲਿੱਕ ਕਰੋ",
    do_search: "ਖੋਜ ਕਰੋ",
    clear: "Clear",
    back: "ਪਿੱਛੇ",
    Select_all_2: "ਸਭ ਚੁਣੋ",
    undo: "ਵਾਪਿਸ",
    response_deleted: "ਜਵਾਬ ਮਿਟਾਇਆ ਗਿਆ",
    question_deleted: "ਸਵਾਲ ਮਿਟਾਇਆ ਗਿਆ",
    answer_deleted: "ਜਵਾਬ ਮਿਟਾਇਆ ਗਿਆ",
    responses_deleted: "ਜਵਾਬ ਮਿਟਾਏ ਗਏ",
    questions_deleted: "ਸਵਾਲ ਮਿਟਾਏ ਗਏ",
    answers_deleted: "ਜਵਾਬ ਮਿਟਾਏ ਗਏ",
    collect_email_header: "ਮੇਲ ਇਕੱਠਾ ਕਰੋ",
    collect_email_alert_message: "ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਸਮਰੱਥ ਕਰਨ ਨਾਲ ਜਵਾਬ ਦੇਣ ਵਾਲੇ ਨੂੰ ਫਾਰਮ ਭਰਨ ਲਈ ਉਹਨਾਂ ਦੇ ਜੀਮੇਲ ਪਤੇ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰਨ ਲਈ ਮਜਬੂਰ ਕੀਤਾ ਜਾਵੇਗਾ।",
    collect_email_alert_note: "ਨੋਟ: ਸਿਰਫ਼ Google ਖਾਤਾ ਸਮਰਥਿਤ ਹੈ",
    allow_response_edit: "ਜਵਾਬ ਸੰਪਾਦਨ ਦੀ ਆਗਿਆ ਦਿਓ",
    allow_response_edit_description: "ਕੀ ਤੁਸੀਂ ਜਵਾਬ ਦੇਣ ਵਾਲਿਆਂ ਨੂੰ ਸਬਮਿਟ ਕਰਨ ਤੋਂ ਬਾਅਦ ਜਵਾਬਾਂ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    enter_desc_here: "ਇੱਥੇ ਵਰਣਨ ਦਰਜ ਕਰੋ",
    passcode_protection: "ਪਾਸਕੋਡ ਸੁਰੱਖਿਆ",
    passcode_protection_description: "ਕੀ ਤੁਸੀਂ ਚਾਹੁੰਦੇ ਹੋ ਕਿ ਤੁਹਾਡਾ ਫਾਰਮ ਪਾਸਕੋਡ ਸੁਰੱਖਿਅਤ ਹੋਵੇ?",
    passcode_protection_description_quiz: "ਕੀ ਤੁਸੀਂ ਚਾਹੁੰਦੇ ਹੋ ਕਿ ਤੁਹਾਡੀ ਕਵਿਜ਼ ਪਾਸਕੋਡ ਸੁਰੱਖਿਅਤ ਹੋਵੇ?",
    crop_image: "ਚਿੱਤਰ ਕੱਟੋ",
    crop: "ਫਸਲ",
    original: "ਮੂਲ",
    collect_email: "ਈਮੇਲ ਪਤਾ ਇਕੱਠਾ ਕਰੋ",
    collect_email_description: "ਕੀ ਤੁਸੀਂ ਸੰਭਾਵੀ ਭਵਿੱਖੀ ਸੰਦਰਭ ਜਾਂ ਉਪਯੋਗਤਾ ਲਈ ਉੱਤਰਦਾਤਾਵਾਂ ਦੇ ਈਮੇਲ ਪਤੇ ਇਕੱਠੇ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    collect_email_description_quiz: "ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਸਮਰੱਥ ਕਰਨ ਨਾਲ ਜਵਾਬ ਦੇਣ ਵਾਲਿਆਂ ਨੂੰ ਕਵਿਜ਼ ਭਰਨ ਲਈ ਉਹਨਾਂ ਦੇ ਜੀਮੇਲ ਪਤੇ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰਨ ਲਈ ਮਜਬੂਰ ਕੀਤਾ ਜਾਵੇਗਾ।",
    collect_email_description_form: "ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਸਮਰੱਥ ਕਰਨ ਨਾਲ ਜਵਾਬ ਦੇਣ ਵਾਲਿਆਂ ਨੂੰ ਫਾਰਮ ਭਰਨ ਲਈ ਉਹਨਾਂ ਦੇ ਜੀਮੇਲ ਪਤੇ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰਨ ਲਈ ਮਜਬੂਰ ਕੀਤਾ ਜਾਵੇਗਾ।",
    opening_date: 'ਖੁੱਲਣ ਦੀ ਮਿਤੀ',
    closing_date: 'ਸਮਾਪਤੀ ਮਿਤੀ',
    schedule: 'ਸਮਾਂ-ਸਾਰਣੀ',
    schedule_form_status: 'ਅਨੁਸੂਚੀ ਫਾਰਮ ਸਥਿਤੀ',
    schedule_quiz_status: 'ਕਵਿਜ਼ ਸਥਿਤੀ ਨੂੰ ਤਹਿ ਕਰੋ',
    schedule_opening_date_and_time: "ਖੁੱਲਣ ਦੀ ਮਿਤੀ ਅਤੇ ਸਮਾਂ ਤਹਿ ਕਰੋ",
    schedule_closing_date_and_time: "ਸਮਾਪਤੀ ਮਿਤੀ ਅਤੇ ਸਮਾਂ ਤਹਿ ਕਰੋ",
    schedule_opening_date_and_time_description: "ਦੱਸੀ ਗਈ ਮਿਤੀ ਅਤੇ ਸਮੇਂ 'ਤੇ ਫਾਰਮ ਨੂੰ ਐਕਟੀਵੇਟ ਕਰੋ",
    schedule_opening_date_and_time_description_quiz: "ਦੱਸੀ ਗਈ ਮਿਤੀ ਅਤੇ ਸਮੇਂ 'ਤੇ ਕਵਿਜ਼ ਨੂੰ ਐਕਟੀਵੇਟ ਕਰੋ",
    schedule_closing_date_and_time_description: "ਦੱਸੀ ਗਈ ਮਿਤੀ ਅਤੇ ਸਮੇਂ 'ਤੇ ਫਾਰਮ ਨੂੰ ਅਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
    schedule_closing_date_and_time_description_quiz: "ਦੱਸੀ ਗਈ ਮਿਤੀ ਅਤੇ ਸਮੇਂ 'ਤੇ ਕਵਿਜ਼ ਨੂੰ ਅਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
    upgrade: "ਅੱਪਗ੍ਰੇਡ",
    gold_plan: "ਗੋਲਡ ਪਲਾਨ",
    view_plans: "ਯੋਜਨਾਵਾਂ ਵੇਖੋ",
    premium_plans: "ਪ੍ਰੀਮੀਅਮ ਯੋਜਨਾਵਾਂ",
    plan_duration: "/ ਮਹੀਨਾ",
    plan_logic_jump: "ਤਰਕ ਜੰਪ",
    plan_remove_ads: "ਇਸ਼ਤਿਹਾਰ ਹਟਾਓ",
    cancel_subscription: "ਗਾਹਕੀ ਰੱਦ ਕਰੋ",
    quiz_has_answer_edit_message: "ਇਹ ਕਵਿਜ਼ ਪਹਿਲਾਂ ਹੀ ਸਪੁਰਦ ਕੀਤੀ ਜਾ ਚੁੱਕੀ ਹੈ ਅਤੇ ਜਵਾਬ ਇਕੱਠੇ ਕੀਤੇ ਜਾ ਚੁੱਕੇ ਹਨ। ਕਵਿਜ਼ ਨੂੰ ਸੋਧਣ ਨਾਲ ਪਿਛਲੇ ਜਵਾਬਾਂ 'ਤੇ ਅਸਰ ਪੈ ਸਕਦਾ ਹੈ।",
    quiz_published_alert_edit_message: "ਇਹ ਕਵਿਜ਼ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤਾ ਗਿਆ ਹੈ, ਜੇਕਰ ਤੁਸੀਂ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਦੇ ਹੋ ਤਾਂ ਅਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤਾ ਜਾਵੇਗਾ।",
    quiz_submit_after_edit_message: "ਕੁਇਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਨਾਲ ਅੰਕ, ਪ੍ਰਤੀਸ਼ਤਤਾ ਪ੍ਰਭਾਵਿਤ ਹੋ ਸਕਦੀ ਹੈ ਅਤੇ ਕੁਝ ਪ੍ਰਸ਼ਨਾਂ ਦੇ ਮੁੜ-ਮੁਲਾਂਕਣ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ।",
    title_activity_privacy_policy: "ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਗਤੀਵਿਧੀ",
    action_blocked_message: "ਇਸ ਕਵਿਜ਼ ਲਈ ਮੌਜੂਦਾ ਜਵਾਬਾਂ ਕਾਰਨ ਕਾਰਵਾਈ ਬਲੌਕ ਕੀਤੀ ਗਈ। ਇਸਦੀ ਇਜਾਜ਼ਤ ਦੇਣ ਨਾਲ ਪਿਛਲੇ ਜਵਾਬਾਂ 'ਤੇ ਅਸਰ ਪੈ ਸਕਦਾ ਹੈ।",
    email_addresses: "ਈਮੇਲ ਪਤੇ",
    confirmation_message: "ਪੁਸ਼ਟੀ ਸੁਨੇਹਾ",
    confirmation_message_description: "ਕੀ ਤੁਸੀਂ ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰਨ ਤੋਂ ਬਾਅਦ ਪੁਸ਼ਟੀਕਰਨ ਸੁਨੇਹਾ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    confirmation_message_description_quiz: "ਕੀ ਤੁਸੀਂ ਕਵਿਜ਼ ਦਾ ਜਵਾਬ ਦੇਣ ਤੋਂ ਬਾਅਦ ਪੁਸ਼ਟੀਕਰਨ ਸੁਨੇਹਾ ਦਿਖਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    default_custom_thank_message: "ਇਸ ਫਾਰਮ ਨੂੰ ਭਰਨ ਲਈ ਧੰਨਵਾਦ।",
    default_custom_thank_message_quiz: "ਇਸ ਕਵਿਜ਼ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਧੰਨਵਾਦ।",
    upgrade_your_plan: "ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ!",
    upgrade_your_plan_1: "ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
    upgrade_your_plan_content: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਐਕਸੈਸ ਕਰਨ ਅਤੇ ਸਟੋਰੇਜ ਦਾ ਆਕਾਰ ਵਧਾਉਣ ਲਈ ਪ੍ਰੀਮੀਅਮ ਸੰਸਕਰਣ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ, ਆਦਿ",
    alert_icon: "ਅਲਰਟ ਆਈਕਨ",
    usage_details: "ਵਰਤੋਂ ਦੇ ਵੇਰਵੇ",
    current_plan: "ਮੌਜੂਦਾ ਯੋਜਨਾ",
    total_submission: "ਕੁੱਲ ਸਪੁਰਦਗੀ",
    image_attachments: "ਚਿੱਤਰ ਨੱਥੀ",
    custom_themes: "ਕਸਟਮ ਥੀਮ",
    free_limit_reached: "ਤੁਸੀਂ ਮੁਫਤ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ -",
    payment_done_but_server_error: "ਤੁਹਾਡਾ ਭੁਗਤਾਨ ਸਫਲਤਾਪੂਰਵਕ ਕੱਟਿਆ ਗਿਆ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਸਾਨੂੰ ਤੁਹਾਡੇ ਭੁਗਤਾਨ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਐਪ ਖੋਲ੍ਹੋ।",
    response_quota_reached: "ਤੁਸੀਂ ਮੁਫਤ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ।",
    answers_quota_reached: "ਤੁਸੀਂ ਮੁਫਤ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ।",
    logic_jump_enabled: "ਤਰਕ ਜੰਪ ਸਮਰੱਥ ਹੈ।",
    logic_jump_disabled: "ਤਰਕ ਜੰਪ ਅਯੋਗ ਹੈ।",
    cancel_subscription_message: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਆਪਣੀ ਗਾਹਕੀ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ? ਗਾਹਕੀ ਨੂੰ ਰੱਦ ਕਰਨ ਨਾਲ ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਖਤਮ ਹੋ ਜਾਵੇਗੀ।",
    plan_expired: "ਤੁਹਾਡੀ ਯੋਜਨਾ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਡਰਾਫਟ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ।",
    plan_expired_edit_form: "ਤੁਹਾਡੀ ਯੋਜਨਾ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
    plan_expired_edit_quiz: "ਤੁਹਾਡੀ ਯੋਜਨਾ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
    plan_expired_duplicate_form: "ਤੁਹਾਡੀ ਯੋਜਨਾ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਫਾਰਮ ਨੂੰ ਡੁਪਲੀਕੇਟ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
    plan_expired_duplicate_quiz: "ਤੁਹਾਡੀ ਯੋਜਨਾ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਕਵਿਜ਼ ਨੂੰ ਡੁਪਲੀਕੇਟ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
    premium_features: "ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾ",
    plan_file_upload: "ਬੇਅੰਤ ਕਸਟਮ ਥੀਮ ਚਿੱਤਰ",
    plan_custom_theme: "ਬੇਅੰਤ ਕਸਟਮ ਥੀਮ ਚਿੱਤਰ",
    plan_call_support: "ਉੱਚ ਤਰਜੀਹ ਚਾਲੂ - ਕਾਲ ਸਹਾਇਤਾ",
    plan_email_support: "ਉੱਚ ਤਰਜੀਹ ਈਮੇਲ ਸਹਾਇਤਾ",
    plan_custom_domain: "ਕਸਟਮ ਡੋਮੇਨ",
    subscribe: "ਗਾਹਕ ਬਣੋ",
    plan_remove_branding: "ਬ੍ਰਾਂਡਿੰਗ ਹਟਾਓ",
    plan_on_call_support: "ਕਾਲ ਸਪੋਰਟ 'ਤੇ",
    plan_image_attachment: "ਬੇਅੰਤ ਚਿੱਤਰ ਅਟੈਚਮੈਂਟ",
    plan_custom_thank_you: "ਅਨੁਕੂਲਿਤ ਧੰਨਵਾਦ ਪੰਨੇ",
    plan_allowed_responses: "ਪ੍ਰਵਾਨਿਤ ਜਵਾਬ ਗਿਣਤੀ 50000",
    trusted_by_number_of_user: "5 ਮਿਲੀਅਨ ਤੋਂ ਵੱਧ ਉਪਭੋਗਤਾਵਾਂ ਦੁਆਰਾ ਭਰੋਸੇਯੋਗ",
    email_support: "ਈਮੇਲ ਸਹਾਇਤਾ",
    call_support: "ਸਹਾਇਤਾ ਨੂੰ ਕਾਲ ਕਰੋ",
    keyboard_shortcuts: "ਕੀਬੋਰਡ ਸ਼ਾਰਟਕੱਟ",
    premium_limit_reached: "ਤੁਸੀਂ ਯੋਜਨਾ ਦੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ। ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ।",
    premium_limit_reached_mobile: "ਤੁਸੀਂ ਯੋਜਨਾ ਦੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ।",
    unlimited: "ਅਸੀਮਤ",
    expired_on_next_renewal: "ਇਸਦੀ ਮਿਆਦ ਤੁਹਾਡੀ ਅਗਲੀ ਨਵਿਆਉਣ ਦੀ ਮਿਤੀ ਨੂੰ ਸਮਾਪਤ ਹੋ ਜਾਵੇਗੀ",
    upgrade_plan: "ਅਪਗ੍ਰੇਡ ਯੋਜਨਾ",
    downgrade_plan: "ਡਾਊਨਗ੍ਰੇਡ ਯੋਜਨਾ",
    file_upload_questions: "ਫਾਈਲ ਅੱਪਲੋਡ ਸਵਾਲ",
    plan_response_edit: "ਜਵਾਬ ਸੰਪਾਦਿਤ ਕਰੋ",
    cant_process: "ਪ੍ਰਕਿਰਿਆ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕਦੀ",
    you_have_subscribed_through_android: "ਤੁਸੀਂ ਸਾਡੀ Android ਐਪ ਰਾਹੀਂ ਗਾਹਕੀ ਲਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਵੀ ਭੁਗਤਾਨ ਸੰਬੰਧੀ ਅੱਪਡੇਟ ਲਈ SurveyHeart ਐਪ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
    pay_now: "ਹੁਣੇ ਭੁਗਤਾਨ ਕਰੋ",
    update_payment_details: "ਭੁਗਤਾਨ ਵੇਰਵਿਆਂ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ",
    limit_reached_alert_message: "ਤੁਸੀਂ ਮੁਫਤ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ।",
    limit_reached_alert_message_quiz: "ਤੁਸੀਂ ਮੁਫਤ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ।",
    limit_reached_UI_message: "ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਯੋਜਨਾ ਲਈ ਜਵਾਬ ਉਪਲਬਧ ਨਹੀਂ ਹੋਣਗੇ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬਾਂ ਨੂੰ ਦੇਖਣ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ।",
    limit_reached_UI_message_quiz: "ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਯੋਜਨਾ ਲਈ ਜਵਾਬ ਉਪਲਬਧ ਨਹੀਂ ਹੋਣਗੇ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ ਜਵਾਬ ਦੇਖਣ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕਰੋ।",
    owner_is_not_premium_user: "ਮਾਲਕ ਇੱਕ ਪ੍ਰੀਮੀਅਮ ਉਪਭੋਗਤਾ ਨਹੀਂ ਹੈ",
    cancel_subscription_description: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਆਪਣੀ ਗਾਹਕੀ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ? ਰੱਦ ਕਰਨ ਦੇ ਨਤੀਜੇ ਵਜੋਂ ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਖਤਮ ਹੋ ਜਾਵੇਗੀ।",
    starter_storage_description: "ਸਖਤ ਡਾਟਾ ਪ੍ਰਬੰਧਨ ਲਈ 5GB ਸਟੋਰੇਜ ਪ੍ਰਾਪਤ ਕਰੋ।",
    bronze_storage_description: "ਸਖਤ ਡਾਟਾ ਪ੍ਰਬੰਧਨ ਲਈ 25GB ਸਟੋਰੇਜ ਪ੍ਰਾਪਤ ਕਰੋ।",
    silver_storage_description: "ਸਖਤ ਡਾਟਾ ਪ੍ਰਬੰਧਨ ਲਈ 50GB ਸਟੋਰੇਜ ਪ੍ਰਾਪਤ ਕਰੋ।",
    gold_storage_description: "ਸਖਤ ਡਾਟਾ ਪ੍ਰਬੰਧਨ ਲਈ 150GB ਸਟੋਰੇਜ ਪ੍ਰਾਪਤ ਕਰੋ।",
    starter_responses_description: "ਸਰਵੇਖਣ ਦੀ ਸ਼ਕਤੀਸ਼ਾਲੀ ਸੂਝ ਹਾਸਲ ਕਰਨ ਲਈ 10,000 ਜਵਾਬਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
    bronze_responses_description: "ਸਰਵੇਖਣ ਦੀ ਸ਼ਕਤੀਸ਼ਾਲੀ ਸੂਝ ਹਾਸਲ ਕਰਨ ਲਈ 35,000 ਜਵਾਬਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
    silver_responses_description: "ਸਰਵੇਖਣ ਦੀ ਸ਼ਕਤੀਸ਼ਾਲੀ ਸੂਝ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ 75,000 ਜਵਾਬਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
    gold_responses_description: "ਸਰਵੇਖਣ ਦੀ ਸ਼ਕਤੀਸ਼ਾਲੀ ਸੂਝ ਹਾਸਲ ਕਰਨ ਲਈ 1,50,000 ਜਵਾਬਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
    starter_image_description: "ਵਿਜ਼ੂਅਲ ਅਪੀਲ ਲਈ 100 ਚਿੱਤਰਾਂ ਤੱਕ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਧਾਓ।",
    bronze_image_description: "ਵਿਜ਼ੂਅਲ ਅਪੀਲ ਲਈ 200 ਚਿੱਤਰਾਂ ਤੱਕ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਧਾਓ।",
    silver_image_description: "ਵਿਜ਼ੂਅਲ ਅਪੀਲ ਲਈ 500 ਚਿੱਤਰਾਂ ਤੱਕ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਧਾਓ।",
    gold_image_description: "ਵਿਜ਼ੂਅਲ ਅਪੀਲ ਲਈ ਅਸੀਮਤ ਚਿੱਤਰਾਂ ਨਾਲ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਧਾਓ।",
    starter_theme_description: "ਆਪਣੇ ਬ੍ਰਾਂਡ ਲਈ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਉਣ ਲਈ 100 ਕਸਟਮ ਚਿੱਤਰ ਸ਼ਾਮਲ ਕਰੋ।",
    bronze_theme_description: "ਆਪਣੇ ਬ੍ਰਾਂਡ ਲਈ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਉਣ ਲਈ 200 ਕਸਟਮ ਚਿੱਤਰ ਸ਼ਾਮਲ ਕਰੋ।",
    silver_theme_description: "ਆਪਣੇ ਬ੍ਰਾਂਡ ਲਈ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਉਣ ਲਈ 500 ਕਸਟਮ ਚਿੱਤਰ ਸ਼ਾਮਲ ਕਰੋ।",
    gold_theme_description: "ਆਪਣੇ ਬ੍ਰਾਂਡ ਲਈ ਆਪਣੇ ਸਰਵੇਖਣਾਂ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਉਣ ਲਈ ਅਸੀਮਤ ਕਸਟਮ ਚਿੱਤਰ ਸ਼ਾਮਲ ਕਰੋ।",
    custom_theme_image: "ਕਸਟਮ ਥੀਮ ਚਿੱਤਰ",
    starter_upload_description: "4 ਫਾਈਲ ਅਪਲੋਡ ਸਵਾਲਾਂ ਨੂੰ ਸਹਿਜੇ ਹੀ ਏਕੀਕ੍ਰਿਤ ਕਰੋ।",
    file_upload_description: "ਬੇਅੰਤ ਫਾਈਲ ਅਪਲੋਡ ਸਵਾਲਾਂ ਨੂੰ ਸਹਿਜੇ ਹੀ ਏਕੀਕ੍ਰਿਤ ਕਰੋ।",
    logic_jump_description: "ਹਰੇਕ ਜਵਾਬਦੇਹ ਦੇ ਅਨੁਭਵ ਲਈ ਸਰਵੇਖਣ ਪ੍ਰਵਾਹ ਨੂੰ ਅਨੁਕੂਲਿਤ ਕਰਨ ਲਈ ਤਰਕ ਜੰਪ ਦੀ ਵਰਤੋਂ ਕਰੋ",
    thank_you_message_description: "ਉੱਤਰਦਾਤਾਵਾਂ ਨੂੰ ਪ੍ਰਭਾਵਿਤ ਕਰਨ ਲਈ ਧੰਨਵਾਦ ਪੰਨਿਆਂ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਓ।",
    response_edit_description: "ਜਵਾਬ ਸੰਪਾਦਨ ਵਿਸ਼ੇਸ਼ਤਾ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਜਵਾਬਾਂ ਨੂੰ ਆਸਾਨੀ ਨਾਲ ਸੋਧੋ।",
    response_addon_description: "ਹੋਰ ਜਾਣਕਾਰੀ ਇਕੱਠੀ ਕਰਨ ਲਈ ਵਾਧੂ ਜਵਾਬ ਪ੍ਰਾਪਤ ਕਰੋ।",
    remove_branding_description: "SurveyHeart ਬ੍ਰਾਂਡਿੰਗ ਨੂੰ ਹਟਾ ਕੇ ਆਪਣੀ ਬ੍ਰਾਂਡ ਦੀ ਮੌਜੂਦਗੀ ਨੂੰ ਵਧਾਓ।",
    bronze_remove_ads_description: "ਸਾਡੀ ਕਾਂਸੀ ਯੋਜਨਾ ਨਾਲ ਵਿਗਿਆਪਨ-ਮੁਕਤ ਹੋਵੋ ਅਤੇ ਆਪਣੇ ਸਰਵੇਖਣਾਂ 'ਤੇ ਧਿਆਨ ਕੇਂਦਰਿਤ ਕਰੋ।",
    silver_remove_ads_description: "ਸਾਡੀ ਸਿਲਵਰ ਪਲਾਨ ਨਾਲ ਵਿਗਿਆਪਨ-ਮੁਕਤ ਹੋਵੋ ਅਤੇ ਆਪਣੇ ਸਰਵੇਖਣਾਂ 'ਤੇ ਧਿਆਨ ਕੇਂਦਰਿਤ ਕਰੋ।",
    gold_remove_ads_description: "ਸਾਡੀ ਗੋਲਡ ਪਲਾਨ ਨਾਲ ਵਿਗਿਆਪਨ-ਮੁਕਤ ਹੋਵੋ ਅਤੇ ਆਪਣੇ ਸਰਵੇਖਣਾਂ 'ਤੇ ਧਿਆਨ ਕੇਂਦਰਿਤ ਕਰੋ।",
    bronze_email_support_description: "ਘੱਟ ਤਰਜੀਹ ਲਈ ਈਮੇਲ ਸਹਾਇਤਾ ਤੱਕ ਪਹੁੰਚ ਕਰੋ, ਸਮੇਂ ਸਿਰ ਸਹਾਇਤਾ ਯਕੀਨੀ ਬਣਾਓ।",
    silver_email_support_description: "ਸਮੇਂ ਸਿਰ ਸਹਾਇਤਾ ਨੂੰ ਯਕੀਨੀ ਬਣਾਉਣ ਲਈ, ਮੱਧਮ-ਪਹਿਲਤਾ ਲਈ ਈਮੇਲ ਸਹਾਇਤਾ ਤੱਕ ਪਹੁੰਚ ਕਰੋ।",
    gold_email_support_description: "ਉੱਚ-ਪ੍ਰਾਥਮਿਕਤਾ ਲਈ ਈਮੇਲ ਸਹਾਇਤਾ ਤੱਕ ਪਹੁੰਚ ਕਰੋ, ਸਮੇਂ ਸਿਰ ਸਹਾਇਤਾ ਯਕੀਨੀ ਬਣਾਓ।",
    bronze_email_support_title: "ਘੱਟ ਤਰਜੀਹੀ ਈਮੇਲ ਸਹਾਇਤਾ",
    silver_email_support_title: "ਮੱਧਮ ਤਰਜੀਹੀ ਈਮੇਲ ਸਹਾਇਤਾ",
    gold_email_support_title: "ਉੱਚ ਤਰਜੀਹ ਈਮੇਲ ਸਹਾਇਤਾ",
    update_your_payment_details_mobile: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਭੁਗਤਾਨ ਵੇਰਵੇ ਅੱਪਡੇਟ ਕਰੋ।",
    update_your_payment_details_pc: "ਸਾਨੂੰ ਇਸ ਖਾਤੇ ਦਾ ਨਵੀਨੀਕਰਨ ਕਰਨ ਵਿੱਚ ਕੁਝ ਸਮੱਸਿਆਵਾਂ ਦਾ ਸਾਹਮਣਾ ਕਰਨਾ ਪੈ ਰਿਹਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਭੁਗਤਾਨ ਵੇਰਵੇ ਅੱਪਡੇਟ ਕਰੋ।",
    card_details_updated_successfully: "ਕਾਰਡ ਦੇ ਵੇਰਵੇ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤੇ ਗਏ",
    card_details_updated_successfully_description: "ਤੁਹਾਡੇ ਕਾਰਡ ਵੇਰਵਿਆਂ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ। ਤੁਸੀਂ ਹੁਣ ਆਪਣੀ ਅੱਪਡੇਟ ਕੀਤੀ ਭੁਗਤਾਨ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹੋ।",
    payment_successful: "ਭੁਗਤਾਨ ਸਫਲ",
    payment_successful_description: "ਤੁਹਾਡਾ ਭੁਗਤਾਨ ਸਫਲ ਹੋ ਗਿਆ ਹੈ। ਤੁਸੀਂ ਹੁਣ SurveyHeart ਦੇ ਪ੍ਰੀਮੀਅਮ ਪਲਾਨ ਦੇ ਗਾਹਕ ਬਣ ਗਏ ਹੋ। ਆਰਾਮਦਾਇਕ ਬਣੋ ਅਤੇ ਤੁਹਾਡੇ ਰਾਹ ਵਿੱਚ ਆਉਣ ਵਾਲੀਆਂ ਸਾਰੀਆਂ ਸ਼ਾਨਦਾਰ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦਾ ਆਨੰਦ ਮਾਣੋ।",
    payment_failed: "ਭੁਗਤਾਨ ਅਸਫਲ!",
    payment_failed_description: "ਬਦਕਿਸਮਤੀ ਨਾਲ। ਅਸੀਂ SurveyHeart ਦੇ ਪ੍ਰੀਮੀਅਮ ਪਲਾਨ ਲਈ ਤੁਹਾਡੀ ਗਾਹਕੀ ਦੀ ਪ੍ਰਕਿਰਿਆ ਨਹੀਂ ਕਰ ਸਕੇ। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    note_for_five_rupees: "ਨੋਟ: ਤੁਹਾਡੇ ਕਾਰਡ ਦੇ ਵੇਰਵਿਆਂ ਨੂੰ ਅੱਪਡੇਟ ਕਰਨ ਲਈ 5 ਰੁਪਏ ਦਾ ਚਾਰਜ ਹੈ, ਜੋ \n ਉਸੇ ਖਾਤੇ ਵਿੱਚ 3 ਤੋਂ 4 ਕਾਰੋਬਾਰੀ ਦਿਨਾਂ ਵਿੱਚ ਵਾਪਸ ਕਰ ਦਿੱਤਾ ਜਾਵੇਗਾ।",
    logic: "ਤਰਕ",
    re_generate_code: "ਕੋਡ ਦੁਬਾਰਾ ਤਿਆਰ ਕਰੋ",
    plan_change_restrictions: "ਯੋਜਨਾ ਬਦਲਣ ਦੀਆਂ ਪਾਬੰਦੀਆਂ",
    plan_change_restriction_desc_1: "ਤੁਸੀਂ UPI ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਆਪਣੀ ਯੋਜਨਾ ਨਹੀਂ ਬਦਲ ਸਕਦੇ।",
    plan_change_restriction_desc_2: "ਅੱਪਗ੍ਰੇਡਾਂ ਅਤੇ ਡਾਊਨਗ੍ਰੇਡਾਂ ਲਈ ਇੱਕ ਕਾਰਡ 'ਤੇ ਜਾਓ, ਜਾਂ ਰੱਦ ਕਰੋ ਅਤੇ ਆਪਣੀ ਲੋੜੀਦੀ ਯੋਜਨਾ ਦੀ ਮੁੜ ਗਾਹਕੀ ਲਓ।",
    update_card_details: "ਕਾਰਡ ਦੇ ਵੇਰਵੇ ਅੱਪਡੇਟ ਕਰੋ",
    features_and_plans: "ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਅਤੇ ਯੋਜਨਾਵਾਂ",
    cancel_plan: "ਯੋਜਨਾ ਰੱਦ ਕਰੋ",
    cancel_plan_desc: "ਤੁਸੀਂ ਯੋਜਨਾ ਨੂੰ ਰੱਦ ਕਰ ਦਿੱਤਾ ਹੈ। ਤੁਹਾਡੀ ਨਵਿਆਉਣ ਦੀ ਮਿਤੀ ਤੱਕ ਤੁਹਾਡੇ ਕੋਲ ਅਜੇ ਵੀ ਅਦਾਇਗੀ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਹੋਵੇਗੀ।",
    explore_text: "ਅੱਜ ਸਾਡੇ ਪ੍ਰੀਮੀਅਮ ਸਬਸਕ੍ਰਿਪਸ਼ਨ ਪਲਾਨ ਵਿੱਚੋਂ ਇੱਕ ਵਿੱਚ ਬੇਮਿਸਾਲ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ ਅਤੇ ਅਨਲੌਕ ਕਰੋ",
    cant_edit_this_form: "ਇਸ ਫਾਰਮ ਨੂੰ ਸੰਪਾਦਿਤ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ",
    cant_edit_this_quiz: "ਇਸ ਕਵਿਜ਼ ਨੂੰ ਸੰਪਾਦਿਤ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ",
    cant_duplicate_this_form: "ਇਸ ਫਾਰਮ ਦੀ ਡੁਪਲੀਕੇਟ ਨਹੀਂ ਕਰ ਸਕਦਾ",
    cant_duplicate_this_quiz: "ਇਸ ਕਵਿਜ਼ ਦੀ ਡੁਪਲੀਕੇਟ ਨਹੀਂ ਕਰ ਸਕਦਾ",
    kindly_upgrade_the_premium: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਐਕਸੈਸ ਕਰਨ ਲਈ ਪ੍ਰੀਮੀਅਮ ਸੰਸਕਰਣ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ।",
    check_box: 'ਚੈੱਕ ਬਾਕਸ',
    continue_to_next_question: "ਅਗਲੇ ਸਵਾਲ 'ਤੇ ਜਾਰੀ ਰੱਖੋ",
    add_logic: "ਤਰਕ ਸ਼ਾਮਲ ਕਰੋ",
    submit_form: "ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰੋ",
    save_logic: "ਤਰਕ ਬਚਾਓ",
    remove_logic: "ਤਰਕ ਹਟਾਓ",
    responses_add_ons: "ਜਵਾਬ ਐਡ-ਆਨ",
    responses_add_available: "ਜਵਾਬ ਐਡ-ਆਨ ਉਪਲਬਧ ਹੈ",
    additional_submissions: "ਵਧੀਕ ਬੇਨਤੀਆਂ",
    validity: "ਵੈਧਤਾ",
    up_to_your_current_plan: "ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਯੋਜਨਾ ਤੱਕ",
    responses_add_ons_description: "ਇਹ ਜਵਾਬ ਗਿਣਤੀ ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਯੋਜਨਾ ਵਿੱਚ ਸ਼ਾਮਲ ਕੀਤੀ ਜਾਵੇਗੀ।",
    redirect_url_title: "ਲੋੜੀਂਦੇ ਲਿੰਕ 'ਤੇ ਰੀਡਾਇਰੈਕਟ ਕਰੋ",
    redirect_url_description: "ਕੀ ਤੁਸੀਂ ਆਪਣੇ ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਕਿਸੇ ਬਾਹਰੀ ਵੈਬਪੇਜ 'ਤੇ ਰੀਡਾਇਰੈਕਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    enter_weblink: "ਵੈਬਲਿੰਕ ਦਾਖਲ ਕਰੋ",
    get: "ਪ੍ਰਾਪਤ ਕਰੋ",
    jump_to: "'ਤੇ ਜਾਓ",
    action_cannot_be_performed: "ਕਾਰਵਾਈ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕਦੀ",
    upgrade_plan_desc1: "ਕੀ ਤੁਸੀਂ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    downgrade_plan_desc1: "ਕੀ ਤੁਸੀਂ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਡਾਊਨਗ੍ਰੇਡ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    downgrade_plan_desc2: "ਇਹ ਤਬਦੀਲੀ ਤੁਹਾਡੀ ਅਗਲੀ ਨਵਿਆਉਣ ਦੀ ਮਿਤੀ ਤੋਂ ਪ੍ਰਭਾਵੀ ਹੋਵੇਗੀ।",
    upgraded_plan: "ਅਪਗ੍ਰੇਡ ਕੀਤੀ ਯੋਜਨਾ",
    downgraded_plan: "ਡਾਊਨਗ੍ਰੇਡ ਕੀਤੀ ਯੋਜਨਾ",
    upgraded_plan_desc: "ਤੁਸੀਂ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅੱਪਗ੍ਰੇਡ ਕੀਤਾ ਹੈ। ਇਹ ਤਬਦੀਲੀ ਤੁਹਾਡੀ ਅਗਲੀ ਨਵਿਆਉਣ ਦੀ ਮਿਤੀ ਤੋਂ ਪ੍ਰਭਾਵੀ ਹੋਵੇਗੀ।",
    downgraded_plan_desc: "ਤੁਸੀਂ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਡਾਊਨਗ੍ਰੇਡ ਕੀਤਾ ਹੈ। ਇਹ ਤਬਦੀਲੀ ਤੁਹਾਡੀ ਅਗਲੀ ਨਵਿਆਉਣ ਦੀ ਮਿਤੀ ਤੋਂ ਪ੍ਰਭਾਵੀ ਹੋਵੇਗੀ।",
    buy_responses: "ਜਵਾਬ ਖਰੀਦੋ",
    passcode: "ਪਾਸਕੋਡ",
    share_link_with_passcode: "ਪਾਸਕੋਡ ਨਾਲ ਲਿੰਕ ਸਾਂਝਾ ਕਰੋ",
    contact_us: "ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    premium_limit_reached_for_gold: "ਤੁਸੀਂ ਯੋਜਨਾ ਦੀ ਸੀਮਾ 'ਤੇ ਪਹੁੰਚ ਗਏ ਹੋ। ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
    follow_us: "ਸਾਡੇ ਪਿਛੇ ਆਓ",
    billing_history: "ਬਿਲਿੰਗ ਇਤਿਹਾਸ",
    your_current_plan: "ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਯੋਜਨਾ",
    next_billing_date: "ਅਗਲੀ ਬਿਲਿੰਗ ਮਿਤੀ",
    transaction_id: "ਲੈਣ-ਦੇਣ ਆਈ.ਡੀ",
    plan: "ਯੋਜਨਾ",
    price: "ਕੀਮਤ",
    status: "ਸਥਿਤੀ",
    follow_us_desc: "ਅੱਪਡੇਟ ਰਹੋ, ਜੁੜੇ ਰਹੋ ਅਤੇ ਸਾਡੇ ਨਾਲ ਜੁੜੇ ਰਹੋ",
    ranking_choice: 'ਦਰਜਾਬੰਦੀ ਦੀ ਚੋਣ',
    ranking: 'ਦਰਜਾਬੰਦੀ',
    picture_choice: 'ਤਸਵੀਰ ਦੀ ਚੋਣ',
    agreement: 'ਸਮਝੌਤਾ',
    signature: "ਦਸਤਖਤ",
    slider: 'ਸਲਾਈਡਰ',
    min: 'ਘੱਟੋ-ਘੱਟ',
    max: 'ਅਧਿਕਤਮ',
    step_value: "ਕਦਮ ਮੁੱਲ",
    scale_range_error: "ਸਕੇਲ ਰੇਂਜ -10,000 ਅਤੇ 10,000 ਦੇ ਵਿਚਕਾਰ ਇੱਕ ਮੁੱਲ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ।",
    scale_range_error2: "ਅਧਿਕਤਮ ਸਕੇਲ ਦਾ ਮੁੱਲ ਸਵੀਕਾਰਯੋਗ ਮੁੱਲ ਤੋਂ ਘੱਟ ਹੈ।",
    max_min_error: "ਨਿਊਨਤਮ ਸਕੇਲ ਦਾ ਮੁੱਲ ਅਧਿਕਤਮ ਸਕੇਲ ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
    step_error: "ਕਦਮ ਦਾ ਆਕਾਰ ਵਿਚਕਾਰ ਇੱਕ ਮੁੱਲ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    add_picture: 'ਤਸਵੀਰ ਸ਼ਾਮਲ ਕਰੋ',
    terms_and_conditions: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
    terms_and_conditions_required: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ ਦੀ ਲੋੜ ਹੈ",
    i_agree: "ਮੈਂ ਸਹਿਮਤ ਹਾਂ l",
    i_disagree: "ਮੈਂ ਅਸਹਿਮਤ ਹਾਂ",
    yearly: "ਸਾਲਾਨਾ",
    monthly: "ਮਹੀਨਾਵਾਰ",
    save_upto_20: "ਸਾਲਾਨਾ ਯੋਜਨਾਵਾਂ ਨਾਲ 20% ਤੱਕ ਦੀ ਬਚਤ ਕਰੋ",
    paid: "ਭੁਗਤਾਨ ਕੀਤਾ",
    cancelled: "ਰੱਦ ਕਰ ਦਿੱਤਾ",
}