import React, {useEffect, useRef} from 'react'
import "@material/card/dist/mdc.card.css"
import '../css/material-text-field.css'
import {MDCTextField} from "@material/textfield/index"
import {translationKeys} from "../../localizations/translationKeys-localization";
import {t} from "i18next";
import * as DOMPurify from 'dompurify';

export const TEXT_FIELD_TYPES = {
    OUTLINED: 'outlined',
    DEFAULT: 'default',
}

const MaterialTextField = props => {
    const selectedLanguage = localStorage?.getItem("i18nextLng")
    const isOutlined = props.textFieldType ? (props.textFieldType === TEXT_FIELD_TYPES.OUTLINED) : false;
    const MDCTextFieldRef = useRef(null);
    const errorLineRef = useRef(null);
    const isValidUrlRef = useRef(null);
    const textInputRef = props.inputRef ? props.inputRef : useRef(null);

    useEffect(() => {
        const MDCText = new MDCTextField(MDCTextFieldRef.current);

        if (props.data.defaultValue !== undefined) {
            if (props.data.inputType === 'number') {
                if (!isNaN(props.data.defaultValue)) {
                    MDCText.value = props.data.defaultValue;
                }
            } else {
                MDCText.value = props.data.defaultValue;
            }
        }
    })

    useEffect(() => {
        if (props.forceUpdateRefVarFocus) {
            if (textInputRef.current) {
                textInputRef.current.focus()
            }
            props.setForceUpdateRefVarFocus(false)

            if (props.highlightText) {
                textInputRef.current.select()
                props.removeHighlightText()
            }
        }
    }, [props.forceUpdateRefVarFocus])


    useEffect(() => {
        if (props.setFocus) {
            MDCTextFieldRef.current.classList.remove('mdc-text-field--invalid')
        }
    }, [])

    useEffect(() => {
        if (props.setFocus) {
            textInputRef.current.focus()

            if (props.setForceUpdateRefVarFocus) {
                props.setForceUpdateRefVarFocus()
            }
        }
    }, [props.setFocus])

    const handleBlur = () => {
        if (textInputRef.current) {
            // Make sure that there are no leading or trailing spaces for the text
            const trimmedText = textInputRef.current.value.trim();

            if (props.required) {
                if (isOutlined) {
                    if (trimmedText.length === 0) {
                        MDCTextFieldRef.current.classList.add("mdc-text-field--invalid")
                    }
                    if (props.errorText?.current !== undefined) {
                        if (trimmedText.length === 0) {
                            props.errorText.current = t(translationKeys.correct_answer_required)
                        } else {
                            props.errorText.current = ''
                        }
                    }
                }
                if (props.onBlur) {
                    props.onBlur(trimmedText)
                } else {
                    props.onChange(trimmedText)
                }
            } else if (props.defaultToOne) {
                if ((trimmedText.length === 0 || props.data.inputType === 'number' && trimmedText.length < 1)) {
                    props.onChange(1)
                }
            } else if (props.onBlur) {
                props.onBlur(trimmedText)
            } else if (props.onChange) {
                props.onChange(trimmedText)
            }

            if (props.required && errorLineRef?.current) {
                textInputRef.current.value = trimmedText;
                if (props.validateURL) {
                    if (isValidUrlRef.current) {
                        errorLineRef.current.style.setProperty('visibility', 'hidden')
                    } else {
                        errorLineRef.current.style.setProperty('visibility', 'visible')
                    }
                } else if (trimmedText.length > 0) {
                    errorLineRef.current.style.setProperty('visibility', 'hidden')
                } else if (trimmedText.length < 1) {
                    errorLineRef.current.style.setProperty('visibility', 'hidden')
                } else {
                    errorLineRef.current.style.setProperty('visibility', 'visible')
                }
            }
            if (props.maxLength && props.data.inputType === 'number') {
                if (textInputRef.current && (textInputRef.current.value.length > props.maxLength)) {
                    props.onChange(textInputRef.current.value.slice(0, props.maxLength))
                }
            }
        }
    }
    const handleChange = () => {
        if (textInputRef?.current) {
            const sanitizedValue = DOMPurify.sanitize(textInputRef.current.value)
            const trimmedText = sanitizedValue.trim();

            if (props.validateURL) {
                if (trimmedText.length === 0) {
                    isValidUrlRef.current = false
                    props.onChange(trimmedText, false)
                } else {
                    let urlPattern = new RegExp("^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$")
                    let result = urlPattern.test(trimmedText)

                    if (result) {
                        isValidUrlRef.current = true
                        props.onChange(sanitizedValue, true)
                    } else {
                        isValidUrlRef.current = false
                        props.onChange(sanitizedValue, false)
                    }
                }
            }

            if (errorLineRef?.current) {
                if (props.validateURL) {
                    if (isValidUrlRef.current) {
                        errorLineRef.current.style.setProperty('visibility', 'hidden')
                    } else {
                        errorLineRef.current.style.setProperty('visibility', 'visible')
                        errorLineRef.current.style.setProperty('color', 'red')
                    }
                } else if (trimmedText.length > 0) {
                    errorLineRef.current.style.setProperty('visibility', 'hidden')
                } else {
                    errorLineRef.current.style.setProperty('visibility', 'visible')
                    errorLineRef.current.style.setProperty('color', 'red')
                }
            }
            if (props.onChange && !props.validateURL) {
                if (props.includeIndexInOnChange) {
                    props.onChange(props.index, sanitizedValue)
                } else {
                    props.onChange(sanitizedValue)
                }
            }
        }
    }
    const handleFocus = () => {
        if (errorLineRef?.current) {
            if (textInputRef.current.value.trim() === '') {
                errorLineRef.current.style.setProperty('visibility', 'visible')
            } else {
                errorLineRef.current.style.setProperty('visibility', 'hidden')
            }
        }

        if (props.handleFocus) {
            props.handleFocus(props.index)
        }
    }
    const getMaxNumber = () => {
        let maxNumber = ''
        for (let i = 0; i < props.maxLength; i++) {
            maxNumber += '9'
        }
        return maxNumber
    }

    return (
        <div id={'material_field_' + props.data.hint ? props.data.hint : props.index}
             className={isOutlined ? 'full-width' : props.applyV2CSS ? 'material-text-field-v2' : 'material-text-field'}
             style={props.noLabel ? props.textFieldStyle ? props.textFieldStyle : {paddingTop: props.hideHelpLine ? 0 : null} : null}>
            <label id={'label_field_' + props.data.hint}
                   className={`mdc-text-field ${isOutlined ? 'mdc-text-field--outlined ' : 'mdc-text-field--filled '}
            ${props.data.leadingIcon ? 'mdc-text-field--with-leading-icon ' : ''}mdc-theme--secondary ${props.noLabel ? 'mdc-text-field--no-label' : ''}
            ${props.data.textArea ? 'mdc-text-field--textarea' : ''}`}
                   style={isOutlined ? {
                       '--mdc-theme-primary': '#1976D2',
                       '--mdc-theme-secondary': '#1976D2',
                       ...props.customStyleObject ? props.customStyleObject : null
                   } : null}
                   ref={MDCTextFieldRef}>

                {!isOutlined ? <span className="mdc-text-field__ripple"/> :
                    <OutLinedSpecificSpanElements hint={props.data.hint ? props.data.hint : props.index}/>}

                {props.data.leadingIcon ?
                    <i className="material-icons mdc-text-field__icon mdc-text-field__icon--leading">
                        {props.data.leadingIcon}</i>
                    : null}
                {props.data.textArea ? <textarea
                    ref={textInputRef}
                    readOnly={props.data.isReadOnly ? props.data.isReadOnly : false}
                    className="mdc-text-field__input" rows="3" aria-label="Label"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={props.required ? 'required' : null}
                    maxLength={props.maxLength ? props.maxLength : null}
                /> : <input type={props.data.inputType ? props.data.inputType : "text"}
                            id={props.id}
                            ref={textInputRef}
                            required={props.required ? 'required' : null}
                            readOnly={props.data.isReadOnly ? props.data.isReadOnly : false}
                            style={{
                                fontSize: props.customStyleObject?.fontSize ? props.customStyleObject.fontSize : null,
                                textAlign: props.customStyleObject?.textAlign ? props.customStyleObject.textAlign : null,
                                color: props.customStyleObject?.color ? props.customStyleObject.color : null
                            }}
                            className="mdc-text-field__input"
                            aria-labelledby="my-label-id"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            autoFocus={props.autoFocus}
                            onKeyDown={props.onKeyDown}
                            placeholder={props.placeholder ? props.placeholder : null}
                            maxLength={props.maxLength ? props.maxLength : null}
                            max={props.maxLength && props.data.inputType === 'number' ? getMaxNumber() : null}
                            min={props.maxLength && props.data.inputType === 'number' ? 1 : null}
                            onInput={props.data.inputType === 'number' ? () => {
                                props.onChange(textInputRef.current.value.replace(/[^0-9]/g, 1))
                                textInputRef.current.value = textInputRef.current.value.replace(/[^0-9]/g, 1)
                            } : null}
                />}
                {isOutlined ? null :
                    <>
                        <span className="mdc-floating-label" style={selectedLanguage === 'te' ? {height: '-webkit-fill-available'} : null}>{props.data.hint}</span>
                        <span className="mdc-line-ripple"/>
                    </>}
            </label>
            <div className="mdc-text-field-helper-line">
                {props.required ? <div
                    style={{visibility: 'hidden'}}
                    className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                    aria-hidden="true" role={'alert'}
                    ref={errorLineRef}>
                    {props.errorText.current}
                </div> : null}
                {props.maxLength ? <div style={!isOutlined && !props.applyV2CSS ? {color: 'white'} : null}
                                        className="mdc-text-field-character-counter">
                        {!isOutlined ? ((props.defaultValue ? props.defaultValue.length : 0) / props.maxLength) : null}
                    </div>
                    : null}
            </div>
        </div>
    )
}
export default MaterialTextField

const OutLinedSpecificSpanElements = (props) => {
    return (
        <span className="mdc-notched-outline">
            <span className="mdc-notched-outline__leading"/>
            <span className="mdc-notched-outline__notch">
                <span className="mdc-floating-label" style={{maxWidth: "fit-content"}}>{props.hint}</span>
            </span>
            <span className="mdc-notched-outline__trailing"/>
        </span>
    )
}