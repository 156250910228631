export const translationsMarathi = {
    all: 'सर्व',
    ago: "पूर्वी",
    now: "आता",
    start: "शुरू करा",
    account_blocked: "अकाउन्ट ब्लाॅक करण्यात आले आहेत",
    account_blocked_alert: "आमच्या अटी व शर्तींचे उल्लंघन केल्या बद्दल हे अकाउंट ब्लाॅक करण्यात आले आहेत",
    sign_out: "साईन आऊट करा",
    short_answer: "लहान उत्तर",
    long_answer: "लांब उत्तर",
    email: "ई-मेल",
    number: "संख्या",
    file: "फाइल",
    files: "फाईल्स",
    faq_videos: "FAQ व्हिडिओ",
    file_upload: "फाइल अपलोड",
    question_error: "प्रश्न जोडला जाऊ शकत नाही",
    storage: "स्टोरेज",
    form: "फॉर्म",
    quiz: "प्रश्नोत्तरी",
    fup_desc: "तुम्‍ही आता तुमच्‍या फॉर्म आणि क्विझमध्‍ये प्रतिसादकर्त्यांना फाइल अपलोड करण्‍याची अनुमती देऊ शकता!\n\nप्रारंभ करण्यासाठी फाइल अपलोड प्रश्न प्रकार निवडा.",
    new_fup_feature_title: "नवीन फाइल अपलोड वैशिष्ट्य!",
    multiple_choice: "एकाधिक निवड",
    date_text: "दिनांक",
    time_text: "वेळ",
    uploading: "अपलोड करीत आहे",
    add_question: "प्रश्न जोडा",
    add_question_alert: "कृपया, कमित कमी एक प्रश्न योग्य निवडांसह जोडा",
    add_question_alert_quiz: "प्रश्नोत्तरी सबमिट करण्यापूर्वी कृपया किमान एक प्रश्न जोडा",
    ok: "ठीक आहे",
    title: "शीर्षक",
    title_required: "शीर्षक आवश्यक आहे",
    form_name_alert: "कृपया, फाॅर्म पब्लिश करण्या आधि नाव द्या",
    quiz_name_alert: "कृपया प्रश्नोत्तराच्या प्रकाशित करण्यापूर्वी त्याचे नाव द्या",
    no_connection: "कनेक्शन नाही आहे",
    check_connection: "कृपया, आपलं नेटवर्क कनेक्शन चेक करून घ्या",
    finish_incomplete_question: "कृपया, फाॅर्मच्या सगळी अपूर्ण प्रश्न पूर्ण करा",
    draft: "ड्राफ्ट",
    save_to_draft_alert: "आपण आपल्या फाॅर्ममध्ये केलेले बदल गमावू नये म्हणून जतन करू इच्छिता?",
    save_to_draft_quiz_alert: "आपण आपले बदल गमावू नयेत म्हणून हा क्विझ ड्राफ्ट म्हणून जतन करू इच्छिता?",
    yes: "हो",
    no: "नाही",
    cancel: "कैन्सल करा",
    live_preview: "लाईव प्रीव्यू",
    themes: "थीम",
    tap_to_set_theme: "फार्मचा थीम सेट करण्यासाठी येथे टैप करें",
    select_image: "फोटो निवडा",
    created: "बनवून झालं",
    form_created_successfully: "फार्म यशस्वीरित्या बनवून झालं",
    warning: "चेतावणी",
    form_edit_alert: "हा फाॅर्म संपादित केल्याने जमा झालेल्या प्रतिक्रियांवर परिणाम होऊ शकतो, कारण कि हा फाॅर्म आधिच प्रकाशित झालेले आहे",
    quiz_edit_alert: 'ही प्रश्नमंजुषा आधीच सबमिट केली गेली आहे आणि उत्तरे गोळा केली गेली आहेत. प्रश्नमंजुषा संपादित केल्याने मागील उत्तरांवर परिणाम होऊ शकतो',
    edit: "संपादन करणे",
    download_as: "असं डाऊनलोड करा",
    delete_form: "फार्म डीलीट करा",
    delete_form_alert: "आपण हा फार्म डिलीट करू इच्छिता? असं केल्याने ते पहिल्यासारखं करणे शक्य नाही",
    delete_quizzes_alert: "आपण या क्विझ हटवू इच्छिता? ही क्रिया पूर्ववत करणे शक्य नाही.",
    delete_forms_alert: "आपण एकापेक्षा अधिक फार्म हटवू इच्छिता? असं केल्याने ते पहिल्यासारखे करणे शक्य नाही",
    delete_quiz_alert: "आपण हा क्विझ हटवू इच्छिता? ही क्रिया पूर्ववत करणे शक्य नाही.",
    delete_answers_alert: "तुम्हाला ही उत्तरे हटवायची आहेत का?",
    delete_answer_alert: "तुम्हाला हे उत्तर हटवायचे आहे का?.",
    delete: "डीलीट",
    delete_all: "सर्व हटवा",
    section: "भाग",
    worst: "सर्वात वाईट",
    server_error_alert: "सर्व्हरशी संपर्क होऊ शकला नाही, कृपया थोड्या वेळा नंतर प्रयत्न करा",
    signed_in_as: "असं साईन इन आहे",
    signing_out: "साईन आऊट झालं",
    create_form: "फार्म बनवा",
    create_blank_form: "रिक्त फॉर्म तयार करा",
    create_blank_quiz: "रिक्त क्विझ तयार करा",
    close: "बंद करा",
    response_collection_exit_alert: "आपण प्रतिक्रिया एकत्रित करणे बंद करू इच्छिता?",
    form_builder: "फाॅर्म बिल्डर",
    form_builder_promo: "आपली टेम्पलेट आणि थीम प्रीव्हयू द्रुतपणे पहा",
    results: "परिणाम",
    result_feature_promo: "आपल्या सर्व्हे परिणामांची विश्लेषण करून त्यांना एक्सपोर्ट करा",
    notification: "नोटीफिकेशन",
    delete_option_alert: "आपण हाे हटवू इच्छिता? [टीप: हे आधी सारखे होऊ शकणार नाही।]",
    question_type: "प्रश्न प्रकार",
    change_question_type_alert: "प्रश्न प्रकार बदलण्यासाटी येथे टैप करा",
    preview_not_available: "प्रीव्हयू उपलब्ध नाही",
    delete_question: "प्रश्न डिलीट करा",
    delete_response_collected_question_alert: "प्रश्न डिलीट केल्यामुळे एकत्रित झालेल्या प्रतिक्रयाँवर परिणाम होईल।आपण अद्याप हा प्रश्न डिलीट करू इच्छिता?",
    delete_question_alert: "आपली खात्री आहे की आपण हा प्रश्न डिलीट करू इच्छिता?",
    options: "अनेक पर्याय",
    option: 'पर्याय',
    no_answer: "उत्तर नाही आहे",
    no_response: "प्रतिसाद नाही",
    responses: "प्रतिसाद",
    response: "प्रतिसाद",
    serial_number: "अनुक्रमांक",
    submit_time: "सादर करण्याची कालावधि",
    general_form_type_description: "नवीन फॉर्म / सर्वेक्षण तयार करा",
    quiz_type_description: "नवीन क्विझ तयार करा",
    sheet_disconnected: 'Google शीट डिस्कनेक्ट झाले',
    sheet_not_connected: 'शीट कनेक्ट केलेले नाही',
    sheet_not_connected_desc: 'कृपया Google शीट कनेक्ट करण्यासाठी मालकाशी संपर्क साधा.',
    export_success: "यशस्वीरित्या निर्यात झाले आहेत।",
    answer: "उत्तर",
    answer_with_colan: "उत्तरे :",
    others: "इतर",
    delete_response: "प्रतिसाद डिलीट करा",
    deleted: "डिलीट झालेय",
    my_themes: "माझ्या थीम्स",
    limit_reached: "निश्चित केलेली सीमा पोहोचली",
    limit_reached_description: "तुम्ही इमेज अपलोड करण्याची मर्यादा गाठली आहे.",
    modified: "सुधार केलेला",
    delete_image: "डिलीट ईमेज",
    delete_image_alert: "आपली खात्री आहेे की आपण ही ईमेज डिलीट करू इच्छिता ?",
    delete_theme_image_desc: "तुम्हाला ही इमेज हटवायची आहे का? तुम्ही पुढे गेल्यास, ही प्रतिमा वापरत असलेल्या कोणत्याही फॉर्म किंवा क्विझसाठी उपलब्ध होणार नाही. ही क्रिया पूर्ववत केली जाऊ शकत नाही.",
    section_title: "विभाग शिर्षक",
    section_description: "विभाग वर्णन",
    show_scale: 'स्केल दाखवा',
    show_options: "इतर पर्याय दाखवा",
    options_required: "पर्याय देणे आवश्यक",
    link_copied: "लिंक काॅपी केला आहे",
    app_not_found: "ॲप उपलब्ध नाही",
    default_text: "डीफाॅल्ट",
    dark_mode: "डार्क मोड",
    done: "झालं",
    upload_image: "ईमेज अपलोड करा",
    question: "प्रश्न",
    required: "आवश्यक",
    share: "शेयर करा",
    form_link: "फाॅर्म लिंक",
    copy_link: "लिंक काॅपी करा",
    view_form: "फाॅर्म बघा",
    menu: "मेन्यू",
    view_all: "सर्व",
    settings: "सेटिंग्स",
    submit: "सबमिट",
    change_theme: "थीम चेंज़ करा",
    tap_here_to_choose_theme: "थीम निवडण्यासाठी येथे टैप करें",
    show_logo: "लोगो दाखवा",
    show_logo_alert: "आपण लोगो फाॅर्ममध्ये दर्शवू इच्छिता?",
    welcome_page: "स्वागत पृष्ठ",
    show_welcome_page_alert: "आपण फाॅर्ममध्ये स्वागत पृष्ठ दर्शवू इच्छिता?",
    start_button: "स्टार्ट बटन",
    allow_multiple_responses: "एकाधििक पर्याय निवडण्याची परवानगी द्या",
    allow_more_response_alert: "आपण यूझर्स यांना एकापेक्षा जास्त वेळा प्रतिक्रिया देण्याची अनुमति देऊ इच्छिता?",
    allow_more_quiz_attempt_alert: "आपण यूझर्स यांना एकापेक्षा जास्त वेळा प्रयत्न करण्याची अनुमति देऊ इच्छिता?",
    show_summary: "सारंश दाखवा",
    show_results: "परिणााम दाखवा",
    allow_responders_view_summary_alert: "आपण यूजर्स यांना फाॅर्म सबमिट केल्यानंतर सर्व प्रतिसादांचा सारांश बघण्याची परवानगी देऊ इच्छिता?",
    allow_result_view_quiz_alert: "आपण यूजर्स यांना परिणाम बघण्याची परवानगी देऊ इच्छिता?",
    show_question_number: "प्रश्न क्रमांक दाखवा",
    show_question_number_in_form: "तुम्हाला फॉर्मवर प्रश्न क्रमांक दाखवायचा आहे का?",
    show_question_number_in_quiz: "कतुम्हाला क्विझवर प्रश्न क्रमांक दाखवायचा आहे का?",
    shuffle_questions: "सर्व प्रश्न शफल करा",
    shuffle_questions_alert: "आपण प्रश्न शफल करू इच्छिता?",
    shuffle_quiz_questions_alert: "तुम्हाला प्रश्नोत्तराच्या प्रश्नांची फेरबदल करायची आहे का?",
    description: "वर्णन",
    builder: "बिल्डर",
    preview: "प्रीव्हयू",
    form_status: "फाॅर्म स्थिती",
    active: "सक्रिय",
    inactive: "निष्क्रिय",
    import_questions: 'प्रश्न आयात करा',
    search_by_question_title_from_forms: 'विद्यमान फॉर्ममधून प्रश्न शीर्षकानुसार शोधा',
    search_by_question_title_from_quizzes: 'विद्यमान क्विझमधून प्रश्न शीर्षकानुसार शोधा',
    view_responses: "प्रतिसाद पहा",
    edit_form: "फाॅर्म संपादित करा",
    duplicate_form: "फाॅर्म डुप्लिकेट करा",
    drafts: "ड्राफ्ट",
    no_form_available: "कोणतेही फाॅर्म उप्लब्ध नहीं!",
    notifications: "अधिसूचना",
    feedback: "फीडबैक",
    privacy_policy: "प्रईवेसी पाॅलिसी",
    open: 'उघडा',
    open_individual_responses: "वैयक्तिक प्रतिक्रिया उघडा",
    bar_chart: "बार चार्ट",
    pie_chart: "पाई चार्ट",
    summary: "सारांश",
    invalidated_form_cannot_be_selected: "अवैध फॉर्म निवडला जाऊ शकत नाही.",
    invalidated_quiz_cannot_be_selected: "अवैध क्विझ निवडले जाऊ शकत नाही.",
    individual: "व्यक्ती",
    search: "शोधा....",
    image: "इमेज",
    video: "व्हिडिओ",
    website: "वेबसाईट",
    exit: "बाहेर पडा",
    response_limit_reached: "प्रतिसादांची ठरवलेली मर्यादा गाठली",
    response_limit_reached_alert: "या फाॅर्मने प्रतिक्रिया डेटासाठी जे सीमा ठरवली गेली आहे ती पोहोचल्यामुळे या फार्मबद्दल येणारे प्रतिसाद थांबवण्यात आले आहेत। जर आपल्याला हे सर्वेक्षण सुरू ठेवण्याची आवश्यकता असेल तर आपण हा फाॅर्म डुप्लिकेट करून दिलेले नवीन लिंक शेयर करा।",
    unapproved_form: "नकारलेला फाॅर्म",
    unapproved_form_alert: "या फाॅर्म मध्ये असे काही शब्द वापरले गेले आहेत जे आमच्या धोरणांच्या विरोधात आहे। जर आपल्याला असे वाटत असेल की हा फाॅर्म चुकून अवरोधित केला गेला असेल तर खालिल दिलेल्या फाॅर्मचा लिंक उघडून मागितलेली माहिती द्या।",
    choose_your_language: "स्वतःची भाषा निवडा",
    provide_explanation: "स्पष्टीकरण द्या",
    selected: "निवडलेले",
    review_button: "रिव्हयू",
    not_specified: "निर्दिष्ट नाही",
    quiz_control: "क्वीज़ कंट्रोल",
    allow_multiple_attempts: "एकाधिक प्रयत्नांना अनुमती द्या",
    user_info: "वापरकरत्याची माहिती",
    name_s: "नाव",
    duration: "कालावधी",
    set_duration_quiz_alert: "आपण क्विझसाठी कालावधी सेट करू इच्छिता?",
    user_information_description: "आपण सहभागी यांची माहिती घेऊ इच्छिता?",
    set_duration: "कालावधी सेट करा (तास:मिनिटे)",
    minutes: "मिनिटे",
    hours: "तास",
    minute: "मिनिट",
    hour: "तास",
    correct: "योग्य",
    wrong: "चुकीचे",
    skipped: "वगळले",
    score: "स्कोअर",
    forms: "फॉर्म",
    quizzes: "क्विझ",
    more: "अधिक",
    quiz_status: "क्विझ स्थिती",
    view_answers: "उत्तरे पहा",
    view_quiz: "क्विझ पहा",
    duplicate_quiz: "डुप्लिकेट क्विझ",
    no_quiz_available: "कोणतीही क्विझ उपलब्ध नाही!",
    quiz_link: "क्विझ दुवा",
    unapproved_quiz: "अस्वीकृत क्विझ",
    unapproved_quiz_alert: "या फाॅर्म मध्ये असे काही शब्द वापरले गेले आहेत जे आमच्या धोरणांच्या विरोधात आहे। जर आपल्याला असे वाटत असेल की हा फाॅर्म चुकून अवरोधित केला गेला असेल तर खालिल दिलेल्या फाॅर्मचा लिंक उघडून मागितलेली माहिती द्या।",
    do_you_want_to_show_all_correct_answers_to_users: "आपण वापरकर्त्यांना योग्य उत्तर दर्शवू इच्छिता?",
    results_publish_date: "निकाल प्रकाशित तारीख",
    disconnect: 'डिस्कनेक्ट करा',
    do_you_want_to_set_date_to_publish_results: "आपण परिणाम प्रकाशित करण्यासाठी तारीख सेट करू इच्छिता?",
    quiz_result: "क्विझ निकाल",
    enter_answer: "उत्तर",
    manage: "व्यवस्थापित",
    manage_storage: 'स्टोरेज व्यवस्थापित करा',
    select_all: 'सर्व निवडा',
    shuffle_options: 'शफल पर्याय',
    shuffle_options_enabled: 'शफल पर्याय सक्षम केले',
    shuffle_options_disabled: 'शफल पर्याय अक्षम केले',
    add_options_to_enable_this_feature: 'हे वैशिष्ट्य सक्षम करण्यासाठी पर्याय जोडा',
    attach: 'संलग्न करा',
    shuffle: 'शफल',
    marks: "गुण",
    mark: "चिन्ह",
    correction_status: "स्थिती :",
    status_validated: "प्रमाणित",
    pending: "प्रलंबित",
    results_publish: "प्रकाशित करा",
    publish: "प्रकाशित करा",
    publish_description: "आपण क्विझ निकाल प्रकाशित करू इच्छिता?",
    publish_description_note: "(गुण समान URL मध्ये प्रकाशित करेल)",
    published_successfully: "यशस्वीरित्या प्रकाशित केले",
    choice_type_validation_note: "*एकाधिक निवड स्वयंचलितपणे सत्यापित",
    short_answer_type_validation_note: "*लहान उत्तर आपोआप मान्य केले",
    long_answer_type_validation_note: "*लांब उत्तर आपोआप मान्य नाही",
    fileupload_answer_type_validation_note: "*फाइल अपलोड स्वयंचलितपणे प्रमाणित होत नाही",
    dropdown_answer_type_validation_note: "*ड्रॉपडाउन निवड स्वयंचलितपणे सत्यापित केली जाते",
    save: "जतन करा",
    questions: "प्रश्न",
    show_correct_answer: "बरोबर उत्तर दर्शवा",
    show_answer: "उत्तर दाखवा",
    hide_answer: "उत्तर लपवा",
    no_marks: "चिन्ह नाही",
    question_is_required: "प्रश्न शीर्षक आवश्यक आहे",
    marks_is_required: "गुण आवश्यक आहेत",
    options_are_required: "पर्याय आवश्यक आहेत",
    options_limit_reached: 'पर्याय मर्यादा गाठली',
    options_limit_reached_description: 'तुम्ही प्रश्नावर अनुमती दिलेल्या पर्यायांची मर्यादा गाठली आहे.',
    correct_answer_required: "बरोबर उत्तर आवश्यक आहे",
    enter_valid_mark: "वैध चिन्ह प्रविष्ट करा",
    evaluated: "मूल्यमापन केले",
    saved: "जतन केले",
    pending_evaluation: "प्रलंबित मूल्यमापन",
    alphabet: "वर्णमाला",
    not_evaluated: "मूल्यांकन नाही",
    percentage_ascending: "टक्केवारी - चढत्या",
    percentage_descending: "टक्केवारी - उतरत्या",
    pending_evaluation_alert_message: "प्रकाशित करण्यापूर्वी आपण सर्व उत्तरांचे मूल्यांकन पूर्ण केले पाहिजे.",
    show_unevaluated: "मूल्यमापन दर्शवा",
    publish_success_alert_message: "उत्तर दिले जाणारे उत्तर त्यांचे संबंधित उत्तरपत्रिकांमध्ये पाहू शकतात.",
    publishing: "प्रकाशन",
    quiz_published: "क्विझ प्रकाशित",
    quiz_published_alert_before_edit_message: "हे क्विझ प्रकाशित केले गेले आहे, आपल्याला बदल करण्याची आवश्यकता असल्यास, आपल्याला क्विझची नक्कल करावी लागेल आणि ती पुन्हा सामायिक करावी लागेल.",
    quiz_has_answers: "क्विझला उत्तरे आहेत",
    quiz_has_answer_alert_message: "ही क्विझ संपादित करण्यासाठी, आपल्याला सर्व विद्यमान उत्तरे हटविणे आवश्यक आहे.",
    unavailable_features_description: 'या फॉर्ममध्ये अशी वैशिष्ट्ये आहेत जी अद्याप वेब अॅपमध्ये समर्थित नाहीत',
    unavailable_quiz_features_description: 'या क्विझमध्ये अशी वैशिष्ट्ये आहेत जी अद्याप वेब ॲपमध्ये समर्थित नाहीत',
    unavailable: 'अनुपलब्ध',
    unpublished: "अप्रकाशित",
    published: "प्रकाशित केले",
    setting_updated: "सेटिंग अद्यतनित",
    account: "खाते",
    on: "चालू",
    off: "बंद",
    scan_qr_code: "क्यूआर कोड स्कॅन करा",
    answers: "उत्तरे",
    answer_s: "उत्तर",
    question_limit_reached: "प्रश्न मर्यादा गाठली",
    reached_limit_for_number_of_questions: "आपण फॉर्मवर परवानगी दिलेल्या प्रश्नांची संख्या गाठली आहे. म्हणून, कोणतेही नवीन प्रश्न जोडले जाऊ शकत नाहीत",
    life_time: "आजीवन",
    last_year: "गेल्या वर्षी",
    last_30_days: "शेवटचे 30 दिवस",
    last_7_days: "शेवटचे 7 दिवस",
    last_24_hours: "शेवटचे 24 तास",
    custom_date: "सानुकूल तारीख",
    filter_by_date: "तारखेनुसार फिल्टर करा",
    filter_by_question: "प्रश्नाद्वारे फिल्टर करा",
    filter_text: "मजकूर फिल्टर करा",
    add_filter: "फिल्टर जोडा",
    apply: "लागू करा",
    from_date: "या तारखेपासून",
    today: 'आज',
    to_date: "तारीख पर्यंत",
    contains: "समाविष्ट आहे",
    equal_to: "च्या समान",
    not_equal_to: "समान नाही",
    from_date_required: "आवश्यक तारखेपासून",
    to_date_required: "आवश्यक तारखेपर्यंत",
    webapp_promo_text: "आपले फॉर्म, सर्वेक्षण, मतदान आणि क्विझ ऑनलाईन प्रवेश करा!",
    question_title: "प्रश्न",
    invalid_date: "अवैध तारीख",
    invalid_url: "अवैध URL",
    invalid_url_description: "URL वैध असल्याचे दिसत नाही.",
    delete_response_alert: "आपली खाात्री आहे की आपण ही प्रतिक्रिया डिलीट करू इच्छिता?",
    delete_responses_alert: "तुम्हाला हे प्रतिसाद हटवायचे आहेत का?",
    overview: "आढावा",
    no_results_found: "कोणतेही परिणाम आढळले नाहीत",
    date_format: "dd-mm-yyyy",
    title_dashboard: "डॅशबोर्ड",
    title_notifications: "अधिसूचना",
    select_one_response: "कृपया हटवण्यासाठी किमान एक प्रतिसाद निवडा",

//Untranslated strings

    your_account: "तुमचे खाते",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    favourites: "आवडते",
    feature_spotlight: 'वैशिष्ट्य स्पॉटलाइट',
    shared: "शेअर केले",
    hide: "लपवा",
    use_templates: "टेम्पलेट्स वापरा",
    quick_settings: "द्रुत सेटिंग्ज",
    leave_collaboration: "सहयोग सोडा",
    templates: "टेम्पलेट्स",
    collapse: "संकुचित करा",
    start_scale_from_zero: '0 पासून स्केल सुरू करा',
    center_label_optional: 'केंद्र लेबल (पर्यायी)',
    left_label_optional: 'डावे लेबल (पर्यायी)',
    right_label_optional: 'उजवे लेबल (पर्यायी)',
    duplicate: "नक्कल",
    row: "पंक्ती",
    column: "स्तंभ",
    rows: "पंक्ती",
    add_column: 'स्तंभ जोडा',
    add_row: 'पंक्ती जोडा',
    user_info_for_file_upload: "फाइल अपलोडसाठी वापरकर्ता माहिती",
    user_info_for_file_upload_desc1: "तुम्ही फाइल अपलोड प्रश्न जोडला असल्याने, वापरकर्ता माहिती गोळा करण्याचा पर्याय सक्षम केला गेला आहे. यामुळे डाउनलोड केलेल्या फाइल्स ओळखणे सोपे होईल. प्रतिसादकर्त्यांना त्यांची माहिती प्रविष्ट करण्यासाठी एक संवाद दिसेल.",
    user_info_for_file_upload_desc2: "तुम्हाला संवाद दाखवायचा नसेल तर तुम्ही सेटिंग्जमध्ये हा पर्याय बदलू शकता.",
    show_rows_and_columns: "पंक्ती दाखवा & स्तंभ",
    scale_down: 'स्केल खाली',
    scale_up: 'वाढवा',
    dislike: 'नापसंत',
    like: 'आवडले',
    best: 'सर्वोत्तम',
    remove_favourite: "आवडते काढा",
    add_favourite: "आवडते जोडा",
    collaborator: "सहयोगी",
    collaborators: "सहयोगी",
    show_more: "अजून दाखवा",
    create_quiz: "क्विझ तयार करा",
    collaborate: "सहकार्य करा",
    share_QR_code: "QR कोड शेअर करा",
    add_image: "प्रतिमा जोडा",
    invalid_file_type: "अवैध फाइल प्रकार",
    drag_drop: "ड्रॅग आणि ड्रॉप करा",
    here: "येथे",
    choose: "निवडा",
    add_account: 'खाते जोडा',
    choose_account: 'खाते निवडा',
    maximum_allowed_file_size: "कमाल अनुमत फाइल आकार आहे ",
    or: "किंवा",
    next: "पुढे",
    change_image: "प्रतिमा बदला",
    upload: "अपलोड करा",
    view: "पहा",
    collaboration: "सहयोग",
    share_collaboration_form: "तुम्हाला हा फॉर्म इतरांसह सामायिक करायचा आहे?",
    share_collaboration_quiz: "तुम्हाला ही क्विझ इतरांसोबत शेअर करायची आहे का?",
    multiple_responses_summary: "एकाधिक प्रतिसाद, सारांश",
    question_number_shuffle: "प्रश्न क्रमांक, प्रश्न गुण, शफल",
    general: "सामान्य",
    browse: "ब्राउझ करा",
    owner: "मालक",
    something_went_wrong: "काहीतरी चूक झाली!",
    forget_your_password: "तुमचा पासवर्ड विसरा?",
    sign_in: "साइन इन करा",
    sign_up: "साइन अप करा",
    using_email: "ईमेल वापरून",
    dont_have_account: "खाते नाही?",
    on_signing_up_agree_to_surveyhearts: "साइन अप करून, तुम्ही SurveyHeart ला सहमती दर्शवता",
    and: "आणि",
    terms_of_use: "वापरण्याच्या अटी",
    already_have_an_account: "आधीपासूनच एक खाते आहे?",
    signed_in_previously_as_a_google_user: "तुम्ही यापूर्वी Google वापरकर्ता म्हणून साइन इन केले आहे.",
    sign_in_google_to_continue: "कृपया सुरू ठेवण्यासाठी Google द्वारे साइन इन करा.",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "टीप: ही माहिती पुन्हा पाहण्यासाठी, डाव्या बाजूला असलेल्या मेनूमधील 'अधिक' वर क्लिक करा",
    no_account_found_email_address: "या ईमेल पत्त्यासह कोणतेही खाते आढळले नाही",
    account_already_exist_email: "या ईमेलसाठी खाते आधीपासूनच अस्तित्वात आहे.",
    install_surveyheart_app: "SurveyHeart अॅप इंस्टॉल करा",
    easy_login_via_app: "अॅपद्वारे सुलभ लॉगिन",
    get_notification_instantly: "त्वरित सूचना मिळवा",
    create_form_quiz_on_the_go: "जाता जाता फॉर्म/क्विझ तयार करा",
    check_your_inbox_to_activate_your_account: "तुमचे खाते सक्रिय करण्यासाठी कृपया तुमचा इनबॉक्स तपासा",
    select_questions: "प्रश्न निवडा",
    percentage: "टक्केवारी",
    free_App_to_create_forms: "फॉर्म तयार करण्यासाठी विनामूल्य अॅप मिळवा",
    we_cant_find_the_page_that_you_are_looking_for: "आपण शोधत असलेले पृष्ठ आम्हाला सापडत नाही",
    answered: "उत्तर दिले",
    completing_this_quiz: "ही क्विझ पूर्ण केल्याबद्दल धन्यवाद",
    connected_successfully: 'यशस्वीरित्या कनेक्ट केले',
    seconds: "सेकंद",
    enter_your_details: "आपले तपशील प्रविष्ट करा",
    enter_all_required_information: "कृपया सर्व आवश्यक माहिती प्रविष्ट करा",
    answer_data_not_found: "उत्तर डेटा सापडला नाही",
    results_published_soon: "निकाल लवकरच प्रकाशित केले जातील",
    previous: 'मागील',
    save_link_for_future_reference: "भविष्यातील संदर्भासाठी ही लिंक जतन करा",
    choice_questions_only: "केवळ निवड प्रश्न",
    score_is_not_final: "टीप: हा गुण अंतिम नाही",
    tabular: "टॅब्युलर",
    text: "मजकूर",
    answer_response_alert: "या प्रश्नाचे उत्तर देणे जरुरीचे आहे",
    invalid_email_address: "अवैध ई - मेल पत्ता",
    answer_not_available: "उत्तर उपलब्ध नाही",
    validated_automatically: "* आपोआप प्रमाणित",
    answer_link: "उत्तर लिंक",
    embed_code: 'एम्बेड कोड',
    copy_code: 'कोड कॉपी करा',
    code_copied: 'कोड कॉपी केला',
    share_via: "द्वारे शेअर करा",
    print: "छापा",
    columns: 'स्तंभ',
    columns_all: "स्तंभ (सर्व)",
    submitted_time: "सबमिट केलेली वेळ",
    unable_to_submit: 'सबमिट करण्यात अक्षम',
    unable_to_submit_alert_line1: 'या क्विझला उत्तरे मिळाली असल्याने, तुम्हाला बदल करण्यासाठी मर्यादित प्रवेश आहे.',
    unable_to_submit_alert_line2: 'ही क्विझ संपादित करण्यासाठी पूर्ण प्रवेश मिळविण्यासाठी, तुम्ही सर्व विद्यमान उत्तरे हटवणे आवश्यक आहे.',
    action_blocked: 'कारवाई अवरोधित',
    action_blocked_alert: 'या प्रश्नमंजुषाकरिता विद्यमान उत्तरांमुळे क्रिया अवरोधित केली आहे. यास अनुमती दिल्याने मागील उत्तरांवर परिणाम होऊ शकतो.',
    quiz_edit_submit_alert: 'प्रश्नमंजुषा संपादित केल्याने गुण, टक्केवारी प्रभावित होऊ शकते आणि काही प्रश्नांचे पुनर्मूल्यांकन आवश्यक आहे.',
    continue: 'सुरू',
    submitted: "सादर केले",
    submitting: "सादर करत आहे",
    filter: "फिल्टर करा",
    exit_screen: "स्क्रीनमधून बाहेर पडा",
    full_screen: "पूर्ण स्क्रीन",
    tabular_view: "टॅब्युलर दृश्य",
    clear_all: "सर्व साफ करा",
    you_are_all_caught_up: "तुम्ही सर्व पकडले आहात!",
    check_back_later_for_new_notifications: "नवीन सूचनांसाठी नंतर परत तपासा.",
    requires_answer: "उत्तर आवश्यक आहे",
    enter_member_id: "सदस्य आयडी प्रविष्ट करा",
    viewers_can_read_form_responses: "दर्शक फॉर्म वाचू शकतात आणि प्रतिसाद पाहू शकतात",
    editors_edit_form_view_responses: "संपादक फॉर्म संपादित करू शकतात आणि प्रतिसाद पाहू शकतात",
    admin_edit_form_add_collaborator: "प्रशासक फॉर्म संपादित करू शकतो आणि सहयोगी जोडू शकतो",
    invalid_member_id: "अवैध सदस्य आयडी",
    already_member_added: "हा सदस्य आधीच जोडला आहे",
    add_collaborator: "सहयोगी जोडा",
    admin: "प्रशासक",
    editor: "संपादक",
    viewer: "दर्शक",
    searched_form_not_found: "शोधला फॉर्म सापडला नाही!",
    searched_quiz_not_found: "शोधले क्विझ सापडले नाही!",
    no_shared_data_available: "कोणताही शेअर केलेला डेटा उपलब्ध नाही!",
    forms_server_is_not_available: "फॉर्म सर्व्हर उपलब्ध नाही",
    quiz_server_is_not_available: "क्विझ सर्व्हर उपलब्ध नाही",
    leave: 'सोडा',
    password: 'पासवर्ड',
    new_password: 'नवीन पासवर्ड',
    retype_password: 'पासवर्ड पुन्हा टाईप करा',
    create_password: 'पासवर्ड तयार करा',
    view_results: 'परिणाम पहा',
    control: 'नियंत्रण',
    q_control: 'क्विझ नियंत्रण',
    q_result: 'क्विझ निकाल',
    no_matches_found: "कोणतीही जुळणी आढळली नाही",
    dropdown: "ड्रॉपडाउन",
    checkboxes: "चेकबॉक्सेस",
    star_rating: 'स्टार रेटिंग',
    linear_scale: 'रेखीय स्केल',
    multiple_choice_grid: 'एकाधिक निवड ग्रिड',
    checkboxes_grid: 'चेकबॉक्स ग्रिड',
    smile: 'हसा',
    info_1: "माहिती 1",
    info_2: "माहिती 2",
    info_3: "माहिती 3",
    new: 'नवीन',
    download_all: 'सर्व डाउनलोड करा',
    download: 'डाउनलोड करा',
    sort_by: 'यानुसार क्रमवारी लावा',
    sorting: 'वर्गीकरण',
    refresh: 'ताजेतवाने',
    add: 'जोडा',
    refresh_preview_description: "पूर्वावलोकन अद्यतनित करण्यासाठी 'रीफ्रेश' बटणावर क्लिक करा",
    enter_users_email_address: 'वापरकर्त्याचा ईमेल पत्ता प्रविष्ट करा',
    do_you_want_to_delete_these: 'तुम्हाला हे हटवायचे आहेत',
    do_you_want_to_delete_this_form: 'तुम्हाला हा फॉर्म हटवायचा आहे का',
    all_responses_will_also_be_deleted: 'सर्व प्रतिसाद देखील हटवले जातील',
    this_action_cannot_be_undone: 'ही क्रिया पूर्ववत केली जाऊ शकत नाही',
    do_you_want_to_delete_this_quiz: 'तुम्हाला ही क्विझ हटवायची आहे का',
    all_answer_sheets_will_also_be_unavailable: 'सर्व उत्तरपत्रिका देखील अनुपलब्ध असतील',
    change: 'बदला',
    roles_rights: 'भूमिका आणि अधिकार',
    learn_more: 'अधिक जाणून घ्या',
    viewers_can_read_quiz_and_view_answers: 'दर्शक प्रश्नमंजुषा वाचू शकतात आणि उत्तरे पाहू शकतात',
    editors_can_edit_quiz_view_answers: 'संपादक प्रश्नमंजुषा संपादित करू शकतात आणि उत्तरे पाहू शकतात',
    admin_can_edit_quiz_add_collaborator: 'प्रशासक क्विझ संपादित करू शकतो आणि सहयोगी जोडू शकतो',
    change_roles: 'भूमिका बदला',
    remove: 'काढा',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'तुम्ही तुमचे बदल सबमिट केलेले नाहीत. सबमिट न करता निघून जाणे योग्य आहे का?',
    select_language: 'भाषा निवडा',
    are_you_sure_you_want_delete_this_collaborator: 'तुमची खात्री आहे की तुम्ही हा सहयोगकर्ता हटवू इच्छिता?',
    are_you_sure_you_want_to_delete_selected_files: 'तुमची खात्री आहे की तुम्ही निवडलेली फाइल कायमची हटवू इच्छिता?',
    please_download_before_deleting: 'कृपया हटवण्यापूर्वी डाउनलोड करा. फायली एकदा हटवल्या गेल्या, पुनर्प्राप्त केल्या जाऊ शकत नाहीत!',
    role_has_been_changed_successfully: 'भूमिका यशस्वीरित्या बदलली आहे',
    do_you_want_to_leave_collaboration: 'तुम्ही सहयोग सोडू इच्छिता?',
    form_builder_access_denied: 'फॉर्म बिल्डर प्रवेश नाकारला',
    quiz_builder_access_denied: 'क्विझ बिल्डर प्रवेश नाकारला',
    you_only_have_viewer_rights_for_quiz: 'या क्विझसाठी तुमच्याकडे फक्त दर्शक अधिकार आहेत.',
    you_only_have_viewer_rights_for_form: 'तुमच्याकडे फक्त या फॉर्मसाठी दर्शक अधिकार आहेत.',
    kindly_contact_the_form_owner_for_more_info: 'अधिक माहितीसाठी कृपया फॉर्म मालकाशी संपर्क साधा.',
    kindly_contact_the_quiz_owner_for_more_info: 'कृपया अधिक माहितीसाठी क्विझ मालकाशी संपर्क साधा.',
    incomplete_questions: 'अपूर्ण प्रश्न:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'तुमच्याकडे जतन न केलेले बदल आहेत - तुम्हाला खात्री आहे की तुम्ही हे पृष्ठ सोडू इच्छिता?',
    languages: 'भाषा',
    copy: "प्रत",
    not_good: 'चांगले नाही',
    not_allowed: 'परवानगी नाही',
    not_allowed_description: 'प्रतिसाद संकलित केल्यानंतर निवड प्रश्न प्रकार बदलता येणार नाही.',
    neutral: 'तटस्थ',
    good: 'चांगले',
    very_good: 'खुप छान',
    edit_rights_removed: 'संपादन अधिकार काढले',
    your_rights_to_edit_this_form_have_been_removed: "हा फॉर्म संपादित करण्याचे तुमचे अधिकार काढून टाकण्यात आले आहेत.",
    your_rights_to_edit_this_quiz_have_been_removed: "ही क्विझ संपादित करण्याचे तुमचे अधिकार काढून टाकण्यात आले आहेत.",
    please_contact_quiz_owner_for_more_info: "अधिक माहितीसाठी कृपया फॉर्म मालकाशी संपर्क साधा.",
    please_contact_form_owner_for_more_info: "अधिक माहितीसाठी कृपया क्विझ मालकाशी संपर्क साधा.",
    last_edited_on: 'रोजी शेवटचे संपादित केले',
    last_edited: 'शेवटचे संपादित',
    by: 'द्वारे',
    you: "आपण",
    add_option: 'पर्याय जोडा',
    add_other_option: "'इतर' पर्याय जोडा",
    estimated_time: "अंदाजे वेळ ",
    do_you_want_to_show_estimated_time_taking_quiz: "तुम्हाला क्विझसाठी अंदाजे वेळ दाखवायचा आहे का ?",
    do_you_want_to_show_estimated_time_taking_form: "तुम्हाला फॉर्म घेण्याचा अंदाजित वेळ दाखवायचा आहे का ?",
    show_question_count: "प्रश्न संख्या दर्शवा",
    do_you_want_to_show_total_number_questions_quiz: "तुम्हाला क्विझमध्ये एकूण प्रश्नांची संख्या दाखवायची आहे का ?",
    do_you_want_to_show_total_number_questions_form: "तुम्हाला फॉर्ममध्ये एकूण प्रश्नांची संख्या दाखवायची आहे का? ?",
    show_question_marks: "प्रश्नचिन्ह दाखवा",
    show_question_marks_quiz: " प्रश्नमंजुषामध्ये प्रश्नचिन्ह दाखवा",
    you_dont_have_add_collaborator_permission: "तुमच्याकडे सहयोगी जोडण्याची परवानगी नाही",
    modified_date: "सुधारित तारीख",
    created_date: "तयार तारीख",
    question_cant_be_added: "प्रश्न जोडला जाऊ शकत नाही",
    another_file_upload_question_cant_added: "दुसरा फाइल अपलोड प्रश्न जोडला जाऊ शकत नाही",
    you_can_have_only: "आपल्याकडे फक्त असू शकते",
    file_upload_questions_in_form: "फॉर्ममध्ये प्रश्न अपलोड करा",
    file_upload_questions_in_quiz: "प्रश्नमंजुषामध्ये प्रश्न अपलोड करा",
    storage_capacity_exceeded: "स्टोरेज क्षमता ओलांडली",
    kindly_inform_form_builder_that: "कृपया फॉर्म बिल्डरला कळवा",
    kindly_inform_quiz_builder_that: "कृपया क्विझ बिल्डरला कळवा",
    their_storage_capacity_file_uploads: "फाइल अपलोडसाठी त्यांची स्टोरेज क्षमता",
    has_been_exceeded: "ओलांडली आहे",
    view_details: "तपशील पहा",
    paste_your_video_link_here: "तुमची व्हिडिओ लिंक येथे पेस्ट करा",
    paste_your_website_link_here: "तुमची वेबसाइट लिंक येथे पेस्ट करा",
    view_answer_sheet: "उत्तरपत्रिका पहा",
    response_count: "प्रतिसाद संख्या",
    count: "मोजणे",
    password_least_8_characters: "पासवर्ड किमान 8 - 50 वर्णांचा असणे आवश्यक आहे",
    password_contain_least_1_number: "पासवर्डमध्ये किमान 1 नंबर असणे आवश्यक आहे",
    password_contain_least_1_capital_letter: "पासवर्डमध्ये किमान 1 कॅपिटल अक्षर असणे आवश्यक आहे",
    password_contain_least_1_special_character: "पासवर्डमध्ये किमान 1 विशेष वर्ण असणे आवश्यक आहे",
    please_fill_all_required_fields: "कृपया सर्व आवश्यक फील्ड भरा",
    passwords_dont_match: "पासवर्ड जुळत नाहीत",
    incorrect_password: "चुकीचा पासवर्ड",
    click_or_change_current_theme: "भिन्न थीम निवडण्यासाठी वर्तमान थीम किंवा खाली 'बदला' क्लिक करा",
    select: "निवडा",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "तुम्हाला ही इमेज हटवायची आहे का? तुम्ही पुढे गेल्यास, ही प्रतिमा वापरत असलेल्या कोणत्याही फॉर्म किंवा क्विझसाठी उपलब्ध होणार नाही. ही क्रिया पूर्ववत केली जाऊ शकत नाही.",
    url_does_not_seem_to_valid: "URL वैध असल्याचे दिसत नाही.",
    you_have_reached_image_uploaded_limit: "तुम्ही इमेज अपलोड करण्याची मर्यादा गाठली आहे.",
    undo: "पूर्ववत करा",
    response_deleted: "प्रतिसाद हटवला",
    question_deleted: "प्रश्न हटवला",
    answer_deleted: "उत्तर हटवले",
    responses_deleted: "प्रतिसाद हटवले",
    questions_deleted: "प्रश्न हटवले",
    collect_email_header: "ईमेल गोळा करा",
    collect_email_alert_message: "हे वैशिष्ट्य सक्षम केल्याने प्रतिसादकर्त्याला फॉर्म भरण्यासाठी त्यांच्या gmail पत्त्यासह साइन इन करण्यास भाग पाडले जाईल.",
    collect_email_alert_note: "सूचना: फक्त Google खाते समर्थित आहे",
    allow_response_edit: "प्रतिसाद संपादनास अनुमती द्या",
    allow_response_edit_description: "आपण प्रतिसादकर्त्यांना सबमिट केल्यानंतर प्रतिसाद संपादित करण्याची परवानगी देऊ इच्छिता?",
    answers_deleted: "उत्तरे हटवली",
    enter_desc_here: "येथे वर्णन प्रविष्ट करा",
    passcode_protection: "पासकोड संरक्षण",
    passcode_protection_description_quiz: "तुमची क्विझ पासकोड संरक्षित असावी असे तुम्हाला वाटते का?",
    passcode_protection_description: "तुमचा फॉर्म पासकोड संरक्षित असावा असे तुम्हाला वाटते का?",
    crop_image: "प्रतिमा क्रॉप करा",
    crop: "पीक",
    original: "मूळ",
    collect_email: "ईमेल पत्ता गोळा करा",
    collect_email_description: "संभाव्य भविष्यातील संदर्भ किंवा उपयोगासाठी तुम्ही प्रतिसादकर्त्यांचे ईमेल पत्ते गोळा करू इच्छिता?",
    collect_email_description_quiz: "हे वैशिष्ट्य सक्षम केल्याने प्रतिसादकर्त्यांना प्रश्नमंजुषा भरण्यासाठी त्यांच्या gmail पत्त्यासह साइन इन करण्यास भाग पाडले जाईल.",
    collect_email_description_form: "हे वैशिष्ट्य सक्षम केल्याने प्रतिसादकर्त्यांना फॉर्म भरण्यासाठी त्यांच्या gmail पत्त्यासह साइन इन करण्यास भाग पाडले जाईल.",
    opening_date: 'उघडण्याची तारीख',
    closing_date: 'बंद होण्याची तारीख',
    schedule: 'वेळापत्रक',
    schedule_form_status: 'शेड्यूल फॉर्म स्थिती',
    schedule_quiz_status: 'शेड्युल क्विझ स्थिती',
    schedule_opening_date_and_time: "उघडण्याची तारीख आणि वेळ शेड्यूल करा",
    schedule_closing_date_and_time: "शेवटची तारीख आणि वेळ शेड्यूल करा",
    schedule_opening_date_and_time_description: "नमूद केलेल्या तारखेला आणि वेळेवर फॉर्म सक्रिय करा",
    schedule_opening_date_and_time_description_quiz: "नमूद केलेल्या तारखेला आणि वेळेवर क्विझ सक्रिय करा",
    schedule_closing_date_and_time_description: "नमूद केलेल्या तारखेला आणि वेळेला फॉर्म निष्क्रिय करा",
    schedule_closing_date_and_time_description_quiz: "नमूद केलेल्या तारखेला आणि वेळेवर क्विझ निष्क्रिय करा",
    upgrade: "श्रेणीसुधारित करा",
    gold_plan: "गोल्ड प्लॅन",
    view_plans: "योजना पहा",
    premium_plans: "प्रीमियम योजना",
    plan_duration: "/ महिना",
    plan_logic_jump: "लॉजिक जंप",
    plan_remove_ads: "जाहिराती काढून",
    cancel_subscription: "सदस्यता रद्द करा",
    quiz_has_answer_edit_message: "ही क्विझ आधीच सबमिट केली गेली आहे आणि उत्तरे गोळा केली गेली आहेत. क्विझ संपादित केल्याने मागील उत्तरांवर परिणाम होऊ शकतो.",
    quiz_published_alert_edit_message: "ही प्रश्नमंजुषा प्रकाशित करण्यात आली आहे, तुम्ही संपादित केल्यास ही प्रश्नमंजुषा अप्रकाशित केली जाईल.",
    quiz_submit_after_edit_message: "क्विझ संपादित केल्याने गुण, टक्केवारीवर परिणाम होऊ शकतो आणि काही प्रश्नांचे पुनर्मूल्यांकन आवश्यक आहे.",
    title_activity_privacy_policy: "गोपनीयता धोरण क्रियाकलाप",
    action_blocked_message: "या क्विझसाठी अस्तित्वात असलेल्या उत्तरांमुळे कृती अवरोधित केली आहे. यास अनुमती दिल्याने मागील उत्तरांवर परिणाम होऊ शकतो.",
    email_addresses: "ईमेल पत्ते",
    confirmation_message: "पुष्टीकरण संदेश",
    confirmation_message_description: "फॉर्म सबमिट केल्यानंतर तुम्हाला पुष्टीकरण संदेश दाखवायचा आहे का?",
    confirmation_message_description_quiz: "क्विझला उत्तर दिल्यानंतर तुम्हाला पुष्टीकरण संदेश दाखवायचा आहे का?",
    default_custom_thank_message: "हा फॉर्म भरल्याबद्दल धन्यवाद.",
    default_custom_thank_message_quiz: "ही प्रश्नमंजुषा पूर्ण केल्याबद्दल धन्यवाद.",
    upgrade_your_plan: "तुमची योजना अपग्रेड करा!",
    upgrade_your_plan_1: "तुमची योजना अपग्रेड करा",
    upgrade_your_plan_content: "कृपया या वैशिष्ट्यात प्रवेश करण्यासाठी आणि स्टोरेज आकार वाढवण्यासाठी प्रीमियम आवृत्ती अपग्रेड करा",
    alert_icon: "सूचना चिन्ह",
    usage_details: "वापर तपशील",
    current_plan: "सध्याची योजना",
    total_submission: "एकूण सबमिशन",
    image_attachments: "प्रतिमा संलग्नक",
    custom_themes: "सानुकूल थीम",
    free_limit_reached: "तुम्ही विनामूल्य मर्यादा गाठली आहे -",
    payment_done_but_server_error: "तुमचे पेमेंट यशस्वीरित्या कापले गेले आहे. कृपया काही वेळानंतर ॲप उघडा जेणेकरून आम्हाला तुमचे पेमेंट सत्यापित करण्याची परवानगी द्या.",
    response_quota_reached: "तुम्ही विनामूल्य मर्यादा गाठली आहे, कृपया सर्व प्रतिसादांमध्ये प्रवेश करण्यासाठी तुमची योजना अपग्रेड करा.",
    answers_quota_reached: "तुम्ही विनामूल्य मर्यादा गाठली आहे, कृपया सर्व उत्तरांमध्ये प्रवेश करण्यासाठी तुमची योजना अपग्रेड करा.",
    logic_jump_enabled: "लॉजिक जंप सक्षम केले आहे.",
    logic_jump_disabled: "लॉजिक जंप अक्षम.",
    cancel_subscription_message: "तुम्हाला खात्री आहे की तुम्ही तुमची सदस्यता रद्द करू इच्छिता? सदस्यता रद्द केल्याने सर्व प्रीमियम वैशिष्ट्यांचा प्रवेश गमावला जाईल.",
    plan_expired: "तुमची योजना कालबाह्य झाली आहे. कृपया हा मसुदा वापरण्यासाठी तुमची योजना अपग्रेड करा.",
    plan_expired_edit_form: "तुमची योजना कालबाह्य झाली आहे. कृपया हा फॉर्म संपादित करण्यासाठी तुमची योजना अपग्रेड करा",
    plan_expired_edit_quiz: "तुमची योजना कालबाह्य झाली आहे. कृपया ही क्विझ संपादित करण्यासाठी तुमची योजना अपग्रेड करा",
    plan_expired_duplicate_form: "तुमची योजना कालबाह्य झाली आहे. कृपया हा फॉर्म डुप्लिकेट करण्यासाठी तुमची योजना अपग्रेड करा",
    plan_expired_duplicate_quiz: "तुमची योजना कालबाह्य झाली आहे. कृपया ही क्विझ डुप्लिकेट करण्यासाठी तुमची योजना अपग्रेड करा",
    premium_features: "प्रीमियम वैशिष्ट्य",
    plan_file_upload: "अमर्यादित सानुकूल थीम प्रतिमा",
    plan_custom_theme: "अमर्यादित सानुकूल थीम प्रतिमा",
    plan_call_support: "उच्च प्राधान्य चालू - कॉल सपोर्ट",
    plan_email_support: "उच्च प्राधान्य ईमेल समर्थन",
    plan_custom_domain: "सानुकूल डोमेन",
    subscribe: "सदस्यता घ्या",
    plan_remove_branding: "ब्रँडिंग काढा",
    plan_on_call_support: "कॉल सपोर्टवर",
    plan_image_attachment: "अमर्यादित प्रतिमा संलग्नक",
    plan_custom_thank_you: "सानुकूलित धन्यवाद पृष्ठे",
    plan_allowed_responses: "अनुमत प्रतिसाद संख्या 50000",
    trusted_by_number_of_user: "5 दशलक्षाहून अधिक वापरकर्त्यांद्वारे विश्वासार्ह",
    email_support: "ईमेल समर्थन",
    call_support: "कॉल सपोर्ट",
    keyboard_shortcuts: "कीबोर्ड शॉर्टकट",
    premium_limit_reached: "तुम्ही योजनेची मर्यादा गाठली आहे. सर्व प्रीमियम वैशिष्ट्यांमध्ये प्रवेश करण्यासाठी कृपया तुमची योजना अपग्रेड करा.",
    premium_limit_reached_mobile: "तुम्ही योजनेची मर्यादा गाठली आहे.",
    unlimited: "अमर्यादित",
    expired_on_next_renewal: "तुमच्या पुढील नूतनीकरण तारखेला ते कालबाह्य होईल",
    upgrade_plan: "अपग्रेड योजना",
    downgrade_plan: "डाउनग्रेड योजना",
    file_upload_questions: "फाइल अपलोड प्रश्न",
    plan_response_edit: "प्रतिसाद संपादित करा",
    cant_process: "प्रक्रिया करू शकत नाही",
    you_have_subscribed_through_android: "तुम्ही आमच्या Android ॲपद्वारे सदस्यत्व घेतले आहे. कृपया कोणत्याही पेमेंट संबंधित अपडेटसाठी SurveyHeart ॲप वापरा.",
    pay_now: "आता द्या",
    update_payment_details: "पेमेंट तपशील अपडेट करा",
    limit_reached_alert_message: "तुम्ही विनामूल्य मर्यादा गाठली आहे, कृपया सर्व प्रतिसादांमध्ये प्रवेश करण्यासाठी तुमची योजना अपग्रेड करा.",
    limit_reached_alert_message_quiz: "तुम्ही विनामूल्य मर्यादा गाठली आहे, कृपया सर्व उत्तरांमध्ये प्रवेश करण्यासाठी तुमची योजना अपग्रेड करा.",
    limit_reached_UI_message: "तुमच्या वर्तमान योजनेसाठी प्रतिसाद उपलब्ध नसतील, कृपया सर्व प्रतिसाद पाहण्यासाठी तुमची योजना अपग्रेड करा.",
    limit_reached_UI_message_quiz: "तुमच्या वर्तमान योजनेसाठी प्रतिसाद उपलब्ध नसतील, कृपया सर्व उत्तरे पाहण्यासाठी तुमची योजना अपग्रेड करा.",
    owner_is_not_premium_user: "मालक प्रीमियम वापरकर्ता नाही",
    cancel_subscription_description: "तुमची खात्री आहे की तुम्ही तुमची सदस्यता रद्द करू इच्छिता? रद्द केल्याने सर्व प्रीमियम वैशिष्ट्यांचा प्रवेश गमावला जाईल.",
    starter_storage_description: "सहज डेटा व्यवस्थापनासाठी 5GB स्टोरेज मिळवा.",
    bronze_storage_description: "सहज डेटा व्यवस्थापनासाठी 25GB स्टोरेज मिळवा.",
    silver_storage_description: "सहज डेटा व्यवस्थापनासाठी 50GB स्टोरेज मिळवा.",
    gold_storage_description: "सहज डेटा व्यवस्थापनासाठी 150GB स्टोरेज मिळवा.",
    starter_responses_description: "सक्षम सर्वेक्षण अंतर्दृष्टी मिळविण्यासाठी 10,000 प्रतिसाद वापरा.",
    bronze_responses_description: "सक्षम सर्वेक्षण अंतर्दृष्टी मिळविण्यासाठी 35,000 प्रतिसाद वापरा.",
    silver_responses_description: "सक्षम सर्वेक्षण अंतर्दृष्टी मिळविण्यासाठी 75,000 प्रतिसाद वापरा.",
    gold_responses_description: "सक्षम सर्वेक्षण अंतर्दृष्टी मिळविण्यासाठी 1,50,000 प्रतिसाद वापरा.",
    starter_image_description: "व्हिज्युअल अपीलसाठी 100 प्रतिमांसह तुमची सर्वेक्षणे वाढवा.",
    bronze_image_description: "व्हिज्युअल अपीलसाठी 200 प्रतिमांसह तुमची सर्वेक्षणे वाढवा.",
    silver_image_description: "व्हिज्युअल अपीलसाठी 500 प्रतिमांसह तुमची सर्वेक्षणे वाढवा.",
    gold_image_description: "व्हिज्युअल अपीलसाठी अमर्यादित इमेजसह तुमचे सर्वेक्षण वाढवा.",
    starter_theme_description: "तुमच्या ब्रँडसाठी तुमचे सर्वेक्षण वैयक्तिकृत करण्यासाठी 100 सानुकूल प्रतिमा जोडा.",
    bronze_theme_description: "तुमच्या ब्रँडसाठी तुमचे सर्वेक्षण वैयक्तिकृत करण्यासाठी 200 सानुकूल प्रतिमा जोडा.",
    silver_theme_description: "तुमच्या ब्रँडसाठी तुमचे सर्वेक्षण वैयक्तिकृत करण्यासाठी 500 सानुकूल प्रतिमा जोडा.",
    gold_theme_description: "तुमच्या ब्रँडसाठी तुमचे सर्वेक्षण वैयक्तिकृत करण्यासाठी अमर्यादित सानुकूल प्रतिमा जोडा.",
    custom_theme_image: "सानुकूल थीम प्रतिमा",
    starter_upload_description: "अखंडपणे 4 फाइल अपलोड प्रश्न एकत्रित करा",
    file_upload_description: "अखंडपणे अमर्यादित फाइल अपलोड प्रश्न समाकलित करा.",
    logic_jump_description: "प्रत्येक प्रतिसादकर्त्याच्या अनुभवासाठी सर्वेक्षण प्रवाह सानुकूलित करण्यासाठी लॉजिक जंप वापरा",
    thank_you_message_description: "प्रतिसादकर्त्यांना प्रभावित करण्यासाठी धन्यवाद पृष्ठे वैयक्तिकृत करा.",
    response_edit_description: "प्रतिसाद संपादन वैशिष्ट्य वापरून प्रतिसाद सहज परिष्कृत करा.",
    response_addon_description: "अधिक माहिती गोळा करण्यासाठी अतिरिक्त प्रतिसाद मिळवा.",
    remove_branding_description: "SurveyHeart ब्रँडिंग काढून तुमच्या ब्रँडची उपस्थिती वाढवा.",
    bronze_remove_ads_description: "आमच्या कांस्य योजनेसह जाहिरातमुक्त व्हा आणि तुमच्या सर्वेक्षणांवर लक्ष केंद्रित करा.",
    silver_remove_ads_description: "आमच्या सिल्व्हर प्लॅनसह जाहिरातमुक्त व्हा आणि तुमच्या सर्वेक्षणांवर लक्ष केंद्रित करा.",
    gold_remove_ads_description: "आमच्या गोल्ड प्लॅनसह जाहिरातमुक्त व्हा आणि तुमच्या सर्वेक्षणांवर लक्ष केंद्रित करा.",
    bronze_email_support_description: "कमी-प्राधान्यासाठी ईमेल सपोर्टमध्ये प्रवेश करा, वेळेवर मदत सुनिश्चित करा.",
    silver_email_support_description: "मध्यम-प्राथमिकतेसाठी ईमेल समर्थनात प्रवेश करा, वेळेवर मदत सुनिश्चित करा.",
    gold_email_support_description: "उच्च-प्राधान्य, वेळेवर सहाय्य सुनिश्चित करण्यासाठी ईमेल समर्थनात प्रवेश करा.",
    bronze_email_support_title: "कमी प्राधान्य ईमेल समर्थन",
    silver_email_support_title: "मध्यम प्राधान्य ईमेल समर्थन",
    gold_email_support_title: "उच्च प्राधान्य ईमेल समर्थन",
    update_your_payment_details_mobile: "कृपया तुमचे पेमेंट तपशील अपडेट करा.",
    update_your_payment_details_pc: "आम्हाला या खात्याचे नूतनीकरण करताना काही समस्या येत आहेत. कृपया तुमचे पेमेंट तपशील अपडेट करा.",
    card_details_updated_successfully: "कार्ड तपशील यशस्वीरित्या अपडेट केले",
    card_details_updated_successfully_description: "तुमचे कार्ड तपशील यशस्वीरित्या अपडेट केले गेले आहेत. तुम्ही आता तुमची अपडेट केलेली पेमेंट माहिती वापरणे सुरू ठेवू शकता.",
    payment_successful: "पेमेंट यशस्वी",
    payment_successful_description: "तुमचे पेमेंट यशस्वी झाले आहे. तुम्ही आता SurveyHeart च्या प्रीमियम योजनेचे सदस्यत्व घेतले आहे. आरामशीर व्हा आणि तुमच्या मार्गावर येणाऱ्या सर्व आश्चर्यकारक वैशिष्ट्यांचा आनंद घ्या.",
    payment_failed: "पेमेंट अयशस्वी!",
    payment_failed_description: "दुर्दैवाने. आम्ही SurveyHeart च्या प्रीमियम योजनेच्या तुमच्या सदस्यतेवर प्रक्रिया करू शकलो नाही. कृपया पुन्हा प्रयत्न करा.",
    note_for_five_rupees: "टीप: तुमच्या कार्डचे तपशील अपडेट करण्यासाठी 5 रुपये शुल्क आकारले जाते, जे \n त्याच खात्यात 3 ते 4 व्यावसायिक दिवसांत परत केले जाईल.",
    logic: "तर्कशास्त्र",
    re_generate_code: "कोड पुन्हा व्युत्पन्न करा",
    plan_change_restrictions: "प्लॅन बदल प्रतिबंध",
    plan_change_restriction_desc_1: "तुम्ही UPI वापरून तुमची योजना बदलू शकत नाही.",
    plan_change_restriction_desc_2: "अपग्रेड आणि डाउनग्रेडसाठी कार्डवर स्विच करा, किंवा रद्द करा आणि तुमची इच्छित योजना पुन्हा सदस्यता घ्या.",
    update_card_details: "कार्डचे तपशील अपडेट करा",
    features_and_pans: "वैशिष्ट्ये आणि योजना",
    cancel_plan: "प्लॅन रद्द करा",
    cancel_plan_desc: "तुम्ही योजना रद्द केली आहे. तुमच्या नूतनीकरणाच्या तारखेपर्यंत तुम्हाला सशुल्क वैशिष्ट्यांमध्ये प्रवेश असेल.",
    explore_text: "आज आमच्या प्रीमियम सबस्क्रिप्शन प्लॅनपैकी एकामध्ये अपवादात्मक वैशिष्ट्ये एक्सप्लोर करा आणि अनलॉक करा",
    cant_edit_this_form: "हा फॉर्म संपादित करू शकत नाही",
    cant_edit_this_quiz: "ही प्रश्नमंजुषा संपादित करू शकत नाही",
    cant_duplicate_this_form: "हा फॉर्म डुप्लिकेट करू शकत नाही",
    cant_duplicate_this_quiz: "या क्विझची डुप्लिकेट करू शकत नाही",
    kindly_upgrade_the_premium: "कृपया या वैशिष्ट्यात प्रवेश करण्यासाठी प्रीमियम आवृत्ती अपग्रेड करा.",
    check_box: 'चेक बॉक्स',
    continue_to_next_question: "पुढील प्रश्नावर सुरू ठेवा",
    add_logic: "तर्क जोडा",
    submit_form: "फॉर्म सबमिट करा",
    save_logic: "सेव्ह लॉजिक",
    remove_logic: "लॉजिक काढा",
    responses_add_ons: "प्रतिसाद ॲड-ऑन",
    additional_submissions: " अतिरिक्त सबमिशन",
    responses_add_available: "प्रतिसाद अ‍ॅड-ऑन उपलब्ध आहे",
    validity: " वैधता",
    up_to_your_current_plan: " तुमच्या वर्तमान योजनेपर्यंत",
    responses_add_ons_description: " ही प्रतिसाद संख्या तुमच्या विद्यमान योजनेमध्ये जोडली जाईल.",
    redirect_url_title: " इच्छित दुव्यावर पुनर्निर्देशित करा",
    redirect_url_description: " तुम्ही तुमच्या वापरकर्त्यांना बाह्य वेबपृष्ठावर पुनर्निर्देशित करू इच्छिता?",
    enter_weblink: " वेबलिंक प्रविष्ट करा",
    get: "मिळवा",
    jump_to: "वर जा",
    action_cannot_be_performed: "कृती करता येत नाही",
    upgrade_plan_desc1: "तुम्हाला तुमची योजना यामध्ये अपग्रेड करायची आहे का",
    downgrade_plan_desc1: "तुम्हाला तुमची योजना डाउनग्रेड करायची आहे का",
    downgrade_plan_desc2: "हा बदल तुमच्या पुढील नूतनीकरण तारखेपासून प्रभावी होईल.",
    upgraded_plan: "श्रेणीसुधारित योजना",
    downgraded_plan: "डाउनग्रेड केलेली योजना",
    upgraded_plan_desc: "तुम्ही तुमची योजना अपग्रेड केली आहे. हा बदल तुमच्या पुढील नूतनीकरण तारखेपासून प्रभावी होईल.",
    downgraded_plan_desc: "तुम्ही तुमची योजना डाउनग्रेड केली आहे. हा बदल तुमच्या पुढील नूतनीकरण तारखेपासून प्रभावी होईल.",
    buy_responses: "प्रतिसाद खरेदी करा",
    passcode: "पासकोड",
    share_link_with_passcode: "पासकोडसह लिंक शेअर करा",
    contact_us: "आमच्याशी संपर्क साधा",
    premium_limit_reached_for_gold: "तुम्ही योजनेची मर्यादा गाठली आहे. सर्व प्रीमियम वैशिष्ट्यांमध्ये प्रवेश करण्यासाठी कृपया आमच्याशी संपर्क साधा.",
    follow_us: "आमचे अनुसरण करा",
    billing_history: "बिलिंग इतिहास",
    your_current_plan: "तुमची सध्याची योजना",
    next_billing_date: "पुढील बिलिंग तारीख",
    transaction_id: "व्यवहार आयडी",
    plan: "योजना",
    price: "किंमत",
    status: "स्थिती",
    follow_us_desc: "अद्ययावत रहा, कनेक्टेड रहा आणि आमच्याशी व्यस्त रहा",
    ranking_choice: 'रँकिंग निवड',
    ranking: 'रँकिंग',
    picture_choice: 'चित्र निवड',
    agreement: 'करार',
    signature: "स्वाक्षरी",
    slider: 'स्लाइडर',
    min: 'मि',
    max: 'कमाल',
    step_value: "चरण मूल्य",
    scale_range_error: "स्केल श्रेणी -10,000 आणि 10,000 मधील मूल्य असणे आवश्यक आहे.",
    scale_range_error2: "कमाल स्केलचे मूल्य स्वीकार्य मूल्यापेक्षा कमी आहे.",
    max_min_error: "किमान स्केलचे मूल्य कमाल स्केलपेक्षा कमी असावे.",
    step_error: "स्टेप साइज दरम्यानचे मूल्य असावे",
    add_picture: 'चित्र जोडा',
    terms_and_conditions: "नियम आणि अटी",
    terms_and_conditions_required: "अटी आणि नियम आवश्यक",
    i_agree: "मी सहमत आहे",
    i_disagree: "मी असहमत",
    yearly: "वार्षिक",
    monthly: "मासिक",
    save_upto_20: "वार्षिक योजनांसह 20% पर्यंत बचत करा",
    paid: "पैसे दिले",
    cancelled: "रद्द केले",
}