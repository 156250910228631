import React, {useEffect, useState} from "react"
import "../css/home-page.css"
import TopNavBar from "./TopNavBar";
import CarouselComponent from "./CarouselComponent";
import YouTubeIcon from "../../../public/images/YouTube.svg"
import IOSIcon from "../../../public/images/IOS_btn.svg"
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {PREMIUM_PLAN_KEYS, PremiumJSON} from "../../shared/utils/helper";
import StoriesCarouselComponent from "./StoriesCarouselComponent";
import PopUpContainer from "../../shared/components/PopUpContainer";
import closeIcon from "../../../public/images/close_black.svg";
import MaterialTextField, {TEXT_FIELD_TYPES} from "../../shared/components/MaterialTextField";
import MaterialTextFieldOutlinedContainer from "../../admin/containers/MaterialTextFieldOutlinedContainer";
import closeBlack from "../../../public/images/close_black.svg";
import countryCodes from "../../shared/utils/country-codes.json";
import {isMobileOnly} from "react-device-detect";
import ImageViewerComponent from "../../form/components/questions/ImageViewerComponent";
import {Provider} from "react-redux";
import appStore from "../../app/store/appStore";
import {isValidEmailTextPattern} from "../../shared/utils/validator";
import {submitFormQuiz} from "../../shared/utils/forq-api-services";
import whiteTick from "../../../public/images/done.svg";

const HomePage = () => {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [yearly, setYearly] = useState(true)
    const [showVideo, setShowVideo] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [requestSent, setRequestSent] = useState(false)
    const [showDemoPopUp, setShowDemoPopUp] = useState(false)
    const [showCodePopUp, setShowCodePopUp] = useState(false)
    const [stickFormTitleCard, setStickFormTitleCard] = useState(false)
    const [filteredCountryCodes, setFilteredCountryCodes] = useState(countryCodes)
    const [selectedCountryCode, setSelectedCountryCode] = useState({
        name: "India",
        dial_code: "+91",
        emoji: "🇮🇳",
        code: "IN"
    })

    useEffect(() => {
        if (localStorage.getItem("sash") && localStorage.getItem("quizzory")) {
            window.location = "/app"
        }
        const handleScroll = (e) => {
            e.stopPropagation();
            window.scrollY > 700 ? setStickFormTitleCard(true) : setStickFormTitleCard(false);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const companiesLogos = [
        "../../images/usersCompaniesLogos/decathlon-logo.svg",
        "../../images/usersCompaniesLogos/flipkart.svg",
        "../../images/usersCompaniesLogos/hdfc-bank-logo.svg",
        "../../images/usersCompaniesLogos/hyundai-motor-company-2.svg",
        "../../images/usersCompaniesLogos/iddlHIPaqZ_1718775331607.svg",
        "../../images/usersCompaniesLogos/idYEgdK4CK_1718776160394.svg",
        "../../images/usersCompaniesLogos/isha-foundation-seeklogo.svg",
        "../../images/usersCompaniesLogos/kotak-mahindra-bank-limited-vector-logo.svg",
        "../../images/usersCompaniesLogos/logo (6).svg",
        "../../images/usersCompaniesLogos/paytm-1.svg",
        "../../images/usersCompaniesLogos/royal-enfield-seeklogo.svg",
        "../../images/usersCompaniesLogos/SpiceJet.svg",
        "../../images/usersCompaniesLogos/suburban.svg",
        "../../images/usersCompaniesLogos/tata-consultancy-services-1.svg",
        "../../images/usersCompaniesLogos/tata-logo.svg",
        "../../images/usersCompaniesLogos/tata-steel-logo.svg",
        "../../images/usersCompaniesLogos/tech-mahindra-new-logo.svg",
        "../../images/usersCompaniesLogos/vi-vodafone-idea.svg",
        "../../images/usersCompaniesLogos/walmart.svg",
    ]

    const goToWebApp = (isSignIn) => {
        localStorage.setItem("referrer", new URLSearchParams(window.location.search).get("referrer"))
        if (isSignIn === false) {
            window.location = "/app?u=1";
        } else if (isSignIn === undefined) {
            window.location = "/app?u=1";
        } else {
            window.location = "/app?u=0";
        }
    }

    const whySurveyHeartCard = (icon, title, description) => {
        return (
            <div style={{
                width: isMobileOnly ? "null" : "20%",
                textAlign: isMobileOnly ? "null" : "start",
                margin: isMobileOnly ? "30px" : "20px"
            }}>
                <img style={{width: isMobileOnly ? "40px" : "60px"}} src={icon} alt={"Icon"}/>
                <h3 style={{color: "#004BA0", fontSize: isMobileOnly ? "25px" : "30px", margin: "10px 0"}}>{title}</h3>
                <span style={{fontSize: "20px"}}>{description}</span>
            </div>
        )
    }

    const handleCodeClick = () => {
        setShowCodePopUp(true)
    }

    const handleSearchChange = (value) => {
        setFilteredCountryCodes(countryCodes.filter((code) => code.name.toLowerCase().includes(value)))
    }

    const validatePhoneNumber = (value) => {
        const checkNumber = /(?:[-+() ]*\d){10,13}/g;
        return value?.match(checkNumber)
    }

    const navigateToUrl = (url) => {
        window.open(url, "_blank")
    }

    return (
        <div className={"home-container"}>
            <div id={"home-first-section"}>
                <div className={"foreground-layer"}>
                    <TopNavBar setShowDemoPopUp={setShowDemoPopUp} showDemo={true}/>
                    <div id={"text-mobile-box"}>
                        <div id={"primary-title"}>
                            <p><span>Get </span>
                                <span style={{color: "#757575"}} className="strikeout">responses</span>
                                <span> Feelings</span><br/>
                                <span> From </span>
                                <span style={{color: "#757575"}} className="strikeout">responders</span>
                                <span> Humans</span>
                            </p>
                            <div className={"animation-text"}>
                                <span/>
                            </div>
                            <div className={"get-started-button"}>
                                <div className={"YouTube"} onClick={() => setShowVideo(true)}>
                                    <img src={YouTubeIcon} alt={"YouTube"}/>
                                    <span>Watch Video</span>
                                </div>
                                {isMobileOnly ? null : <MaterialButton data={{
                                    title: "Get Started",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#1976D2',
                                    fontSize: '20px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        fontWeight: "bold",
                                        width: '200px',
                                        height: '60px',
                                    }
                                }} handleClick={() => goToWebApp(true)}/>}
                            </div>
                            <div className="download-apps">
                                <a href='https://play.google.com/store/apps/details?id=com.surveyheart&referrer=internal_home_page'>
                                    <img alt='Get it on Google Play'
                                         id="download-image"
                                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                </a>
                                <a href='https://apps.apple.com/us/app/surveyheart-online-form-maker/id6443449067?itsct=apps_box_badge&amp;itscg=30200%22'>
                                    <img alt='Get it on App Store'
                                         id="ios-download-image"
                                         src={IOSIcon}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobileOnly ? null : <div className={stickFormTitleCard ? "onScroll-navbar sticky" : "nonScroll-navbar"}>
                <TopNavBar color={"#000000"} setShowDemoPopUp={setShowDemoPopUp} showDemo={true}/>
            </div>}
            <div className={"second-section"}>
                <h2>Join our Global community of over</h2>
                <h1 style={{color: "#1976D2"}}>10 Million plus Users Worldwide</h1>
                <h2 style={{color: "#8E8E8E"}}>Trusted by</h2>
                <div id={"scroll-container"}>
                    <div id={"wrapper"}>
                        <div id={"images"}>
                            {companiesLogos.map((logo) => {
                                return <div className={"image"}>
                                    <img src={logo} alt={"logo"}/>
                                </div>
                            })}
                        </div>
                        <div id={"images"}>
                            {companiesLogos.map((logo) => {
                                return <div className={"image"}>
                                    <img src={logo} alt={"logo"}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <p style={{fontSize: isMobileOnly ? "20px" : "30px", width: "90%", margin: "30px auto"}}>
                    Discover the power of connection in our thriving global community, uniting over 10 million users
                    worldwide. Join us as we share knowledge, experiences, and opportunities across borders, creating a
                    vibrant ecosystem where collaboration knows no bounds.
                </p>
            </div>
            <div className={"third-section"}>
                <h1><span style={{color: "#1976D2"}}>SurveyHeart</span> can be used for</h1>
                <CarouselComponent/>
            </div>
            <div className={"forth-section"}>
                <h1>Why <span style={{color: "#1976D2"}}>SurveyHeart?</span></h1>
                <div style={{display: isMobileOnly ? "null" : "flex", justifyContent: "center"}}>
                    {whySurveyHeartCard("../../images/simplified.svg", "Simplified", "Enjoy a simple, user-friendly dashboard that makes managing your data effortless.")}
                    {whySurveyHeartCard("../../images/smart-builder.svg", "Smart Builder", "Very easy to create forms and quizzes with our intuitive user-friendly builder.")}
                    {whySurveyHeartCard("../../images/question-types.svg", "Question Types", "Offer over 15 different question formats, including MCQ, Rating scales, Dropdowns Etc.")}
                    {whySurveyHeartCard("../../images/analyze-result.svg", "Analyze the Results", "Analyze your data with effective graphs and charts for clear insights.")}
                </div>
                <div style={{display: isMobileOnly ? "null" : "flex", justifyContent: "center"}}>
                    {whySurveyHeartCard("../../images/customization.svg", "Customization", "Choose from 80+ customizable themes to match your brand or personal style.")}
                    {whySurveyHeartCard("../../images/language-support.svg", "Language Support", "Access SurveyHeart in 12 languages, making it a truly global tool.")}
                    {whySurveyHeartCard("../../images/innovative.svg", "Innovative Features", "More features like Logic Jump, Custom Logo, Custom Thanking Page, Passcode Protection, Auto Scheduling forms and quizzes Etc")}
                    {whySurveyHeartCard("../../images/templates.svg", "Templates", "Start quickly with 30+ ready templates designed to ease your workflow.")}
                </div>
                <div style={{display: isMobileOnly ? "null" : "flex", justifyContent: "center"}}>
                    {whySurveyHeartCard("../../images/collaboration.svg", "Collaboration", "Collaborate seamlessly with friends and colleagues to analyze results effectively.")}
                    {whySurveyHeartCard("../../images/customer-support.svg", "Customer Support", "We provide support for timely and effective assistance, enhancing customer satisfaction.")}
                    {whySurveyHeartCard("../../images/instant-notification.svg", "Instant Notification", "Get real-time alerts on your device and never miss important updates or activities.")}
                    {whySurveyHeartCard("../../images/security.svg", "Security and Privacy", "Ensure data protection with advanced encryption, fraud detection and strict privacy policies.")}
                </div>
            </div>
            <div className={"fifth-section"}>
                <h1 style={{color: "#1976D2", marginBottom: "0"}}>Find your Ideal Plan</h1>
                <h2 style={{margin: "0"}}>with us</h2>
                <p style={{
                    fontSize: isMobileOnly ? "18px" : "30px",
                    width: "90%",
                    margin: isMobileOnly ? "20px auto" : "30px auto"
                }}>
                    From affordable beginnings to premium perks, our plans cover every need.
                </p>
                <div className={"yearly-monthly"}>
                    <span style={yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(true)}>Yearly</span>
                    <span style={!yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(false)}>Monthly</span>
                </div>
                <p className={"save-upto"} style={{visibility: !yearly ? "hidden" : null}}>
                    Save upto 20% with Yearly plans
                </p>
                <div className={"premium-plans"}>
                    <div className={"premium-plan"}>
                        <h3>Starter Plan</h3>
                        {yearly ?
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                <span style={{fontSize: "20px", fontWeight: "normal"}}>{"/month billed annually"}</span>
                            </h2> :
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                {"Month"}
                            </h2>
                        }
                        <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#FFFFFF',
                            fontSize: '14px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '200px',
                            },
                            customButtonTextStyle: {
                                color: "#004BA0",
                                fontWeight: "bold"
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", JSON.stringify({rank: 0.5, yearly: yearly}))
                            window.open("/app")
                        }}/>
                    </div>
                    <span className={"border"}/>
                    <div className={"premium-plan"}>
                        <h3>Bronze Plan</h3>
                        {yearly ?
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                <span style={{fontSize: "20px", fontWeight: "normal"}}>{"/month billed annually"}</span>
                            </h2> :
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                {"Month"}
                            </h2>
                        }
                        <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#FFFFFF',
                            fontSize: '14px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '200px',
                            },
                            customButtonTextStyle: {
                                color: "#004BA0",
                                fontWeight: "bold"
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", JSON.stringify({rank: 1, yearly: yearly}))
                            window.open("/app")
                        }}/>
                    </div>
                    <span className={"border"}/>
                    <div className={"premium-plan"}>
                        <h3>Silver Plan</h3>
                        {yearly ?
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                <span style={{fontSize: "20px", fontWeight: "normal"}}>{"/month billed annually"}</span>
                            </h2> :
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                {"Month"}
                            </h2>
                        }
                        <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#FFFFFF',
                            fontSize: '14px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '200px',
                            },
                            customButtonTextStyle: {
                                color: "#004BA0",
                                fontWeight: "bold"
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", JSON.stringify({rank: 2, yearly: yearly}))
                            window.open("/app")
                        }}/>
                    </div>
                    <span className={"border"}/>
                    <div className={"premium-plan"}>
                        <h3>Gold Plan</h3>
                        {yearly ?
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                <span style={{fontSize: "20px", fontWeight: "normal"}}>{"/month billed annually"}</span>
                            </h2> :
                            <h2 style={{marginBottom: "10px"}}>
                                ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                {"Month"}
                            </h2>
                        }
                        <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#FFFFFF',
                            fontSize: '14px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '200px',
                            },
                            customButtonTextStyle: {
                                color: "#004BA0",
                                fontWeight: "bold"
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", JSON.stringify({rank: 3, yearly: yearly}))
                            window.open("/app")
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"sixth-section"}>
                <h2><span style={{color: "#1976D2", fontSize: isMobileOnly ? "30px" : "60px"}}>Templates,</span> Ready
                    for you!</h2>
                <p style={{fontSize: isMobileOnly ? "20px" : "30px", width: "90%", margin: "30px auto"}}>
                    Discover our extensive collection of over 30+ templates designed to cover all your needs.<br/>
                    From business forms to personal documents, we’ve got you covered with ready-made solutions that make
                    your tasks easier and more efficient.
                </p>
                <div className={"templates"}>
                    <div className={"template"} onClick={() => window.location.hash = "#templates"}
                         style={{backgroundImage: "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/form_filling.jpeg')"}}>
                        <h3>Contact form</h3>
                    </div>
                    <div className={"template"} onClick={() => window.location.hash = "#templates"}
                         style={{backgroundImage: "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/students.jpeg')"}}>
                        <h3>Course<br/>Feedback<br/>form</h3>
                    </div>
                    <div className={"template"} onClick={() => window.location.hash = "#templates"}
                         style={{backgroundImage: "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/form_filling.jpeg')"}}>
                        <h3>Leave form<br/>for Teachers</h3>
                    </div>
                    <div className={"template"} onClick={() => window.location.hash = "#templates"}
                         style={{backgroundImage: "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/pressure_check.jpeg')"}}>
                        <h3>Doctors<br/>Report</h3>
                    </div>
                    <div className={"template"} onClick={() => window.location.hash = "#templates"}
                         style={{backgroundImage: "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/green_spoon.jpeg')"}}>
                        <h3>Restaurant<br/>Feedback<br/>form</h3>
                    </div>
                </div>
                <MaterialButton data={{
                    title: "Browse All Templates",
                    buttonType: ButtonTypes.CONTAINED,
                    fontSize: '20px',
                    customButtonContainerStyle: {
                        margin: '10px',
                        borderRadius: '50px',
                        padding: '15px 35px',
                        height: '60px',
                        width: '270px',
                    },
                    customButtonTextStyle: {
                        color: "#FFFFFF",
                        fontWeight: "bold"
                    }
                }} handleClick={() => window.location.hash = "#templates"}/>
            </div>
            <div className={"seventh-section"}>
                <h1>Real<span style={{color: "#1976D2"}}> Stories, </span> Real <span
                    style={{color: "#1976D2"}}> Success</span></h1>
                <StoriesCarouselComponent/>
            </div>
            <footer className={"footer-distributed"} style={{marginTop: "150px"}}>

                <div className={"footer-left"}>
                    <img id={"app-logo"} alt={"app-logo"} src={"images/surveyheart1.png"}/>
                    <div className="download-apps" style={{marginLeft: isMobileOnly ? null : "-15px"}}>
                        <a href='https://play.google.com/store/apps/details?id=com.surveyheart&referrer=internal_home_page'>
                            <img alt='Get it on Google Play'
                                 id="download-image"
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                        <a href='https://apps.apple.com/us/app/surveyheart-online-form-maker/id6443449067?itsct=apps_box_badge&amp;itscg=30200%22'>
                            <img alt='Get it on App Store'
                                 id="ios-download-image"
                                 src={IOSIcon}/>
                        </a>
                    </div>
                    <div className={"quick-links"}>
                        <p className={"footer-quick-links"}>
                            <p>Quick Links</p>
                        </p>
                        <span id={"policy"} onClick={() => navigateToUrl("/#privacypolicy")}>Privacy Policy</span>
                        <span id={"tos"} onClick={() => navigateToUrl("/#terms")}>TOS</span>
                        <span id={"rc"} onClick={() => navigateToUrl("/#rc-policy")}>Refund & Cancellation Policy</span>
                        <span id={"abuse-report"}
                              onClick={() => navigateToUrl('https://www.surveyheart.com/form/5c6cdd029f25685f413430ef#welcome')}>Report</span>
                    </div>
                </div>

                <div className={"footer-center"}>

                    <div>
                        <p>
                            <div className={"address"}>
                                <div>Address</div>
                            </div>
                            <span>SurveyHeart LLP,</span>
                            <span>Awfis Space Solutions, 2nd Floor,</span>
                            <span>Survey No 34,</span>
                            <span>Kothaguda Junction, Kondapur,</span>
                            <span>Hyderabad, Telangana,</span>
                            <span>India - 500084.</span>
                            <span>Contact: +91 70105 47789</span>
                            <br/>
                            <span><a href="mailto:support@surveyheart.com">support@surveyheart.com</a></span>
                        </p>
                    </div>

                </div>

                <div className="footer-right">

                    <p className={"footer-company-about"}>
                        <span>About the Company</span>
                        We're here to help you easily create Forms and Quizzes without any hassle.
                    </p>
                    <p className={"footer-company-about"}>
                        <span>Follow Us</span>
                        <div style={{display: "flex"}}>
                            <img style={{width: "50px", margin: "10px 10px 10px 0", cursor: "pointer"}}
                                 onClick={() => window.open("https://www.facebook.com/surveyheart")}
                                 src={"../../images/followUs/facebook.svg"} alt={"follow-us"}/>
                            <img style={{width: "50px", margin: "10px", cursor: "pointer"}}
                                 onClick={() => window.open("https://www.instagram.com/surveyheartapp/")}
                                 src={"../../images/followUs/instagram.svg"} alt={"follow-us"}/>
                            <img style={{width: "50px", margin: "10px", cursor: "pointer"}}
                                 onClick={() => window.open("https://x.com/surveyheartapp")}
                                 src={"../../images/followUs/x-social-media.svg"} alt={"follow-us"}/>
                            <img style={{width: "50px", margin: "10px", cursor: "pointer"}}
                                 onClick={() => window.open("https://www.linkedin.com/company/74654182/admin/dashboard/")}
                                 src={"../../images/followUs/linkedin.svg"} alt={"follow-us"}/>
                            <img style={{width: "50px", margin: "10px", cursor: "pointer"}}
                                 onClick={() => window.open("https://www.youtube.com/@surveyheart")}
                                 src={"../../images/followUs/youtube.svg"} alt={"follow-us"}/>
                        </div>
                    </p>

                </div>

            </footer>
            {showDemoPopUp ? <PopUpContainer zIndex={"12"} children={
                <div className={"demo-popup"}>
                    <div style={{display: "flex", justifyContent: "end", margin: "10px"}}>
                        <img src={closeIcon} alt={"close"} style={{cursor: "pointer"}}
                             onClick={() => {
                                 setName("")
                                 setEmail("")
                                 setPhoneNumber("")
                                 setRequestSent(false)
                                 setShowDemoPopUp(false)
                             }}/>
                    </div>
                    {requestSent ? <div className={"request-sent"}>
                        <div className={"green-tick"}>
                            <img src={whiteTick} alt={"success"}/>
                        </div>
                        <h2>Your Request has been sent.</h2>
                        <p style={{fontSize: isMobileOnly ? "18px" : "24px"}}>A dedicated sales person will contact you
                            soon to guide you further and <br/> assist with your needs.</p>
                    </div> : <div className={"demo"}>
                        <div>
                            <h2 style={{margin: "0", textAlign: isMobileOnly ? "center" : null}}>
                                Book a Demo session with
                            </h2>
                            <h2 style={{color: "#1976D2", margin: "0", textAlign: isMobileOnly ? "center" : null}}>
                                SurveyHeart.
                            </h2>
                            {isMobileOnly ? <img style={{
                                width: "100%",
                                height: "120px",
                                borderRadius: "10px",
                                objectFit: "cover"
                            }} src={"../../images/home_bg.jpg"} alt={"home"}/> : null}
                            <MaterialTextFieldOutlinedContainer placeHolder={"Name"}
                                                                onChange={(value) => setName(value)}
                                                                validationFunction={(value) => value !== ""}
                                                                autoFocus={true}
                                                                maxLength={100}
                                                                customStyleObject={{
                                                                    marginTop: "10px",
                                                                    height: isMobileOnly ? '40px' : null,
                                                                    '--mdc-theme-primary': '#9e9e9e',
                                                                    '--mdc-theme-secondary': '#1976D2'
                                                                }}
                                                                errorText={"Name is required"}
                            />
                            <div className={"phone-number"}>
                                <div style={{width: "35%", margin: "0 5px"}} onClick={handleCodeClick}>
                                    <MaterialTextField
                                        data={{
                                            hint: "Code",
                                            inputType: 'drop-down',
                                            defaultValue: `${selectedCountryCode.emoji + " " + selectedCountryCode.dial_code} ▼`,
                                            isReadOnly: true
                                        }}
                                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                                        customStyleObject={{marginTop: '10px'}}
                                    />
                                </div>
                                <div style={{width: "100%"}}>
                                    <MaterialTextFieldOutlinedContainer placeHolder={'Phone Number'}
                                                                        inputType={"number"}
                                                                        onChange={(value) => setPhoneNumber(value)}
                                                                        validationFunction={validatePhoneNumber}
                                                                        errorText={'Invalid Phone Number'}
                                    />
                                </div>
                            </div>
                            <MaterialTextFieldOutlinedContainer placeHolder={"Email ID"}
                                                                inputType={"email"}
                                                                onChange={(value) => setEmail(value)}
                                                                validationFunction={isValidEmailTextPattern}
                                                                customStyleObject={{
                                                                    height: isMobileOnly ? '40px' : null,
                                                                    '--mdc-theme-primary': '#9e9e9e',
                                                                    '--mdc-theme-secondary': '#1976D2'
                                                                }}
                                                                errorText={"Invalid Email ID"}
                            />
                            <MaterialButton data={{
                                title: "Send Request",
                                buttonType: ButtonTypes.CONTAINED,
                                bg_color: '#1976D2',
                                fontSize: '18px',
                                customButtonContainerStyle: {
                                    margin: '10px',
                                    borderRadius: '50px',
                                    padding: '15px 35px',
                                    width: '185px',
                                    height: '50px',
                                }
                            }} handleClick={() => {
                                if (isValidEmailTextPattern(email) && validatePhoneNumber(phoneNumber) && name?.length) {
                                    const successFunction = (res) => {
                                        if (res.status === 200) {
                                            setRequestSent(true)
                                        }
                                    }
                                    const failureFunction = (err) => {
                                        console.log(err)
                                    }
                                    let formSubmitData = {
                                        form_id: "66c70954ec9174452bf0cc5a",
                                        email: "",
                                        responses: [
                                            {
                                                "question_id": "66c70954ec9174452bf0cc5c",
                                                "type": "SHORT_TEXT",
                                                "text": name
                                            },
                                            {
                                                "question_id": "66c70954ec9174452bf0cc5d",
                                                "type": "NUMBER",
                                                "text": selectedCountryCode.dial_code + " " + phoneNumber
                                            },
                                            {
                                                "question_id": "66c70954ec9174452bf0cc5e",
                                                "type": "EMAIL",
                                                "text": email
                                            }
                                        ],
                                        submit_time: String(new Date().getTime())
                                    }
                                    submitFormQuiz('/response', formSubmitData, successFunction, failureFunction)
                                } else {
                                    document.querySelectorAll(".mdc-text-field__input")[0].focus()
                                    document.querySelectorAll(".mdc-text-field__input")[2].focus()
                                    document.querySelectorAll(".mdc-text-field__input")[3].focus()
                                    document.querySelectorAll(".mdc-text-field__input")[0].blur()
                                    document.querySelectorAll(".mdc-text-field__input")[2].blur()
                                    document.querySelectorAll(".mdc-text-field__input")[3].blur()
                                }
                            }}/>
                            <p style={{fontSize: isMobileOnly ? "16px" : "20px"}}>Our sales team will reach out to you
                                shortly.</p>
                        </div>
                        {isMobileOnly ? null : <img style={{width: "40%", height: "auto", borderRadius: "10px"}}
                                                    src={"../../images/home_bg.jpg"} alt={"home"}/>}
                    </div>}
                </div>
            }/> : null}
            {showCodePopUp ? <PopUpContainer zIndex={"12"} children={
                <div className={"country-code"}
                     style={{width: isMobileOnly ? "90%" : null, height: isMobileOnly ? "50%" : null}}>
                    <div className={"pop-up-header"} style={{backgroundColor: "#FFFFFF"}}>
                        <span style={{color: "#000000"}}>Select Country</span>
                        <img className={'localization-close-img'}
                             src={closeBlack} alt={"close"} onClick={() => setShowCodePopUp(false)}/>
                    </div>
                    <div className={"search"}>
                        <MaterialTextField
                            data={{
                                hint: "Search",
                                inputType: 'search',
                            }}
                            onChange={handleSearchChange}
                            textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                            customStyleObject={{width: '100%'}}
                        />
                    </div>
                    <div className={"codes"}>
                        {filteredCountryCodes.map((code) => {
                            return (
                                <div className={"code-row"} onClick={() => {
                                    setSelectedCountryCode(code)
                                    setShowCodePopUp(false)
                                }}>
                                    <p><span>{code.emoji}</span> {code.name} ({code.dial_code})</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }/> : null}
            {showVideo ? <Provider store={appStore}>
                <ImageViewerComponent
                    attachment={"https://www.youtube.com/embed/KWIlB1nZXUM?si=keR7zuGpr1za3Lb3&&autoplay=1"}
                    video={true}
                    close={() => setShowVideo(false)}/>
            </Provider> : null}
        </div>
    )
}

export default HomePage
