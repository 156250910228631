const initialState = {
    searchText: '',
    listView: true,
    formsList: null,
    showQuiz: false,
    showMore: false,
    showForms: true,
    showShared: false,
    sharesList: null,
    quizzesList: null,
    showSortIcon: true,
    showVersion: false,
    showFollowUs: false,
    showTemplates: false,
    showShortCuts: false,
    showMorePopUp: false,
    isBlockedUser: false,
    mobileSortOrder: null,
    templateType: undefined,
    showResponseAddOn: false,
    showBillingHistory: false,
    showMobileSortButton: true,
    showEditPremiumPopUp: false,
    showMailSupportPopUp: false,
    showCallSupportPopUp: false,
    showDataCollectedWarning: false,
    showDuplicatePremiumPopup: false,
    isShowingMobileFormControl: false,
    searchResultTimeStamp: Date.now(),
    showSubmittedZohoTicket: false,
    showAlreadySubmittedZohoTicket: false
}

export const APP_DASHBOARD_ACTION_TYPE = {
    RESET_ALL: 'RESET_ALL',
    SET_LIST_VIEW: "SET_LIST_VIEW",
    SET_SHOW_QUIZ: 'SET_SHOW_QUIZ',
    SET_SHOW_MORE: 'SET_SHOW_MORE',
    SET_SHOW_FORMS: 'SET_SHOW_FORMS',
    SET_SHOW_SHARED: 'SET_SHOW_SHARED',
    SET_FORMS_LIST: 'SET_FORMS_LIST',
    SET_SHARES_LIST: 'SET_SHARES_LIST',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_SHOW_VERSION: "SET_SHOW_VERSION",
    SET_QUIZZES_LIST: 'SET_QUIZZES_LIST',
    SET_TEMPLATE_TYPE: 'SET_TEMPLATE_TYPE',
    SET_SHOW_SHORTCUTS: 'SET_SHOW_SHORTCUTS',
    SET_SHOW_SORT_ICON: 'SET_SHOW_SORT_ICON',
    SET_SHOW_TEMPLATES: 'SET_SHOW_TEMPLATES',
    SET_SHOW_FOLLOW_US: 'SET_SHOW_FOLLOW_US',
    SET_SHOW_MORE_POPUP: 'SET_SHOW_MORE_POPUP',
    SET_IS_BLOCKED_USER: 'SET_IS_BLOCKED_USER',
    SET_MOBILE_SORT_ORDER: 'SET_MOBILE_SORT_ORDER',
    SET_SHOW_RESPONSE_ADD_ON: 'SET_SHOW_RESPONSE_ADD_ON',
    SET_SHOW_BILLING_HISTORY: 'SET_SHOW_BILLING_HISTORY',
    SET_SHOW_EDIT_PREMIUM_POP_UP: "SET_SHOW_EDIT_PREMIUM_POP_UP",
    SET_SHOW_MAIL_SUPPORT_POPUP: "SET_SHOW_MAIL_SUPPORT_POPUP",
    SET_SHOW_CALL_SUPPORT_POPUP: "SET_SHOW_CALL_SUPPORT_POPUP",
    SET_SHOW_MOBILE_SORT_BUTTON: 'SET_SHOW_MOBILE_SORT_BUTTON',
    SET_SEARCH_RESULT_TIME_STAMP: 'SET_SEARCH_RESULT_TIME_STAMP',
    SET_SHOW_DATA_COLLECTED_WARNING: 'SET_SHOW_DATA_COLLECTED_WARNING',
    SET_SHOW_DUPLICATE_PREMIUM_POP_UP: "SET_SHOW_DUPLICATE_PREMIUM_POP_UP",
    SET_IS_SHOWING_MOBILE_FORM_CONTROL: 'SET_IS_SHOWING_MOBILE_FORM_CONTROL',
    SET_SHOW_SUBMITTED_ZOHO_TICKET: "SET_SHOW_SUBMITTED_ZOHO_TICKET",
    SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET: "SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET"
}

const appDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING):
            return {
                ...state,
                showDataCollectedWarning: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_LIST_VIEW):
            return {
                ...state,
                listView: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER):
            return {
                ...state,
                isBlockedUser: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL):
            return {
                ...state,
                isShowingMobileFormControl: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP):
            return {
                ...state,
                showEditPremiumPopUp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DUPLICATE_PREMIUM_POP_UP):
            return {
                ...state,
                showDuplicatePremiumPopUp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP):
            return {
                ...state,
                searchResultTimeStamp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FOLLOW_US):
            return {
                ...state,
                showFollowUs: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON):
            return {
                ...state,
                showMobileSortButton: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_VERSION):
            return {
                ...state,
                showVersion: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER):
            return {
                ...state,
                mobileSortOrder: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_RESPONSE_ADD_ON):
            return {
                ...state,
                showResponseAddOn: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SORT_ICON):
            return {
                ...state,
                showSortIcon: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT):
            return {
                ...state,
                searchText: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHARES_LIST):
            return {
                ...state,
                sharesList: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST):
            return {
                ...state,
                formsList: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST):
            return {
                ...state,
                quizzesList: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS):
            return {
                ...state,
                showShortCuts: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE):
            return {
                ...state,
                templateType: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET):
            return {
                ...state,
                showAlreadySubmittedZohoTicket: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SUBMITTED_ZOHO_TICKET):
            return {
                ...state,
                showSubmittedZohoTicket: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MAIL_SUPPORT_POPUP):
            return {
                ...state,
                showMailSupportPopUp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_CALL_SUPPORT_POPUP):
            return {
                ...state,
                showCallSupportPopUp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MORE_POPUP):
            return {
                ...state,
                showMorePopUp: action.payload
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MORE):
            return {
                ...state,
                showForms: false,
                showQuiz: false,
                showShared: false,
                showTemplates: false,
                showMore: true,
                showMorePopUp: false,
                showBillingHistory: false,
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_BILLING_HISTORY):
            return {
                ...state,
                showForms: false,
                showQuiz: false,
                showShared: false,
                showTemplates: false,
                showMore: false,
                showBillingHistory: true,
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS):
            return {
                ...state,
                showForms: true,
                showQuiz: false,
                showShared: false,
                showTemplates: false,
                showMore: false,
                showMorePopUp: false,
                templateType: undefined,
                showBillingHistory: false,
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHARED):
            return {
                ...state,
                showForms: false,
                showQuiz: false,
                showShared: true,
                showTemplates: false,
                showMore: false,
                showMorePopUp: false,
                showBillingHistory: false,
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ):
            return {
                ...state,
                showForms: false,
                showQuiz: true,
                showShared: false,
                showTemplates: false,
                showMore: false,
                showMorePopUp: false,
                showBillingHistory: false,
            }
        case (APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES):
            return {
                ...state,
                showForms: false,
                showQuiz: false,
                showShared: false,
                showTemplates: true,
                showMore: false,
                showMorePopUp: false,
                showBillingHistory: false,
            }
        case (APP_DASHBOARD_ACTION_TYPE.RESET_ALL):
            state = initialState
            return {
                ...state
            }
        default:
            return state
    }
}

export default appDashboardReducer
