import React from 'react'
import '../css/left-navigator.css'
import ChangeContainerMenuItem from "../classes/ChangeContainerMenuItem";
import NavigationMenuItem from "../classes/NavigationMenuItem";
import PopupMenuItem from "../classes/PopupMenuItem";
import StorageBandComponent from "../../app/dashboard/components/StorageBandComponent"
import {useSelector} from "react-redux";
import {Pages} from "../../app/utils/pages";
import {formatBytes, PremiumJSON} from "../utils/helper";

const ChangeContainerDiv = (props, index, menuItem, updateContainer, imgGiven, selectedLanguage) => {
    return (
        <div id={'left_nav_icon_container_' + index} key={menuItem.getKey()}
             className="left-nav-icon-container"
             style={{
                 backgroundColor: menuItem.getSelected() ? '#f5f5f5' : '#fff',
                 height: menuItem.getIconInfo().getAltText() === 'survey-heart-icon' ? '70px' : null
             }}
             onClick={() => {
                 updateContainer(menuItem)
             }}
        >
            <img style={{
                filter: props.service === 'Quizzes' ? 'hue-rotate(300deg)' : null,
                width: menuItem.getIconInfo().getAltText() === 'survey-heart-icon' ? '-webkit-fill-available' : null
            }}
                 src={imgGiven ? menuItem.getIconInfo().getImgSrcPath()
                     : menuItem.getSelected() ?
                         `${menuItem.getIconInfo().getImgSrcPath()}_blue.svg` :
                         `${menuItem.getIconInfo().getImgSrcPath()}_grey.svg`}
                 alt={menuItem.getIconInfo().getAltText()}/>
            <span className="icon-title"
                  style={{
                      color: menuItem.getSelected() ? (props.service === 'Quizzes' ? '#329d58' : '#1976d2') : '#5E5E5E',
                      fontSize: selectedLanguage === "ta" || selectedLanguage === "es" || selectedLanguage === "pt" ? "13px" :
                          selectedLanguage === "fr" ? "11px" :
                              selectedLanguage === "ml" ? "15px" :
                                  selectedLanguage === "in" ? "16px" : null
                  }}>
            {menuItem.getTitle()}
            </span>
        </div>
    )
}

const NavigationAndPopupMenuDiv = (index, menuItem, doNavigation, doPopupAction, selectedLanguage) => {
    return (
        <div id={'left-nav-icon-container-' + index} key={menuItem.getKey()} className="left-nav-icon-container"
             style={{
                 backgroundColor: '#fff',
                 height: menuItem.getIconInfo().getAltText() === 'survey-heart-icon' ? '70px' : null
             }}
             onClick={() => {
                 if (menuItem instanceof NavigationMenuItem) return (doNavigation(menuItem))
                 if (menuItem instanceof PopupMenuItem) return (doPopupAction(menuItem))
             }}
        >
            <img src={`${menuItem.getIconInfo().getImgSrcPath()}`} alt={menuItem.getIconInfo().getAltText()}
                 style={{width: menuItem.getIconInfo().getAltText() === 'survey-heart-icon' ? '-webkit-fill-available' : null}}/>
            <span className="icon-title" style={{
                color: '#424242',
                fontSize: selectedLanguage === "ta" || selectedLanguage === "es" || selectedLanguage === "pt" ? "13px" :
                    selectedLanguage === "fr" ? "11px" :
                        selectedLanguage === "ml" ? "15px" :
                            selectedLanguage === "in" ? "16px" : null
            }}>
            {menuItem.getTitle()}
            </span>
            {menuItem.getLastImg() ? <img src={menuItem.getLastImg()} alt={"image"} style={{width: "20px"}}/> : null}
        </div>
    )
}

const LeftNavigator = (props) => {
    const currentPage = useSelector(state => state.appReducer?.currentPage) ? useSelector(state => state.appReducer?.currentPage) : undefined
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const totalStorage = PremiumJSON[subscriptionData?.subscription_plan]?.storage

    return (
        <div id={'left_navigator'} className={`left-navigator ${props.hideStorageBand ? 'z-index-1' : 'z-index-2'}`}>
            {
                props.LeftNavigatorMenuItems?.map((menuItem, index) => (
                    (() => {
                        if (menuItem instanceof ChangeContainerMenuItem) return (ChangeContainerDiv(props, index, menuItem, props.updateContainer, props.imgGiven, selectedLanguage))
                        else if (menuItem instanceof NavigationMenuItem || menuItem instanceof PopupMenuItem) return (
                            NavigationAndPopupMenuDiv(index, menuItem, props.doNavigation, props.doPopupAction, selectedLanguage)
                        )
                    })()
                ))
            }
            {
                (() => {
                    if (!props.hideStorageBand && totalStorage)
                        return (
                            <div className='file-storage'>
                                <StorageBandComponent totalStorage={formatBytes(totalStorage)}
                                                      showManageButton={currentPage === Pages.dashboard ? true : null}/>
                            </div>
                        )
                })()
            }
        </div>
    )
}

export default LeftNavigator
