import React, {useState, useRef, useEffect} from 'react'
import {QUIZ_BASE_URL} from "../utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import MaterialButton, {ButtonTypes} from "./MaterialButton";
import closeBlack from '../../../public/images/close_black.svg';
import mailShare from '../../../public/images/mail_share.svg';
import facebookShare from '../../../public/images/facebook_share.svg';
import twitterShare from '../../../public/images/twitter_share.svg';
import whatsappShare from '../../../public/images/whatsapp_icon.png';
import linkIconBlue from "../../../public/images/icons/link_icon_blue.svg";
import linkIconGrey from "../../../public/images/icons/link_icon_grey.svg";
import embeddedIconBlue from "../../../public/images/icons/embedded_icon_blue.svg";
import embeddedIconGrey from "../../../public/images/icons/embedded_icon_grey.svg";
import copyBlueIcon from "../../../public/images/copy_icon_blue.svg";
import {PAGE_HASH_LOCATION} from "../../app/utils/pages";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../app/reducers/appReducer";
import {useDispatch} from "react-redux";
import {FORMS_ACTION_TYPE} from "../../app/reducers/formsReducer";
import {sortFields} from "../../app/dashboard/utils/sort-form-list";
import MaterialCheckBox from "./MaterialCheckBox";
import {BUILDER_ACTION_TYPE} from "../../app/reducers/builderReducer";

const ShareCard = (props) => {
    const TABS = {
        LINK: "link",
        EMBEDDED: "embedded"
    }
    const {t} = useTranslation()
    const [isTextCopied, setIsTextCopied] = useState(false);
    const [selectedTab, setSelectedTab] = useState(TABS.LINK);
    const [shareLinkWithPasscode, setShareLinkWithPasscode] = useState();
    const [isAnswerSheetLink, setIsAnswerSheetLink] = useState();
    const InputRef = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        setShareLinkWithPasscode(props?.passcode)
        if (props?.isQuiz) {
            if (props.isAnswerSheetLink)
                setIsAnswerSheetLink(true)
            else
                setIsAnswerSheetLink(false)
        }
    }, []);

    const updateTextCopied = (e) => {
        e.preventDefault();
        const forqLink = InputRef.current.value;

        if (shareLinkWithPasscode && selectedTab === TABS.LINK) {
            const linkWithPasscode = `${props.isQuiz ? 'Quiz link' : 'Form link'}: ${forqLink}\nPasscode: ${props?.passcode}`;

            navigator.clipboard.writeText(linkWithPasscode)
                .then(() => {
                })
                .catch((error) => {
                    console.error('Unable to copy text to clipboard:', error);
                });
        } else {
            navigator.clipboard.writeText(forqLink)
                .then(() => {
                })
                .catch((error) => {
                    console.error('Unable to copy text to clipboard:', error);
                });
        }
        setIsTextCopied(true)
        setTimeout(() => {
            setIsTextCopied(false)
        }, 2000)
    }

    const handleShareClick = (url) => {
        InputRef.current.select();
        window.open(url);
    }

    let viewLink;
    if (props.isQuiz) {
        if (props.isAnswerSheetLink) {
            viewLink = QUIZ_BASE_URL + '/answer-sheet/' + props.formId
        } else {
            viewLink = QUIZ_BASE_URL + '/id/' + props.formId
        }
    } else {
        const domainName = location.href.split('/').slice(0, 3).join('/');
        if (props.isAnswerSheetLink) {
            viewLink = domainName + '/view-response/' + props.formId
        } else {
            viewLink = domainName + '/form/' + props.formId
        }
    }
    const embeddedCode = `<iframe src="${viewLink}">Loading...</iframe>`

    const handleTabClick = (tab) => {
        setSelectedTab(tab)
        setIsTextCopied(false)
    }

    const handleShareWithPasscode = (e) => {
        setShareLinkWithPasscode(e);
    }

    const copyPasscode = () => {
        navigator.clipboard.writeText(props?.passcode)
            .then(() => {
                dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Copied'})
                dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
            })
            .catch((error) => {
                console.error('Unable to copy text to clipboard:', error);
            });
    }

    return (
        <div id={'share-card'} className='share-card'>
            <div className='share-close-button' onClick={() => {
                if (props.isShowSharePopUp) {
                    props.isShowSharePopUp()
                }
                if (!props.isAnswerSheetLink) {
                    window.location.href = PAGE_HASH_LOCATION.dashboard
                    dispatch({type: FORMS_ACTION_TYPE.SET_LAST_FORMS_SORTED_BY, payload: sortFields.DATE_EDITED})
                    dispatch({type: APP_ACTION_TYPE.SET_SHOW_SHARE_POP_UP, payload: false})
                }
            }}><img
                src={closeBlack} alt='close'/></div>
            <div>
                <div style={{display: "flex"}}>
                    <p id={'form-link-text'}
                       style={{display: "flex", alignItems: "center", marginTop: "0"}}
                       className={isMobileOnly ? 'form-link-text-mobile' : 'form-link-text'}>{t(translationKeys.share)}:</p>
                    <div className={"form-link-tab"} style={{
                        borderBottom: selectedTab === TABS.LINK ? "3px solid #004BA0" : null,
                        color: selectedTab === TABS.LINK ? "#004BA0" : "#4D4D4D"
                    }} onClick={() => handleTabClick(TABS.LINK)}>
                        <img src={selectedTab === TABS.LINK ? linkIconBlue : linkIconGrey} alt={"link"}/>
                        <span>{props.isQuiz ? props.isAnswerSheetLink ? t(translationKeys.answer_link) : t(translationKeys.quiz_link) : t(translationKeys.form_link)}</span>
                    </div>
                    <div className={"form-link-tab"} style={{
                        borderBottom: selectedTab === TABS.EMBEDDED ? "3px solid #004BA0" : null,
                        color: selectedTab === TABS.EMBEDDED ? "#004BA0" : "#4D4D4D"
                    }} onClick={() => handleTabClick(TABS.EMBEDDED)}>
                        <img src={selectedTab === TABS.EMBEDDED ? embeddedIconBlue : embeddedIconGrey}
                             alt={"embedded"}/>
                        <span>{t(translationKeys.embed_code)}</span>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: selectedTab === TABS.EMBEDDED ? "20px" : null}}>
                    <div className='form-link-container' style={{width: selectedTab === TABS.EMBEDDED ? "100%" : null}}>
                        <input ref={InputRef} className='share-form-link-text'
                               value={selectedTab === TABS.EMBEDDED ? embeddedCode : viewLink} readOnly={true}/>
                        <div id={'share-copy-button'} onClick={(e) => updateTextCopied(e)}
                             className='share-copy-button'
                             style={isTextCopied ? {backgroundColor: '#029E37', width: 'auto'} : null}>
                            {isTextCopied ? (selectedTab === TABS.EMBEDDED ? t(translationKeys.code_copied) : t(translationKeys.link_copied)) : (selectedTab === TABS.EMBEDDED ? t(translationKeys.copy_code) : t(translationKeys.copy_link))}
                        </div>
                    </div>
                    {selectedTab === TABS.LINK ? <MaterialButton data={{
                        imageFileName: 'preview_icon_white.svg',
                        buttonType: ButtonTypes.CONTAINED, bg_color: '#1976d2',
                        title: '',
                        customButtonContainerStyle: {
                            minWidth: '0',
                            height: '50px',
                            padding: '0 10px 0 15px',
                            borderRadius: 0,
                            margin: '0 10px',
                        }, customImageStyle: {
                            marginRight: '0',
                            width: isMobileOnly ? '24px' : '28px',
                            height: isMobileOnly ? '24px' : '28px'
                        }
                    }} handleClick={() => open(viewLink)}/> : null}
                </div>
                {/*passcode protection*/}
                {
                    (props?.passcode && selectedTab === TABS.LINK && !isAnswerSheetLink) &&
                    <>
                        {shareLinkWithPasscode &&
                            <div className={'share-card-passcode-container'}>
                                <p className={'share-card-passcode-label'}>{t(translationKeys.passcode_protection)}: </p>
                                <div className={'share-card-passcode-text-container'}>
                                    <p className={'share-card-passcode-text'}>{props?.passcode}</p>
                                    <img src={copyBlueIcon} alt={'copy blue icon'}
                                         className={'share-card-passcode-copy-icon'} onClick={copyPasscode}/>
                                </div>
                            </div>}
                        <div className={'share-passcode-link-container'}>
                            <div id={'share-form-link'} className="passcode-checkbox">
                                <MaterialCheckBox removeWidth={true}
                                                  onChange={handleShareWithPasscode}
                                                  isChecked={shareLinkWithPasscode}
                                />
                            </div>
                            <p className="share-passcode-link-label">{t(translationKeys.share_link_with_passcode)}</p>
                        </div>
                    </>
                }
                {selectedTab === TABS.LINK ? <div id={'share-option-text'} className='share-options-container'>
                    <p style={{
                        fontWeight: 'normal',
                        fontSize: '24px',
                        margin: '12px 12px 12px 0'
                    }}>{t(translationKeys.share_via)}:</p>
                    <img className='share-images' src={mailShare} alt='GMail'
                         onClick={() => handleShareClick('https://mail.google.com/mail/u/0/?view=cm&body=' + (selectedTab === TABS.EMBEDDED ? embeddedCode : props.passcode ? `${props.isQuiz ? 'Quiz link' : 'Form link'}: ${viewLink} \n Passcode: ${props?.passcode}` : viewLink))}/>
                    <img className='share-images' src={facebookShare} alt='FaceBook'
                         onClick={() => handleShareClick('https://www.facebook.com/sharer/sharer.php?u=' + (selectedTab === TABS.EMBEDDED ? embeddedCode : props.passcode ? `${props.isQuiz ? 'Quiz link' : 'Form link'}: ${viewLink} \n Passcode: ${props?.passcode}` : viewLink))}/>
                    <img className='share-images' src={twitterShare} alt='Twitter'
                         onClick={() => handleShareClick('https://twitter.com/intent/tweet?text=' + (selectedTab === TABS.EMBEDDED ? embeddedCode : props.passcode ? `${props.isQuiz ? 'Quiz link' : 'Form link'}: ${viewLink} \n Passcode: ${props?.passcode}` : viewLink))}/>
                    <img className='share-images' src={whatsappShare} alt='WhatsApp'
                         onClick={() => handleShareClick('https://web.whatsapp.com/send?text=' + (selectedTab === TABS.EMBEDDED ? embeddedCode : props.passcode ? `${props.isQuiz ? 'Quiz link' : 'Form link'}: ${viewLink} \n Passcode: ${props?.passcode}` : viewLink))}/>
                </div> : null}
            </div>
        </div>
    );
}


export default ShareCard
