import React, {useState} from 'react'
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate";
import Styles from '../css/pagination-container.module.css';
import CurrentPageInfo from "./CurrentPageInfo";
import {PER_PAGE_LIMIT} from "../../admin/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

export default function PaginationContainer(props) {
    const {t} = useTranslation()
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const perPageLimit = props.perPageLimit ? props.perPageLimit : PER_PAGE_LIMIT

    //* CurrentPageIndex state is used to update the <currentPageInfo/> */
    const handlePageChange = (clickedData) => {
        setCurrentPageIndex(clickedData.selected);
        props.handlePageChange(clickedData)
    }

    // ** calculating total number of pages **
    const totalPagesCount = Math.abs(props.totalDataCount / perPageLimit)

    return (
        <div id={'pagination-container'} className={Styles.paginator_outer_wrapper}>
            {props.totalDataCount && (props.totalDataCount > perPageLimit) ?
                <ReactPaginate
                    previousLabel={t(translationKeys.previous)}
                    nextLabel={t(translationKeys.next)}
                    breakLabel={'...'}
                    forcePage={props.forcePage}
                    pageCount={totalPagesCount}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={Styles.pagination_container}
                    pageClassName={Styles.each_page}
                    activeClassName={Styles.active}
                    nextClassName={totalPagesCount > (currentPageIndex + 1) ? Styles.next_button : Styles.hide_button}
                    previousClassName={currentPageIndex === 0 ? Styles.hide_button : Styles.previous_button}
                    breakClassName={Styles.each_page}/> : null}
            {props.totalDataCount ?
                <CurrentPageInfo perPageLimit={perPageLimit}
                                 currentPageIndex={currentPageIndex}
                                 totalDataCount={props.totalDataCount}/> : null}
        </div>
    )
}

PaginationContainer.prototype = {
    handlePageChange: PropTypes.func,
    totalDataCount: PropTypes.number
}

PaginationContainer.defaultProps = {
    handlePageChange: clickedData => console.log(clickedData),
    totalDataCount: 0
}