import React from "react"
import warningIcon from "../../../../public/images/warning.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {useDispatch, useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {PREMIUM_PLAN_KEYS} from "../../../shared/utils/helper";

const DashboardLimitReachedUI = () => {
    const {t} = useTranslation()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    return (
        <div className={"cards-container-width"}
             style={{
                 height: "10%",
                 marginTop: "0",
                 display: "flex",
                 margin: "20px 0",
                 padding: "0 15px",
                 minHeight: "50px",
                 overflow: "hidden",
                 borderRadius: "5px",
                 background: "#ffffff",
                 boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)"
             }}>
            <img src={warningIcon} alt={"Info"} style={{margin: "auto 10px", height: "25px"}}/>
            <p style={{fontSize: isMobileOnly ? "4.5vw" : "18px", alignSelf: "center"}}>
                {subscriptionData?.subscription_plan === PREMIUM_PLAN_KEYS.GOLD_YEARLY ? t(translationKeys.premium_limit_reached_for_gold) : t(translationKeys.premium_limit_reached)}
            </p>
            {isMobileOnly ?
                <p style={{color: '#1976d2', textDecoration: "underline", fontSize: "4.5vw"}}
                   onClick={() => {
                       dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
                   }}>{t(translationKeys.view_plans)}</p>
                : <div style={{margin: "auto 0"}}>
                    <MaterialButton data={{
                        title: subscriptionData?.subscription_plan === PREMIUM_PLAN_KEYS.GOLD_YEARLY ? t(translationKeys.contact_us) : t(translationKeys.view_plans),
                        customButtonContainerStyle: {
                            borderRadius: '10px',
                            backgroundColor: '#1976d2',
                            width: '120px',
                            margin: "10px"
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                            fontSize: '14px'
                        }
                    }} handleClick={() => {
                        if (subscriptionData?.subscription_plan === PREMIUM_PLAN_KEYS.GOLD_YEARLY) {
                            window.open('https://www.surveyheart.com/form/5dc50aa5b678b61dbec474a4#welcome', "__blank")
                        } else {
                            dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                        }
                    }}/>
                </div>}
        </div>
    )
}

export default DashboardLimitReachedUI
