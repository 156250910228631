export const translationsHindi = {
    all: 'सभी',
    ago: "पहले",
    now: "अब",
    start: "शुरू करें",
    account_blocked: "अकाउन्ट ब्लाॅक कर दिया गया है",
    account_blocked_alert: "हमारे नियमों और शर्तों का उल्लंघन करने की वजह से यह अकाउंट ब्लाॅक कर दिया गया है",
    sign_out: "साईन आऊट",
    short_answer: "संक्षिप्त जवाब",
    long_answer: "लंबे जवाब",
    email: "ई-मेल",
    number: "संख्या",
    file: "फाइल",
    files: "फ़ाइलें",
    faq_videos: "अक्सर पूछे जाने वाले प्रश्न वीडियो",
    file_upload: "फाइल अपलोड",
    question_error: "प्रश्न जोड़ा नहीं जा सकता",
    storage: "भंडारण",
    form: "प्रपत्र",
    quiz: "प्रश्नोत्तरी",
    fup_desc: "अब आप अपने फ़ॉर्म और क्विज़ के उत्तरदाताओं को फ़ाइलें अपलोड करने की अनुमति दे सकते हैं!\n\nआरंभ करने के लिए फ़ाइल अपलोड प्रश्न प्रकार का चयन करें।",
    new_fup_feature_title: "नई फ़ाइल अपलोड सुविधा!",
    multiple_choice: "विभिन्न विकल्प",
    date_text: "दिनांक",
    time_text: "समय",
    uploading: "अपलोड हो रहा है",
    add_question: "प्रश्न जोडें",
    add_question_alert: "कृपया, कम से कम एक प्रश्न सभी जरूरी विकल्पों के साथ जोडें",
    add_question_alert_quiz: "क्विज़ प्रस्तुत करने से पहले कृपया कम से कम एक प्रश्न जोड़ें",
    ok: "ठीक है",
    title: "शीर्षक",
    title_required: "शीर्षक आवश्यक है",
    form_name_alert: "कृपया, फाॅर्म को पब्लिश करने से पहले नाम दें",
    quiz_name_alert: "कृपया इसे प्रकाशित करने से पहले क्विज़ को नाम दें",
    no_connection: "कनेक्शन नहीं है",
    check_connection: "कृपया, अपना नेटवर्क कनेक्शन चेक कर लें",
    finish_incomplete_question: "कृपया, फाॅर्म के सभी अधुरे क्वेशचन्स को पूरा करें",
    draft: "ड्राफ्ट",
    save_to_draft_alert: "क्या आप अपने फाॅर्म में किये बदलावों को ड्राफ्ट के रूप में सहेजना चाहेंगे?",
    save_to_draft_quiz_alert: "क्या आप अपने परिवर्तनों को खोने से बचाने के लिए इस प्रश्नोत्तरी को एक ड्राफ्ट के रूप में सहेजना चाहेंगे?",
    yes: "हाँ",
    no: "नहीं",
    cancel: "कैन्सल करें",
    live_preview: "लाईव प्रीव्यू",
    themes: "थीम्स",
    tap_to_set_theme: "फार्म का थीम सेट करने के लिए यहाँ टैप करें",
    select_image: "ईमेज चुनिए",
    created: "बन गया",
    form_created_successfully: "फार्म सफलतापूर्वक बन गया है",
    warning: "चेतावनी",
    form_edit_alert: "यह फाॅर्म एडिट करने से सबमिट की गईं प्रतिक्रियाएँ प्रभावित हो सकती हैं, क्योंकि यह फाॅर्म पहले से ही प्रकाशित हो चुका है।",
    quiz_edit_alert: 'यह प्रश्नोत्तरी पहले ही सबमिट की जा चुकी है और उत्तर एकत्र कर लिए गए हैं। प्रश्नोत्तरी संपादित करने से पिछले उत्तर प्रभावित हो सकते हैं',
    edit: "एडिट करें",
    download_as: "ऐसे डाऊनलोड करें",
    delete_form: "फाॅर्म डिलीट करें",
    delete_form_alert: "क्या आप यह फाॅर्म डीलीट करना चाहते हैं? ऐसा करने से आप इसे खो देंगे",
    delete_quizzes_alert: "क्या आप इन क्विज़ को हटाना चाहते हैं? इस एक्शन को वापस नहीं किया जा सकता।",
    delete_forms_alert: "क्या आप एक से ज़्यादा फाॅर्म डिलीट करना चाहते हैं? ऐसा करने से आप इन्हें खो देंगे",
    delete_quiz_alert: "क्या आप इस क्विज़ को हटाना चाहते हैं? इस एक्शन को वापस नहीं किया जा सकता।",
    delete_answers_alert: "क्या आप इन उत्तरों को हटाना चाहते हैं?",
    delete_answer_alert: "क्या आप इस उत्तर को हटाना चाहते हैं?",
    delete: "डिलीट",
    delete_all: "सभी हटा दो",
    section: "अनुभाग",
    worst: "बहुत बुरा",
    server_error_alert: "सर्वर से संपर्क नहीं हो पा रहा है, कृपया थोड़ी देर बाद कोशीश करें",
    signed_in_as: "इस रूप में साईन इन है",
    signing_out: "साईन आऊट हो रहा है",
    create_form: "फार्म बनाएँ",
    create_blank_form: "खाली प्रपत्र बनाएँ",
    create_blank_quiz: "रिक्त प्रश्नोत्तरी बनाएं",
    close: "बंद करें",
    response_collection_exit_alert: "क्या आप प्रतिक्रिया एकत्रित करने पे रोक लगाना चाहते हैं?",
    form_builder: "फाॅर्म बिल्डर",
    form_builder_promo: "अपने टेम्पलेट्स और थीम्स प्रीव्यू तुरंत देखें",
    results: "परिणाम",
    result_feature_promo: "अपनेे सर्वे परिणामों का विश्लेषण कर उन्हें एक्सपोर्ट करें",
    notification: "नोटीफिकेश़न",
    delete_option_alert: "क्या आप यह डिलीट करना चाहते हैं? [ध्यान दें : यह पहले जैसा नहीं हो पायेगा]",
    question_type: "क्वेशचन टाईप",
    change_question_type_alert: "क्वेशचन टाईप बदलने के लिए यहाँ टैप करें",
    preview_not_available: "प्रीव्यू उपलब्ध नहीं है",
    delete_question: "क्वेशचन डिलीट करें",
    delete_response_collected_question_alert: "क्वेशचन डिलीट करने से एकत्रित की गईं प्रतिक्रयाएँ प्रभावित होंगी। क्या आप फिर भी यह क्वेशचन डिलीट करना चाहते हैं?",
    delete_question_alert: "क्या आप वाकई यह प्रश्न डिलीट करना चाहते हैं?",
    options: "कई विकल्प",
    option: 'विकल्प',
    no_answer: "उत्तर नहीं है",
    no_response: "कोई जवाब नहीं",
    responses: "प्रतिक्रियाएँ",
    response: "प्रतिक्रिया",
    serial_number: "क्रमिक सं",
    submit_time: "सबमिट करने की अवधि",
    general_form_type_description: "नया फॉर्म / सर्वे बनाएं",
    quiz_type_description: "नया क्विज बनाएं",
    sheet_disconnected: 'Google शीट डिस्कनेक्ट हो गई',
    sheet_not_connected: 'शीट कनेक्ट नहीं है',
    sheet_not_connected_desc: 'Google शीट को कनेक्ट करने के लिए कृपया मालिक से संपर्क करें।',
    export_success: "सफलतापूर्वक निर्यात हो गया है",
    answer: "उत्तर",
    answer_with_colan: "उत्तर :",
    others: "अन्य",
    delete_response: "प्रतिक्रिया डिलीट करें",
    deleted: "डिलीट हो गया",
    my_themes: "मेरे थीम्स",
    limit_reached: "निर्धारित सीमा पहूँच गई है",
    limit_reached_description: "आप छवि अपलोड की सीमा तक पहुंच गए हैं।",
    modified: "सुधार किया हुआ",
    delete_image: "डिलीट ईमेज",
    delete_image_alert: "क्या आप वाकई यह ईमेज डिलीट करना चाहते हैं?",
    delete_theme_image_desc: "क्या आप इस छवि को हटाना चाहते हैं? यदि आप आगे बढ़ते हैं, तो यह छवि किसी भी फ़ॉर्म या क्विज़ के लिए उपलब्ध नहीं होगी जो इसका उपयोग कर रहे हैं। इस क्रिया को पूर्ववत नहीं किया जा सकता है।",
    section_title: "अनुभाग शिर्षक",
    section_description: "अनुभाग विवरण",
    show_scale: 'स्केल दिखाएँ',
    show_options: "अन्य विकल्प दिखाएँ",
    options_required: "विकल्प देना आवश्यक है",
    link_copied: "लिंक काॅपी हो गई है",
    app_not_found: "ऐप्लिकेशन उपलब्ध नहीं है",
    default_text: "डीफाॅल्ट",
    dark_mode: "डार्क मोड",
    done: "हो गया",
    upload_image: "ईमेज़ अपलोड करें",
    question: "प्रश्न",
    required: "अपेक्षित",
    share: "शेयर",
    form_link: "फाॅर्म लिंक",
    copy_link: "लिंक काॅपी करें",
    view_form: "फाॅर्म देखें",
    menu: "मेन्यू",
    view_all: "सर्व",
    settings: "सेटिंग्स",
    submit: "सबमिट",
    change_theme: "थीम चेंज़ करें",
    tap_here_to_choose_theme: "थीम चयन के लिए यहाँ टैप करें",
    show_logo: "लोगो दिखाएँ",
    show_logo_alert: "क्या आप लोगो को फार्म में दिखाना चाहते हैं?",
    welcome_page: "स्वागत पृष्ठ",
    show_welcome_page_alert: "क्या आप स्वागत पृष्ठ को फार्म में दिखाना चाहते हैं?",
    start_button: "स्टार्ट बटन",
    allow_multiple_responses: "एक से ज्यादा विकल्पों को चुनने की अनुमति दें",
    allow_more_response_alert: "क्या आप यूज़र्स को एक से अधिक बार प्रतिक्रिया करने की अनुमति देना चाहते हैं?",
    allow_more_quiz_attempt_alert: "क्या आप यूज़र्स को एक से अधिक बार प्रयास करने की अनुमति देना चाहते हैं?",
    show_summary: "सारांश दिखाएँ",
    show_results: "परिणाम दिखाएँ",
    allow_responders_view_summary_alert: "यूज़र्स के फाॅर्म को सबमिट करने के बाद क्या आप उन्हें सभी प्रतिक्रियाओं का सारांश देखने की अनुमति देना चाहते हैं?",
    allow_result_view_quiz_alert: "क्या आप यूज़र्स को परिणाम देखने की अनुमति देना चाहते हैं?",
    show_question_number: "प्रश्न क्रमांक दिखाएँ",
    show_question_number_in_form: "क्या आप फॉर्म पर प्रश्न संख्या दिखाना चाहते हैं?",
    show_question_number_in_quiz: "क्या आप प्रश्नोत्तरी पर प्रश्न संख्या दिखाना चाहते हैं?",
    shuffle_questions: "प्रश्नों को शफल करें",
    shuffle_questions_alert: "क्या आप प्रश्नों को शफल करना चाहते हैं?",
    shuffle_quiz_questions_alert: "क्या आप क्विज़ प्रश्नों में फेरबदल करना चाहते हैं?",
    description: "विवरण",
    builder: "बिल्डर",
    preview: "प्रीव्यू",
    form_status: "फाॅर्म की स्थिती",
    active: "सक्रिय",
    inactive: "निष्क्रिय",
    import_questions: 'आयात प्रश्न',
    search_by_question_title_from_forms: 'मौजूदा प्रपत्रों से प्रश्न शीर्षक के आधार पर खोजें',
    search_by_question_title_from_quizzes: 'मौजूदा क्विज़ से प्रश्न शीर्षक के आधार पर खोजें',
    view_responses: "प्रतिक्रियाएँ देखें",
    edit_form: "फाॅर्म संपादित करें",
    duplicate_form: "फाॅर्म डुप्लिकेट करें",
    drafts: "ड्राफ्ट",
    no_form_available: "कोई फाॅर्म उप्लब्ध नहीं!",
    notifications: "सुचनाएँ",
    feedback: "फीडबैक",
    privacy_policy: " गोपनीयता धोरण",
    open: 'खोलें',
    open_individual_responses: "व्यक्तिगत प्रतिक्रियाएँ खोलें",
    bar_chart: "बार चार्ट",
    pie_chart: "पाई चार्ट",
    summary: "सारांश",
    invalidated_form_cannot_be_selected: "अमान्य प्रपत्र का चयन नहीं किया जा सकता.",
    invalidated_quiz_cannot_be_selected: "अमान्य क्विज़ का चयन नहीं किया जा सकता.",
    individual: "व्यक्ति",
    search: "खोजें...",
    image: "इमेज",
    video: "वीडिओ",
    website: "वेबसाईट",
    exit: "बाहर जाएँ",
    response_limit_reached: "प्रतिक्रिया की निर्धारित सीमा पहूँच गई है",
    response_limit_reached_alert: "इस फाॅर्म ने प्रतिक्रिया डेटा के लिए नियत सीमा पार कर ली है, इसलिए हमने इस फाॅर्म के लिए आने वाली प्रतिक्रियाओं को रोक दिया है। यदि आपको सर्वे जा़री रखने की आवश्यकता है तो हमारा सुझाव है कि आप इस फाॅर्म को डुप्लिकेट कर लें और निर्धारित लिंक शेयर करें।",
    unapproved_form: "अस्वीकृत फाॅर्म",
    unapproved_form_alert: "इस फाॅर्म में कुछ ऐसे शब्दों का प्रयोग हो रहा है जो हमारे नीतियों के खिलाफ़ है।अगर आपको ऐसा लगता है कि यह फाॅर्म ग़लती से ब्लाॅक हो गया है तो नीचे दिये गये फाॅर्म लिंक को खोलें और फाॅर्म में माँगी गयी जानकारी दें।",
    choose_your_language: "अपनी भाषा चुनें",
    provide_explanation: "स्पष्टीकरण दें",
    selected: "चुनी हुईं",
    review_button: "रिव्हयू",
    not_specified: "निर्दिष्ट नहीं है",
    quiz_control: "क्वीज़ कंट्रोल",
    allow_multiple_attempts: "एकाधिक प्रयासों की अनुमति दें",
    user_info: "उपयोगकर्ता की जानकारी",
    name_s: "नाम",
    duration: "अवधि",
    set_duration_quiz_alert: "क्या आप क्वीज़ के लिए अवधि निर्धारित करना चाहते हैं?",
    user_information_description: "क्या आप प्रतिभागी की जानकारी लेना चाहते हैं?",
    set_duration: "अवधि नियत करें (घंटे:मिनट)",
    minutes: "मिनट",
    hours: "घंटे",
    minute: "मिनट",
    hour: "घंटा",
    correct: "सही बात",
    wrong: "गलत",
    skipped: "छोड़ा गया",
    score: "स्कोर",
    forms: "फार्म",
    quizzes: "क्विज़",
    more: "अधिक",
    quiz_status: "प्रश्नोत्तरी स्थिति",
    view_answers: "उत्तर देखें",
    view_quiz: "प्रश्नोत्तरी देखें",
    duplicate_quiz: "डुप्लीकेट क्विज़",
    no_quiz_available: "कोई प्रश्नोत्तरी उपलब्ध नहीं है!",
    quiz_link: "क्विज़ लिंक",
    unapproved_quiz: "अस्वीकृत प्रश्नोत्तरी",
    unapproved_quiz_alert: "इस फाॅर्म में कुछ ऐसे शब्दों का प्रयोग हो रहा है जो हमारे नीतियों के खिलाफ़ है।अगर आपको ऐसा लगता है कि यह फाॅर्म ग़लती से ब्लाॅक हो गया है तो नीचे दिये गये फाॅर्म लिंक को खोलें और फाॅर्म में माँगी गयी जानकारी दें।",
    disconnect: 'डिस्कनेक्ट',
    do_you_want_to_show_all_correct_answers_to_users: "क्या आप उपयोगकर्ताओं को सही उत्तर दिखाना चाहते हैं?",
    results_publish_date: "परिणाम प्रकाशित तिथि",
    do_you_want_to_set_date_to_publish_results: "क्या आप परिणाम प्रकाशित करने के लिए तिथि निर्धारित करना चाहते हैं",
    quiz_result: "प्रश्नोत्तरी परिणाम",
    enter_answer: "उत्तर",
    manage: "प्रबंधित",
    manage_storage: 'संग्रहण प्रबंधित करें',
    select_all: 'सबका चयन करें',
    shuffle_options: 'शफ़ल विकल्प',
    shuffle_options_enabled: 'शफ़ल विकल्प सक्षम',
    shuffle_options_disabled: 'शफ़ल विकल्प अक्षम',
    add_options_to_enable_this_feature: 'इस सुविधा को सक्षम करने के लिए विकल्प जोड़ें',
    attach: 'संलग्न करना',
    shuffle: 'शफ़ल',
    marks: "निशान",
    mark: "निशान",
    correction_status: "स्थिति :",
    status_validated: "मान्य",
    pending: "विचाराधीन",
    results_publish: "प्रकाशित करना",
    publish: "प्रकाशित करना",
    publish_description: "क्या आप क्विज़ परिणाम प्रकाशित करना चाहते हैं?",
    publish_description_note: "(अंक एक ही URL में प्रकाशित होंगे)",
    published_successfully: "सफलतापूर्वक प्रकाशित",
    choice_type_validation_note: "*एकाधिक विकल्प स्वचालित रूप से मान्य है",
    short_answer_type_validation_note: "*लघु उत्तर स्वचालित रूप से मान्य है",
    long_answer_type_validation_note: "*लंबे उत्तर स्वचालित रूप से मान्य नहीं है",
    fileupload_answer_type_validation_note: "*फ़ाइल अपलोड स्वचालित रूप से मान्य नहीं है",
    dropdown_answer_type_validation_note: "*ड्रॉपडाउन विकल्प स्वचालित रूप से मान्य",
    save: "सहेजें",
    questions: "प्रश्न",
    show_correct_answer: "सही उत्तर दिखाएं",
    show_answer: "उत्तर दिखाओ",
    hide_answer: "उत्तर छिपाएं",
    no_marks: "कोई निशान नहीं",
    question_is_required: "प्रश्न शीर्षक आवश्यक है",
    marks_is_required: "मार्क्स की आवश्यकता है",
    options_are_required: "विकल्प आवश्यक हैं",
    options_limit_reached: 'विकल्प की सीमा पूरी हो गई',
    options_limit_reached_description: 'आप किसी प्रश्न पर अनुमत विकल्पों की संख्या की सीमा तक पहुंच गए हैं।',
    correct_answer_required: "सही उत्तर की आवश्यकता है",
    enter_valid_mark: "मान्य चिह्न दर्ज करें",
    evaluated: "का मूल्यांकन",
    saved: "बचाया",
    pending_evaluation: "लंबित मूल्यांकन",
    alphabet: "वर्णमाला",
    not_evaluated: "मूल्यांकित नहीं",
    percentage_ascending: "प्रतिशत - आरोही",
    percentage_descending: "प्रतिशत - अवरोही",
    pending_evaluation_alert_message: "प्रकाशन से पहले आपको सभी उत्तरों का पूर्ण मूल्यांकन करना चाहिए।",
    show_unevaluated: "अनवैलेंटेड दिखाएँ",
    publish_success_alert_message: "उत्तरदाता अपने परिणाम अपनी संबंधित उत्तर पुस्तिकाओं में देख सकते हैं।",
    publishing: "प्रकाशन",
    quiz_published: "क्विज प्रकाशित",
    quiz_published_alert_before_edit_message: "यह क्विज़ प्रकाशित किया गया है, यदि आपको बदलाव करने की आवश्यकता है, तो आपको क्विज़ की नकल करके उसे फिर से साझा करना होगा।",
    quiz_has_answers: "क्विज़ के उत्तर हैं",
    quiz_has_answer_alert_message: "इस क्विज़ को संपादित करने के लिए, आपको सभी मौजूदा उत्तरों को हटाना होगा।",
    unavailable_features_description: 'इस फॉर्म में ऐसी विशेषताएं हैं जो अभी तक वेब ऐप में समर्थित नहीं हैं',
    unavailable_quiz_features_description: 'इस क्विज़ में ऐसी विशेषताएं हैं जो अभी तक वेब ऐप में समर्थित नहीं हैं',
    unavailable: 'अनुपलब्ध',
    unpublished: "अप्रकाशित",
    published: "प्रकाशित",
    setting_updated: "अद्यतन स्थापित करना",
    account: "लेखा",
    on: "पर",
    off: "बंद",
    scan_qr_code: "क्यू आर कोड स्कैन करें",
    answers: "जवाब",
    answer_s: "उत्तर",
    question_limit_reached: "प्रश्न सीमा पहुँच गई",
    reached_limit_for_number_of_questions: "आप किसी फ़ॉर्म पर अनुमत प्रश्नों की संख्या तक पहुँच गए हैं। इसलिए, कोई नया प्रश्न नहीं जोड़ा जा सकता है",
    life_time: "जीवन काल",
    last_year: "पिछले साल",
    last_30_days: "पिछले 30 दिनों में",
    last_7_days: "आखिरी 7 दिन",
    last_24_hours: "पिछले 24 घंटे",
    custom_date: "कस्टम तिथि",
    filter_by_date: "तिथि के अनुसार फ़िल्टर करें",
    filter_by_question: "प्रश्न के अनुसार फ़िल्टर करें",
    filter_text: "फ़िल्टर टेक्स्ट",
    add_filter: "फ़िल्टर जोड़ें",
    apply: "लागू करना",
    from_date: "की तिथि से",
    today: 'आज',
    to_date: "तारीख तक",
    contains: "शामिल है",
    equal_to: "के बराबर",
    not_equal_to: "असमान",
    from_date_required: "आवश्यक तिथि से",
    to_date_required: "तिथि करने के लिए आवश्यक",
    webapp_promo_text: "अपने फ़ॉर्म, सर्वेक्षण, पोल और क्विज़ ऑनलाइन एक्सेस करें!",
    question_title: "प्रश्न",
    invalid_date: "अमान्य दिनांक",
    invalid_url: "अवैध यूआरएल",
    invalid_url_description: "ऐसा लगता है कि यूआरएल मान्य नहीं है.",
    delete_response_alert: "क्या आप वाकई यह प्रतिक्रिया डिलीट करना चाहते हैं?",
    delete_responses_alert: "क्या आप इन प्रतिक्रियाओं को हटाना चाहते हैं?",
    overview: "अवलोकन",
    no_results_found: "कोई परिणाम नहीं मिला",
    date_format: "dd-mm-yyyy",
    title_dashboard: "डैशबोर्ड",
    title_notifications: "सूचनाएं",
    select_one_response: "कृपया हटाने के लिए कम से कम एक प्रतिक्रिया का चयन करें",

//Untranslated strings

    your_account: "आपका खाता",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    feature_spotlight: 'फ़ीचर स्पॉटलाइट',
    favourites: "पसंदीदा",
    shared: "साझा",
    hide: "छिपाना",
    use_templates: "टेम्प्लेट का उपयोग करें", //
    quick_settings: "त्वरित सेटिंग",
    leave_collaboration: "सहयोग छोड़ें",
    templates: "टेम्पलेट्स",
    collapse: "गिर जाना",
    start_scale_from_zero: 'स्केल 0 से प्रारंभ करें',
    center_label_optional: 'केंद्र लेबल (वैकल्पिक)',
    left_label_optional: 'बाएं लेबल (वैकल्पिक)',
    right_label_optional: 'राइट लेबल (वैकल्पिक)',
    duplicate: "डुप्लिकेट",
    row: "पंक्ति",
    column: "स्तम्भ",
    rows: "पंक्तियां",
    add_column: 'स्तंभ जोड़ें',
    add_row: 'पंक्ति जोड़ें',
    user_info_for_file_upload: "फ़ाइल अपलोड के लिए उपयोगकर्ता जानकारी",
    user_info_for_file_upload_desc1: "चूँकि आपने फ़ाइल अपलोड प्रश्न जोड़ा है, उपयोगकर्ता जानकारी एकत्र करने का विकल्प सक्षम कर दिया गया है। इससे डाउनलोड की गई फ़ाइलों की पहचान करना आसान हो जाएगा। उत्तरदाताओं को अपनी जानकारी दर्ज करने के लिए एक संवाद दिखाई देगा।",
    user_info_for_file_upload_desc2: "यदि आप नहीं चाहते कि संवाद दिखाया जाए तो आप सेटिंग्स में इस विकल्प को बदल सकते हैं।",
    show_rows_and_columns: "पंक्तियाँ दिखाएँ & स्तंभ",
    scale_down: 'स्केल कम करें',
    scale_up: 'स्केल ऊपर करें',
    dislike: 'अरुचि',
    like: 'पसंद',
    best: 'सर्वोत्तम',
    remove_favourite: "पसंदीदा हटाएं",
    add_favourite: "पसंदीदा जोड़ें",
    collaborator: "सहयोगी",
    collaborators: "सहयोगियों",
    show_more: "और दिखाओ",
    create_quiz: "प्रश्नोत्तरी बनाएं",
    collaborate: "सहयोग",
    share_QR_code: "शेयर QR कोड",
    add_image: "छवि जोड़ें",
    invalid_file_type: "अमान्य फ़ाइल प्रकार",
    drag_drop: "खींचें और छोड़ें",
    here: "यहां",
    choose: "चुनना",
    add_account: 'खाता जोड़ें',
    choose_account: 'खाता चुनें',
    maximum_allowed_file_size: "अधिकतम अनुमत फ़ाइल आकार है ",
    or: "या",
    next: "अगला",
    change_image: "चित्र को बदलें",
    upload: "डालना",
    view: "राय",
    collaboration: "सहयोग",
    share_collaboration_form: "क्या आप इस फॉर्म को दूसरों के साथ साझा करना चाहते हैं?",
    share_collaboration_quiz: "क्या आप इस प्रश्नोत्तरी को दूसरों के साथ साझा करना चाहते हैं?",
    multiple_responses_summary: "एकाधिक प्रतिक्रियाएं, सारांश",
    question_number_shuffle: "प्रश्न संख्या, प्रश्न चिह्न, साधा",
    general: "सामान्य",
    browse: "ब्राउज़",
    owner: "स्वामी",
    something_went_wrong: "कुछ गलत हो गया!",
    forget_your_password: "अपना पासवर्ड भूल गए हैं?",
    sign_in: "साइन इन करें",
    sign_up: "साइन अप करें",
    using_email: "ईमेल का उपयोग करना",
    dont_have_account: "खाता नहीं है?",
    on_signing_up_agree_to_surveyhearts: "साइन अप करके, आप सहमत हैं SurveyHeart's",
    and: "तथा",
    terms_of_use: "उपयोग की शर्तें",
    already_have_an_account: "क्या आपके पास पहले से एक खाता मौजूद है?",
    signed_in_previously_as_a_google_user: "आपने पहले एक Google उपयोगकर्ता के रूप में साइन इन किया है.",
    sign_in_google_to_continue: "जारी रखने के लिए कृपया Google के माध्यम से साइन इन करें.",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "नोट: यह जानकारी दोबारा देखने के लिए, बाईं ओर मेनू में 'अधिक' पर क्लिक करें",
    no_account_found_email_address: "इस ईमेल पते के साथ कोई खाता नहीं मिला",
    account_already_exist_email: "इस ईमेल के लिए खाता पहले से मौजूद है.",
    install_surveyheart_app: "Install SurveyHeart App", //
    easy_login_via_app: "ऐप के माध्यम से आसान लॉगिन",
    get_notification_instantly: "तुरंत सूचना प्राप्त करें",
    create_form_quiz_on_the_go: "चलते-फिरते फ़ॉर्म/प्रश्नोत्तरी बनाएँ",
    check_your_inbox_to_activate_your_account: "अपना खाता सक्रिय करने के लिए कृपया अपना इनबॉक्स देखें",
    select_questions: "प्रश्नों का चयन करें",
    percentage: "प्रतिशत",
    free_App_to_create_forms: "फ़ॉर्म बनाने के लिए निःशुल्क ऐप प्राप्त करें",
    we_cant_find_the_page_that_you_are_looking_for: "हमें वह पेज नहीं मिल रहा है जिसकी आप तलाश कर रहे हैं",
    answered: "उत्तर",
    completing_this_quiz: "इस प्रश्नोत्तरी को पूरा करने के लिए धन्यवाद",
    connected_successfully: 'सफलतापूर्वक कनेक्ट किया गया',
    seconds: "सेकंड",
    enter_your_details: "अपना विवरण दर्ज करें",
    enter_all_required_information: "कृपया जरूरी सभी जानकारियां दर्ज करें",
    answer_data_not_found: "उत्तर डेटा नहीं मिला",
    results_published_soon: "परिणाम जल्द ही प्रकाशित किया जाएगा",
    previous: 'पिछला',
    save_link_for_future_reference: "भविष्य के संदर्भ के लिए इस लिंक को सेव करें",
    choice_questions_only: "केवल विकल्प प्रश्न",
    score_is_not_final: "नोट: यह स्कोर अंतिम नहीं है",
    tabular: "तालिकाबद्ध",
    text: "मूलपाठ",
    answer_response_alert: "इस सवाल को एक जवाब की आवश्यकता है",
    invalid_email_address: "अमान्य ईमेल पता",
    answer_not_available: "उत्तर उपलब्ध नहीं है",
    validated_automatically: "* स्वचालित रूप से मान्य",
    answer_link: "उत्तर लिंक",
    embed_code: 'एम्बेड कोड',
    copy_code: 'कोड कॉपी करें',
    code_copied: 'कोड कॉपी हो गया',
    share_via: "के माध्यम से बाँटे",
    print: "छाप",
    columns: 'कॉलम',
    columns_all: "कॉलम (सभी)",
    submitted_time: "जमा किया गया समय",
    unable_to_submit: 'सबमिट करने में असमर्थ',
    unable_to_submit_alert_line1: 'चूंकि इस प्रश्नोत्तरी के उत्तर प्राप्त हो गए हैं, इसलिए परिवर्तन करने के लिए आपकी पहुंच सीमित है।',
    unable_to_submit_alert_line2: 'इस प्रश्नोत्तरी को संपादित करने की पूर्ण पहुंच प्राप्त करने के लिए, आपको सभी मौजूदा उत्तरों को हटाना होगा।',
    action_blocked: 'कार्रवाई अवरुद्ध',
    action_blocked_alert: 'इस प्रश्नोत्तरी के मौजूदा उत्तरों के कारण कार्रवाई अवरुद्ध है। इसकी अनुमति देने से पिछले उत्तरों पर असर पड़ सकता है।',
    quiz_edit_submit_alert: 'क्विज़ को संपादित करने से अंक, प्रतिशत प्रभावित हो सकता है और कुछ प्रश्नों के पुनर्मूल्यांकन की आवश्यकता हो सकती है।',
    continue: 'जारी रखना',
    submitted: "प्रस्तुत",
    submitting: "भेजने से",
    filter: "फ़िल्टर",
    exit_screen: "स्क्रीन से बाहर निकलें",
    full_screen: "पूर्ण स्क्रीन",
    tabular_view: "सारणीबद्ध दृश्य",
    clear_all: "सभी साफ करें",
    you_are_all_caught_up: "आप सब फंस गए हैं!",
    check_back_later_for_new_notifications: "नई सूचनाओं के लिए बाद में देखें.",
    requires_answer: "उत्तर की आवश्यकता है",
    enter_member_id: "सदस्य आईडी दर्ज करें",
    viewers_can_read_form_responses: "दर्शक फॉर्म को पढ़ सकते हैं और प्रतिक्रियाएं देख सकते हैं",
    editors_edit_form_view_responses: "संपादक फ़ॉर्म को संपादित कर सकते हैं और प्रतिक्रियाएँ देख सकते हैं",
    admin_edit_form_add_collaborator: "व्यवस्थापक फ़ॉर्म को संपादित कर सकता है और सहयोगी जोड़ सकता है",
    invalid_member_id: "अमान्य सदस्य आईडी",
    already_member_added: "यह सदस्य पहले ही जोड़ा जा चुका है",
    add_collaborator: "सहयोगी जोड़ें",
    admin: "व्यवस्थापक",
    editor: "संपादक",
    viewer: "दर्शक",
    searched_form_not_found: "खोजा गया प्रपत्र नहीं मिला!",
    searched_quiz_not_found: "खोजी गई प्रश्नोत्तरी नहीं मिली!",
    no_shared_data_available: "कोई साझा डेटा उपलब्ध नहीं है!",
    forms_server_is_not_available: "प्रपत्र सर्वर उपलब्ध नहीं है",
    quiz_server_is_not_available: "प्रश्नोत्तरी सर्वर उपलब्ध नहीं है",
    leave: 'छुट्टी',
    password: 'पासवर्ड',
    new_password: 'नया पासवर्ड',
    retype_password: 'पासवर्ड फिर से लिखें',
    create_password: 'पासवर्ड बनाएं',
    view_results: 'परिणाम देखें',
    control: 'नियंत्रण',
    q_control: 'प्रश्नोत्तरी नियंत्रण', //
    q_result: 'प्रश्नोत्तरी परिणाम', //
    no_matches_found: "कोई मेल नहीं मिले",
    dropdown: "ड्रॉप डाउन",
    checkboxes: "चेक बॉक्स",
    star_rating: 'सितारा रेटिंग',
    linear_scale: 'रैखिक पैमाना',
    multiple_choice_grid: 'बहुविकल्पीय ग्रिड',
    checkboxes_grid: 'चेकबॉक्स ग्रिड',
    smile: 'मुस्कान',
    info_1: "जानकारी 1",
    info_2: "जानकारी 2",
    info_3: "जानकारी 3",
    new: 'नया',
    download: 'डाउनलोड',
    download_all: 'सभी डाउनलोड',
    sort_by: 'अनुसार क्रमबद्ध करें',
    sorting: 'छंटाई',
    refresh: 'ताज़ा करना',
    add: 'जोड़ें',
    refresh_preview_description: "पूर्वावलोकन को अद्यतन करने के लिए 'ताज़ा करें' बटन पर क्लिक करें",
    enter_users_email_address: 'उपयोगकर्ता का ईमेल पता दर्ज करें',
    do_you_want_to_delete_these: 'क्या आप इन्हें हटाना चाहते हैं',
    do_you_want_to_delete_this_form: 'क्या आप इस फॉर्म को हटाना चाहते हैं',
    all_responses_will_also_be_deleted: 'सभी प्रतिक्रियाएं भी हटा दी जाएंगी',
    this_action_cannot_be_undone: 'यह कार्य दोबारा से नहीं हो सकता',
    do_you_want_to_delete_this_quiz: 'क्या आप इस प्रश्नोत्तरी को हटाना चाहते हैं',
    all_answer_sheets_will_also_be_unavailable: 'सभी उत्तर पुस्तिकाएं भी उपलब्ध नहीं होंगी',
    change: 'परिवर्तन',
    roles_rights: 'भूमिकाएं और अधिकार',
    learn_more: 'और अधिक जानें',
    viewers_can_read_quiz_and_view_answers: 'दर्शक प्रश्नोत्तरी पढ़ सकते हैं और उत्तर देख सकते हैं',
    editors_can_edit_quiz_view_answers: 'संपादक प्रश्नोत्तरी संपादित कर सकते हैं और उत्तर देख सकते हैं',
    admin_can_edit_quiz_add_collaborator: 'व्यवस्थापक प्रश्नोत्तरी संपादित कर सकते हैं और सहयोगी जोड़ सकते हैं',
    change_roles: 'भूमिकाएं बदलें',
    remove: 'हटाना',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'आपने अपने परिवर्तन सबमिट नहीं किए हैं. क्या सबमिट किए बिना छोड़ना ठीक है?',
    select_language: 'भाषा चुने',
    are_you_sure_you_want_delete_this_collaborator: 'क्या आप वाकई इस सहयोगी को हटाना चाहते हैं?',
    are_you_sure_you_want_to_delete_selected_files: 'क्या आप वाकई चयनित फ़ाइल को स्थायी रूप से हटाना चाहते हैं?',
    please_download_before_deleting: 'कृपया हटाने से पहले डाउनलोड करें. एक बार हटा दी गई फ़ाइलें पुनर्प्राप्त नहीं की जा सकतीं!',
    role_has_been_changed_successfully: 'भूमिका सफलतापूर्वक बदल दी गई है',
    do_you_want_to_leave_collaboration: 'क्या आप सहयोग छोड़ना चाहते हैं?',
    form_builder_access_denied: 'फॉर्म बिल्डर एक्सेस अस्वीकृत',
    quiz_builder_access_denied: 'क्विज़ बिल्डर एक्सेस अस्वीकृत',
    you_only_have_viewer_rights_for_quiz: 'इस प्रश्नोत्तरी के लिए आपके पास केवल दर्शकों के अधिकार हैं',
    you_only_have_viewer_rights_for_form: 'इस फ़ॉर्म के लिए आपके पास केवल दर्शक अधिकार हैं',
    kindly_contact_the_form_owner_for_more_info: 'अधिक जानकारी के लिए कृपया फॉर्म स्वामी से संपर्क करें।',
    kindly_contact_the_quiz_owner_for_more_info: 'अधिक जानकारी के लिए कृपया प्रश्नोत्तरी स्वामी से संपर्क करें।',
    incomplete_questions: 'अधूरे प्रश्न :',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'आपके पास सहेजे नहीं गए परिवर्तन हैं - क्या आप वाकई इस पृष्ठ को छोड़ना चाहते हैं?',
    languages: 'भाषा',
    copy: "प्रतिलिपि",
    not_good: 'ठीक नहीं',
    not_allowed: 'अनुमति नहीं',
    not_allowed_description: 'प्रतिक्रियाएँ एकत्र करने के बाद विकल्प प्रश्न का प्रकार बदला नहीं जा सकता।',
    neutral: 'तटस्थ',
    good: 'अच्छा',
    very_good: 'बहुत अच्छा',
    edit_rights_removed: 'संपादित अधिकार हटा दिए गए',
    your_rights_to_edit_this_form_have_been_removed: "इस फ़ॉर्म को संपादित करने के आपके अधिकार हटा दिए गए हैं।",
    your_rights_to_edit_this_quiz_have_been_removed: "इस प्रश्नोत्तरी को संपादित करने के आपके अधिकार हटा दिए गए हैं।",
    please_contact_quiz_owner_for_more_info: "अधिक जानकारी के लिए कृपया फॉर्म स्वामी से संपर्क करें।",
    please_contact_form_owner_for_more_info: "अधिक जानकारी के लिए कृपया प्रश्नोत्तरी स्वामी से संपर्क करें।",
    last_edited_on: 'अंतिम बार संपादित',
    last_edited: 'पिछला संपादित',
    by: 'द्वारा',
    you: "आप",
    add_option: 'विकल्प जोड़ें',
    add_other_option: "'अन्य' विकल्प जोड़ें",
    estimated_time: "अनुमानित समय",
    do_you_want_to_show_estimated_time_taking_quiz: "क्या आप प्रश्नोत्तरी लेने के लिए अनुमानित समय दिखाना चाहते हैं ?",
    do_you_want_to_show_estimated_time_taking_form: "क्या आप फॉर्म लेने का अनुमानित समय दिखाना चाहते हैं ?",
    show_question_count: "प्रश्न संख्या दिखाएं",
    do_you_want_to_show_total_number_questions_quiz: "क्या आप प्रश्नोत्तरी में कुल प्रश्नों की संख्या दिखाना चाहते हैं ?",
    do_you_want_to_show_total_number_questions_form: "क्या आप फॉर्म में कुल प्रश्नों की संख्या दिखाना चाहते हैं ?",
    show_question_marks: "प्रश्न चिह्न दिखाएँ",
    show_question_marks_quiz: "प्रश्नोत्तरी में प्रश्न चिह्न दिखाएं",
    you_dont_have_add_collaborator_permission: "आपके पास सहयोगी जोड़ने की अनुमति नहीं है",
    modified_date: "संशोधित तिथि",
    created_date: "सृजित दिनांक",
    question_cant_be_added: "प्रश्न जोड़ा नहीं जा सकता",
    another_file_upload_question_cant_added: "एक अन्य फ़ाइल अपलोड प्रश्न जोड़ा नहीं जा सकता",
    you_can_have_only: "आपके पास केवल हो सकता है",
    file_upload_questions_in_form: "प्रपत्र में फ़ाइल अपलोड प्रश्न",
    file_upload_questions_in_quiz: "प्रश्नोत्तरी में फ़ाइल अपलोड प्रश्न",
    storage_capacity_exceeded: "भंडारण क्षमता पार हो गई",
    kindly_inform_form_builder_that: "कृपया फॉर्म बिल्डर को सूचित करें कि",
    kindly_inform_quiz_builder_that: "कृपया प्रश्नोत्तरी निर्माता को सूचित करें कि",
    their_storage_capacity_file_uploads: "फ़ाइल अपलोड के लिए उनकी भंडारण क्षमता",
    has_been_exceeded: "बढ़ा दिया गया है",
    view_details: "विवरण देखें",
    paste_your_video_link_here: "अपना वीडियो लिंक यहां पेस्ट करें",
    paste_your_website_link_here: "अपनी वेबसाइट का लिंक यहां पेस्ट करें",
    view_answer_sheet: "उत्तर पत्रक देखें",
    response_count: "प्रतिक्रिया गणना",
    count: "गणना",
    password_least_8_characters: "पासवर्ड कम से कम 8 - 50 वर्णों का होना चाहिए",
    password_contain_least_1_number: "पासवर्ड में कम से कम 1 नंबर होना चाहिए",
    password_contain_least_1_capital_letter: "पासवर्ड में कम से कम 1 बड़ा अक्षर होना चाहिए",
    password_contain_least_1_special_character: "पासवर्ड में कम से कम 1 विशेष वर्ण होना चाहिए",
    please_fill_all_required_fields: "कृपया सभी आवश्यक फ़ील्ड भरें",
    passwords_dont_match: "पासवर्ड मेल नहीं खाते",
    incorrect_password: "गलत पासवर्ड",
    click_or_change_current_theme: "एक अलग थीम चुनने के लिए वर्तमान थीम या 'बदलें' पर क्लिक करें",
    select: "चुनना",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "क्या आप इस छवि को हटाना चाहते हैं? यदि आप आगे बढ़ते हैं, तो यह छवि किसी भी फ़ॉर्म या क्विज़ के लिए उपलब्ध नहीं होगी जो इसका उपयोग कर रहे हैं। इस क्रिया को पूर्ववत नहीं किया जा सकता है।",
    url_does_not_seem_to_valid: "ऐसा लगता है कि यूआरएल मान्य नहीं है.",
    you_have_reached_image_uploaded_limit: "आप छवि अपलोड की सीमा तक पहुंच गए हैं।",
    undo: "पूर्ववत",
    response_deleted: "प्रतिक्रिया हटा दी गई",
    question_deleted: "प्रश्न हटा दिया गया",
    answer_deleted: "उत्तर हटा दिया गया",
    responses_deleted: "प्रतिक्रियाएँ हटा दी गईं",
    questions_deleted: "प्रश्न हटा दिए गए",
    answers_deleted: "उत्तर हटा दिए गए",
    collect_email_header: "ईमेल एकत्रित करें",
    collect_email_alert_message: "इस सुविधा को सक्षम करने से उत्तरदाता को फ़ॉर्म भरने के लिए अपने जीमेल पते से साइन इन करना होगा।",
    collect_email_alert_note: "नोट: केवल Google खाता समर्थित है",
    allow_response_edit: "प्रतिक्रिया संपादित करने की अनुमति दें",
    allow_response_edit_description: "क्या आप उत्तरदाताओं को सबमिट करने के बाद प्रतिक्रियाओं को संपादित करने की अनुमति देना चाहते हैं?",
    enter_desc_here: "यहां विवरण दर्ज करें",
    passcode_protection: "पासकोड सुरक्षा",
    passcode_protection_description_quiz: "क्या आप चाहते हैं कि आपकी प्रश्नोत्तरी पासकोड सुरक्षित रहे?",
    passcode_protection_description: "क्या आप चाहते हैं कि आपका फॉर्म पासकोड सुरक्षित रहे?",
    crop_image: "छवि सुधारें # क्रॉप इमेज फोटो एडिटर",
    crop: "काटना",
    original: "मूल",
    collect_email: "ईमेल पता एकत्रित करें",
    collect_email_description: "क्या आप संभावित भविष्य के संदर्भ या उपयोग के लिए उत्तरदाताओं के ईमेल पते एकत्र करना चाहते हैं?",
    collect_email_description_quiz: "इस सुविधा को सक्षम करने से उत्तरदाताओं को क्विज़ भरने के लिए अपने जीमेल पते से साइन इन करना होगा।",
    collect_email_description_form: "इस सुविधा को सक्षम करने से उत्तरदाताओं को फ़ॉर्म भरने के लिए अपने जीमेल पते से साइन इन करना होगा।",
    opening_date: 'खुलने की तिथि',
    closing_date: 'अंतिम तिथि',
    schedule: 'अनुसूची',
    schedule_form_status: 'अनुसूची प्रपत्र स्थिति',
    schedule_quiz_status: 'अनुसूची प्रश्नोत्तरी स्थिति',
    schedule_opening_date_and_time: "खुलने की तिथि और समय निर्धारित करें",
    schedule_closing_date_and_time: "समापन तिथि और समय निर्धारित करें",
    schedule_opening_date_and_time_description: "उल्लिखित तिथि और समय पर फॉर्म को सक्रिय करें",
    schedule_opening_date_and_time_description_quiz: "उल्लिखित तिथि और समय पर प्रश्नोत्तरी सक्रिय करें",
    schedule_closing_date_and_time_description: "उल्लिखित तिथि और समय पर फॉर्म को निष्क्रिय करें",
    schedule_closing_date_and_time_description_quiz: "उल्लिखित तिथि और समय पर प्रश्नोत्तरी को निष्क्रिय करें",
    upgrade: "अपग्रेड करें",
    gold_plan: "गोल्ड प्लान",
    view_plans: "प्लान देखें",
    premium_plans: "प्रीमियम प्लान",
    plan_duration: "/ महीना",
    plan_logic_jump: "लॉजिक जंप",
    plan_remove_ads: "विज्ञापन हटाएं",
    cancel_subscription: "सदस्यता रद्द करें",
    quiz_has_answer_edit_message: "यह क्विज़ पहले ही सबमिट किया जा चुका है और उत्तर एकत्र किए जा चुके हैं। क्विज़ को संपादित करने से पिछले उत्तर प्रभावित हो सकते हैं।",
    quiz_published_alert_edit_message: "यह क्विज़ प्रकाशित हो चुका है, यदि आप इसे संपादित करते हैं तो यह क्विज़ अप्रकाशित हो जाएगी।",
    quiz_submit_after_edit_message: "क्विज़ को संपादित करने से अंक, प्रतिशत प्रभावित हो सकते हैं और कुछ प्रश्नों का पुनर्मूल्यांकन करना पड़ सकता है।",
    title_activity_privacy_policy: "गोपनीयता नीति गतिविधि",
    action_blocked_message: "इस क्विज़ के लिए मौजूदा उत्तरों के कारण कार्रवाई अवरुद्ध है। इसे अनुमति देने से पिछले उत्तर प्रभावित हो सकते हैं।",
    email_addresses: "ईमेल पते",
    confirmation_message: "पुष्टि संदेश",
    confirmation_message_description: "क्या आप फ़ॉर्म सबमिट करने के बाद पुष्टि संदेश दिखाना चाहते हैं?",
    confirmation_message_description_quiz: "क्या आप क्विज़ का उत्तर देने के बाद पुष्टि संदेश दिखाना चाहते हैं?",
    default_custom_thank_message: "इस फ़ॉर्म को पूरा करने के लिए धन्यवाद।",
    default_custom_thank_message_quiz: "इस क्विज़ को पूरा करने के लिए धन्यवाद।",
    upgrade_your_plan: "अपनी योजना को अपग्रेड करें!",
    upgrade_your_plan_1: "अपना प्लान अपग्रेड करें",
    upgrade_your_plan_content: "कृपया इस सुविधा तक पहुँचने और स्टोरेज साइज़ बढ़ाने आदि के लिए प्रीमियम वर्शन को अपग्रेड करें",
    alert_icon: "अलर्ट आइकन",
    usage_details: "उपयोग विवरण",
    current_plan: "वर्तमान प्लान",
    total_submission: "कुल सबमिशन",
    image_attachments: "इमेज अटैचमेंट",
    custom_themes: "कस्टम थीम",
    free_limit_reached: "आप मुफ़्त सीमा तक पहुँच चुके हैं -",
    payment_done_but_server_error: "आपका भुगतान सफलतापूर्वक काट लिया गया है। कृपया कुछ समय बाद ऐप खोलें ताकि हम आपके भुगतान को सत्यापित कर सकें।",
    response_quota_reached: "आप मुफ़्त सीमा तक पहुँच चुके हैं, कृपया सभी प्रतिक्रियाओं तक पहुँचने के लिए अपना प्लान अपग्रेड करें।",
    answers_quota_reached: "आप मुफ़्त सीमा तक पहुँच चुके हैं, कृपया सभी उत्तरों तक पहुँचने के लिए अपना प्लान अपग्रेड करें।",
    logic_jump_enabled: "लॉजिक जंप सक्षम है।",
    logic_jump_disabled: "लॉजिक जंप अक्षम है।",
    cancel_subscription_message: "क्या आप वाकई अपनी सदस्यता रद्द करना चाहते हैं? सदस्यता रद्द करने से सभी प्रीमियम सुविधाओं तक पहुँच समाप्त हो जाएगी।",
    plan_expired: "आपकी योजना समाप्त हो गई है। कृपया इस ड्राफ़्ट का उपयोग करने के लिए अपना प्लान अपग्रेड करें।",
    plan_expired_edit_form: "आपकी योजना समाप्त हो गई है। कृपया इस फ़ॉर्म को संपादित करने के लिए अपनी योजना को अपग्रेड करें",
    plan_expired_edit_quiz: "आपकी योजना समाप्त हो गई है। कृपया इस क्विज़ को संपादित करने के लिए अपनी योजना को अपग्रेड करें",
    plan_expired_duplicate_form: "आपकी योजना समाप्त हो गई है। कृपया इस फ़ॉर्म को डुप्लिकेट करने के लिए अपनी योजना को अपग्रेड करें",
    plan_expired_duplicate_quiz: "आपकी योजना समाप्त हो गई है। कृपया इस क्विज़ को डुप्लिकेट करने के लिए अपनी योजना को अपग्रेड करें",
    premium_features: "प्रीमियम सुविधा",
    plan_file_upload: "असीमित कस्टम थीम छवियाँ",
    plan_custom_theme: "असीमित कस्टम थीम छवियाँ",
    plan_call_support: "उच्च प्राथमिकता ऑन-कॉल सहायता",
    plan_email_support: "उच्च प्राथमिकता ईमेल सहायता",
    plan_custom_domain: "कस्टम डोमेन",
    subscribe: "सदस्यता लें",
    plan_remove_branding: "ब्रांडिंग हटाएँ",
    plan_on_call_support: "ऑन कॉल सहायता",
    plan_image_attachment: "असीमित छवियाँ अनुलग्नक",
    plan_custom_thank_you: "अनुकूलन योग्य धन्यवाद पृष्ठ",
    plan_allowed_responses: "अनुमत प्रतिक्रिया संख्या 50000",
    trusted_by_number_of_user: "5 मिलियन से अधिक उपयोगकर्ताओं द्वारा विश्वसनीय",
    email_support: "ईमेल सहायता",
    call_support: "सहायता को कॉल करें",
    keyboard_shortcuts: "कीबोर्ड शॉर्टकट",
    premium_limit_reached: "आप प्लान की सीमा तक पहुँच चुके हैं। सभी प्रीमियम सुविधाओं तक पहुँचने के लिए कृपया अपना प्लान अपग्रेड करें।",
    premium_limit_reached_mobile: "आप प्लान की सीमा तक पहुँच चुके हैं।",
    unlimited: "असीमित",
    expired_on_next_renewal: "यह आपकी अगली नवीनीकरण तिथि पर समाप्त हो जाएगा",
    upgrade_plan: "अपग्रेड प्लान",
    downgrade_plan: "डाउनग्रेड प्लान",
    file_upload_questions: "फ़ाइल अपलोड प्रश्न",
    plan_response_edit: "प्रतिक्रिया संपादित करें",
    cant_process: "प्रक्रिया नहीं की जा सकती",
    you_have_subscribed_through_android: "आपने हमारे Android ऐप के ज़रिए सदस्यता ली है। कृपया भुगतान से संबंधित किसी भी अपडेट के लिए SurveyHeart ऐप का उपयोग करें।",
    pay_now: "अभी भुगतान करें",
    update_payment_details: "भुगतान विवरण अपडेट करें",
    limit_reached_alert_message: "आप निःशुल्क सीमा तक पहुंच गए हैं, कृपया सभी प्रतिक्रियाओं तक पहुंचने के लिए अपनी योजना को अपग्रेड करें।",
    limit_reached_alert_message_quiz: "आप निःशुल्क सीमा तक पहुंच गए हैं, कृपया सभी उत्तरों तक पहुंचने के लिए अपनी योजना को अपग्रेड करें।",
    limit_reached_UI_message: "आपकी वर्तमान योजना के लिए प्रतिक्रियाएँ उपलब्ध नहीं होंगी, कृपया सभी प्रतिक्रियाएँ देखने के लिए अपनी योजना को अपग्रेड करें।",
    limit_reached_UI_message_quiz: "आपकी वर्तमान योजना के लिए प्रतिक्रियाएँ उपलब्ध नहीं होंगी, कृपया सभी उत्तर देखने के लिए अपनी योजना को अपग्रेड करें।",
    owner_is_not_premium_user: "स्वामी कोई प्रीमियम उपयोगकर्ता नहीं है",
    cancel_subscription_description: "क्या आप वाकई अपनी सदस्यता रद्द करना चाहते हैं? रद्द करने पर सभी प्रीमियम सुविधाओं तक पहुंच समाप्त हो जाएगी।",
    starter_storage_description: "आसान डेटा प्रबंधन के लिए 5GB स्टोरेज प्राप्त करें।",
    bronze_storage_description: "आसान डेटा प्रबंधन के लिए 25GB स्टोरेज प्राप्त करें।",
    silver_storage_description: "आसान डेटा प्रबंधन के लिए 50GB स्टोरेज प्राप्त करें।",
    gold_storage_description: "आसान डेटा प्रबंधन के लिए 150GB स्टोरेज प्राप्त करें।",
    starter_responses_description: "शक्तिशाली सर्वेक्षण जानकारी प्राप्त करने के लिए 10,000 प्रतिक्रियाओं का उपयोग करें।",
    bronze_responses_description: "शक्तिशाली सर्वेक्षण जानकारी प्राप्त करने के लिए 35,000 प्रतिक्रियाओं का उपयोग करें।",
    silver_responses_description: "शक्तिशाली सर्वेक्षण जानकारी प्राप्त करने के लिए 75,000 प्रतिक्रियाओं का उपयोग करें।",
    gold_responses_description: "शक्तिशाली सर्वेक्षण जानकारी प्राप्त करने के लिए 1,50,000 प्रतिक्रियाओं का उपयोग करें।",
    starter_image_description: "विज़ुअल अपील के लिए 100 छवियों तक के साथ अपने सर्वेक्षणों को बेहतर बनाएँ।",
    bronze_image_description: "विज़ुअल अपील के लिए 200 छवियों तक के साथ अपने सर्वेक्षणों को बेहतर बनाएँ।",
    silver_image_description: "विज़ुअल अपील के लिए 500 छवियों तक के साथ अपने सर्वेक्षणों को बेहतर बनाएँ।",
    gold_image_description: "विज़ुअल अपील के लिए असीमित छवियों के साथ अपने सर्वेक्षणों को बेहतर बनाएँ अपील.",
    starter_theme_description: "अपने ब्रांड के लिए अपने सर्वेक्षणों को वैयक्तिकृत करने के लिए 100 कस्टम छवियाँ जोड़ें.",
    bronze_theme_description: "अपने ब्रांड के लिए अपने सर्वेक्षणों को वैयक्तिकृत करने के लिए 200 कस्टम छवियाँ जोड़ें.",
    silver_theme_description: "अपने ब्रांड के लिए अपने सर्वेक्षणों को वैयक्तिकृत करने के लिए 500 कस्टम छवियाँ जोड़ें.",
    gold_theme_description: "अपने ब्रांड के लिए अपने सर्वेक्षणों को वैयक्तिकृत करने के लिए असीमित कस्टम छवियाँ जोड़ें.",
    custom_theme_image: "कस्टम थीम छवि",
    starter_upload_description: "4 फ़ाइल अपलोड प्रश्नों को सहजता से एकीकृत करें.",
    file_upload_description: "असीमित फ़ाइल अपलोड प्रश्नों को सहजता से एकीकृत करें.",
    logic_jump_description: "प्रत्येक उत्तरदाता के अनुभव के लिए सर्वेक्षण प्रवाह को अनुकूलित करने के लिए लॉजिक जंप का उपयोग करें",
    thanks_you_message_description: "उत्तरदाताओं को प्रभावित करने के लिए धन्यवाद पृष्ठों को वैयक्तिकृत करें.",
    response_addon_description: "अधिक जानकारी प्राप्त करने के लिए अतिरिक्त प्रतिक्रियाएँ प्राप्त करें.",
    response_edit_description: "प्रतिक्रिया संपादन सुविधा का उपयोग करके आसानी से प्रतिक्रियाओं को परिष्कृत करें.",
    remove_branding_description: "सर्वेहार्ट ब्रांडिंग को हटाकर अपने ब्रांड की उपस्थिति बढ़ाएँ.",
    bronze_remove_ads_description: "हमारे कांस्य योजना के साथ विज्ञापन-मुक्त हो जाएँ और अपने सर्वेक्षणों पर ध्यान केंद्रित करें।",
    silver_remove_ads_description: "हमारे सिल्वर प्लान के साथ विज्ञापन-मुक्त रहें और अपने सर्वेक्षणों पर ध्यान केंद्रित करें।",
    gold_remove_ads_description: "हमारे गोल्ड प्लान के साथ विज्ञापन-मुक्त रहें और अपने सर्वेक्षणों पर ध्यान केंद्रित करें।",
    bronze_email_support_description: "समय पर सहायता सुनिश्चित करते हुए, कम प्राथमिकता के लिए ईमेल सहायता तक पहुँचें।",
    silver_email_support_description: "समय पर सहायता सुनिश्चित करते हुए, मध्यम प्राथमिकता के लिए ईमेल सहायता तक पहुँचें।",
    gold_email_support_description: "समय पर सहायता सुनिश्चित करते हुए, उच्च प्राथमिकता के लिए ईमेल सहायता तक पहुँचें।",
    bronze_email_support_title: "कम प्राथमिकता वाला ईमेल सहायता",
    silver_email_support_title: "मध्यम प्राथमिकता वाला ईमेल सहायता",
    gold_email_support_title: "उच्च प्राथमिकता वाला ईमेल सहायता",
    update_your_payment_details_mobile: "कृपया अपने भुगतान विवरण अपडेट करें।",
    update_your_payment_details_pc: "हमें कुछ समस्याओं का सामना करना पड़ रहा है इस खाते को नवीनीकृत करने के साथ। कृपया अपने भुगतान विवरण अपडेट करें।",
    card_details_updated_successfully: "कार्ड विवरण सफलतापूर्वक अपडेट किया गया",
    card_details_updated_successfully_description: "आपके कार्ड विवरण सफलतापूर्वक अपडेट किए गए हैं। अब आप अपनी अपडेट की गई भुगतान जानकारी का उपयोग जारी रख सकते हैं।",
    payment_successful: "भुगतान सफल",
    payment_successful_description: "आपका भुगतान सफल रहा। अब आप SurveyHeart की प्रीमियम योजना के लिए सब्सक्राइब हो गए हैं। आराम से रहें और अपने रास्ते में आने वाली सभी अद्भुत सुविधाओं का आनंद लें।",
    payment_failed: "भुगतान विफल!",
    payment_failed_description: "दुर्भाग्य से। हम SurveyHeart की प्रीमियम योजना के लिए आपकी सदस्यता को संसाधित नहीं कर सके। कृपया पुनः प्रयास करें।",
    note_for_five_rupees: "नोट: आपके कार्ड विवरण को अपडेट करने के लिए 5 रुपये का शुल्क है, जिसे 3 से 4 व्यावसायिक दिनों के भीतर उसी खाते में वापस कर दिया जाएगा।",
    logic: "तर्क",
    re_generate_code: "कोड फिर से जनरेट करें",
    plan_change_restrictions: "योजना परिवर्तन प्रतिबंध",
    plan_change_restriction_desc_1: "आप UPI का उपयोग करके अपनी योजना नहीं बदल सकते।",
    plan_change_restriction_desc_2: "अपग्रेड और डाउनग्रेड के लिए कार्ड पर स्विच करें, या अपनी इच्छित योजना को रद्द करें और फिर से सब्सक्राइब करें।",
    update_card_details: "कार्ड विवरण अपडेट करें",
    features_and_plans: "सुविधाएँ और योजनाएँ",
    cancel_plan: "योजना रद्द करें",
    cancel_plan_desc: "आपने योजना रद्द कर दी है। आपके पास अभी भी अपनी नवीनीकरण तिथि तक सशुल्क सुविधाओं तक पहुँच होगी।",
    explore_text: "आज ही हमारी प्रीमियम सदस्यता योजनाओं में से किसी एक में असाधारण सुविधाओं का पता लगाएँ और उन्हें अनलॉक करें",
    cant_edit_this_form: "इस फॉर्म को संपादित नहीं किया जा सकता",
    cant_edit_this_quiz: "इस क्विज़ को संपादित नहीं किया जा सकता",
    cant_duplicate_this_form: "इस फॉर्म को डुप्लिकेट नहीं किया जा सकता",
    cant_duplicate_this_quiz: "इस क्विज़ को डुप्लिकेट नहीं किया जा सकता",
    kindly_upgrade_the_premium: "कृपया इस सुविधा तक पहुँचने के लिए प्रीमियम संस्करण को अपग्रेड करें।",
    check_box: 'चेक बॉक्स',
    continue_to_next_question: "अगले प्रश्न पर जाएँ",
    add_logic: "तर्क जोड़ें",
    submit_form: "फ़ॉर्म सबमिट करें",
    save_logic: "तर्क सहेजें",
    remove_logic: "तर्क हटाएँ",
    responses_add_ons: "प्रतिक्रियाएँ ऐड-ऑन",
    responses_add_available: "प्रतिक्रियाएँ ऐड-ऑन उपलब्ध",
    additional_submissions: "अतिरिक्त सबमिशन",
    validity: "वैधता",
    up_to_your_current_plan: "आपकी वर्तमान योजना तक",
    responses_add_ons_description: "यह प्रतिक्रिया गणना आपकी मौजूदा योजना में जोड़ी जाएगी।",
    redirect_url_title: "इच्छित लिंक पर रीडायरेक्ट करें",
    redirect_url_description: "क्या आप अपने उपयोगकर्ताओं को किसी बाहरी वेबपेज पर रीडायरेक्ट करना चाहते हैं?",
    enter_weblink: "वेबलिंक दर्ज करें",
    get: "पाना",
    jump_to: "पर जाएँ",
    action_cannot_be_performed: "कार्रवाई नहीं की जा सकती",
    upgrade_plan_desc1: "क्या आप अपनी योजना को अपग्रेड करना चाहते हैं",
    downgrade_plan_desc1: "क्या आप अपनी योजना को डाउनग्रेड करना चाहते हैं",
    downgrade_plan_desc2: "यह परिवर्तन आपकी अगली नवीनीकरण तिथि पर प्रभावी होगा।",
    upgraded_plan: "अपग्रेड की गई योजना",
    downgraded_plan: "डाउनग्रेड की गई योजना",
    upgraded_plan_desc: "आपने अपनी योजना को अपग्रेड कर लिया है। यह परिवर्तन आपकी अगली नवीनीकरण तिथि पर प्रभावी होगा।",
    downgraded_plan_desc: "आपने अपनी योजना को डाउनग्रेड कर लिया है। यह परिवर्तन आपकी अगली नवीनीकरण तिथि पर प्रभावी होगा।",
    buy_responses: "खरीदें प्रतिक्रियाएँ",
    passcode: "पासकोड",
    share_link_with_passcode: "पासकोड के साथ लिंक साझा करें",
    contact_us: "हमसे संपर्क करें",
    premium_limit_reached_for_gold: "आप प्लान की सीमा तक पहुँच चुके हैं। सभी प्रीमियम सुविधाओं तक पहुँचने के लिए कृपया हमसे संपर्क करें।",
    follow_us: "हमें फ़ॉलो करें",
    billing_history: "बिलिंग इतिहास",
    your_current_plan: "आपकी वर्तमान योजना",
    next_billing_date: "अगली बिलिंग तिथि",
    transaction_id: "लेनदेन आईडी",
    plan: "प्लान",
    price: "कीमत",
    status: "स्थिति",
    follow_us_desc: "अपडेट रहें, हमसे जुड़े रहें और हमारे साथ जुड़ें",
    ranking_choice: 'रैंकिंग विकल्प',
    ranking: 'रैंकिंग',
    picture_choice: 'तस्वीर विकल्प',
    agreement: 'समझौता',
    signature: "हस्ताक्षर",
    slider: 'स्लाइडर',
    min: 'न्यूनतम',
    max: 'अधिकतम',
    step_value: "चरण मान",
    scale_range_error: "स्केल रेंज -10,000 और 10,000 के बीच का मान होना चाहिए।",
    scale_range_error2: "अधिकतम स्केल का मान स्वीकार्य मान से कम है।",
    max_min_error: "न्यूनतम स्केल का मान अधिकतम स्केल से कम होना चाहिए।",
    step_error: "चरण आकार के बीच का मान होना चाहिए",
    add_picture: 'तस्वीर जोड़ें',
    terms_and_conditions: "नियम और शर्तें",
    terms_and_conditions_required: "आवश्यक नियम और शर्तें",
    i_agree: "मैं सहमत हूँ",
    i_disagree: "मैं असहमत हूँ",
    yearly: "वार्षिक",
    monthly: "मासिक",
    save_upto_20: "वार्षिक योजनाओं के साथ 20% तक की बचत करें",
    paid: "भुगतान किया गया",
    cancelled: "रद्द किया गया",
}