import React, {useState} from "react"
import "../../home/css/top-nav-bar.css"
import "../css/pricing-body.css"
import tick from '../../../public/images/tick_green.svg'
import cross from '../../../public/images/cross_red.svg'
import {formatBytes, PREMIUM_PLAN_KEYS, PremiumJSON} from "../../shared/utils/helper";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";

const PricingBody = () => {
    const [yearly, setYearly] = useState(true)
    const [clickedPlan, setClickedPlan] = useState(0)

    const pricingBodyRow = (heading, description, freeValue, starterValue, bronzeValue, silverValue, goldValue, image, lastText) => {
        return (
            <>
                {isMobileOnly ? <div className={"pricing-body-row"}>
                    <div className={"info"}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                    {clickedPlan === 0 ? <>
                        {image ? <h3><img src={freeValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{freeValue === Infinity ? "Unlimited" : freeValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 0.5 ? <>
                        {image ? <h3><img src={starterValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{starterValue === Infinity ? "Unlimited" : starterValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 1 ? <>
                        {image ? <h3><img src={bronzeValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{bronzeValue === Infinity ? "Unlimited" : bronzeValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 2 ? <>
                        {image ? <h3><img src={silverValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{silverValue === Infinity ? "Unlimited" : silverValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 3 ? <>
                        {image ? <h3><img src={goldValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{goldValue === Infinity ? "Unlimited" : goldValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                </div> : <div className={"pricing-body-row"}>
                    <div className={"info"}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                    {image ? <h3><img src={freeValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{freeValue === Infinity ? "Unlimited" : freeValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={starterValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{starterValue === Infinity ? "Unlimited" : starterValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={bronzeValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{bronzeValue === Infinity ? "Unlimited" : bronzeValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={silverValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{silverValue === Infinity ? "Unlimited" : silverValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={goldValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{goldValue === Infinity ? "Unlimited" : goldValue} {lastText ? lastText : null}</h3>}
                </div>}
            </>
        )
    }

    return (
        <>
            <div className={"top-navigator-bar"} style={{justifyContent: "center"}}>
                <div className="title-banner" style={{margin: "0", width: "auto"}}>
                    <img id={"app-logo"} alt={"logo"} src="images/surveyheart1.png"/>
                </div>
            </div>
            <div className={"pricing-body"}>
                <div className={"body-heading"}>
                    <h1>Find Your Ideal Plan with Us</h1>
                    <span
                        style={{fontSize: "18px"}}>From basic beginnings to premium perks, our plans cover every need.</span>
                </div>
                <div className={"yearly-monthly"}>
                    <span style={yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(true)}>Yearly</span>
                    <span style={!yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(false)}>Monthly</span>
                </div>
                <p className={"save-upto"} style={{visibility: !yearly ? "hidden" : null}}>
                    Save upto 20% with Yearly plans
                </p>
                <div className={"pricing"}>
                    {isMobileOnly ? <div className={"pricing-header-row"}>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 0 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(0)}>
                                <h2 style={{color: clickedPlan === 0 ? "#004BA0" : null}}>Basic</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 0.5 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(0.5)}>
                                <h2 style={{color: clickedPlan === 0.5 ? "#004BA0" : null}}>Starter</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 1 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(1)}>
                                <h2 style={{color: clickedPlan === 1 ? "#004BA0" : null}}>Bronze</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 2 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(2)}>
                                <h2 style={{color: clickedPlan === 2 ? "#004BA0" : null}}>Silver</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 3 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(3)}>
                                <h2 style={{color: clickedPlan === 3 ? "#004BA0" : null}}>Gold</h2>
                            </div>
                        </div> :
                        <div className={"pricing-header-row"} style={yearly ? {width: "55%", marginRight: "8%"} : null}>
                            <h2 style={yearly ? {width: "150px", textAlign: "center"} : null}>Free</h2>
                            <div style={{textAlign: "center"}}>
                                <h2>Starter</h2>
                                {yearly ?
                                    <span>
                                        ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                        <span style={{fontWeight: "normal"}}>{"/month billed annually"}</span>
                                    </span> :
                                    <span>
                                        ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                        {"Month"}
                                    </span>
                                }
                            </div>
                            <div style={{textAlign: "center"}}>
                                <h2>Bronze</h2>
                                {yearly ?
                                    <span>
                                        ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                        <span style={{fontWeight: "normal"}}>{"/month billed annually"}</span>
                                    </span> :
                                    <span>
                                        ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                        {"Month"}
                                    </span>
                                }
                            </div>
                            <div style={{textAlign: "center"}}>
                                <h2>Silver</h2>
                                {yearly ?
                                    <span>
                                        ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                        <span style={{fontWeight: "normal"}}>{"/month billed annually"}</span>
                                    </span> :
                                    <span>
                                        ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                        {"Month"}
                                    </span>
                                }
                            </div>
                            <div style={{textAlign: "center"}}>
                                <h2>Gold</h2>
                                {yearly ?
                                    <span>
                                        ₹ {Math.floor(PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_YEARLY].price / 12).toLocaleString()} {isMobileOnly ? null : <br/>}
                                        <span style={{fontWeight: "normal"}}>{"/month billed annually"}</span>
                                    </span> :
                                    <span>
                                        ₹ {PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].price?.toLocaleString()} / {isMobileOnly ? null : <br/>}
                                        {"Month"}
                                    </span>
                                }
                            </div>
                        </div>}
                    {isMobileOnly && clickedPlan !== 0 ?
                        <p className={"amount-mobile"}>
                            ₹ {yearly ? Math.floor(PremiumJSON[clickedPlan === 0.5 ? (yearly ? PREMIUM_PLAN_KEYS.STARTER_YEARLY : PREMIUM_PLAN_KEYS.STARTER_MONTHLY) : clickedPlan === 1 ? (yearly ? PREMIUM_PLAN_KEYS.BRONZE_YEARLY : PREMIUM_PLAN_KEYS.BRONZE_MONTHLY) : clickedPlan === 2 ? (yearly ? PREMIUM_PLAN_KEYS.SILVER_YEARLY : PREMIUM_PLAN_KEYS.SILVER_MONTHLY) : clickedPlan === 3 ? (yearly ? PREMIUM_PLAN_KEYS.GOLD_YEARLY : PREMIUM_PLAN_KEYS.GOLD_MONTHLY) : PREMIUM_PLAN_KEYS.FREE_USER].price / 12)?.toLocaleString() : PremiumJSON[clickedPlan === 0.5 ? (yearly ? PREMIUM_PLAN_KEYS.STARTER_YEARLY : PREMIUM_PLAN_KEYS.STARTER_MONTHLY) : clickedPlan === 1 ? (yearly ? PREMIUM_PLAN_KEYS.BRONZE_YEARLY : PREMIUM_PLAN_KEYS.BRONZE_MONTHLY) : clickedPlan === 2 ? (yearly ? PREMIUM_PLAN_KEYS.SILVER_YEARLY : PREMIUM_PLAN_KEYS.SILVER_MONTHLY) : clickedPlan === 3 ? (yearly ? PREMIUM_PLAN_KEYS.GOLD_YEARLY : PREMIUM_PLAN_KEYS.GOLD_MONTHLY) : PREMIUM_PLAN_KEYS.FREE_USER].price?.toLocaleString()} {yearly ? <br/> : null} {yearly ? <span style={{color: "#000000", fontWeight: "normal", fontSize: "18px"}}> / month billed annually</span> : " / month"}
                        </p> : <>{isMobileOnly ? <p className={"amount-mobile"}>Free</p> : null}</>
                    }
                    {pricingBodyRow("Image Attachments", "Enhance your surveys with up to more images for visual appeal.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].question_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].question_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].question_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].question_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].question_attachments)}
                    {pricingBodyRow("Custom Theme Image", "Add custom images to personalize your surveys for your brand.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].theme_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].theme_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].theme_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].theme_attachments, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].theme_attachments)}
                    {pricingBodyRow("File Upload Question", "Seamlessly integrate unlimited file upload questions.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].file_upload_questions_per_form, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].file_upload_questions_per_form, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].file_upload_questions_per_form, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].file_upload_questions_per_form, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].file_upload_questions_per_form)}
                    {pricingBodyRow("Logic Jump", "Use logic jumps to customize survey flow for each respondent's experience", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].logic_jump, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].logic_jump, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].logic_jump, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].logic_jump, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].logic_jump, true)}
                    {pricingBodyRow("Responses Count", "Get more responses to gain powerful survey insights.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].response_limit.access, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].response_limit.access, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].response_limit.access, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].response_limit.access, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].response_limit.access, false, "/Lifetime")}
                    {pricingBodyRow("Response Add-ons", "Get additional responses to gather more insights.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].responses_add_ons, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].responses_add_ons, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].responses_add_ons, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].responses_add_ons, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].responses_add_ons, true)}
                    {pricingBodyRow("Response Edit", "Easily refine responses using the Response Edit feature.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].response_edit, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].response_edit, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].response_edit, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].response_edit, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].response_edit, true)}
                    {pricingBodyRow("Storage", "Get more storage for effortless data management.", formatBytes(PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].storage, 0, false, "GB"), formatBytes(PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].storage, 0, false, "GB"), formatBytes(PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].storage, 0, false, "GB"), formatBytes(PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].storage, 0, false, "GB"), formatBytes(PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].storage, 0, false, "GB"))}
                    {pricingBodyRow("Customizable Thank You Pages", "Personalize Thank You pages to impress respondents.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].customizable_thank_you, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].customizable_thank_you, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].customizable_thank_you, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].customizable_thank_you, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].customizable_thank_you, true)}
                    {pricingBodyRow("Remove SurveyHeart Branding", "Elevate your brand presence by removing SurveyHeart branding.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].remove_branding, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].remove_branding, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].remove_branding, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].remove_branding, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].remove_branding, true)}
                    {pricingBodyRow("Remove Ads", "Go ad-free with our Bronze Plan and focus on your surveys", !PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].advertising, !PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].advertising, !PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].advertising, !PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].advertising, !PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].advertising, true)}
                    {pricingBodyRow("On-call Support", "Access email support for your inquiries, ensuring timely assistance.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].on_call_support, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].on_call_support, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].on_call_support, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].on_call_support, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].on_call_support, true)}
                    {pricingBodyRow("Email Support", "Access high, medium and low priority email support for your inquiries, ensuring timely assistance.", PremiumJSON[PREMIUM_PLAN_KEYS.FREE_USER].email_support_rank, PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].email_support_rank, PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY].email_support_rank, PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY].email_support_rank, PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY].email_support_rank, true)}
                    {isMobileOnly ? <div style={{textAlign: "center"}}>
                        {clickedPlan ? <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#004BA0',
                            fontSize: '20px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '300px',
                                height: '50px'
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", JSON.stringify({rank: clickedPlan, yearly: yearly}))
                            window.open("/app")
                        }}/> : null}
                    </div> : <>
                        <div className={"pricing-buttons-row"}>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", JSON.stringify({rank: 0.5, yearly: yearly}))
                                    window.open("/app")
                                }}/>
                            </div>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", JSON.stringify({rank: 1, yearly: yearly}))
                                    window.open("/app")
                                }}/>
                            </div>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", JSON.stringify({rank: 2, yearly: yearly}))
                                    window.open("/app")
                                }}/>
                            </div>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", JSON.stringify({rank: 3, yearly: yearly}))
                                    window.open("/app")
                                }}/>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default PricingBody
