import React, {useEffect, useState} from "react"
import "../css/billing-history.css"
import Styles from "../../../admin/css/table-container.module.css"
import {getBillingHistory} from "../../../shared/utils/sash-v2-api";
import {getPlanBasedOnRank, PremiumJSON} from "../../../shared/utils/helper";
import {getDate} from "../utils/get-date";
import {getTimeByFormat} from "../../../summary/utils/helper";
import {useSelector} from "react-redux";
import PremiumIcon from "../../../../public/images/premium_crown.svg";
import PaginationContainer from "../../../shared/components/PaginationContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const BillingHistoryContainer = () => {
    const {t} = useTranslation()
    const [selectedPage, setSelectedPage] = useState(0)
    const [billingHistory, setBillingHistory] = useState(null)
    const [totalBillingHistoryCount, setTotalBillingHistoryCount] = useState(20)
    const headerTitlesArray = [t(translationKeys.transaction_id), t(translationKeys.plan), t(translationKeys.date_text), t(translationKeys.time_text), t(translationKeys.price), t(translationKeys.status)]
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)

    const tableHeaderStyle = {
        border: "none",
        padding: "15px",
        fontSize: "16px",
        cursor: "pointer",
        backgroundColor: "#F5F5F5"
    }

    useEffect(() => {
        if (!billingHistory) {
            fetchBillingHistoryByPage(0)
        }
    }, [])

    const fetchBillingHistoryByPage = (pageIndex) => {
        const successFunction = (res) => {
            if (res.result) {
                setTotalBillingHistoryCount(res.total_billing_history_count)
                setBillingHistory(res.billing_history)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        getBillingHistory(pageIndex, 20, successFunction, failureFunction)
    }

    const handlePageChange = (page) => {
        setSelectedPage(page.selected)
        fetchBillingHistoryByPage(page.selected)
    }

    return (
        <div className={"billing-history-container"}>
            <h2>{t(translationKeys.billing_history)}</h2>
            <p>
                {t(translationKeys.your_current_plan)}: <img alt={"crown"} src={PremiumIcon}/>
                {" " + getPlanBasedOnRank(PremiumJSON[subscriptionData.subscription_plan].rank)}
            </p>
            <p>{t(translationKeys.next_billing_date)}: {getDate(subscriptionData.expires_on)}</p>
            <div>
                <div className={Styles.table_holder} style={{height: "70vh", overflowY: "auto"}}>
                    <table>
                        <thead>
                        <tr>
                            {headerTitlesArray.map((headerData) => {
                                return <th style={tableHeaderStyle}>
                                    {headerData}
                                </th>
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {billingHistory?.map((bill) => {
                            return (
                                <tr style={{borderBottom: "1px solid #BDBDBD"}}>
                                    <td>{bill.transaction_id}</td>
                                    <td>{getPlanBasedOnRank(PremiumJSON[bill.plan].rank)}</td>
                                    <td>{getDate(bill.date_time)}</td>
                                    <td>{getTimeByFormat(new Date(parseInt(bill.date_time)))}</td>
                                    <td>{bill.price} INR</td>
                                    <td style={{
                                        color: bill.status === "SUCCESS" ? "#46A24A" : "#F52424",
                                        fontWeight: "bold"
                                    }}>{bill.status === "SUCCESS" ? t(translationKeys.paid) : t(translationKeys.cancelled)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <div style={{width: "-webkit-fill-available", position: "fixed", bottom: "0"}}>
                        <PaginationContainer handlePageChange={handlePageChange}
                                             forcePage={selectedPage}
                                             totalDataCount={totalBillingHistoryCount}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingHistoryContainer
