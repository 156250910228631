import React, {useEffect, useState} from "react"
import "../css/pay-now-popup.css"
import SurveyHeartIcon from "../../../../public/images/surveyheart_icon_small.svg"
import close from "../../../../public/images/close_black.svg";
import {formatBytes, getPlanBasedOnRank, PREMIUM_PLAN_KEYS, PremiumJSON} from "../../../shared/utils/helper";
import blueTick from "../../../../public/images/tick_blue.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";
import {useSelector} from "react-redux";
import {verifyPayment} from "../../../shared/utils/sash-v2-api";
import PaymentSuccessPopUp from "./PaymentSuccessPopUp";
import usePremiumMgr from "../../hooks/usePremiumMgr";
import redAlert from "../../../../public/images/red-alert.svg";
import infoIcon from "../../../../public/images/info.svg";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const PayNowPopUp = (props) => {

    const {t} = useTranslation()
    const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState()
    const [showPaymentFailedPopup, setShowPaymentFailedPopup] = useState()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const storage = formatBytes(props.premiumPlanData.storage, 0, true, "GB")
    const {displayRazorpay} = usePremiumMgr()

    const handleCloseClick = () => {
        props.setShowPayNow(false)
    }

    const featureRow = (title, description) => {
        return (
            <div className={"feature-row"}
                 style={{margin: props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? "30px 0" : null}}>
                <img src={blueTick} alt={"tick"}/>
                <div className={"row-text"}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        )
    }

    const verifyRazorpayPayment = (res) => {
        props.setShowLoading(true)
        setShowPaymentFailedPopup(false)
        const successFunction = (res) => {
            if (res?.result) {
                props.setShowLoading(false)
                setShowPaymentFailedPopup(false)
                setShowPaymentSuccessPopup(true)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (res?.razorpay_payment_id && res?.razorpay_signature && res?.razorpay_subscription_id) {
            verifyPayment(res.razorpay_subscription_id, res.razorpay_payment_id, res.razorpay_signature, successFunction, failureFunction)
        }
    }

    const paymentFailFunction = () => {
        props.setShowLoading(false)
        setShowPaymentFailedPopup(true)
    }

    const getButtonText = () => {
        if (PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank) {
            return t(translationKeys.update_payment_details).toUpperCase()
        } else if (subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER) {
            if (props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank) {
                return t(translationKeys.upgrade_plan).toUpperCase()
            } else {
                return t(translationKeys.downgrade_plan).toUpperCase()
            }
        } else {
            return t(translationKeys.pay_now).toUpperCase()
        }
    }

    const handleButtonClick = () => {
        if (PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank) {
            displayRazorpay(props.subscriptionId, subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER, verifyRazorpayPayment, paymentFailFunction)
        } else if (subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER) {
            props.setShowUpgradePopUp(true)
        } else {
            displayRazorpay(props.subscriptionId, false, verifyRazorpayPayment, paymentFailFunction)
        }
    }

    const getTextBasedOnPlan = (item) => {
        if (item === "storage") {
            if (props.premiumPlanData.rank === 0.5) {
                return t(translationKeys.starter_storage_description)
            } else if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_storage_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_storage_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_storage_description)
            }
        } else if (item === "responses") {
            if (props.premiumPlanData.rank === 0.5) {
                return t(translationKeys.starter_responses_description)
            } else if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_responses_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_responses_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_responses_description)
            }
        } else if (item === "images") {
            if (props.premiumPlanData.rank === 0.5) {
                return t(translationKeys.starter_image_description)
            } else if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_image_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_image_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_image_description)
            }
        } else if (item === "themes") {
            if (props.premiumPlanData.rank === 0.5) {
                return t(translationKeys.starter_theme_description)
            } else if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_theme_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_theme_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_theme_description)
            }
        } else if (item === "remove-ads") {
            if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_remove_ads_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_remove_ads_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_remove_ads_description)
            }
        } else if (item === "email-support-title") {
            if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_email_support_title)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_email_support_title)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_email_support_title)
            }
        } else if (item === "email-support-description") {
            if (props.premiumPlanData.rank === 1) {
                return t(translationKeys.bronze_email_support_description)
            } else if (props.premiumPlanData.rank === 2) {
                return t(translationKeys.silver_email_support_description)
            } else if (props.premiumPlanData.rank === 3) {
                return t(translationKeys.gold_email_support_description)
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("site-plan-rank")) {
            localStorage.removeItem("site-plan-rank")
        }
    }, [])

    return (
        <div id={"pay-now"} className={"premium-plans-popup"}>
            <div className={"pay-now"}>
                <div className={"premium-pop-up-header"}
                     style={{margin: isMobileOnly ? "10px 5% 0 5%" : "30px 5% 0 5%", justifyContent: "end"}}>
                    <img className={'localization-close-img'} style={{margin: "0 0 auto 0"}}
                         src={close} alt={close} onClick={handleCloseClick}/>
                </div>
                <div style={{overflow: isMobileOnly ? "scroll" : null, height: isMobileOnly ? "95%" : null}}>
                    <div className={"pay-now-header"}>
                        <div className={"surveyheart-icon"}>
                            <img src={SurveyHeartIcon} alt={"SurveyHeart"}/>
                            <h2>SurveyHeart</h2>
                        </div>
                        <h2>{getPlanBasedOnRank(props.premiumPlanData.rank)} Features</h2>
                        <span>{props.premiumPlanData.price ? "₹ " + props.premiumPlanData.price.toLocaleString() + `${props.yearly ? "/year" : "/month"}` : "Free"}</span>
                    </div>
                    <div className={"pay-now-body"}>
                        <div style={isMobileOnly ? null : {borderRight: "1px solid #8E8E8E", padding: "10px"}}>
                            {featureRow(`${t(translationKeys.storage)} ${storage}`, getTextBasedOnPlan("storage"))}
                            {props.premiumPlanData.rank <= PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? null : featureRow(t(translationKeys.responses_add_ons), t(translationKeys.response_addon_description))}
                            {featureRow(`${props.premiumPlanData.theme_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.theme_attachments} ${t(translationKeys.custom_theme_image)}`, getTextBasedOnPlan("themes"))}
                            {featureRow((props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].file_upload_questions_per_form : t(translationKeys.unlimited)) + " " + t(translationKeys.file_upload_questions), props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? t(translationKeys.starter_upload_description) : t(translationKeys.file_upload_description))}
                            {featureRow(t(translationKeys.plan_custom_thank_you), t(translationKeys.thank_you_message_description))}
                            {props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? null : featureRow(getTextBasedOnPlan("email-support-title"), getTextBasedOnPlan("email-support-description"))}
                        </div>
                        <div>
                            {featureRow(`${t(translationKeys.response_count)} ${props.premiumPlanData.response_limit.access}`, getTextBasedOnPlan("responses"))}
                            {featureRow(`${props.premiumPlanData.question_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.question_attachments} ${t(translationKeys.image_attachments)}`, getTextBasedOnPlan("images"))}
                            {featureRow(t(translationKeys.plan_logic_jump), t(translationKeys.logic_jump_description))}
                            {featureRow(t(translationKeys.plan_response_edit), t(translationKeys.response_edit_description))}
                            {props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? null : featureRow(t(translationKeys.plan_remove_ads), getTextBasedOnPlan("remove-ads"))}
                            {props.premiumPlanData.rank === PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY].rank ? null : featureRow(t(translationKeys.plan_remove_branding), t(translationKeys.remove_branding_description))}
                        </div>
                    </div>
                    {subscriptionData.subscription_plan === "premium-on-hold" ?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={redAlert} alt={"Alert"} style={{margin: "auto 10px", height: "25px"}}/>
                            <p style={{fontSize: isMobileOnly ? "4.5vw" : "18px"}}>
                                {isMobileOnly ? t(translationKeys.update_your_payment_details_mobile) : t(translationKeys.update_your_payment_details_pc)}
                            </p>
                        </div> : null}
                    {PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank ?
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: isMobileOnly ? "20px 0 50px" : null
                        }}>
                            <img src={infoIcon} alt={'infoIcon'} style={{margin: "0 10px", height: "25px"}}/>
                            <p style={{
                                fontSize: isMobileOnly ? "4.5vw" : "18px",
                                color: "#8E8E8E",
                                textAlign: "center",
                                margin: "0"
                            }}>
                                {t(translationKeys.note_for_five_rupees)}
                            </p>
                        </div> : null}
                    {subscriptionData.subscription_plan === "premium-on-hold" ? null :
                        <div className={"pay-button"} style={{textAlign: "center"}}>
                            <MaterialButton
                                data={{
                                    title: getButtonText(),
                                    customButtonContainerStyle: {
                                        borderRadius: '50px',
                                        backgroundColor: '#004BA0',
                                        width: "300px",
                                        height: "50px"
                                    },
                                    customButtonTextStyle: {
                                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                                        fontSize: '20px'
                                    }
                                }}
                                handleClick={handleButtonClick}
                            />
                        </div>}
                </div>
            </div>
            {showPaymentSuccessPopup ? <PaymentSuccessPopUp premiumPlanData={props.premiumPlanData}
                                                            success={true}
                                                            title={subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER ? t(translationKeys.card_details_updated_successfully) : t(translationKeys.payment_successful)}
                                                            description={subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER ? t(translationKeys.card_details_updated_successfully_description) : t(translationKeys.payment_successful_description)}
                                                            handlePopUpClick={() => {
                                                                setShowPaymentSuccessPopup(false)
                                                                window.location.reload()
                                                            }}/> : null}
            {showPaymentFailedPopup ? <PaymentSuccessPopUp premiumPlanData={props.premiumPlanData}
                                                           success={false}
                                                           title={t(translationKeys.payment_failed)}
                                                           description={t(translationKeys.payment_failed_description)}
                                                           handlePopUpClick={() => {
                                                               setShowPaymentFailedPopup(false)
                                                           }}/> : null}
        </div>
    )
}

export default PayNowPopUp
