export const translationsTelugu = {
    all: 'అన్ని',
    ago: "క్రితం",
    now: "ఇప్పుడు",
    start: "ప్రారంభించండి",
    account_blocked: "ఖాతా నిలిపివేయబడినది",
    account_blocked_alert: "మా నిబంధనలు మరియు షరతులను ఉల్లంఘించినందుకు ఈ ఖాతా నిలిపివేయబడినది",
    sign_out: "సైన్ అవుట్ చేయండి",
    short_answer: "సంక్షిప్త సమాధానం",
    long_answer: "దీర్ఘ సమాధానం",
    email: "ఇ-మెయిల్",
    number: "సంఖ్య",
    file: "ఫైల్",
    files: "ఫైళ్లు",
    faq_videos: "తరచుగా అడిగే ప్రశ్నలు వీడియోలు",
    file_upload: "ఫైల్ ఎక్కించుట",
    question_error: "ప్రశ్న జోడించబడదు",
    storage: "నిల్వ",
    form: "ఫారం",
    quiz: "క్విజ్",
    fup_desc: "మీరు ఇప్పుడు మీ ఫారమ్‌లు మరియు క్విజ్‌లకు ప్రతివాదులను ఫైల్‌లను అప్‌లోడ్ చేయడానికి అనుమతించవచ్చు!",
    new_fup_feature_title: "కొత్త ఫైల్ అప్‌లోడ్ ఫీచర్!",
    multiple_choice: "బహుళ ఎంపిక ప్రశ్నలు",
    date_text: "తేదీ",
    time_text: "సమయం",
    uploading: "అప్",
    add_question: "ప్రశ్నను జోడించండి",
    add_question_alert: "అవసరమైన అన్ని అంశాలతో కనీసం ఒక ప్రశ్నను దయచేసి జోడించండి.",
    add_question_alert_quiz: "క్విజ్ సమర్పించే ముందు కనీసం ఒక ప్రశ్ననైనా దయచేసి జోడించండి",
    ok: "అలాగే",
    title: "శీర్షిక",
    title_required: "శీర్షిక అవసరం",
    form_name_alert: "ఫారమ్‌ను ప్రచురించే ముందు దయచేసి పేరు పెట్టండి",
    quiz_name_alert: "క్విజ్‌ను ప్రచురించే ముందు దయచేసి పేరు పెట్టండి",
    no_connection: "కనెక్షన్ లేదు",
    check_connection: "దయచేసి మీ నెట్",
    finish_incomplete_question: "అసంపూర్తిగా ఉన్న ప్రశ్నలను ఫారంలో నింపండి",
    draft: "డ్రాఫ్ట్",
    save_to_draft_alert: "మీ మార్పులను కోల్పోకుండా ఉండటానికి మీరు ఈ ఫారమ్",
    save_to_draft_quiz_alert: "మీ మార్పులను కోల్పోకుండా ఉండటానికి మీరు ఈ క్విజ్‌ను చిత్తుప్రతిగా సేవ్ చేయాలనుకుంటున్నారా?",
    yes: "అవును",
    no: "లేదు",
    cancel: "రద్దు చేయండి",
    live_preview: "ప్రత్యక్ష పరిదృశ్యం",
    themes: "థీమ్స్",
    tap_to_set_theme: "ఫారం కోసం థీమ్",
    select_image: "ఇమేజ్ ను ఎంచుకోండి",
    created: "సృష్టించబడింది",
    form_created_successfully: "ఫారం విజయవంతంగా సృష్టించబడింది",
    warning: "హెచ్చరిక",
    form_edit_alert: "ఈ ఫారం ఇప్పటికే ప్రచురించబడింది మరియు ప్రతిస్పందనలు సేకరించబడి ఉండవచ్చు. సవరిస్తున్న ఫారం ఇప్పటికే సేకరించిన ప్రతిస్పందనలను ప్రభావితం చేస్తుంది.",
    quiz_edit_alert: 'ఈ క్విజ్ ఇప్పటికే సమర్పించబడింది మరియు సమాధానాలు సేకరించబడ్డాయి. క్విజ్‌ని సవరించడం వలన మునుపటి సమాధానాలు ప్రభావితం కావచ్చు',
    edit: "సవరించండి",
    download_as: "ఇలా డౌన్‌లోడ్ చేయండి",
    delete_form: "రూపం తొలగించు",
    delete_form_alert: "మీరు ఈ ఫారమ్",
    delete_quizzes_alert: "మీరు ఈ క్విజ్‌లను తొలగించాలనుకుంటున్నారా? ఈ చర్య రద్దు చేయబడదు.",
    delete_forms_alert: "మీరు ఈ ఫారమ్",
    delete_quiz_alert: "మీరు ఈ క్విజ్‌ను తొలగించాలనుకుంటున్నారా? ఈ చర్య రద్దు చేయబడదు.",
    delete_answers_alert: "మీరు ఈ సమాధానాలను తొలగించాలనుకుంటున్నారా? ఈ చర్య రద్దు చేయబడదు.",
    delete_answer_alert: "మీరు ఈ సమాధానాన్ని తొలగించాలనుకుంటున్నారా? ఈ చర్య రద్దు చేయబడదు.",
    delete: "తొలగించు",
    delete_all: "అన్నిటిని తొలిగించు",
    section: "విభాగం",
    worst: "పనికిరాని",
    server_error_alert: "సర్వర్ కనుగొనబడలేదు. దయచేసి కొంత సమయం తర్వాత మళ్ళీ ప్రయత్నించండి !!",
    signed_in_as: "ఇలా సైన్ ఇన్ చేయబడింది",
    signing_out: "సైన్ అవుట్ అవుతోంది…",
    create_form: "రూపాన్ని సృష్టించండి",
    create_blank_form: "ఖాళీ ఫారమ్‌ని సృష్టించు",
    create_blank_quiz: "ఖాళీ క్విజ్ సృష్టించు",
    close: "మూసివేయు",
    response_collection_exit_alert: "ప్రతిస్పందన సేకరించడం నుండి మీరు నిష్క్రమించాలనుకుంటున్నారా?",
    form_builder: "ఫారమ్ బిల్డర్",
    form_builder_promo: "మీ సర్వేల కోసం తక్షణ పరిదృశ్యం, టెంప్లేట్లు మరియు థీమ్",
    results: "ఫలితాలు",
    result_feature_promo: "మీ సర్వే ఫలితాలను విశ్లేషించండి మరియు ఎగుమతి చేయండి",
    notification: "నోటిఫికేషన్",
    delete_option_alert: "మీరు ఈ ఎంపికను తొలగించాలనుకుంటున్నారా? [గమనిక: ఈ చర్య రద్దు చేయబడదు]",
    question_type: "ప్రశ్నరకం",
    change_question_type_alert: "ప్రశ్న రకాన్ని మార్చడానికి ఇక్కడ నొక్కండి",
    preview_not_available: "పరిదృశ్యం అందుబాటులో లేదు",
    delete_question: "ప్రశ్నను తొలగించండి",
    delete_response_collected_question_alert: "ఈ ప్రశ్నను తొలగించడం ద్వారా సేకరించిన ప్రతిస్పందనలు తప్పనిసరిగా ప్రభావితం చేస్తుంది. \ n \ n మీరు ఈ ప్రశ్నను తొలగించాలనుకుంటున్నారా? మీకు ఖచ్చితంగా తెలుసా?",
    delete_question_alert: "మీరు ఈ ప్రశ్నను తొలగించాలనుకుంటున్నారా? మీకు ఖచ్చితంగా తెలుసా?",
    options: "ఎంపికలు",
    option: 'ఎంపిక',
    no_answer: "జవాబు లేదు",
    no_response: "స్పందన లేదు",
    responses: "ప్రతిస్పందనలు",
    response: "ప్రతిస్పందన",
    serial_number: "ఎస్ నెం.",
    submit_time: "సమయం సమర్పించండి",
    general_form_type_description: "క్రొత్త ఫారం / సర్వేను సృష్టించండి",
    quiz_type_description: "క్రొత్త క్విజ్ సృష్టించండి",
    sheet_disconnected: 'Google షీట్ డిస్‌కనెక్ట్ చేయబడింది',
    sheet_not_connected: 'షీట్ కనెక్ట్ కాలేదు',
    sheet_not_connected_desc: 'దయచేసి Google షీట్‌ని కనెక్ట్ చేయడానికి యజమానిని సంప్రదించండి.',
    export_success: "విజయవంతంగా ఎగుమతి చేయబడింది",
    answer: "సమాధానం",
    answer_with_colan: "సమాధానం :",
    others: "ఇతరులు",
    delete_response: "ప్రతిస్పందనను తొలగించండి",
    deleted: "తొలగించబడింది",
    my_themes: "నా థీమ్స్",
    limit_reached: "పరిమితి చేరుకుంది",
    limit_reached_description: "మీరు చిత్రం అప్‌లోడ్ చేసిన పరిమితిని చేరుకున్నారు.",
    modified: "సవరించబడింది",
    delete_image: "ఇమేజ్ ని తొలగించండి",
    delete_image_alert: "మీకు ఖచ్చితంగా తెలుసా, మీరు ఈ ఇమేజ్ ని తొలగించాలనుకుంటున్నారా?",
    delete_theme_image_desc: "మీరు ఈ చిత్రాన్ని తొలగించాలనుకుంటున్నారా? మీరు కొనసాగితే, ఈ చిత్రాన్ని ఉపయోగిస్తున్న ఏ ఫారమ్‌లు లేదా క్విజ్‌లకు ఈ చిత్రం అందుబాటులో ఉండదు. ఈ చర్య రద్దు చేయబడదు.",
    section_title: "శీర్షిక విభాగం",
    section_description: "విభాగం వివరణ",
    show_scale: 'స్కేల్ చూపించు',
    show_options: "ఎంపికలను చూపించు",
    options_required: "ఎంపికలు అవసరం",
    link_copied: "లింక్ కాపీ చేయబడింది",
    app_not_found: "ఆప్ కనుగొనబడలేదు",
    default_text: "డిఫాల్ట్",
    dark_mode: "డార్క్ మోడ్",
    done: "పూర్తి",
    upload_image: "ఇమేజ్ అప్",
    question: "ప్రశ్న",
    required: "అవసరం",
    share: "పంచుకోండి",
    form_link: "ఫారం లింక్",
    copy_link: "లింకును కాపీ చేయండి",
    view_form: "రూపం వీక్షణ",
    menu: "మెను",
    view_all: "అన్నీచూడండి",
    settings: "సెట్టింగులు",
    submit: "సమర్పించండి",
    change_theme: "థీమ్ మార్చండి",
    tap_here_to_choose_theme: "థీమ్ ఎంచుకోవడానికి ఇక్కడ నొక్కండి",
    show_logo: "లోగో చూపించు",
    show_logo_alert: "మీరు లోగోను ఫారంలో చూపించాలనుకుంటున్నారా?",
    welcome_page: "స్వాగతం పేజీ",
    show_welcome_page_alert: "మీరు స్వాగతం పేజీని చూపించాలనుకుంటున్నారా?",
    start_button: "బటనును ప్రారంభించండి",
    allow_multiple_responses: "బహుళ ప్రతిస్పందనలను అనుమతించండి",
    allow_more_response_alert: "మీరు ఒకసారి కంటే ఎక్కువసార్లు ప్రతిస్పందించడానికి వినియోగదారుని అనుమతించాలనుకుంటున్నారా?",
    allow_more_quiz_attempt_alert: "మీరు ఒకసారి కంటే ఎక్కువసార్లు ప్రయత్నించడానికి వినియోగదారుని అనుమతించాలనుకుంటున్నారా?",
    show_summary: "సారాంశాన్ని చూపించు",
    show_results: "క్విజ్ ఫలితాలను చూపించు",
    allow_responders_view_summary_alert: "సమర్పించిన తర్వాత అన్ని ప్రతిస్పందనల సారాంశాన్ని చూడటానికి ప్రతిస్పందనదారులను అనుమతించాలనుకుంటున్నారా?",
    allow_result_view_quiz_alert: "ఫలితాలను వీక్షించడానికి మీరు వినియోగదారుని అనుమతించాలనుకుంటున్నారా?",
    show_question_number: "ప్రశ్న సంఖ్యను చూపించు",
    show_question_number_in_form: "మీరు ఫారమ్‌లో ప్రశ్న సంఖ్యలను చూపాలనుకుంటున్నారా?",
    show_question_number_in_quiz: "మీరు క్విజ్‌లో ప్రశ్న సంఖ్యలను చూపాలనుకుంటున్నారా?",
    shuffle_questions: "ప్రశ్నలను షఫుల్ చేయండి",
    shuffle_questions_alert: "మీరు ఫారమ్ ప్రశ్నలను షఫుల్ చేయాలనుకుంటున్నారా?",
    shuffle_quiz_questions_alert: "మీరు క్విజ్ ప్రశ్నలను షఫుల్ చేయాలనుకుంటున్నారా?",
    description: "వివరణ",
    builder: "బిల్డర్",
    preview: "పరిదృశ్యం",
    form_status: "ఫారం స్థితి",
    active: "యాక్టివ్",
    inactive: "ఇన్ ఆక్టివ్",
    import_questions: 'ప్రశ్నలను దిగుమతి చేయండి',
    search_by_question_title_from_forms: 'ఇప్పటికే ఉన్న ఫారమ్‌ల నుండి ప్రశ్న శీర్షిక ద్వారా శోధించండి',
    search_by_question_title_from_quizzes: 'ఇప్పటికే ఉన్న క్విజ్‌ల నుండి ప్రశ్న శీర్షిక ద్వారా శోధించండి',
    view_responses: "ప్రతిస్పందనలను చూడండి",
    edit_form: "రూపం సవరణ",
    duplicate_form: "నకిలీ ఫారం",
    drafts: "డ్రాఫ్టులు",
    no_form_available: "ఫారం అందుబాటులో లేదు!",
    notifications: "నోటిఫికేషన్",
    feedback: "అభిప్రాయం",
    privacy_policy: "గోప్యతా విధానం",
    open: 'తెరవండి',
    open_individual_responses: "వ్యక్తిగత ప్రతిస్పందనలను తెరవండి",
    bar_chart: "బార్ చార్ట్",
    pie_chart: "పై చార్ట్",
    summary: "సారాంశం",
    invalidated_form_cannot_be_selected: "చెల్లని ఫారమ్ ఎంచుకోబడదు.",
    invalidated_quiz_cannot_be_selected: "చెల్లని క్విజ్‌ని ఎంచుకోలేరు.",
    individual: "వ్యక్తిగత",
    search: "వెతకండి…",
    image: "ఇమేజ్",
    video: "వీడియో",
    website: "వెబ్సైట్",
    exit: "బయటకి దారి",
    response_limit_reached: "ప్రతిస్పందన పరిమితి చేరుకుంది",
    response_limit_reached_alert: "ఈ ఫారం ప్రతిస్పందన డేటా కోసం అనుమతించబడిన పరిమితిని మించిపోయింది; అందువల్ల, ఈ ఫారమ్",
    unapproved_form: "ఆమోదించని ఫారం",
    unapproved_form_alert: "ఈ ఫారం మా విధానాలను ఉల్లంఘించే పదాలను ఉపయోగిస్తుంది.\n \n ఈ ఫారం పొరపాటున నిరోధించడమైనదని మీరు భావిస్తే ,దయచేసి క్రింద ఇచ్చిన ఫారంని తెరిచి ,అభ్యర్థించిన విషయాలను పొందుపరుచగలరు.",
    choose_your_language: "మీ భాషను ఎంచుకోండి",
    provide_explanation: "వివరణ ఇవ్వండి",
    selected: "ఎంచుకోబడింది",
    review_button: "సమీక్ష",
    not_specified: "పేర్కొనలేదు",
    quiz_control: "క్విజ్ నియంత్రణ",
    allow_multiple_attempts: "బహుళ ప్రయత్నాలను అనుమతించండి",
    user_info: "వినియోగదారుని సమాచారం",
    name_s: "పేరు",
    duration: "వ్యవధి",
    set_duration_quiz_alert: "మీరు క్విజ్ కోసం వ్యవధిని సెట్ చేయాలనుకుంటున్నారా?",
    user_information_description: "మీరు పాల్గొనే సమాచారాన్ని పొందాలనుకుంటున్నారా?",
    set_duration: "వ్యవధిని సెట్ చేయండి (గంటలు: నిమిషాలు)",
    minutes: "నిమిషాలు",
    hours: "గంటలు",
    minute: "నిమిషం",
    hour: "గంట",
    correct: "సరైన",
    wrong: "తప్పు",
    skipped: "దాటవేయబడింది",
    score: "స్కోరు",
    forms: "రూపాలు",
    quizzes: "క్విజ్",
    more: "మరింత",
    quiz_status: "క్విజ్ స్థితి",
    view_answers: "సమాధానాలను చూడండి",
    view_quiz: "క్విజ్ చూడండి",
    duplicate_quiz: "నకిలీ క్విజ్",
    no_quiz_available: "క్విజ్ అందుబాటులో లేదు!",
    quiz_link: "క్విజ్ లింక్",
    unapproved_quiz: "ఆమోదించని క్విజ్",
    unapproved_quiz_alert: "ఈ క్విజ్ మా విధానాలను ఉల్లంఘించే పదాలను ఉపయోగిస్తుంది .\n \n ఈ క్విజ్ పొరపాటున నిరోధించడమైనదని మీరు భావిస్తే ,దయచేసి క్రింద ఇచ్చిన ఫారంని తెరిచి ,అభ్యర్థించిన విషయాలను పొందుపరుచగలరు.",
    do_you_want_to_show_all_correct_answers_to_users: "మీరు వినియోగదారులకు సరైన సమాధానం చూపించాలనుకుంటున్నారా?",
    results_publish_date: "ఫలితాలు ప్రచురించే తేదీ",
    disconnect: 'డిస్‌కనెక్ట్ చేయండి',
    do_you_want_to_set_date_to_publish_results: "మీరు ఫలితాలను ప్రచురించడానికి తేదీని సెట్ చేయాలనుకుంటున్నారా",
    quiz_result: "క్విజ్ ఫలితం",
    enter_answer: "సమాధానం",
    manage: "నిర్వహించు",
    manage_storage: 'నిల్వను నిర్వహించండి',
    select_all: 'అన్ని ఎంచుకోండి',
    shuffle_options: 'షఫుల్ ఎంపికలు',
    shuffle_options_enabled: 'షఫుల్ ఎంపికలు ప్రారంభించబడ్డాయి',
    shuffle_options_disabled: 'షఫుల్ ఎంపికలు నిలిపివేయబడ్డాయి',
    add_options_to_enable_this_feature: 'ఈ లక్షణాన్ని ఎనేబుల్ చేయడానికి ఎంపికలను జోడించండి',
    attach: 'అటాచ్ చేయండి',
    shuffle: 'షఫుల్ చేయండి',
    marks: "మార్కులు",
    mark: "మార్కు",
    correction_status: "స్టేటస్",
    status_validated: "ధృవీకరించబడింది",
    pending: "పెండింగ్‌లో ఉంది",
    results_publish: "ప్రచురించండి",
    publish: "ప్రచురించండి",
    publish_description: "మీరు క్విజ్ ఫలితాలను ప్రచురించాలనుకుంటున్నారా?",
    publish_description_note: "(మార్కులు అదే URL లో ప్రచురించబడతాయి)",
    published_successfully: "విజయవంతంగా ప్రచురించబడింది",
    choice_type_validation_note: "*బహుళ ఎంపిక ఆటోమేటిక్ గా ధృవీకరించబడింది",
    short_answer_type_validation_note: "*చిన్న సమాధానం ఆటోమేటిక్ గా ధృవీకరించబడుతుంది",
    long_answer_type_validation_note: "*దీర్ఘ సమాధానం ఆటోమేటిక్ గా ధృవీకరించబడలేదు",
    fileupload_answer_type_validation_note: "*ఫైల్ అప్‌లోడ్ స్వయంచాలకంగా ధృవీకరించబడదు",
    dropdown_answer_type_validation_note: "*డ్రాప్‌డౌన్ ఎంపిక స్వయంచాలకంగా ధృవీకరించబడుతుంది",
    save: "సేవ్ చేయండి",
    questions: "ప్రశ్నలు",
    show_correct_answer: "సరైన సమాధానం చూపించు",
    show_answer: "సమాధానం చూపించు",
    hide_answer: "సమాధానం దాచు",
    no_marks: "మార్కులు లేవు",
    question_is_required: "ప్రశ్న శీర్షిక అవసరం",
    marks_is_required: "మార్కులు అవసరం",
    options_are_required: "ఎంపికలు అవసరం",
    options_limit_reached: 'ఎంపికల పరిమితిని చేరుకున్నారు',
    options_limit_reached_description: 'మీరు ప్రశ్నపై అనుమతించబడిన ఎంపికల సంఖ్య పరిమితిని చేరుకున్నారు.',
    correct_answer_required: "సరైన సమాధానం అవసరం",
    enter_valid_mark: "చెల్లుబాటు అయ్యే గుర్తును నమోదు చేయండి",
    evaluated: "మూల్యాంకనం",
    saved: "సేవ్ చేయబడింది",
    pending_evaluation: "మూల్యాంకనం పెండింగ్‌లో ఉంది",
    alphabet: "వర్ణమాల",
    not_evaluated: "మూల్యాంకనం చేయబడలేదు",
    percentage_ascending: "శాతం - ఆరోహణ",
    percentage_descending: "శాతం - అవరోహణ",
    pending_evaluation_alert_message: "ప్రచురించే ముందు మీరు అన్ని సమాధానాల మూల్యాంకనం పూర్తి చేయాలి.",
    show_unevaluated: "తక్కువ అంచనా చూపించు",
    publish_success_alert_message: "ప్రతివాదులు వారి ఫలితాలను వారి సంబంధిత జవాబు పత్రాలలో చూడవచ్చు.",
    publishing: "ప్రచురణ",
    quiz_published: "క్విజ్ ప్రచురించబడింది",
    quiz_published_alert_before_edit_message: "ఈ క్విజ్ ప్రచురించబడింది, మీరు మార్పులు చేయవలసి వస్తే, మీరు క్విజ్ నకిలీ చేసి మళ్ళీ భాగస్వామ్యం చేయాలి.",
    quiz_has_answers: "క్విజ్‌లో సమాధానాలు ఉన్నాయి",
    quiz_has_answer_alert_message: "ఈ క్విజ్‌ను సవరించడానికి, మీరు ఇప్పటికే ఉన్న అన్ని సమాధానాలను తొలగించాలి.",
    unavailable_features_description: 'ఈ ఫారమ్ వెబ్ యాప్‌లో ఇంకా సపోర్ట్ చేయని ఫీచర్‌లను కలిగి ఉంది',
    unavailable_quiz_features_description: 'ఈ క్విజ్ వెబ్ యాప్‌లో ఇంకా సపోర్ట్ చేయని ఫీచర్‌లను కలిగి ఉంది',
    unavailable: 'అందుబాటులో లేదు',
    unpublished: "ప్రచురించబడలేదు",
    published: "ప్రచురించబడింది",
    setting_updated: "సెట్టింగ్ నవీకరించబడింది",
    account: "ఖాతా",
    on: "పై",
    off: "ఆఫ్",
    scan_qr_code: "QR కోడ్‌ను స్కాన్ చేయండి",
    answers: "సమాధానాలు",
    answer_s: "సమాధానం",
    question_limit_reached: "ప్రశ్న పరిమితి చేరుకుంది",
    reached_limit_for_number_of_questions: "ఫారమ్‌లో అనుమతించబడిన ప్రశ్నల సంఖ్యకు మీరు పరిమితిని చేరుకున్నారు. అందువల్ల, కొత్త ప్రశ్నలు జోడించబడవు",
    life_time: "జీవితకాలం",
    last_year: "గత సంవత్సరం",
    last_30_days: "గత 30 రోజులు",
    last_7_days: "గత 7 రోజులు",
    last_24_hours: "చివరి 24 గంటలు",
    custom_date: "అనుకూల తేదీ",
    filter_by_date: "తేదీ వారీగా ఫిల్టర్ చేయండి",
    filter_by_question: "ప్రశ్న ద్వారా ఫిల్టర్ చేయండి",
    filter_text: "వచనాన్ని ఫిల్టర్ చేయండి",
    add_filter: "ఫిల్టర్‌ని జోడించండి",
    apply: "వర్తించు",
    from_date: "తేదీ నుండి",
    today: 'ఈ రోజు',
    to_date: "తేదీకి",
    contains: "కలిగి ఉంది",
    equal_to: "సమానంగా",
    not_equal_to: "సమానము కాదు",
    from_date_required: "అవసరమైన తేదీ నుండి",
    to_date_required: "తేదీ అవసరం",
    webapp_promo_text: "మీ ఫారమ్‌లు, సర్వేలు, పోల్స్ & క్విజ్‌లను ఆన్‌లైన్‌లో యాక్సెస్ చేయండి!",
    question_title: "ప్రశ్న",
    invalid_date: "చెల్లని తేదీ",
    invalid_url: "చెల్లని URL",
    invalid_url_description: "URL చెల్లుబాటు అయ్యేలా లేదు.",
    delete_response_alert: "మీరు ఈ ప్రతిస్పందనను తొలగించాలనుకుంటున్నారా? ఈ చర్య రద్దు చేయబడదు.",
    delete_responses_alert: "మీరు ఈ ప్రతిస్పందనలను తొలగించాలనుకుంటున్నారా? ఈ చర్య చర్యరద్దు చేయబడదు.",
    overview: "అవలోకనం",
    no_results_found: "ఎటువంటి ఫలితాలు లభించలేదు",
    date_format: "dd-mm-yyyy",
    title_dashboard: "డాష్బోర్డ్",
    title_notifications: "నోటిఫికేషన్‌లు",
    select_one_response: "దయచేసి తొలగించడానికి కనీసం ఒక ప్రతిస్పందనను ఎంచుకోండి",
//Untranslated strings
    your_account: "మీ ఖాతా",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    feature_spotlight: 'ఫీచర్ స్పాట్‌లైట్',
    favourites: "ఇష్టమైనవి",
    shared: "పంచుకున్నారు",
    hide: "దాచు",
    use_templates: "టెంప్లేట్‌లను ఉపయోగించండి",
    quick_settings: "త్వరిత సెట్టింగ్లు",
    leave_collaboration: "సహకారాన్ని వదిలివేయండి",
    templates: "టెంప్లేట్లు",
    collapse: "కుదించు",
    start_scale_from_zero: '0 నుండి స్కేల్‌ని ప్రారంభించండి',
    center_label_optional: 'మధ్య లేబుల్ (ఆప్షనల్)',
    left_label_optional: 'ఎడమ లేబుల్ (ఆప్షనల్)',
    right_label_optional: 'కుడి లేబుల్ (ఆప్షనల్)',
    duplicate: "నకిలీ",
    row: "అడ్డు వరుస",
    column: "నిలువు వరుస",
    rows: "అడ్డ వరుసలు",
    add_column: 'నిలువు వరుస జోడించండి',
    add_row: 'అడ్డు వరుసను జోడించండి',
    user_info_for_file_upload: "ఫైల్ అప్‌లోడ్‌ల కోసం వినియోగదారు సమాచారం",
    user_info_for_file_upload_desc1: "మీరు ఫైల్ అప్‌లోడ్ ప్రశ్నను జోడించినందున, వినియోగదారు సమాచారాన్ని సేకరించే ఎంపిక ప్రారంభించబడింది. ఇది డౌన్‌లోడ్ చేసిన ఫైల్‌లను గుర్తించడం సులభం చేస్తుంది. ప్రతివాదులు తమ సమాచారాన్ని నమోదు చేయడానికి డైలాగ్‌ని చూస్తారు.",
    user_info_for_file_upload_desc2: "డైలాగ్ చూపబడకూడదనుకుంటే మీరు సెట్టింగ్‌లలో ఈ ఎంపికను మార్చవచ్చు.",
    show_rows_and_columns: "అడ్డు వరుసనులు & నిలువు వరుసలు చూపించు",
    scale_down: 'స్కేల్ క్రిందికి',
    scale_up: 'స్కేల్ పైకి',
    dislike: 'నచ్చలేదు',
    like: 'నచ్చింది',
    best: 'ఉత్తమమైనది',
    remove_favourite: "ఇష్టమైనదాన్ని తీసివేయండి",
    add_favourite: "ఇష్టమైనవి జోడించండి",
    collaborator: "సహకారి",
    collaborators: "సహకారులు",
    show_more: "ఇంకా చూపించు",
    create_quiz: "క్విజ్ సృష్టించండి",
    collaborate: "సహకరించండి",
    share_QR_code: "QR కోడ్‌ని షేర్ చేయండి",
    add_image: "చిత్రాన్ని జోడించండి",
    invalid_file_type: "చెల్లని ఫైల్ రకం",
    drag_drop: "డ్రాగ్ & డ్రాప్",
    here: "ఇక్కడ",
    choose: "ఎంచుకోండి",
    add_account: 'ఖాతా జోడించండి',
    choose_account: 'ఖాతాను ఎంచుకోండి',
    maximum_allowed_file_size: "అనుమతించబడిన గరిష్ట ఫైల్ పరిమాణం",
    or: "లేదా",
    next: "తరువాత",
    change_image: "చిత్రాన్ని మార్చండి",
    upload: "అప్‌లోడ్ చేయండి",
    view: "చూడండి",
    collaboration: "సహకారం",
    share_collaboration_form: "మీరు ఈ ఫారమ్‌ను ఇతరులతో భాగస్వామ్యం చేయాలనుకుంటున్నారా?",
    share_collaboration_quiz: "మీరు ఈ క్విజ్‌ని ఇతరులతో పంచుకోవాలనుకుంటున్నారా?",
    multiple_responses_summary: "బహుళ ప్రతిస్పందనలు, సారాంశం",
    question_number_shuffle: "ప్రశ్న సంఖ్య, ప్రశ్నార్థకం, షఫుల్",
    general: "జనరల్",
    browse: "బ్రౌజ్ చేయండి",
    owner: "యజమాని",
    something_went_wrong: "ఏదో తప్పు జరిగింది!",
    forget_your_password: "మీ పాస్వర్డ్ మర్చిపోయారా?",
    sign_in: "సైన్ ఇన్ చేయండి",
    sign_up: "సైన్ అప్",
    using_email: "ఇమెయిల్ ఉపయోగించి",
    dont_have_account: "ఖాతా లేదు?",
    on_signing_up_agree_to_surveyhearts: "సైన్ అప్ చేయడం ద్వారా, మీరు SurveyHeart's కి అంగీకరిస్తున్నారు",
    and: "మరియు",
    terms_of_use: "ఉపయోగించవలసిన విధానం",
    already_have_an_account: "ఖాతా కలిగి ఉన్నారా?",
    signed_in_previously_as_a_google_user: "మీరు మునుపు Google వినియోగదారుగా సైన్ ఇన్ చేసారు.",
    sign_in_google_to_continue: "కొనసాగించడానికి దయచేసి Google ద్వారా సైన్ ఇన్ చేయండి.",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "గమనిక: ఈ సమాచారాన్ని మళ్లీ చూడటానికి, ఎడమవైపు మెనులో 'మరిన్ని' క్లిక్ చేయండి",
    no_account_found_email_address: "ఈ ఇమెయిల్ చిరునామాతో ఖాతా ఏదీ కనుగొనబడలేదు",
    account_already_exist_email: "ఈ ఇమెయిల్ కోసం ఖాతా ఇప్పటికే ఉంది.",
    install_surveyheart_app: "SurveyHeart యాప్‌ను ఇన్‌స్టాల్ చేయండి",
    easy_login_via_app: "యాప్ ద్వారా సులభంగా లాగిన్ అవ్వండి",
    get_notification_instantly: "తక్షణమే నోటిఫికేషన్ పొందండి",
    create_form_quiz_on_the_go: " ఫారమ్/క్విజ్‌ని సృష్టించండి",
    check_your_inbox_to_activate_your_account: "దయచేసి మీ ఖాతాను సక్రియం చేయడానికి మీ ఇన్‌బాక్స్‌ని తనిఖీ చేయండి",
    select_questions: "ప్రశ్నలను ఎంచుకోండి",
    percentage: "శాతం",
    free_App_to_create_forms: "ఫారమ్‌లను సృష్టించడానికి ఉచిత యాప్‌ని పొందండి",
    we_cant_find_the_page_that_you_are_looking_for: "మీరు వెతుకుతున్న పేజీని మేము కనుగొనలేకపోయాము", //
    answered: "సమాధానం ఇచ్చారు",
    completing_this_quiz: "ఈ క్విజ్‌ని పూర్తి చేసినందుకు ధన్యవాదాలు",
    connected_successfully: 'విజయవంతంగా కనెక్ట్ చేయబడింది',
    seconds: "సెకన్లు",
    enter_your_details: "మీ వివరాలను నమోదు చేయండి",
    enter_all_required_information: "దయచేసి అవసరమైన మొత్తం సమాచారాన్ని నమోదు చేయండి",
    answer_data_not_found: "జవాబు డేటా కనుగొనబడలేదు",
    results_published_soon: "ఫలితాలు త్వరలో ప్రచురించబడతాయి",
    previous: 'మునుపటి',
    save_link_for_future_reference: "భవిష్యత్ సూచన కోసం ఈ లింక్‌ను సేవ్ చేయండి",
    choice_questions_only: "ఎంపిక ప్రశ్నలు మాత్రమే",
    score_is_not_final: "గమనిక: ఈ స్కోర్ ఫైనల్ కాదు",
    tabular: "పట్టిక",
    text: "వచనం",
    answer_response_alert: "ఈ ప్రశ్నకు సమాధానం కావాలి",
    invalid_email_address: "చెల్లని ఇమెయిల్ చిరునామా",
    answer_not_available: "సమాధానం అందుబాటులో లేదు",
    validated_automatically: "* స్వయంచాలకంగా ధృవీకరించబడింది",
    answer_link: "జవాబు లింక్",
    embed_code: 'పొందుపరిచిన కోడ్',
    copy_code: 'కాపీ కోడ్',
    code_copied: 'కోడ్ కాపీ చేయబడింది',
    share_via: "షేర్ చేయండి",
    print: "ముద్రణ",
    columns: 'నిలువు వరుసలు',
    columns_all: "నిలువు వరుసలు (అన్నీ)",
    submitted_time: "సమర్పించిన సమయం",
    unable_to_submit: 'సమర్పించడం సాధ్యం కాలేదు',
    unable_to_submit_alert_line1: 'ఈ క్విజ్‌కి సమాధానాలు లభించినందున, మార్పులు చేయడానికి మీకు పరిమిత ప్రాప్యత ఉంది.',
    unable_to_submit_alert_line2: 'ఈ క్విజ్‌ని సవరించడానికి పూర్తి ప్రాప్యతను పొందడానికి, మీరు ఇప్పటికే ఉన్న అన్ని సమాధానాలను తప్పనిసరిగా తొలగించాలి.',
    action_blocked: 'చర్య నిరోధించబడింది',
    action_blocked_alert: 'ఈ క్విజ్‌కి ఇప్పటికే ఉన్న సమాధానాల కారణంగా చర్య బ్లాక్ చేయబడింది. దీన్ని అనుమతించడం మునుపటి సమాధానాలపై ప్రభావం చూపవచ్చు.',
    quiz_edit_submit_alert: 'క్విజ్‌ని సవరించడం వలన మార్కులు, శాతాలు ప్రభావితం కావచ్చు మరియు కొన్ని ప్రశ్నలను తిరిగి మూల్యాంకనం చేయాల్సి ఉంటుంది.',
    continue: 'కొనసాగించు',
    submitted: "సమర్పించారు",
    submitting: "సమర్పిస్తోంది",
    filter: "ఫిల్టర్ చేయండి",
    exit_screen: "స్క్రీన్ నుండి నిష్క్రమించండి",
    full_screen: "పూర్తి స్క్రీన్",
    tabular_view: "పట్టిక వీక్షణ",
    clear_all: "అన్నీ క్లియర్ చేయండి",
    you_are_all_caught_up: "మీరు అన్ని నోటిఫికేషన్‌లను చూశారు!",
    check_back_later_for_new_notifications: "కొత్త నోటిఫికేషన్‌ల కోసం తర్వాత మళ్లీ తనిఖీ చేయండి.",
    requires_answer: "సమాధానం అవసరం",
    enter_member_id: "సభ్యుల ఐడిని నమోదు చేయండి",
    viewers_can_read_form_responses: "వీక్షకులు ఫారమ్‌ను చదవగలరు మరియు ప్రతిస్పందనలను వీక్షించగలరు",
    editors_edit_form_view_responses: "ఎడిటర్లు ఫారమ్‌ను సవరించగలరు మరియు ప్రతిస్పందనలను వీక్షించగలరు",
    admin_edit_form_add_collaborator: "అడ్మిన్ ఫారమ్‌ని సవరించవచ్చు మరియు సహకారిని జోడించవచ్చు",
    invalid_member_id: "చెల్లని సభ్యుడు id",
    already_member_added: "ఇప్పటికే ఈ సభ్యుడు జోడించబడ్డారు",
    add_collaborator: "సహకారిని జోడించండి",
    admin: "అడ్మిన్",
    editor: "సంపాదకుడు",
    viewer: "వీక్షకుడు",
    searched_form_not_found: "శోధించిన ఫారమ్ దొరకలేదు!",
    searched_quiz_not_found: "వెతికినా క్విజ్ దొరకలేదు!",
    no_shared_data_available: "షేర్డ్ డేటా అందుబాటులో లేదు!",
    forms_server_is_not_available: "ఫారమ్‌ల సర్వర్ అందుబాటులో లేదు",
    quiz_server_is_not_available: "క్విజ్ సర్వర్ అందుబాటులో లేదు",
    leave: 'వదిలివేయండి',
    password: 'పాస్వర్డ్',
    new_password: 'కొత్త పాస్వర్డ్',
    retype_password: 'పాస్‌వర్డ్‌ని మళ్లీ టైప్ చేయండి',
    create_password: 'పాస్వర్డ్ సృష్టించండి',
    view_results: 'ఫలితాలను వీక్షించండి',
    control: 'నియంత్రణ',
    q_control: 'క్విజ్ నియంత్రణ',
    q_result: 'క్విజ్ ఫలితాలు',
    no_matches_found: "ఎలాంటి పోలికలు దొరకలేదు",
    dropdown: "డ్రాప్-డౌన్", //
    checkboxes: "చెక్‌బాక్స్‌లు",
    star_rating: 'స్టార్ రేటింగ్',
    linear_scale: 'సరళ స్థాయి',
    multiple_choice_grid: 'బహుళ ఎంపిక గ్రిడ్',
    checkboxes_grid: 'చెక్‌బాక్స్ గ్రిడ్',
    smile: 'చిరునవ్',
    info_1: "సమాచారం 1",
    info_2: "సమాచారం 2",
    info_3: "సమాచారం 3",
    new: 'కొత్త',
    download_all: 'అన్నీ డౌన్‌లోడ్ చేయండి',
    download: 'డౌన్‌లోడ్',
    sort_by: 'ఆమరిక',
    sorting: 'క్రమబద్ధీకరణ',
    refresh: 'రిఫ్రెష్',
    add: 'జోడించు',
    refresh_preview_description: "ప్రివ్యూని అప్‌డేట్ చేయడానికి 'రిఫ్రెష్' బటన్‌ను క్లిక్ చేయండి",
    enter_users_email_address: 'ఇ-మెయిల్‌ని నమోదు చేయండి',
    do_you_want_to_delete_these: 'మీరు వీటిని తొలగించాలనుకుంటున్నారా',
    do_you_want_to_delete_this_form: 'మీరు ఈ ఫారమ్‌ను తొలగించాలనుకుంటున్నారా',
    all_responses_will_also_be_deleted: 'అన్ని ప్రతిస్పందనలు కూడా తొలగించబడతాయి',
    this_action_cannot_be_undone: 'ఈ చర్య రద్దు చేయబడదు.',
    do_you_want_to_delete_this_quiz: 'మీరు ఈ క్విజ్‌ని తొలగించాలనుకుంటున్నారా',
    all_answer_sheets_will_also_be_unavailable: 'అన్ని జవాబు పత్రాలు కూడా అందుబాటులో ఉండవు',
    change: 'మార్చు',
    roles_rights: 'పాత్రలు & హక్కులు',
    learn_more: 'ఇంకా నేర్చుకో',
    viewers_can_read_quiz_and_view_answers: 'వీక్షకులు క్విజ్‌ని చదవగలరు మరియు సమాధానాలను చూడవచ్చు',
    editors_can_edit_quiz_view_answers: 'ఎడిటర్‌లు క్విజ్‌ని సవరించగలరు మరియు సమాధానాలను వీక్షించగలరు',
    admin_can_edit_quiz_add_collaborator: ' అడ్మిన్ క్విజ్‌ని సవరించగలరు మరియు సహకారిని జోడించగలరు',
    change_roles: 'పాత్రలను మార్చండి',
    remove: 'తొలగించు',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'మీరు మీ మార్పులను సమర్పించలేదు. సమర్పించకుండా వదిలేయడం సరైందేనా?',
    select_language: 'భాషను ఎంచుకోండి',
    are_you_sure_you_want_delete_this_collaborator: 'మీరు ఖచ్చితంగా ఈ సహకారిని తొలగించాలనుకుంటున్నారా?',
    are_you_sure_you_want_to_delete_selected_files: 'మీరు ఎంచుకున్న ఫైల్‌ను ఖచ్చితంగా శాశ్వతంగా తొలగించాలనుకుంటున్నారా?',
    please_download_before_deleting: 'దయచేసి తొలగించే ముందు డౌన్‌లోడ్ చేయండి. ఫైల్‌లు ఒకసారి తొలగించబడినా, తిరిగి పొందలేము!',
    role_has_been_changed_successfully: 'పాత్ర విజయవంతంగా మార్చబడింది',
    do_you_want_to_leave_collaboration: 'మీరు సహకారాన్ని విడిచిపెట్టాలనుకుంటున్నారా?',
    form_builder_access_denied: 'ఫారమ్ బిల్డర్ యాక్సెస్ నిరాకరించబడింది',
    quiz_builder_access_denied: 'క్విజ్ బిల్డర్ యాక్సెస్ నిరాకరించబడింది',
    you_only_have_viewer_rights_for_quiz: 'ఈ క్విజ్ కోసం మీకు వీక్షకుల హక్కులు మాత్రమే ఉన్నాయి.',
    you_only_have_viewer_rights_for_form: 'ఈ ఫారమ్ కోసం మీకు వీక్షకుల హక్కులు మాత్రమే ఉన్నాయి.',
    kindly_contact_the_form_owner_for_more_info: 'దయచేసి మరింత సమాచారం కోసం ఫారమ్ యజమానిని సంప్రదించండి.',
    kindly_contact_the_quiz_owner_for_more_info: 'దయచేసి మరింత సమాచారం కోసం క్విజ్ యజమానిని సంప్రదించండి..',
    incomplete_questions: 'అసంపూర్ణ ప్రశ్నలు:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'మీరు సేవ్ చేయని మార్పులను కలిగి ఉన్నారు - మీరు ఖచ్చితంగా ఈ పేజీ నుండి నిష్క్రమించాలనుకుంటున్నారా?',
    languages: 'భాష',
    copy: "నకలు",
    not_good: 'బాగాలేదు',
    not_allowed: 'ప్రవేశము లేదు',
    not_allowed_description: 'ప్రతిస్పందనలను సేకరించిన తర్వాత ఎంపిక ప్రశ్న రకాన్ని మార్చలేరు.',
    neutral: 'తటస్థ',
    good: 'బాగుంది',
    very_good: 'చాలా బాగుంది',
    edit_rights_removed: 'సవరణ హక్కులు తీసివేయబడ్డాయి',
    your_rights_to_edit_this_form_have_been_removed: "ఈ ఫారమ్‌ని సవరించడానికి మీ హక్కులు తీసివేయబడ్డాయి.",
    your_rights_to_edit_this_quiz_have_been_removed: "ఈ క్విజ్‌ని సవరించడానికి మీ హక్కులు తీసివేయబడ్డాయి.",
    please_contact_quiz_owner_for_more_info: "దయచేసి మరింత సమాచారం కోసం ఫారమ్ యజమానిని సంప్రదించండి.",
    please_contact_form_owner_for_more_info: "దయచేసి మరింత సమాచారం కోసం క్విజ్ యజమానిని సంప్రదించండి.",
    last_edited_on: 'చివరిగా సవరించబడినది',
    last_edited: 'చివరిగా సవరించబడింది',
    by: 'ద్వారా',
    you: "మీరు",
    add_option: 'ఎంపికను జోడించండి',
    add_other_option: "'ఇతర' ఎంపికను జోడించండి",
    estimated_time: "అంచనా వేసిన సమయం",
    do_you_want_to_show_estimated_time_taking_quiz: "మీరు క్విజ్ తీసుకోవడానికి అంచనా వేసిన సమయాన్ని చూపాలనుకుంటున్నారా ?",
    do_you_want_to_show_estimated_time_taking_form: "మీరు ఫారమ్ తీసుకోవడానికి అంచనా వేసిన సమయాన్ని చూపాలనుకుంటున్నారా ?",
    show_question_count: " ప్రశ్నల సంఖ్యను చూపు",
    do_you_want_to_show_total_number_questions_quiz: "మీరు క్విజ్‌లో మొత్తం ప్రశ్నల సంఖ్యను చూపాలనుకుంటున్నారా ?",
    do_you_want_to_show_total_number_questions_form: "మీరు ఫారమ్‌లో మొత్తం ప్రశ్నల సంఖ్యను చూపాలనుకుంటున్నారా ?",
    show_question_marks: "ప్రశ్న గుర్తులను చూపించు",
    show_question_marks_quiz: "క్విజ్‌లో ప్రశ్న గుర్తులను చూపండి",
    you_dont_have_add_collaborator_permission: "మీకు సహకారిని జోడించడానికి అనుమతి లేదు",
    modified_date: "సవరించిన తేదీ",
    created_date: "సృష్టించిన తేదీ",
    question_cant_be_added: "ప్రశ్నలు జోడించబడవు",
    another_file_upload_question_cant_added: "మరొక ఫైల్ అప్‌లోడ్ ప్రశ్న జోడించబడవు",
    you_can_have_only: "మీరు ఒక ఫారమ్‌లో ",
    file_upload_questions_in_form: "ఫైల్ అప్‌లోడ్ ప్రశ్నలను మాత్రమే కలిగి ఉండవచ్చు",
    file_upload_questions_in_quiz: "క్విజ్‌లో ప్రశ్నలను అప్‌లోడ్ చేయండి",
    storage_capacity_exceeded: "నిల్వ సామర్థ్యం మించిపోయింది",
    kindly_inform_form_builder_that: "దయచేసి ఫారమ్ బిల్డర్‌కి తెలియజేయండి",
    kindly_inform_quiz_builder_that: "దయచేసి క్విజ్ బిల్డర్‌కి తెలియజేయండి",
    their_storage_capacity_file_uploads: "ఫైల్ అప్‌లోడ్‌ల కోసం వాటి నిల్వ సామర్థ్యం",
    has_been_exceeded: "మించిపోయింది",
    view_details: "వివరాలను వీక్షించండి",
    paste_your_video_link_here: "మీ వీడియో లింక్‌ని ఇక్కడ పెట్టండి",
    paste_your_website_link_here: "మీ వెబ్‌సైట్ లింక్‌ని ఇక్కడ పెట్టండి",
    view_answer_sheet: "జవాబు పత్రాన్ని వీక్షించండి",
    response_count: "ప్రతిస్పందన గణన",
    count: "లెక్కించండి",
    password_least_8_characters: "పాస్‌వర్డ్ తప్పనిసరిగా కనీసం 8 - 50 అక్షరాలు ఉండాలి",
    password_contain_least_1_number: "పాస్‌వర్డ్ తప్పనిసరిగా కనీసం 1 సంఖ్యను కలిగి ఉండాలి",
    password_contain_least_1_capital_letter: "పాస్‌వర్డ్ తప్పనిసరిగా కనీసం 1 పెద్ద అక్షరాన్ని కలిగి ఉండాలి",
    password_contain_least_1_special_character: "పాస్‌వర్డ్ తప్పనిసరిగా కనీసం 1 ప్రత్యేక అక్షరాన్ని కలిగి ఉండాలి",
    please_fill_all_required_fields: "దయచేసి అవసరమైన అన్ని ఫీల్డ్‌లను పూరించండి",
    passwords_dont_match: "పాస్‌వర్డ్‌లు సరిపోలడం లేదు",
    incorrect_password: "పాస్‌వర్డ సరియినది కాని ",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "మీరు ఈ చిత్రాన్ని తొలగించాలనుకుంటున్నారా? మీరు కొనసాగితే, ఈ చిత్రాన్ని ఉపయోగిస్తున్న ఏ ఫారమ్‌లు లేదా క్విజ్‌లకు ఈ చిత్రం అందుబాటులో ఉండదు. ఈ చర్య రద్దు చేయబడదు.",
    url_does_not_seem_to_valid: "URL చెల్లుబాటు అయ్యేలా లేదు.",
    you_have_reached_image_uploaded_limit: "మీరు చిత్రం అప్‌లోడ్ చేసిన పరిమితిని చేరుకున్నారు.",
    click_or_change_current_theme: "వేరొక థీమ్‌ను ఎంచుకోవడానికి దిగువ ప్రస్తుత థీమ్ లేదా 'మార్చు'ని క్లిక్ చేయండి",
    select: "ఎంచుకోండి",
    undo: "అన్డు",
    response_deleted: "ప్రతిస్పందన తొలగించబడింది",
    answer_deleted: "సమాధానం తొలగించబడింది",
    question_deleted: "ప్రశ్న తొలగించబడింది",
    responses_deleted: "ప్రతిస్పందనలు తొలగించబడ్డాయి",
    answers_deleted: "సమాధానాలు తొలగించబడ్డాయి",
    questions_deleted: "పప్రశ్నలు తొలగించబడ్డాయి",
    enter_desc_here: "వివరణను ఇక్కడ నమోదు చేయండి",
    collect_email_header: "ఇమెయిల్‌ని సేకరించండి",
    collect_email_alert_message: "ఈ లక్షణాన్ని ప్రారంభించడం వలన ప్రతిస్పందనదారు ఫారమ్‌ను పూరించడానికి వారి gmail చిరునామాతో సైన్ ఇన్ చేయవలసి వస్తుంది.",
    collect_email_alert_note: "గమనిక : Google ఖాతాకు మాత్రమే మద్దతు ఉంది",
    allow_response_edit: "ప్రతిస్పందన సవరణను అనుమతించు",
    allow_response_edit_description: "సమర్పించిన తర్వాత ప్రతిస్పందనలను సవరించడానికి మీరు ప్రతిస్పందనదారులను అనుమతించాలనుకుంటున్నారా?",
    passcode_protection: "పాస్‌కోడ్ రక్షణ",
    passcode_protection_description_quiz: "మీ క్విజ్ పాస్‌కోడ్ రక్షించబడాలని మీరు కోరుకుంటున్నారా?",
    passcode_protection_description: "మీ ఫారమ్ పాస్‌కోడ్ రక్షించబడాలని మీరు కోరుకుంటున్నారా?",
    crop_image: "చిత్రాన్ని కత్తిరించండి",
    crop: "పంట",
    original: "అసలైనది",
    collect_email: "ఇమెయిల్ చిరునామాను సేకరించండి",
    collect_email_description: "సంభావ్య భవిష్యత్ సూచన లేదా వినియోగం కోసం మీరు ప్రతివాదుల ఇమెయిల్ చిరునామాలను సేకరించాలనుకుంటున్నారా?",
    collect_email_description_quiz: "ఈ లక్షణాన్ని ప్రారంభించడం వలన ప్రతిస్పందనదారులు క్విజ్‌ని పూరించడానికి వారి gmail చిరునామాతో సైన్ ఇన్ చేయవలసి వస్తుంది.",
    collect_email_description_form: "ఈ లక్షణాన్ని ప్రారంభించడం వలన ప్రతిస్పందనదారులు ఫారమ్‌ను పూరించడానికి వారి gmail చిరునామాతో సైన్ ఇన్ చేయవలసి వస్తుంది.",
    opening_date: 'ప్రారంభ తేదీ',
    closing_date: 'ముగింపు తేది',
    schedule: 'షెడ్యూల్',
    schedule_form_status: 'షెడ్యూల్ ఫారమ్ స్థితి',
    schedule_quiz_status: 'క్విజ్ స్థితిని షెడ్యూల్ చేయండి',
    schedule_opening_date_and_time: "ప్రారంభ తేదీ మరియు సమయాన్ని షెడ్యూల్ చేయండి",
    schedule_closing_date_and_time: "ముగింపు తేదీ మరియు సమయాన్ని షెడ్యూల్ చేయండి",
    schedule_opening_date_and_time_description: "పేర్కొన్న తేదీ మరియు సమయంలో ఫారమ్‌ను సక్రియం చేయండి",
    schedule_opening_date_and_time_description_quiz: "పేర్కొన్న తేదీ మరియు సమయంలో క్విజ్‌ని సక్రియం చేయండి",
    schedule_closing_date_and_time_description: "పేర్కొన్న తేదీ మరియు సమయంలో ఫారమ్‌ను నిష్క్రియం చేయండి",
    schedule_closing_date_and_time_description_quiz: "పేర్కొన్న తేదీ మరియు సమయంలో క్విజ్‌ను నిష్క్రియం చేయండి",
    upgrade: "అప్‌గ్రేడ్",
    gold_plan: "గోల్డ్ ప్లాన్",
    view_plans: "ప్రణాళికలను వీక్షించండి",
    premium_plans: "ప్రీమియం ప్లాన్‌లు",
    plan_duration: "/ నెల",
    plan_logic_jump: "లాజిక్ జంప్",
    plan_remove_ads: "ప్రకటనలు తొలగించండి",
    cancel_subscription: "చందాను రద్దు చేయి",
    quiz_has_answer_edit_message: "ఈ క్విజ్ ఇప్పటికే సమర్పించబడింది మరియు సమాధానాలు సేకరించబడ్డాయి. క్విజ్‌ని సవరించడం వలన మునుపటి సమాధానాలు ప్రభావితం కావచ్చు.",
    quiz_published_alert_edit_message: "ఈ క్విజ్ ప్రచురించబడింది, మీరు సవరించినట్లయితే ఈ క్విజ్ ప్రచురించబడదు.",
    quiz_submit_after_edit_message: "క్విజ్‌ని సవరించడం వలన మార్కులు, శాతాలు ప్రభావితం కావచ్చు మరియు కొన్ని ప్రశ్నలకు తిరిగి మూల్యాంకనం అవసరం.",
    title_activity_privacy_policy: "గోప్యతా విధానం కార్యాచరణ",
    action_blocked_message: "ఈ క్విజ్‌కి ఇప్పటికే ఉన్న సమాధానాల కారణంగా చర్య బ్లాక్ చేయబడింది. దీన్ని అనుమతించడం మునుపటి సమాధానాలపై ప్రభావం చూపవచ్చు.",
    email_addresses: "ఇమెయిల్ చిరునామాలు",
    confirmation_message: "ధృవీకరణ సందేశం",
    confirmation_message_description: "మీరు ఫారమ్‌ను సమర్పించిన తర్వాత నిర్ధారణ సందేశాన్ని చూపాలనుకుంటున్నారా?",
    confirmation_message_description_quiz: "క్విజ్‌కి సమాధానం ఇచ్చిన తర్వాత మీరు నిర్ధారణ సందేశాన్ని చూపించాలనుకుంటున్నారా?",
    default_custom_thank_message: "ఈ ఫారమ్‌ను పూర్తి చేసినందుకు ధన్యవాదాలు.",
    default_custom_thank_message_quiz: "ఈ క్విజ్‌ని పూర్తి చేసినందుకు ధన్యవాదాలు.",
    upgrade_your_plan: "మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేసుకోండి!",
    upgrade_your_plan_1: "మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి",
    upgrade_your_plan_content: "దయచేసి ఈ ఫీచర్‌ని యాక్సెస్ చేయడానికి ప్రీమియం వెర్షన్‌ని అప్‌గ్రేడ్ చేయండి మరియు స్టోరేజ్ సైజును పెంచండి, మొదలైనవి",
    alert_icon: "హెచ్చరిక చిహ్నం",
    usage_details: "వినియోగ వివరాలు",
    current_plan: "ప్రస్తుత ప్రణాళిక",
    total_submission: "మొత్తం సమర్పణ",
    image_attachments: "చిత్రం జోడింపులు",
    custom_themes: "అనుకూల థీమ్‌లు",
    free_limit_reached: "మీరు ఉచిత పరిమితిని చేరుకున్నారు -",
    payment_done_but_server_error: "మీ చెల్లింపు విజయవంతంగా తీసివేయబడింది. దయచేసి కొంత సమయం తర్వాత మీ చెల్లింపును ధృవీకరించడానికి మమ్మల్ని అనుమతించడానికి అనువర్తనాన్ని తెరవండి.",
    response_quota_reached: "మీరు ఉచిత పరిమితిని చేరుకున్నారు, దయచేసి అన్ని ప్రతిస్పందనలను యాక్సెస్ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    answers_quota_reached: "మీరు ఉచిత పరిమితిని చేరుకున్నారు, దయచేసి అన్ని సమాధానాలను యాక్సెస్ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    logic_jump_enabled: "లాజిక్ జంప్ ప్రారంభించబడింది.",
    logic_jump_disabled: "లాజిక్ జంప్ డిసేబుల్ చేయబడింది.",
    cancel_subscription_message: "మీరు ఖచ్చితంగా మీ సభ్యత్వాన్ని రద్దు చేయాలనుకుంటున్నారా? సబ్‌స్క్రిప్షన్‌ను రద్దు చేయడం వలన అన్ని ప్రీమియం ఫీచర్‌లకు యాక్సెస్ కోల్పోతుంది.",
    plan_expired: "మీ ప్లాన్ గడువు ముగిసింది. దయచేసి ఈ డ్రాఫ్ట్‌ని ఉపయోగించడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    plan_expired_edit_form: "మీ ప్లాన్ గడువు ముగిసింది. దయచేసి ఈ ఫారమ్‌ని సవరించడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి",
    plan_expired_edit_quiz: "మీ ప్లాన్ గడువు ముగిసింది. దయచేసి ఈ క్విజ్‌ని సవరించడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి",
    plan_expired_duplicate_form: "మీ ప్లాన్ గడువు ముగిసింది. దయచేసి ఈ ఫారమ్‌ను నకిలీ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి",
    plan_expired_duplicate_quiz: "మీ ప్లాన్ గడువు ముగిసింది. దయచేసి ఈ క్విజ్‌ని నకిలీ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి",
    premium_features: "ప్రీమియం ఫీచర్",
    plan_file_upload: "అపరిమిత అనుకూల థీమ్ చిత్రాలు",
    plan_custom_theme: "అపరిమిత అనుకూల థీమ్ చిత్రాలు",
    plan_call_support: "అధిక ప్రాధాన్యత ఆన్ - కాల్ సపోర్ట్",
    plan_email_support: "అధిక ప్రాధాన్యత ఇమెయిల్ మద్దతు",
    plan_custom_domain: "అనుకూల డొమైన్",
    subscribe: "సబ్స్క్రయిబ్",
    plan_remove_branding: "బ్రాండింగ్ తొలగించు",
    plan_on_call_support: "ఆన్ కాల్ సపోర్ట్",
    plan_image_attachment: "అపరిమిత చిత్రాల జోడింపులు",
    plan_custom_thank_you: "అనుకూలీకరించదగిన ధన్యవాదాలు పేజీలు",
    plan_allowed_responses: "అనుమతించబడిన ప్రతిస్పందన సంఖ్య 50000",
    trusted_by_number_of_user: "5 మిలియన్లకు పైగా వినియోగదారులచే విశ్వసించబడింది",
    email_support: "ఇమెయిల్ మద్దతు",
    call_support: "మద్దతుకు కాల్ చేయండి",
    keyboard_shortcuts: "కీబోర్డ్ సత్వరమార్గాలు",
    premium_limit_reached: "మీరు ప్లాన్ పరిమితిని చేరుకున్నారు. అన్ని ప్రీమియం ఫీచర్‌లను యాక్సెస్ చేయడానికి దయచేసి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    premium_limit_reached_mobile: "మీరు ప్లాన్ పరిమితిని చేరుకున్నారు.",
    unlimited: "అపరిమిత",
    expired_on_next_renewal: "ఇది మీ తదుపరి పునరుద్ధరణ తేదీతో గడువు ముగుస్తుంది",
    upgrade_plan: "ప్రణాళికను అప్‌గ్రేడ్ చేయండి",
    downgrade_plan: "డౌన్‌గ్రేడ్ ప్లాన్",
    file_upload_questions: "ఫైల్ అప్‌లోడ్ ప్రశ్నలు",
    plan_response_edit: "ప్రతిస్పందన సవరణ",
    cant_process: "ప్రాసెస్ చేయలేరు",
    you_have_subscribed_through_android: "మీరు మా Android యాప్ ద్వారా సభ్యత్వం పొందారు. ఏదైనా చెల్లింపు సంబంధిత అప్‌డేట్‌ల కోసం దయచేసి SurveyHeart యాప్‌ని ఉపయోగించండి.",
    pay_now: "ఇప్పుడు చెల్లించండి",
    update_payment_details: "చెల్లింపు వివరాలను నవీకరించండి",
    limit_reached_alert_message: "మీరు ఉచిత పరిమితిని చేరుకున్నారు, దయచేసి అన్ని ప్రతిస్పందనలను యాక్సెస్ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    limit_reached_alert_message_quiz: "మీరు ఉచిత పరిమితిని చేరుకున్నారు, దయచేసి అన్ని సమాధానాలను యాక్సెస్ చేయడానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    limit_reached_UI_message: "మీ ప్రస్తుత ప్లాన్‌కు ప్రతిస్పందనలు అందుబాటులో ఉండవు, దయచేసి అన్ని ప్రతిస్పందనలను చూడటానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    limit_reached_UI_message_quiz: "మీ ప్రస్తుత ప్లాన్‌కు సమాధానాలు అందుబాటులో ఉండవు, దయచేసి అన్ని సమాధానాలను చూడటానికి మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయండి.",
    owner_is_not_premium_user: "యజమాని ప్రీమియం వినియోగదారు కాదు",
    cancel_subscription_description: "మీరు మీ సభ్యత్వాన్ని ఖచ్చితంగా రద్దు చేయాలనుకుంటున్నారా? రద్దు చేయడం వలన అన్ని ప్రీమియం ఫీచర్‌లకు యాక్సెస్ కోల్పోతుంది.",
    starter_storage_description: "అప్రయత్నమైన డేటా నిర్వహణ కోసం 5GB నిల్వను పొందండి.",
    bronze_storage_description: "అప్రయత్నమైన డేటా నిర్వహణ కోసం 25GB నిల్వను పొందండి.",
    silver_storage_description: "అప్రయత్నమైన డేటా నిర్వహణ కోసం 50GB నిల్వను పొందండి.",
    gold_storage_description: "అప్రయత్నమైన డేటా నిర్వహణ కోసం 150GB నిల్వను పొందండి.",
    starter_responses_description: "శక్తివంతమైన సర్వే అంతర్దృష్టులను పొందడానికి 10,000 ప్రతిస్పందనలను ఉపయోగించండి.",
    bronze_responses_description: "శక్తివంతమైన సర్వే అంతర్దృష్టులను పొందడానికి 35,000 ప్రతిస్పందనలను ఉపయోగించండి.",
    silver_responses_description: "శక్తివంతమైన సర్వే అంతర్దృష్టులను పొందడానికి 75,000 ప్రతిస్పందనలను ఉపయోగించండి.",
    gold_responses_description: "శక్తివంతమైన సర్వే అంతర్దృష్టులను పొందడానికి 1,50,000 ప్రతిస్పందనలను ఉపయోగించండి.",
    starter_image_description: "విజువల్ అప్పీల్ కోసం గరిష్టంగా 100 చిత్రాలతో మీ సర్వేలను మెరుగుపరచండి.",
    bronze_image_description: "విజువల్ అప్పీల్ కోసం గరిష్టంగా 200 చిత్రాలతో మీ సర్వేలను మెరుగుపరచండి.",
    silver_image_description: "విజువల్ అప్పీల్ కోసం గరిష్టంగా 500 చిత్రాలతో మీ సర్వేలను మెరుగుపరచండి.",
    gold_image_description: "విజువల్ అప్పీల్ కోసం అపరిమిత చిత్రాలతో మీ సర్వేలను మెరుగుపరచండి.",
    starter_theme_description: "మీ బ్రాండ్ కోసం మీ సర్వేలను వ్యక్తిగతీకరించడానికి 100 అనుకూల చిత్రాలను జోడించండి.",
    bronze_theme_description: "మీ బ్రాండ్ కోసం మీ సర్వేలను వ్యక్తిగతీకరించడానికి 200 అనుకూల చిత్రాలను జోడించండి.",
    silver_theme_description: "మీ బ్రాండ్ కోసం మీ సర్వేలను వ్యక్తిగతీకరించడానికి 500 అనుకూల చిత్రాలను జోడించండి.",
    gold_theme_description: "మీ బ్రాండ్ కోసం మీ సర్వేలను వ్యక్తిగతీకరించడానికి అపరిమిత అనుకూల చిత్రాలను జోడించండి.",
    custom_theme_image: "అనుకూల థీమ్ చిత్రం",
    starter_upload_description: "4 ఫైల్ అప్‌లోడ్ ప్రశ్నలను సజావుగా ఇంటిగ్రేట్ చేయండి",
    file_upload_description: "అపరిమిత ఫైల్ అప్‌లోడ్ ప్రశ్నలను సజావుగా ఇంటిగ్రేట్ చేయండి.",
    logic_jump_description: "ప్రతి ప్రతివాది అనుభవం కోసం సర్వే ఫ్లోను అనుకూలీకరించడానికి లాజిక్ జంప్‌లను ఉపయోగించండి",
    thank_you_message_description: "ప్రతివాదులను ఆకట్టుకోవడానికి ధన్యవాదాలు పేజీలను వ్యక్తిగతీకరించండి.",
    response_addon_description: "మరిన్ని అంతర్దృష్టులను సేకరించడానికి అదనపు ప్రతిస్పందనలను పొందండి.",
    response_edit_description: "స్పందన సవరణ లక్షణాన్ని ఉపయోగించి ప్రతిస్పందనలను సులభంగా మెరుగుపరచండి.",
    remove_branding_description: "SurveyHeart బ్రాండింగ్‌ని తీసివేయడం ద్వారా మీ బ్రాండ్ ఉనికిని పెంచుకోండి.",
    bronze_remove_ads_description: "మా కాంస్య ప్లాన్‌తో ప్రకటన రహితంగా వెళ్లండి మరియు మీ సర్వేలపై దృష్టి పెట్టండి.",
    silver_remove_ads_description: "మా సిల్వర్ ప్లాన్‌తో యాడ్-ఫ్రీగా వెళ్లండి మరియు మీ సర్వేలపై దృష్టి పెట్టండి.",
    gold_remove_ads_description: "మా గోల్డ్ ప్లాన్‌తో ప్రకటన రహితంగా వెళ్లండి మరియు మీ సర్వేలపై దృష్టి పెట్టండి.",
    bronze_email_support_description: "తక్కువ ప్రాధాన్యత కోసం ఇమెయిల్ మద్దతును యాక్సెస్ చేయండి, సకాలంలో సహాయానికి భరోసా.",
    silver_email_support_description: "మీడియం-ప్రాధాన్యత కోసం ఇమెయిల్ మద్దతును యాక్సెస్ చేయండి, సకాలంలో సహాయాన్ని నిర్ధారిస్తుంది.",
    gold_email_support_description: "అధిక-ప్రాధాన్యత కోసం ఇమెయిల్ మద్దతును యాక్సెస్ చేయండి, సకాలంలో సహాయాన్ని నిర్ధారిస్తుంది.",
    bronze_email_support_title: "తక్కువ ప్రాధాన్యత కలిగిన ఇమెయిల్ మద్దతు",
    silver_email_support_title: "మధ్యస్థ ప్రాధాన్యత ఇమెయిల్ మద్దతు",
    gold_email_support_title: "అధిక ప్రాధాన్యత కలిగిన ఇమెయిల్ మద్దతు",
    update_your_payment_details_mobile: "దయచేసి మీ చెల్లింపు వివరాలను నవీకరించండి.",
    update_your_payment_details_pc: "మేము ఈ ఖాతాను పునరుద్ధరించడంలో కొన్ని సమస్యలను ఎదుర్కొంటున్నాము. దయచేసి మీ చెల్లింపు వివరాలను నవీకరించండి.",
    card_details_updated_successfully: "కార్డ్ వివరాలు విజయవంతంగా నవీకరించబడ్డాయి",
    card_details_updated_successfully_description: "మీ కార్డ్ వివరాలు విజయవంతంగా నవీకరించబడ్డాయి. మీరు ఇప్పుడు మీ నవీకరించబడిన చెల్లింపు సమాచారాన్ని ఉపయోగించడం కొనసాగించవచ్చు.",
    payment_successful: "చెల్లింపు విజయవంతమైంది",
    payment_successful_description: "మీ చెల్లింపు విజయవంతమైంది. మీరు ఇప్పుడు SurveyHeart యొక్క ప్రీమియం ప్లాన్‌కు సభ్యత్వం పొందారు. సౌకర్యవంతంగా పొందండి మరియు మీకు అందుతున్న అన్ని అద్భుతమైన ఫీచర్‌లను ఆస్వాదించండి.",
    payment_failed: "చెల్లింపు విఫలమైంది!",
    payment_failed_description: "దురదృష్టవశాత్తూ. మేము SurveyHeart ప్రీమియం ప్లాన్‌కి మీ సభ్యత్వాన్ని ప్రాసెస్ చేయలేకపోయాము. దయచేసి మళ్లీ ప్రయత్నించండి.",
    note_for_five_rupees: "గమనిక: మీ కార్డ్ వివరాలను అప్‌డేట్ చేయడానికి రూ. 5 ఛార్జీ ఉంది, ఇది \n అదే ఖాతాకు 3 నుండి 4 పని దినాలలోపు తిరిగి చెల్లించబడుతుంది.",
    logic: "లాజిక్",
    re_generate_code: "రీ-జెనరేట్ కోడ్",
    plan_change_restrictions: "ప్లాన్ మార్పు పరిమితులు",
    plan_change_restriction_desc_1: "మీరు UPIని ఉపయోగించి మీ ప్లాన్‌ని మార్చలేరు.",
    plan_change_restriction_desc_2: "అప్‌గ్రేడ్‌లు మరియు డౌన్‌గ్రేడ్‌ల కోసం కార్డ్‌కి మారండి లేదా మీరు కోరుకున్న ప్లాన్‌ని రద్దు చేసి, మళ్లీ సబ్‌స్క్రయిబ్ చేసుకోండి.",
    update_card_details: "కార్డ్ వివరాలను నవీకరించు",
    features_and_plans: "ఫీచర్‌లు మరియు ప్లాన్‌లు",
    cancel_plan: "ప్లాన్ రద్దు",
    cancel_plan_desc: "మీరు ప్లాన్‌ని రద్దు చేసారు. మీరు ఇప్పటికీ మీ పునరుద్ధరణ తేదీ వరకు చెల్లింపు ఫీచర్‌లకు యాక్సెస్‌ను కలిగి ఉంటారు.",
    explore_text: "ఈరోజు మా ప్రీమియం సబ్‌స్క్రిప్షన్ ప్లాన్‌లలో ఒకదానిలో అసాధారణమైన ఫీచర్‌లను అన్వేషించండి మరియు అన్‌లాక్ చేయండి",
    cant_edit_this_form: "ఈ ఫారమ్‌ని సవరించలేరు",
    cant_edit_this_quiz: "ఈ క్విజ్‌ని సవరించలేరు",
    cant_duplicate_this_form: "ఈ ఫారమ్‌ను నకిలీ చేయలేరు",
    cant_duplicate_this_quiz: "ఈ క్విజ్‌ని నకిలీ చేయడం సాధ్యపడదు",
    kindly_upgrade_the_premium: "ఈ లక్షణాన్ని యాక్సెస్ చేయడానికి దయచేసి ప్రీమియం వెర్షన్‌ను అప్‌గ్రేడ్ చేయండి.",
    check_box: 'చెక్ బాక్స్',
    continue_to_next_question: "తదుపరి ప్రశ్నకు కొనసాగించు",
    add_logic: "లాజిక్‌ని జోడించు",
    submit_form: "ఫారమ్‌ను సమర్పించు",
    save_logic: "లాజిక్‌ను సేవ్ చేయి",
    remove_logic: "లాజిక్ తీసివేయి",
    responses_add_ons: "ప్రతిస్పందనల యాడ్-ఆన్",
    responses_add_available: "ప్రతిస్పందనల యాడ్-ఆన్ అందుబాటులో ఉంది",
    additional_submissions: " అదనపు సమర్పణలు",
    validity: " చెల్లుబాటు",
    up_to_your_current_plan: " మీ ప్రస్తుత ప్లాన్ వరకు",
    responses_add_ons_description: " ఈ ప్రతిస్పందన గణనలు మీ ప్రస్తుత ప్లాన్‌కి జోడించబడతాయి.",
    redirect_url_title: " కావలసిన లింక్‌కి దారి మళ్లించండి",
    redirect_url_description: " మీరు మీ వినియోగదారులను బాహ్య వెబ్‌పేజీకి దారి మళ్లించాలనుకుంటున్నారా?",
    enter_weblink: " వెబ్‌లింక్‌ని నమోదు చేయండి",
    get: "పొందండి",
    jump_to: "ఇక్కడికి గెంతు",
    action_cannot_be_performed: "చర్య నిర్వహించబడదు",
    upgrade_plan_desc1: "మీరు మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేయాలనుకుంటున్నారా",
    downgrade_plan_desc1: "మీరు మీ ప్లాన్‌ని డౌన్‌గ్రేడ్ చేయాలనుకుంటున్నారా",
    downgrade_plan_desc2: "ఈ మార్పు మీ తదుపరి పునరుద్ధరణ తేదీ నుండి అమలులోకి వస్తుంది.",
    upgraded_plan: "అప్‌గ్రేడ్ చేసిన ప్లాన్",
    downgraded_plan: "డౌన్‌గ్రేడ్ చేసిన ప్లాన్",
    upgraded_plan_desc: "మీరు మీ ప్లాన్‌ని అప్‌గ్రేడ్ చేసారు. ఈ మార్పు మీ తదుపరి పునరుద్ధరణ తేదీ నుండి అమలులోకి వస్తుంది.",
    downgraded_plan_desc: "మీరు మీ ప్లాన్‌ని డౌన్‌గ్రేడ్ చేసారు. ఈ మార్పు మీ తదుపరి పునరుద్ధరణ తేదీ నుండి అమలులోకి వస్తుంది.",
    buy_responses: "ప్రతిస్పందనలను కొనుగోలు చేయండి",
    passcode: "పాస్‌కోడ్",
    share_link_with_passcode: "పాస్‌కోడ్‌తో లింక్‌ను షేర్ చేయండి",
    contact_us: "మమ్మల్ని సంప్రదించండి",
    premium_limit_reached_for_gold: "మీరు ప్లాన్ పరిమితిని చేరుకున్నారు. అన్ని ప్రీమియం ఫీచర్లను యాక్సెస్ చేయడానికి దయచేసి మమ్మల్ని సంప్రదించండి.",
    follow_us: "మమ్మల్ని అనుసరించండి",
    billing_history: "బిల్లింగ్ చరిత్ర",
    your_current_plan: "మీ ప్రస్తుత ప్లాన్",
    next_billing_date: "తదుపరి బిల్లింగ్ తేదీ",
    transaction_id: "లావాదేవీ ID",
    plan: "ప్లాన్ చేయండి",
    price: "ధర",
    status: "స్థితి",
    follow_us_desc: "అప్‌డేట్‌గా ఉండండి, కనెక్ట్ అవ్వండి మరియు మాతో ఎంగేజ్ అవ్వండి",
    ranking_choice: 'ర్యాంకింగ్ ఎంపిక',
    ranking: 'ర్యాంకింగ్',
    picture_choice: 'చిత్రం ఎంపిక',
    agreement: 'ఒప్పందం',
    signature: "సంతకం",
    slider: 'స్లయిడర్',
    min: 'కనిష్ట',
    max: 'గరిష్టంగా',
    step_value: "దశ విలువ",
    scale_range_error: "స్కేల్ పరిధి తప్పనిసరిగా -10,000 మరియు 10,000 మధ్య విలువ ఉండాలి.",
    scale_range_error2: "గరిష్ట స్కేల్ విలువ ఆమోదయోగ్యమైన విలువ కంటే తక్కువగా ఉంటుంది.",
    max_min_error: "కనిష్ట స్కేల్ విలువ గరిష్ట స్కేల్ కంటే తక్కువగా ఉండాలి.",
    step_error: "దశ పరిమాణం మధ్య విలువ ఉండాలి",
    add_picture: 'చిత్రాన్ని జోడించండి',
    terms_and_conditions: "నిబంధనలు & షరతులు",
    terms_and_conditions_required: "నిబంధనలు & షరతులు అవసరం",
    i_agree: "నేను అంగీకరిస్తున్నాను",
    i_disagree: "నేను విభేదిస్తున్నాను",
    yearly: "సంవత్సరానికి",
    monthly: "నెలవారీ",
    save_upto_20: "వార్షిక ప్లాన్‌లతో 20% వరకు ఆదా చేసుకోండి",
    paid: "చెల్లించారు",
    cancelled: "రద్దు చేయబడింది",
}