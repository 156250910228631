import React from "react"
import redAlert from "../../../../public/images/red-alert.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {useDispatch, useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import usePremiumMgr from "../../hooks/usePremiumMgr";
import {getSubscriptionId, verifyPayment} from "../../../shared/utils/sash-v2-api";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {PREMIUM_PLAN_KEYS} from "../../../shared/utils/helper";

const DashboardLimitReachedUI = () => {
    const {displayRazorpay} = usePremiumMgr()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    const verifyRazorpayPayment = (res) => {
        const successFunction = (res) => {
            if (res?.result) {
                window.location.reload()
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (res?.razorpay_payment_id && res?.razorpay_signature && res?.razorpay_subscription_id) {
            verifyPayment(res.razorpay_subscription_id, res.razorpay_payment_id, res.razorpay_signature, successFunction, failureFunction)
        }
    }

    const successFunction = (res) => {
        if (res?.result) {
            displayRazorpay(res.subscription_id, true, verifyRazorpayPayment)
        }
    }

    return (
        <div className={"quiz-summary-inner-wrapper"}
             style={{
                 display: "flex",
                 marginTop: "0",
                 height: "10%",
                 padding: "0 5px",
                 width: isMobileOnly ? "95%" : null,
                 maxWidth: isMobileOnly ? null : "1180px",
                 background: isMobileOnly ? "#eeeeee" : null
             }}>
            <img src={redAlert} alt={"Alert"} style={{margin: "auto 10px", height: "25px"}}/>
            <p style={{fontSize: isMobileOnly ? "4.5vw" : "18px"}}>
                {isMobileOnly ? "Update your payment details." : "We are facing some issues with renewing this account. Please update your payment details."}
            </p>
            {isMobileOnly ?
                <p style={{color: '#1976d2', textDecoration: "underline", fontSize: "4.5vw"}}
                   onClick={() => {
                       if (subscriptionData.subscription_plan === PREMIUM_PLAN_KEYS.FREE_USER || subscriptionData.subscription_platform === "WEBAPP") {
                           getSubscriptionId(successFunction, (err) => console.log(err))
                       } else {
                           dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
                       }
                   }}>Update</p>
                : <MaterialButton data={{
                    title: "Update",
                    customButtonContainerStyle: {
                        borderRadius: '10px',
                        backgroundColor: '#1976d2',
                        width: '120px',
                        margin: "10px"
                    },
                    customButtonTextStyle: {
                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                        fontSize: '14px'
                    }
                }} handleClick={() => {
                    if (subscriptionData.subscription_plan === PREMIUM_PLAN_KEYS.FREE_USER || subscriptionData.subscription_platform === "WEBAPP") {
                        getSubscriptionId(successFunction, (err) => console.log(err))
                    } else {
                        dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                    }
                }}/>}
        </div>
    )
}

export default DashboardLimitReachedUI
