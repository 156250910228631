import React, {useState, useEffect} from 'react'
import FormCard, {CARD_TYPE} from "./FormCard";
import Loader from "../../../shared/components/Loader";
import DataUnavailableComponent from "./DataUnavailableComponent";
import ContentHeader from "./ContentHeader";
import {isMobileOnly} from "react-device-detect";
import {removeDataFromDataList} from "../utils/remove-data-from-datalist";
import {
    deleteForm,
    deleteManyForms, deleteMultipleResponsesInForm,
    deleteResponse,
    getForms,
    setFormFavouriteStatus
} from "../../../shared/utils/sash-v2-api";
import {sortFields, sortForms} from "../utils/sort-form-list";
import {RESPONSE_CODE} from "../../../shared/utils/helper";
import {Pages} from "../../utils/pages";
import BottomAlertContainer from "../../../shared/components/BottomAlertContainer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {showResponseDeleteAlert} from "../utils/dashboard-helper";
import {getQuizzesListService} from "../../../shared/utils/quiz-api-services";
import MobileFormControlContainer from "./MobileFormControlContainer"
import {useDispatch, useSelector} from "react-redux"
import {registerGAEvent} from "../../../shared/utils/analytics";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import deleteIcon from "../../../../public/images/delete_white.svg";
import {useTranslation} from "react-i18next";
import CollaborateCard from "../../../shared/components/CollaborateCard";
import ShareCard from "../../../shared/components/ShareCard";
import Message from "../../../shared/containers/ConfirmMessage";
import FormListCard from "./FormListCard";
import {getQuizzoryWebToken, getSASHWebToken} from "../../utils/jwt";

const FormsContainer = (props) => {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const listView = useSelector(state => state.appDashboardReducer.listView)
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const showDeletePopUp = useSelector(state => state.formsReducer.showDeletePopUp)
    const deletableFormId = useSelector(state => state.formsReducer.deletableFormId)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const mobileSortOrder = useSelector(state => state.appDashboardReducer.mobileSortOrder)
    const updatedFormData = useSelector(state => state.updatedFormDataReducers.updatedFormData)
    const showMultipleDeleteOption = useSelector(state => state.formsReducer.showMultipleDeleteOption)
    const searchResultTimeStamp = useSelector(state => state.appDashboardReducer.searchResultTimeStamp)
    const multipleFormsSelectedArray = useSelector(state => state.formsReducer.multipleFormsSelectedArray)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const showCollaboratePopUp = useSelector(state => state.appReducer.showCollaboratePopUp)
    const showSharePopUp = useSelector(state => state.appReducer.showSharePopUp)
    const shareFormId = useSelector(state => state.appReducer.formData?._id)
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const [isLoading, setIsLoading] = useState(true);
    const [favFormsList, setFavFormsList] = useState([]);
    const dispatch = useDispatch()
    const formData = useSelector(state => state.appReducer?.formData)


    const getDataFromServer = () => {
        setIsLoading(true);
        if (getSASHWebToken()) {
            getForms((responseData) => {
                let formsList;
                let resJson = responseData;

                if (resJson.result.length > 1) {
                    formsList = sortForms(resJson.result, sortFields.DATE_EDITED);
                } else if (resJson.result) {
                    formsList = resJson.result;
                }
                dispatch({
                    type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST,
                    payload: JSON.parse(JSON.stringify(formsList))
                })
                setIsLoading(false);
                setFavFormsList(sortFavForms(formsList.filter((formData) => formData.date_favoured)));

                if (formData && !formData?.is_quiz && formData?.response_count > -1) {
                    const updatedFormsListData = [...formsList]
                    updatedFormsListData.filter((form) => form._id === formData._id)[0].response_count = formData.response_count
                    dispatch({
                        type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST,
                        payload: updatedFormsListData
                    })
                }
            }, (error) => {
                if (error) {
                    if (error.status === RESPONSE_CODE.BLOCKED_USER) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                    } else if (error.status === RESPONSE_CODE.FORBIDDEN) {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.login})
                        setIsLoading(false);
                    } else {
                        console.log(error)
                        setIsLoading(false);
                    }
                } else {
                    console.log(error)
                    setIsLoading(false);
                }
            });
        }
        if (getQuizzoryWebToken()) {getQuizzesListService((res) => {
            dispatch({
                type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST,
                payload: JSON.parse(JSON.stringify(res.result))
            })
        }, err => {
            if (err) {
                if (err.status === RESPONSE_CODE.BLOCKED_USER) {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                } else {
                    console.log(err)
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
            console.log(err)
        })}
    }

    useEffect(() => {
        // Check if the key exists in local storage
        const storedValue = localStorage.getItem('deletedResponse');

        if (storedValue) {
            try {
                const storedObject = JSON.parse(storedValue);
                setIsLoading(true);
                if (Array.isArray(storedObject.responseId)) {
                    deleteMultipleResponsesInForm(storedObject.formId, storedObject.responseId, () => {
                        setIsLoading(false)
                    }, () => {
                        setIsLoading(false)
                    })
                } else {
                    deleteResponse(storedObject.formId, storedObject.responseId, () => {
                        setIsLoading(false)
                    }, () => {
                        setIsLoading(false)
                    })
                }
            } catch (error) {
                // Handle the error, e.g., the JSON string is invalid
                console.error('Error parsing JSON:', error);
            }
        }
    }, [])
    // componentDidMount

    useEffect(() => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortFields.DATE_EDITED})
        getDataFromServer()
    }, [])

    useEffect(() => {
        if (multipleFormsSelectedArray.length === 0 && isMobileOnly) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortFields.DATE_EDITED})
            getDataFromServer()
        }
    }, [multipleFormsSelectedArray])

    useEffect(() => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortFields.DATE_EDITED})
        if (!formsList) {
            getDataFromServer();
        } else {
            // Check for an Updated or New Form
            if (updatedFormData) {
                let updatedFormsList = formsList;
                let formToUpdate = updatedFormsList.filter(form => form._id === updatedFormData._id)[0]
                if (formToUpdate === undefined) {
                    // This is a new Form that should be inserted first
                    updatedFormsList.unshift(updatedFormData)
                } else {
                    // If an updated Form is found .... Update form data by matching the ID
                    let indexOfFormToUpdate = updatedFormsList.indexOf(formToUpdate)
                    if (indexOfFormToUpdate === 0) {
                        // Update the First Form
                        if (formToUpdate?.response_count <= updatedFormData?.response_count) {
                            updatedFormsList.splice(0, 1, updatedFormData)
                        }
                    } else if (formToUpdate.response_count && (formToUpdate.response_count !== updatedFormData.response_count)) {
                        //update response count without disturbing the forms order.
                        updatedFormsList.splice(indexOfFormToUpdate, 1, updatedFormData)
                    } else {
                        // Move the updated Form to the start of the Form's List
                        updatedFormsList.splice(indexOfFormToUpdate, 1)
                        if (formToUpdate?.response_count <= updatedFormData?.response_count) {
                            updatedFormsList.unshift(updatedFormData)
                        } else {
                            updatedFormsList.unshift(formToUpdate)
                        }
                    }
                }
                updateFormsList(updatedFormsList);
            }
            setIsLoading(false);
            setFavFormsList(sortFavForms(formsList.filter((formData) => formData.date_favoured)))
        }
    }, []);   // empty dependency to stop continuous re-rendering.

    const updateFormsList = (formsList) => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST, payload: JSON.parse(JSON.stringify(formsList))})
        props.refreshStorageTotals()
    }

    const updateFormStatusInFormsList = (formId, isActive) => {
        let formList = formsList;
        for (let i = 0; i < formList.length; i++) {
            if (formList[i]._id === formId) {
                if (formList[i].date_favoured) {
                    // update favFormsList Status
                    favFormsList.find(form => form._id === formId).setting.is_active = isActive
                }
                formList[i].setting.is_active = isActive;
                updateFormsList(formList);
                return;
            }
        }
    }

    const handleNonFavMobileViewTabClick = () => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: true})
    }

    const removeDeletedFormDataInFormsList = (formId) => {
        let removedFormsList = formsList;
        if (Array.isArray(formId)) {
            for (let i = 0; i < formId.length; i++) {
                removedFormsList = removeDataFromDataList(removedFormsList, formId[i]);
            }

            for (let ctr = 0; ctr < formId.length; ctr++) {
                if (favFormsList.find(form => form._id === formId[ctr])) {
                    // Remove the Form from Favs
                    setFavFormsList(removeDataFromDataList(favFormsList, formId[ctr]))
                }
            }
        } else {
            removedFormsList = removeDataFromDataList(removedFormsList, formId);
            if (favFormsList.find(form => form._id === formId)) {
                // Remove the Form from Favs
                setFavFormsList(sortFavForms(favFormsList.filter(favForm => favForm._id !== formId)))
            }
        }

        updateFormsList(removedFormsList)
    }

    // componentDidUpdate

    useEffect(() => {

        if (formsList && isLoading) {
            setIsLoading(false);
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST, payload: JSON.parse(JSON.stringify(formsList))})
        }

        if (isMobileOnly && mobileSortOrder) {
            setSortOrder(mobileSortOrder)
        }
    }, [isShowingMobileFormControl, searchResultTimeStamp, mobileSortOrder]);

    const updateCollabIcons = () => {
        if (formData && formsList) {
            const updatedFormsList = formsList
            const index = updatedFormsList.indexOf(updatedFormsList.filter(form => form._id === formData._id)[0])
            updatedFormsList[index].collaborators = collaborator
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST, payload: updatedFormsList})
        }
    }

    const getFormCard = (formData, index) => {
        if (listView) {
            return <FormListCard key={formData._id} CARD_TYPE={CARD_TYPE.USER_FORM}
                                 cardIndex={index}
                                 formData={formData}
                                 updateCollabIcons={updateCollabIcons}
                                 favouriteClicked={favouriteClicked}
                                 updateStatus={updateFormStatusInFormsList}
                                 previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                 showDataEditWarning={props.showDataEditWarning}
                                 timeOutForLongPress={props.timeOutForLongPress}
            />
        } else {
            return <FormCard key={formData._id} CARD_TYPE={CARD_TYPE.USER_FORM}
                             cardIndex={index}
                             formData={formData}
                             updateCollabIcons={updateCollabIcons}
                             favouriteClicked={favouriteClicked}
                             updateStatus={updateFormStatusInFormsList}
                             previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                             showDataEditWarning={props.showDataEditWarning}
                             timeOutForLongPress={props.timeOutForLongPress}
            />
        }
    }

    const getNonFavForms = () => {
        if (!formsList) return null
        if (searchText === "") {
            return (
                () => {
                    return formsList.filter((formData) => !formData.date_favoured).map((formData, index) =>
                        getFormCard(formData, index)
                    )
                }
            )()
        }

        // Filter the Forms by the Search Text
        return (
            () => {
                return formsList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                    getFormCard(formData, index)
                )
            }
        )()
    }

    const formsSectionShouldBeVisible = (formsList, favoriteSection) => {
        if (searchText === '') {
            return formsList.length > 0
        } else {
            if (favoriteSection) {
                return formsList.length > 0 && formsList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > 0
            } else {
                return formsList.length > 0 && formsList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > 0
            }
        }
    }

    const getFavForms = () => {
        if (searchText === "") {
            return favFormsList.map((formData, index) => getFormCard(formData, index))
        }

        // Filter the Fav Forms by the Search Text
        return favFormsList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) => getFormCard(formData, index))
    }

    const multipleDeleteCancelButtonClick = () => {
        dispatch({type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY, payload: []})
        dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION, payload: false})
    }

    const multipleDeleteButtonClick = () => {
        dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
    }

    const handleMultipleFormDeleteSuccess = (formIds) => {
        dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
        dispatch({type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY, payload: []})
        dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION, payload: false})
        removeDeletedFormDataInFormsList(formIds);

        for (let ctr = 0; ctr < formIds.length; ctr++) {
            if (favFormsList.find(form => form._id === formIds[ctr])) {
                // Remove the Form from Favs
                setFavFormsList(sortFavForms(favFormsList.filter(favForm => favForm._id !== formIds[ctr])))
            }
            if (favFormsList.length === 0) {
                // No more Favourite Forms are remaining.
                handleNonFavMobileViewTabClick()
            }
        }
    }

    const handleSingleFormDeleteSuccess = (res) => {
        if (res.result) {
            dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
            isMobileOnly ? dispatch({
                type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL,
                payload: false
            }) : null;

            if (isMobileOnly) {

                const favFormDeleted = favFormsList.find(favForm => favForm._id === deletableFormId)
                removeDeletedFormDataInFormsList(deletableFormId);
                const favFormsLength = formsList.filter((formData) => formData.date_favoured).length
                if (favFormDeleted) {
                    if (favFormsLength === 0) {
                        // No more Favourite Forms are remaining.
                        handleNonFavMobileViewTabClick()
                    }
                }
            } else {
                removeDeletedFormDataInFormsList(deletableFormId);
            }
        }
    }

    const handleMultipleFormDelete = (userId, itemIds, successFunction, failureFunction) => {
        if (itemIds) {
            deleteManyForms(userId, itemIds, (res) => {
                if (res.result) {
                    successFunction(itemIds);
                }
            }, (err) => {
                failureFunction(err)
            });
        }
    }

    const setSortOrder = (sortOrder) => {
        dispatch({
            type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST,
            payload: sortForms(JSON.parse(JSON.stringify(formsList)), sortOrder)
        })
    }

    const favouriteClicked = (formId, dateFavouredValue) => {
        setFormFavouriteStatus(formId, dateFavouredValue, (responseData) => {
            if (responseData) {
                if (responseData.result === '') {
                    // 'favFormsList.filter(favForm => favForm._id !== formId)' is used twice below
                    // because of the delay with the useState operation setFavFormsList() updating favFormsList
                    if (favFormsList.filter(favForm => favForm._id !== formId).length === 0) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: true})
                    }

                    // 'date_favoured' has being removed. Remove the Form from favFormsList.
                    registerGAEvent('Form-Dashboard', 'fav-changed', 'removed')
                    setFavFormsList(sortFavForms(favFormsList.filter(favForm => favForm._id !== formId)))

                    // Remove 'date_favoured' from the Form property.
                    let formToUpdate = formsList.find(form => form._id === formId)
                    let updatedFormsList = formsList.filter(form => form._id !== formId).map(form => ({...form}));

                    delete formToUpdate['date_favoured']

                    updatedFormsList.push(formToUpdate)
                    updateFormsList(sortForms(updatedFormsList, sortFields.DATE_EDITED))
                } else {
                    // 'date_favoured' has being added. Add the Form to favFormsList.
                    registerGAEvent('Form-Dashboard', 'fav-changed', 'added')

                    let formToUpdate = formsList.find(form => form._id === formId)
                    let updatedFavFormsList = favFormsList.map(form => ({...form}));
                    formToUpdate.date_favoured = responseData.result
                    updatedFavFormsList.push(formToUpdate)
                    setFavFormsList(sortFavForms(updatedFavFormsList))
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: false})
                }
            }
        }, (error) => {
            console.log(error)
        });
    }

    const sortFavForms = (favForms) => {
        return sortForms(favForms, sortFields.DATE_FAVOURED)
    }

    if (isLoading) return <Loader/>
    if (formsList === undefined || formsList == null) return <DataUnavailableComponent
        data={t(translationKeys.forms_server_is_not_available)}/>
    if (formsList.length === 0) return <DataUnavailableComponent data={t(translationKeys.no_form_available)}/>
    if (!formsSectionShouldBeVisible(formsList) && !formsSectionShouldBeVisible(favFormsList, true)) return (
        <DataUnavailableComponent data={t(translationKeys.searched_form_not_found)}/>)

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }
    return (
        <>
            {
                (() => {

                    if (isMobileOnly)
                        return (// ************************** Mobile Version ************************** //
                            <>
                                <div className={'forms-container-mobile'}
                                     style={isShowingMobileFormControl ? {marginBottom: '0'} : null}>
                                    {isShowingMobileFormControl ?
                                        <div className={'dashboard-content-container-mobile'}
                                             style={{padding: '0'}}>
                                            <MobileFormControlContainer
                                                deleteFormInFormList={removeDeletedFormDataInFormsList}
                                                updateStatus={updateFormStatusInFormsList}
                                                showDataEditWarning={props.showDataEditWarning}
                                                favouriteClicked={favouriteClicked}
                                            />
                                        </div> :
                                        <div className='cards-container cards-container-width'>
                                            {
                                                (() => {
                                                    if (formsList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length === 0) {
                                                        return <DataUnavailableComponent
                                                            data={t(translationKeys.no_form_available)}
                                                            setTemplateType={props.setTemplateType}
                                                            showPage={props.showPage}
                                                        />
                                                    } else return getFavForms().concat(getNonFavForms())
                                                })()
                                            }
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    else {
                        return (
                            // ************************** Desktop Version ************************** //
                            <>
                                <div className='forms-container'>
                                    {
                                        (() => {
                                                let forms = getFavForms().concat(getNonFavForms())

                                                if (formsSectionShouldBeVisible(formsList, true)) return (
                                                    <div className={'dashboard-content-container'}
                                                         style={{paddingBottom: showMultipleDeleteOption ? '70px' : null}}>
                                                        <div
                                                            className='cards-container cards-container-width'>
                                                            <>
                                                                <ContentHeader
                                                                    setSortOrder={getNonFavForms()?.length ? setSortOrder : null}
                                                                    previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                                                    isQuiz={false}
                                                                    formCount={forms ? forms.length : 0}
                                                                    updateDataList={updateFormsList}
                                                                />
                                                                {listView ? <div style={{
                                                                    display: "flex",
                                                                    width: "-webkit-fill-available",
                                                                    margin: "auto 75px"
                                                                }}>
                                                                    <h2 style={{
                                                                        width: "48%",
                                                                        fontSize: "16px",
                                                                        textAlign: "start"
                                                                    }}>{t(translationKeys.title).toUpperCase()}</h2>
                                                                    <h2 style={{
                                                                        width: "10%",
                                                                        fontSize: "16px",
                                                                        textAlign: "start"
                                                                    }}>{t(translationKeys.responses).toUpperCase()}</h2>
                                                                    <h2 style={{
                                                                        width: "80px",
                                                                        fontSize: "16px",
                                                                        textAlign: "center"
                                                                    }}>{t(translationKeys.status).toUpperCase()}</h2>
                                                                </div> : null}
                                                                {forms}
                                                            </>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()
                                    }
                                </div>
                            </>
                        )
                    }
                })()
            }
            {
                multipleFormsSelectedArray.length > 0 && !isMobileOnly ?
                    <BottomAlertContainer positiveButtonClick={multipleDeleteButtonClick}
                                          negativeButtonClick={multipleDeleteCancelButtonClick}
                                          negativeButtonText={t(translationKeys.cancel)}
                                          positiveButtonText={t(translationKeys.delete)}
                                          selectedDataType={t(translationKeys.form)}
                                          selectedDataArray={multipleFormsSelectedArray}
                    /> : null
            }
            {
                showDeletePopUp ?
                    <ModalDialog
                        imageIcon={isMobileOnly ? deleteIcon : null}
                        header={t(translationKeys.delete).toUpperCase() + ' ' + (multipleFormsSelectedArray.length > 0 ? multipleFormsSelectedArray.length > 1 ? multipleFormsSelectedArray.length + ' ' + t(translationKeys.forms) : t(translationKeys.form) : t(translationKeys.form))}
                        body={[multipleFormsSelectedArray.length > 1 ? t(translationKeys.do_you_want_to_delete_these) + ' ' + multipleFormsSelectedArray.length + ' ' + t(translationKeys.forms) + '?' : t(translationKeys.do_you_want_to_delete_this_form) + '?',
                            showResponseDeleteAlert(multipleFormsSelectedArray, formsList, deletableFormId) ? t(translationKeys.all_responses_will_also_be_deleted) : null, t(translationKeys.this_action_cannot_be_undone)]}
                        buttons={
                            [
                                {
                                    text: t(translationKeys.cancel).toUpperCase(),
                                    action: () => dispatch({
                                        type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP,
                                        payload: false
                                    })
                                }, {
                                text: (props.shared ? t(translationKeys.leave).toUpperCase() : t(translationKeys.delete).toUpperCase()) + (multipleFormsSelectedArray.length ? " (" + multipleFormsSelectedArray.length + ")" : ""),
                                buttonTextColor: '#E02424',
                                action:
                                    () => {
                                        if (multipleFormsSelectedArray.length > 0) {
                                            handleMultipleFormDelete(userId, multipleFormsSelectedArray, handleMultipleFormDeleteSuccess, (err) => {
                                                console.log(err);
                                                alert(t(translationKeys.something_went_wrong));
                                            })
                                        } else if (deletableFormId) {
                                            deleteForm(deletableFormId, handleSingleFormDeleteSuccess, e => console.log(e))
                                        }

                                    }
                            }
                            ]
                        }
                    /> : null
            }
            {/*** share popup ***/}
            {showSharePopUp ?
                <div style={popUpStyle}>
                    <ShareCard isQuiz={false}
                               formId={shareFormId}
                               passcode={formData?.setting?.passcode}/>
                </div> : null}
            {/*** collaborator popup ***/}
            {showCollaboratePopUp ?
                <div style={popUpStyle}>
                    <CollaborateCard updateCollabIcons={updateCollabIcons}/>
                </div> : null}
            {/*{toast message}*/}
            {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}

        </>
    )

}


export default FormsContainer
