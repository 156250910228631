// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ZpYmt1427SoC4mCKJr5M1 {\n    width: 96%;\n    display: flex;\n    justify-content: center;\n    list-style: none;\n    border: 1px solid #fff;\n    background-color: #fff;\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n    padding: 5px 0;\n    margin-top: 0;\n}\n\n._3yiESgIV4qQSlIZXoJEDIz {\n    position: relative;\n}\n\nul {\n    margin: 0;\n}\n\n._3avq9jyhFuja1fjr1alE47, ._2-HVsod0ASzDimTLFAFOHD, ._23lED5UC52V8yMTSQkL7DP {\n    border: 1px solid transparent;\n    border-radius: 5px;\n    outline: none;\n    display: flex;\n    align-items: center;\n    color: #1976d2;\n}\n\n._3avq9jyhFuja1fjr1alE47:hover, ._2-HVsod0ASzDimTLFAFOHD:hover, ._23lED5UC52V8yMTSQkL7DP:hover {\n    color: #fff;\n    background-color: #1976d2;\n    cursor: pointer;\n}\n\n._17WJoWiD-mx81JKiwFOnkH {\n    visibility: hidden;\n}\n\n._5CRfVfRsUxktgNMpAMVNS {\n    font-weight: bolder;\n}\n\n._3avq9jyhFuja1fjr1alE47 a, ._2-HVsod0ASzDimTLFAFOHD a, ._23lED5UC52V8yMTSQkL7DP a {\n    outline: none;\n    padding: 5px 10px;\n}\n\n\n@media screen and (max-width: 600px) {\n    ._1ZpYmt1427SoC4mCKJr5M1 {\n        padding: 10px;\n        width: 94%;\n    }\n\n    ._2-HVsod0ASzDimTLFAFOHD {\n        border-radius: 0;\n    }\n\n    ._3avq9jyhFuja1fjr1alE47, ._23lED5UC52V8yMTSQkL7DP {\n        border-radius: 0;\n        border-right-color: transparent;\n    }\n\n    ._3avq9jyhFuja1fjr1alE47 a, ._2-HVsod0ASzDimTLFAFOHD a, ._23lED5UC52V8yMTSQkL7DP a  {\n        padding: 5px;\n        font-size: 12px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"pagination_container": "_1ZpYmt1427SoC4mCKJr5M1",
	"paginator_outer_wrapper": "_3yiESgIV4qQSlIZXoJEDIz",
	"each_page": "_3avq9jyhFuja1fjr1alE47",
	"next_button": "_2-HVsod0ASzDimTLFAFOHD",
	"previous_button": "_23lED5UC52V8yMTSQkL7DP",
	"hide_button": "_17WJoWiD-mx81JKiwFOnkH",
	"active": "_5CRfVfRsUxktgNMpAMVNS"
};
module.exports = exports;
